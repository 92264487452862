import jwtDecode from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { _auth } from "../modules/_auth";
import { Connections } from "../modules/connections";
import { RadioGroupSingle } from "./projects/components/DataEntry";
import Button from "../elements/Button";
import CenterPageWithButton from "../components/CenterPageWithButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import CenterCard from "../components/CenterCard";
import StackedList from "../components/StackedList";
import StackedListButtons from "../components/StackedListButtons";
import { set } from "date-fns";

const AcceptBusinessConnectionPage = () => {
  const history = useHistory();
  const profile = _auth.getUserProfile();
  const { token } = useParams();
  const decoded = jwtDecode(token);
  console.log("decoded", decoded);
  const [selectedTenant, setSelectedTenant] = useState();
  const [accepted, setAccepted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [tenantList, setTenantList] = useState(profile.tenants);
  // const [options, setOptions] = useState([]);

  //decode the jwt token

  // useEffect(() => {
  //   setTenantList(profile.tenants);
  // }, []);

  useEffect(() => {
    if (profile.tenants.length > 1) {
      //display a dropdown to select the tenant
      setSelectedTenant(profile.tenantId);
    } else {
      setSelectedTenant(profile.tenantId);
    }
  }, []);
  // useEffect(() => {
  //   const updatedTenantList = getTenantList();
  //   setOptions(updatedTenantList);
  // }, [selectedTenant]);

  const options = useMemo(() => {
    return tenantList.map((tenant) => {
      return {
        id: tenant._id,
        title: tenant.tenantId.tenantTitle,
        description: tenant.description,
        selected: selectedTenant === tenant._id,
        handleClick: (id) => {
          console.log("Clicked on", id);
          setSelectedTenant(id);
        },
      };
    });
  }, [tenantList, selectedTenant]);

  useEffect(() => {
    console.log("OPTIONS CHANGED", options);
  }, [options]);

  const acceptConnection = async () => {
    setLoading(true);
    let connectionInfo = {
      connectionId: decoded.connection._id,
      tenantId: selectedTenant,
    };
    let accepted = await Connections.acceptConnection(
      connectionInfo,
      decoded.tenantId //requestor tenant id
    );
    if (accepted) {
      //redirect to the dashboard
      setAccepted(true);
    } else {
      //display an error message
      setAccepted(false);
    }
    setLoading(false);
  };
  const getTenantList = () => {
    const list = tenantList.map((tenant) => {
      return {
        id: tenant._id,
        title: tenant.tenantTitle,
        description: tenant.description,
        selected: selectedTenant === tenant._id,
        handleClick: (id) => {
          console.log("Clicked on", id);
          setSelectedTenant(id);
          const updatedTenantList = options.map((t) => {
            const isSelected = t.id.toString() === id.toString();
            console.log(`Option id: ${t.id}, Selected: ${isSelected}`);
            return {
              ...t,
              selected: isSelected,
            };
          });

          // setOptions(updatedTenantList);
        },
      };
    });
    return list;
  };
  return (
    <div className="px-6 w-full h-full">
      {accepted ? (
        <div className="flex flex-col h-full items-center justify-center m-auto">
          <CenterPageWithButton
            icon={
              <Icon
                icon="flat-color-icons:ok"
                className="flex h-[42px] w-[42px]"
              />
            }
            title="Success!"
            description="Congratulations! You've successfully connected to {Company}."
            showButton={true}
            buttonText="Go to Dashboard"
            onClick={(e) => {
              history.push("/dashboard");
            }}
          />
        </div>
      ) : (
        <div>
          <CenterPageWithButton
            icon={
              <Icon
                icon="carbon:collaborate"
                className="flex h-[42px] w-[42px]"
              />
            }
            title="New Connection Invitation"
            description="You've been invited to collaborate with a business partner!"
            showButton={true}
            buttonText="Decine Invitation"
            buttonColor="white"
            onClick={(e) => {
              console.log("Decline Invitation");
            }}
            showSecondButton={true}
            secondButtonText="Accept Invitation"
            secondButtonColor="secondary"
            secondButtonOnClick={(e) => {
              acceptConnection();
              console.log("Accept Invitation");
            }}
            body={
              profile.tenants.length > 1 && (
                <div className="w-full space-y-6">
                  <div className="text-left text-md font-md text-slate-600">
                    Select the workspace you want to use to connect with{" "}
                    {decoded.tenantTitle}.
                  </div>
                  <StackedListButtons options={options} />
                </div>
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default AcceptBusinessConnectionPage;
