import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";
{
  /*
Route.post("/comments/add", auth, (req, res) =>
  comments_controller.addComment(req, res)
);
Route.get("/comments/get", auth, (req, res) =>
  comments_controller.getComments(req, res)
);
Route.post("/comments/delete", auth, (req, res) =>
  comments_controller.deleteComment(req, res)
);
Route.post("/comments/edit", auth, (req, res) =>
  comments_controller.editComment(req, res)
);
Route.post("/comments/addReply", auth, (req, res) =>
  comments_controller.addReply(req, res)
);
Route.post("/comments/addThanks", auth, (req, res) =>
  comments_controller.addThanks(req, res)
);
Route.post("/comments/removeThanks", auth, (req, res) =>
  comments_controller.removeThanks(req, res)
);
Route.post("/comments/addReplyThanks", auth, (req, res) =>
  comments_controller.addReplyThanks(req, res)
);
Route.post("/comments/removeReplyThanks", auth, (req, res) =>
  comments_controller.removeReplyThanks(req, res)
);
Route.post("/comments/getReplyCountByComment", auth, (req, res) =>
  comments_controller.getReplyCountByComment(req, res)
);

Route.post("/comments/getThanksCountByComment", auth, (req, res) =>
  comments_controller.getThanksCountByComment(req, res)
);

Route.post("/comments/getThanksCountByReply", auth, (req, res) =>
  comments_controller.getThanksCountByReply(req, res)
);
*/
}

export const _comments = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(_auth.getGuestProfile());
    } else {
      return _auth.getUserProfile();
    }
  },
  async addComment(data) {
    try {
      let profile = _auth.profile();
      let token = _auth.getUserToken("jwtToken");
      if (!token) {
        token = profile.token;
      }
      console.log("addComment token", token);
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      data.tenantId = profile?.tenantId ? profile.tenantId : null;
      data.companyId = data.companyId
        ? data.companyId
        : profile?.tenantCompanyID
        ? profile.tenantCompanyID
        : null;
      data.userId = profile.user_id;
      data.name = profile.name;
      data.email = data.email ? data.email : profile.email;

      let response = await postRequest("comments/add", data);
      if (response && response.data) {
        console.log(
          "addComment response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      console.log("addComment error", e);
      return [];
    }
  },
  async likeOnComment(data, userId) {
    try {
      let profile = _auth.profile();
      let token = _auth.getUserToken("jwtToken");
      if (!token) {
        token = profile.token;
      }
      console.log("addComment token", token);
      console.log(profile);
      console.log(userId);
      data.userId =
        profile && profile.user_id ? profile.user_id : profile.userId;
      console.log(data, "check");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("comments/like", data);
      if (response && response) {
        console.log(
          "likeComment  response.data.data.data.result",
          response.data.data.result
        );
        return response.data.data.result;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
    }
  },
  async getCommentsById(data) {
    try {
      console.log("getCommentsById data", data);
      let profile = _auth.profile();
      let token = _auth.getUserToken("jwtToken");
      if (!token) {
        token = profile.token;
      }
      // setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      data.tenantId = profile?.tenantId ? profile.tenantId : null;
      data.companyId = profile?.tenantCompanyID
        ? profile.tenantCompanyID
        : null;
      data.userId = profile
        ? profile.user_id
          ? profile.user_id
          : profile.userId
        : "";
      data.name = profile?.name ? profile.name : "";

      let response = await postRequest("/comments/get-comments-by-id", data);
      if (response && response.data) {
        console.log(
          "getCommentsByImpediment response.data",
          response.data.data
        );
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      console.log("getCommentsByImpediment error", e);
      return [];
    }
  },
  async deleteComment(commentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/delete", data);
      if (response && response.data) {
        console.log(
          "deleteComment response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async editComment(commentId, comment) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        comment: comment,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/edit", data);
      if (response && response.data) {
        console.log(
          "editComment response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addReply(commentId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/addReply", data);
      if (response && response.data) {
        console.log(
          "addReply response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/addThanks", data);
      if (response && response.data) {
        console.log(
          "addThanks response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async removeThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/removeThanks", data);
      if (response && response.data) {
        console.log(
          "removeThanks response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addReplyThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/addReplyThanks", data);
      if (response && response.data) {
        console.log(
          "addReplyThanks response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async removeReplyThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/removeReplyThanks", data);
      if (response && response.data) {
        console.log(
          "removeReplyThanks response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getCommentCountByImpediment(impedimentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        impedimentId: impedimentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest(
        "/comments/getCommentCountByImpediment",
        data
      );
      if (response && response.data) {
        console.log(
          "getCommentCountByImpediment response.data.data.data",
          response.data.data.count
        );
        return response.data.data.count;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
  async getReplyCountByComment(commentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        commentId: commentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest(
        "/comments/getReplyCountByComment",
        data
      );
      if (response && response.data) {
        console.log(
          "getReplyCountByComment response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
  async getThanksCountByComment(commentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        commentId: commentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest(
        "/comments/getThanksCountByComment",
        data
      );
      if (response && response.data) {
        console.log(
          "getThanksCountByComment response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
  async getThanksCountByReply(replyId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        replyId: replyId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/getThanksCountByReply", data);
      if (response && response.data) {
        console.log(
          "getThanksCountByReply response.data.data.data",
          response.data.data.data
        );
        return response.data.data.data;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
};
