import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Preferences = {
  getPreference(preferences, preferenceIdToFind) {
    const foundPreference = preferences?.find(
      (preference) => preference?.id === preferenceIdToFind
    );

    if (foundPreference) {
      console.log(`Found preference: ${foundPreference}`);
    } else {
      console.log(`Preference ${preferenceIdToFind} not found`);
    }
    return foundPreference;
  },
  getPreferenceValue(preferences, preferenceIdToFind) {
    if (!preferences) {
      console.log(`Preferences array does not exist`);
      return true;
    }

    const foundPreference = preferences.find(
      (preference) => preference.id === preferenceIdToFind
    );

    if (foundPreference) {
      console.log(
        `Found preference value for ${preferenceIdToFind}: ${foundPreference.value}`
      );
      return foundPreference.value;
    } else {
      console.log(`Preference ${preferenceIdToFind} not found`);
      return true;
    }
  },
  async getDefaultPreferences() {
    let data = {};
    console.log("Call API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.user_id = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile?.tenantCompanyId && profile.tenantCompanyId;

    console.log("API body - preferences/get-default-preferences", data);
    let response = await postRequest(
      "preferences/get-default-preferences",
      data
    );
    if (response) {
      console.log(
        "Got preferences/get-default-preferences response",
        response.data.data
      );
      return response.data.data;
    } else {
      return [];
    }
  },
  async getUserPreferences() {
    let data = {};
    console.log("Call API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.user_id = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile?.tenantCompanyId && profile.tenantCompanyId;

    console.log("API body - preferences/get-user-preferences", data);
    let response = await postRequest("preferences/get-user-preferences", data);
    if (response) {
      console.log(
        "Got preferences/get-user-preferences response",
        response.data.data
      );
      return response.data.data;
    } else {
      return [];
    }
  },
  async setUserPreferences(updates) {
    let data = {};

    console.log("Call API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.user_id = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile?.tenantCompanyId && profile.tenantCompanyId;
    data.updates = updates;
    // data.updates.userId = profile.user_id;
    console.log("API body - preferences/set-preferences", data);
    let response = await postRequest("preferences/set-preferences", data);
    if (response) {
      console.log("Got preferences/set-preferences response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
};
