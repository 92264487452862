import { utils } from "./_utils";
import { postRequest, getRequest } from "./ApiRequest";
import { _auth } from "./_auth";
import setAuthToken from "../config/setAuthToken";
export const messaging = {
  async contact_us_email(params) {
    console.info("Received contact email params", params);
    //add company and user info

    try {
      const response = await postRequest("email", params);
      if (response && response.data && response.data.status === 200) {
        // history.push("/home")
        return response;
      } else {
        console.log("not running");
      }
    } catch (e) {
      console.log(e);
    }
  },
  async marketplace_email(params) {
    const profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    try {
      console.info("Received marketplace email params", params);
      params.tenantId = profile.tenantId;
      params.companyId = profile.companyId;
      params.clientName = profile.name;
      params.clientEmail = profile.email;
      params.clientCompany = profile.company;
      const response = await postRequest("messaging/email/marketplace", params);
      if (response) {
        console.log("messaging/email/marketplace", response);
        return response;
      } else {
        console.log("messaging/email/marketplace ERROR", response);
      }
    } catch (e) {
      console.log("messaging/email/marketplace ERROR try", e);
    }
  },
};
