/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";
import { Icon } from "@iconify/react";
import { Label } from "./Typography/FieldLabel";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import Tip from "./Tip";
import Button from "../elements/Button";
export default function VendorPublicLandingIntro(props) {
  const [checked, setChecked] = useState(false);
  const launch = props.launch || true;
  const newTerms = `You have been invited by the requestor to bid on this bid request
    through the Breachlink platform. To protect the requestor from
    unsolicited communication and to maintain the integrity of the
    bidding process, you must agree to the following terms and
    conditions before you can view this request:`;
  const termsAndConditions = [
    "This bid request is confidential and may not be shared with any other party.",
    "This bid request is for the sole purpose of submitting a bid through the Breachlink platform.",
    "Information contained in this bid request may not be used for any other purpose.",
    "You may not contact the requestor outside of the Breachlink platform regarding this request.",
    "You may not submit a bid outside of the platform for this project.",
    "After revewing the bid request, if you do not wish to submit a bid, you may Decline the request.",
    // "You may Decline the request if you do not wish to submit a bid.",
    // "If you do not agree to these terms, you may click the Decline button to exit the request.",
  ];

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <div id="landing-page" className="h-full overflow-y-auto scrollbar-default">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center ">
        {/* Left: Title */}
        <div className=" mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-2xl text-slate-800 font-medium flex items-center">
            {launch ? (
              <span className="flex items-center">
                {checked ? (
                  <Fade>
                    <Icon
                      icon="flat-color-icons:ok"
                      // icon="fluent-emoji-flat:middle-finger"
                      className="flex mr-2"
                    />
                  </Fade>
                ) : (
                  <Icon
                    icon="flat-color-icons:high-priority"
                    // icon="fluent-emoji-flat:middle-finger"
                    className="flex mr-2"
                  />
                )}
                Terms and Conditions
              </span>
            ) : (
              <span className="flex items-center">
                <Icon icon="fluent-emoji-flat:bell" className="flex mr-2" />
                Important Changes Coming Soon
              </span>
            )}
          </h1>
        </div>
      </div>

      {launch ? (
        <div>
          <p className="mt-4 ">{newTerms}</p>
          <div className="mt-4 text-base ">
            <ul className="list-disc list-inside space-y-4 pl-4">
              {termsAndConditions.map((term) => (
                <li key={term} className="">
                  {term}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-8 text-base ">
            <p>
              By continuing to view this bid request, you are agreeing to the
              terms and conditions outlined above.
            </p>
          </div>

          <div className="py-2 text-base ">
            <div className="p-2">
              <input
                id={"agreeNotToBidOutsidePlatform"}
                name={"agreeNotToBidOutsidePlatform"}
                type="checkbox"
                checked={checked}
                onChange={props.handleCheckboxClick}
                defaultChecked={checked}
                defaultValue={false}
                value={checked}
                className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
              />
              <span
                className="inline-block mr-2 ml-2"
                sm
                htmlFor={"agreeNotToBidOutsidePlatform"}
              >
                I agree to these terms and conditions and wish to view this bid
                request.
              </span>
            </div>
            <Tip title="Why do I need to agree to these terms?">
              {" "}
              Please note that before submitting a bid, you must agree to our
              recently updated payment terms. To view the new terms, proceed to
              the bid request and click the "View Payment Terms" button.
            </Tip>
          </div>
        </div>
      ) : (
        <div className=" pb-12 pr-2 mx-auto text-left text-base h-full overflow-y-auto scrollbar-default ">
          <p className="mt-4  ">
            We want to start by saying thank you for being part of our journey.
            We founded Breachlink on a committment to creating opportunities and
            fostering fairness for both vendors and consumers. We truly hope
            that your experience with the Breachlink platform has opened up some
            exciting opportunities.
          </p>

          <p className="mt-4  ">
            To ensure a level playing field for all vendors and sustain our
            commitment to innovation, we're implementing some upcoming changes
            to our pricing model and terms and conditions.
          </p>
          <p className="mt-4  ">
            <strong>What's changing?</strong>
          </p>
          <p className="mt-4  ">
            We're transitioning to a commission-based structure for won bids.
            This means that instead of paying a fee to receive leads, you'll pay
            a commission on bids you win, allowing us to continue providing a
            valuable service while investing in new features to help you win
            more bids.
          </p>
          <p className="mt-4  ">
            Second, we're introducing an optional tiered subscription approach
            that empowers you to choose the features that best enable you to put
            your best foot forward.
          </p>
          <p className="mt-4  ">
            Additionally, we're introducing add-on features tailored for those
            who want to enhance their visibility. These add-ons will be
            available to subscribers who are looking to stand out and be
            discovered.
          </p>
          <p className="mt-4  ">
            Whether your aim is to win the bids you receive or to be discovered
            by those seeking your services, we have a plan designed to help you
            achieve your goals.
          </p>
          <p className="mt-4  ">
            Finally, we're updating our terms and conditions to ensure the
            integrity of the bidding process. These updates will govern how bids
            are submitted and accepted on the platform.
            {/* At launch, vendors who receive a bid
          request will need to agree not to view the request and then submit a
          bid outside of the platform. Similarly, requestors will be restricted
          from accepting bids from vendors outside of the platform if those
          vendors were included in a bid request through Breachlink. These
          changes are aimed at maintaining the integrity of our platform and
          fostering fair competition among all participants. accepting bids from
          vendors outside of the platform if those vendors were included in a
          bid request through Breachlink. These changes are aimed at maintaining
          the integrity of our platform and fostering fair competition among all
          participants. */}
          </p>

          <p className="mt-4  ">
            We'll be sharing more details in the weeks to come. If you're
            curious about the benefits of our new features or have any
            questions, don't hesitate to reach out. We're here to support your
            success on Breachlink.
          </p>
          {/* <p className="mt-4  ">
            We will ask you for your agreement to these terms each time you are
            invited to bid on a project. If you would like to learn more about
            the value our subscriptions provide, please reach out to us!
          </p>
          <p className="mt-4  ">
            You many continue without selecting this box if you would like to
            review the bid or decline the request. If you would like to come
            back to this page later, you can do so by clicking the link in the
            invitation email.
          </p>
          <p className="mt-4  ">If you agree, please check the box below. </p> */}
        </div>
      )}
      {/* <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-md leading-6 font-medium text-slate-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base ">{feature.description}</dd>
            </div>
          ))}
        </dl> */}
      {/* <p className="mt-4  ">
          You many continue without selecting this box if you would like to
          review the bid or decline the request. If you would like to come back
          to this page later, you can do so by clicking the link in the
          invitation email.
        </p> */}
    </div>
  );
}
