import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Transition from "../../utils/Transition";
import { _navigation } from "../../modules/_navigation";
import { audit } from "../../modules/_audit";
import { _auth } from "../../modules/_auth";
import { FeatureFlag } from "../../components/FeatureFlag";
import { Icon } from "@iconify/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function HeaderLinks(props) {
  const navigation = props.navigation;
  const location = useLocation();
  const { pathname } = location;
  // const [navigation, setNavigation] = useState();
  const [showHome, setShowHome] = useState();
  // const [navigation, setNavigation] = useState([]);
  // useEffect(() => {
  //   setShowHome(props.showHome);
  // }, [props.showHome]);

  // useEffect(() => {
  //   const nav = _navigation.getHeaderLinks(props.showHome);
  //   console.log(
  //     "navigation in headerlinks ORIGINAL",
  //     JSON.stringify(props.navigation)
  //   );
  //   setNavigation(nav);
  // }, []);

  // useEffect(() => {
  //   console.log(
  //     "navigation in headerlinks changed",
  //     JSON.stringify(navigation)
  //   );
  // }, []);

  const typeIcon = (type) => {
    switch (type) {
      case "home":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        );
      case "dashboard":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            className="shrink-0 h-4 w-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
            />
          </svg>
        );

      case "marketplace":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
            />
          </svg>
        );
      case "support":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="shrink-0 h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        );
    }
  };

  return (
    <div className=" sm:ml-6 sm:flex sm:space-x-4">
      <nav className=" lg:py-2 lg:flex lg:space-x-2" aria-label="Global">
        {navigation &&
          navigation.length > 0 &&
          navigation.map(
            (item) =>
              item.show && (
                //   (item.show === false &&
                //     item.status === "beta" &&
                //     process.env !== "production")) && (
                <FeatureFlag featureName={item.name} type="nav">
                  <Link
                    exact
                    // onClick={() => {
                    //   audit.logAction("View " + item.name);
                    // }}
                    to={
                      item.status
                        ? item.status == "coming soon"
                          ? "#"
                          : item.href
                        : item.href
                    }
                    aria-current={item.current ? "page" : undefined}
                    // className={`block text-slate-900 hover:text-primary-500 truncate transition duration-150 ${pathname === item.href && 'text-primary-500 hover:text-primary-500 border-primary-500'}`}
                    className={classNames(
                      pathname.includes(item.href) //=== item.href
                        ? "text-brand-600"
                        : item.status === "coming soon"
                        ? null
                        : "text-slate-600  hover:text-brand-700", //hover:bg-gray-100
                      item.status === "coming soon"
                        ? "text-slate-400 hover:text-slate-400 hover:bg-white cursor-not-allowed"
                        : null,
                      "rounded-md py-2 px-3 inline-flex items-center text-sm font-medium hover:bg-brand-50"
                    )}
                  >
                    <div className="flex items-center">
                      {item.iconify ? (
                        <Icon icon={item.iconify} className="flex w-4 h-4" />
                      ) : item.icon ? (
                        item.icon && <span>{typeIcon(item.icon)}</span>
                      ) : null}

                      <span className=" text-sm font-medium ml-2  duration-200">
                        {" "}
                        {/*hidden sm:ml-6 sm:flex sm:space-x-8*/}
                        {item.name}
                        <span className="hidden  xl:inline-flex 2xl:inline-flex">
                          {item.status == "coming soon" ? (
                            <span className="ml-2  rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-slate-400">
                              Coming Soon
                            </span>
                          ) : item.status == "beta" ? (
                            <span className="ml-2 rounded-full bg-primary-100 px-2 text-xs font-semibold leading-5 text-primary-500">
                              BETA
                            </span>
                          ) : item.status == "preview" ? (
                            <span className="ml-2   rounded-full bg-primary-100 px-2 text-xs font-semibold leading-5 text-primary-500">
                              Preview
                            </span>
                          ) : null}
                        </span>
                      </span>
                    </div>
                  </Link>
                </FeatureFlag>
              )
          )}
      </nav>
    </div>
  );
}

export default HeaderLinks;
