/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState, useEffect, useMemo } from "react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  ChevronDownIcon,
  FunnelIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Combobox } from "@headlessui/react";
import QuestionToolTip from "./QuestionToolTip";

// const people = [
//   { id: 1, name: "Leslie Alexander" },
//   // More users...
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ComboboxSelector(props) {
  const showDescription = props.showDescription ? props.showDescription : false;
  const divider = props.divider ? props.divider : false;
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(
    props.value ? props.value : []
  );
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [options, setOptions] = useState([]);
  const [people, setPeople] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [textbutton, settextbutton] = useState(props.textbutton);
  const [addService, setAddService] = useState(props.addService);

  useMemo(() => {
    setPeople(props.options);
  }, []);

  useEffect(() => {
    if (textbutton || props.fieldType == "single") {
      setSelectedPerson(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.fieldType == "multiSelect") {
      setSelectedPerson(props.value);
    }
  }, [props.value]);

  // useEffect(() => {
  //   if (textbutton || props.fieldType == "multiSelect") {
  //     console.log("ComboboxSelector multi useEffect", props.value);
  //     setSelectedPerson(props.value);
  //   }
  // }, [props.value]);

  useEffect(() => {
    if (props.fieldType == "multiSelect") {
      console.log("ComboboxSelector multi useEffect", props.name, props.value);
    }
  }, []);

  const handleClearAllSelections = () => {
    setSelectedPerson([]);
    setSelectedPeople([]);
    props.handleClearAllSelections();
  };

  const handleSingleSelect = (person) => {
    console.log(
      "handleSingleSelect",
      person.name,
      "selectedPerson",
      selectedPerson
    );
    if (isSelected(person.name)) {
      // setSelectedPerson((prev) => prev.filter((p) => p.name !== person.name));
      handleDeselect(person.name);
    } else {
      setSelectedPerson(person.name);
    }
    setIsOpen(true);
    props.handleSelect(person.name);
  };

  const handleMultiSelect = (persons) => {
    if (persons && persons.length > 0) {
      for (let index = 0; index < persons.length; index++) {
        const person = persons[index];
        if (person && person.name) {
          if (isSelected(person.name)) {
            // setSelectedPerson((prev) => prev.filter((p) => p.name !== person.name));
            handleDeselect(person.name);
            // props.handleDeselect(person.name);
          } else {
            console.log("handleMultiSelect", selectedPerson);
            props.handleSelect(person.name);
            setSelectedPerson((prev) => {
              if (prev && prev.length > 0) {
                return [...prev, person.name];
              } else {
                return [person.name];
              }
            });
          }
          setIsOpen(true);
          // props.handleSelect(person.name);
        }
      }
    }
  };
  function isSelected(value) {
    let selected;
    if (props.fieldType == "single") {
      selected = selectedPerson == value ? true : false;
    } else {
      selected =
        selectedPerson && selectedPerson.length > 0
          ? selectedPerson.find((el) => el === value)
            ? true
            : false
          : false;
    }

    // console.log("isSelected", selected, value, selectedPerson);
    return selected;
  }
  function handleDeselect(value) {
    const selectedPersonsUpdated = selectedPerson.filter((el) => el !== value);
    console.log("handeSelect deSelect", selectedPersonsUpdated);
    setSelectedPerson(selectedPersonsUpdated);
    setIsOpen(true);
    props.handleDeselect(value);
  }

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          console.log("Person", person, query);
          return (
            person &&
            person.name &&
            person.name
              .toString()
              .toLowerCase()
              .includes(query.toString().toLowerCase())
          );
        });

  const getButton = () => {
    let buttonType = props.buttonType;
    return (
      (buttonType && buttonType == "addService" && (
        <div className="flex items-center text-brand-500">
          <PlusIcon className="h-4 w-4 mr-2" /> Select item
        </div>
      )) ||
      (buttonType && buttonType == "dropdown" && (
        //i need to distinguish between required and not required
        //so I can make required red
        <div className="flex items-center ">
          {selectedPerson ? selectedPerson : "Select"}
          <ChevronDownIcon className="h-4 ml-2" />{" "}
        </div>
      )) ||
      (buttonType && buttonType == "unit" && (
        //i need to distinguish between required and not required
        //so I can make required red
        <div className="flex items-center">
          {selectedPerson ? (
            selectedPerson
          ) : (
            <span className="text-red-500">
              {" "}
              {`Select ${props.buttonType}`}
            </span>
          )}
          <ChevronDownIcon className="h-4 ml-2" />{" "}
        </div>
      )) ||
      (buttonType && buttonType == "interval" && (
        <div className="flex items-center  ">
          {selectedPerson ? (
            selectedPerson
          ) : (
            <span className="text-red-500">
              {" "}
              {`Select ${props.buttonType}`}
            </span>
          )}
          <ChevronDownIcon className="h-4 ml-2" />{" "}
        </div>
      )) ||
      (buttonType && buttonType == "line_item_divider" && (
        <div>
          <div
            className="absolute inset-0 flex items-center px-3"
            aria-hidden="true"
          >
            <div id="divider" className="w-full border-t border-gray-200 " />
          </div>

          <div className="my-6 items-center text-brand-500 relative flex justify-center">
            <button
              type="button"
              // onClick={() => {
              //   handleClick();
              // }}
              className={`inline-flex items-center rounded-full border   shadow-sm
          bg-primary-700 text-white
          border-gray-300 px-4 py-1.5 text-sm font-medium leading-5  
        
          hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-primary-500 
             
          hover:bg-primary-500  hover:border-primary-500 
          focus:outline-none focus:ring-1 focus:ring-primary-500 `}
            >
              <PlusIcon className="-ml-1.5 mr-1 h-5 w-5 " aria-hidden="true" />
              Add Line Item
            </button>
          </div>
        </div>
      )) ||
      (buttonType && buttonType == "line_item" && (
        <div className="flex items-center text-brand-500">
          <PlusIcon className="h-4 w-4 mr-2" /> Add Line Item
        </div>
      )) ||
      (buttonType == "pricing_item" && (
        <div className="flex items-center text-red-500 font-semibold">
          <PlusIcon className="h-4 w-4 mr-2" /> Line Item Name
        </div>
      )) ||
      (buttonType == "unit" && (
        <div className="flex items-center text-brand-500">
          <PlusIcon className="h-4 w-4 mr-2" /> How are you charging?
        </div>
      )) || (
        <div className="flex items-center font-semibold">
          <PlusIcon className="h-4 w-4 mr-2" /> Add Service
        </div>
      )
    );
  };

  return (
    <Combobox
      as="div"
      className={"w-full"}
      value={selectedPerson}
      open={true}
      // disabled={
      //   props.fieldType &&
      //   props.fieldType == "single" &&
      //   selectedPerson.length > 0
      // }
      onChange={(value) => {
        setIsOpen(true);
        props.fieldType && props.fieldType == "single"
          ? handleSingleSelect(value)
          : handleMultiSelect(value);
      }}
      multiple={props.fieldType == "multiSelect" ? true : false} // for multi select
    >
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Assigned to
      </Combobox.Label> */}
      <div className="relative ">
        <div classnName="flex ">
          {divider && (
            <div
              className="absolute inset-0 flex items-center px-3"
              aria-hidden="true"
            >
              <div id="divider" className="w-full border-t border-gray-200 " />
            </div>
          )}
          <Combobox.Button className="w-full">
            {textbutton ? (
              getButton()
            ) : (
              <Combobox.Input
                className="w-full flex-1 rounded-md border-0 bg-white  pl-3 pr-10 text-gray-900  
            ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                // className=" w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(person) => person?.name}
                disabled={
                  // true
                  props.fieldType && props.fieldType == "single"
                  // &&
                  // selectedPerson !== "" &&
                  // selectedPerson !== null &&
                  // selectedPerson !== undefined
                }
                placeholder={
                  props.fieldType == "single"
                    ? selectedPerson
                      ? selectedPerson
                      : "Select one"
                    : selectedPerson
                    ? selectedPerson.length > 0
                      ? selectedPerson.length + " selected"
                      : "Select one or more"
                    : "Select one or more"
                }
              />
            )}
          </Combobox.Button>
          {!textbutton && (
            <div className="absolute inset-y-0 right-10 flex items-center">
              {selectedPerson && selectedPerson.length > 0 && (
                <XMarkIcon
                  className="cursor-pointer inline h-5 w-5 text-gray-400"
                  onClick={() => {
                    handleClearAllSelections();
                  }}
                />
              )}
            </div>
          )}
          {!textbutton && (
            <div className="absolute inset-y-0 right-20 flex items-center">
              {query !== "" && (
                <FunnelIcon
                  className="cursor-pointer inline h-4 w-4 text-gray-400"
                  onClick={() => {
                    setQuery("");
                  }}
                />
              )}
            </div>
          )}
        </div>
        {/* {people && people.length > 2 ? ( */}
        {!textbutton && (
          <Combobox.Button className=" absolute inset-y-0 right-0 flex  items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        )}

        {filteredPeople.length > 0 && (
          <Combobox.Options
            className="absolute flex-1 z-10 mt-1 max-h-60 min-w-96 overflow-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
          scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredPeople.map((person) => {
              const selected = isSelected(
                person.name ? person.name : person.value
              );
              return (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-brand-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold",
                          person.description && "font-semibold"
                        )}
                      >
                        {person.name}{" "}
                        {person.showTooltip && (
                          <QuestionToolTip
                            color={
                              //white on hover, otherwise secondary
                              active ? "white" : "secondary"
                            }
                            field={person.name}
                            text={person.tooltipText}
                          />
                        )}
                      </span>

                      {person.description && (
                        <div
                          className={classNames(
                            "block ",

                            person.description &&
                              "mt-1 flex items-center text-sm text-slate-500",
                            active ? "text-white" : "text-slate-500"
                          )}
                        >
                          {person.description}
                        </div>
                      )}
                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-brand-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
