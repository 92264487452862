import React, { useState, useEffect, useRef } from "react";
import PreferencesSection from "../../components/Preferences/PreferenceSection";
import { Preferences } from "../../modules/_user_preferences";

function PreferencesPage({ group }) {
  const [userPreferences, setUserPreferences] = useState([]);
  const [defaultPreferences, setDefaultPreferences] = useState([]);
  const timerRef = useRef(null);
  const prevPreferencesRef = useRef(JSON.stringify({}));

  useEffect(() => {
    (async () => {
      let response = await Preferences.getDefaultPreferences();
      console.log("getDefaultPreferences response", response);
      if (response) {
        setDefaultPreferences(response);
      } else {
        setDefaultPreferences([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let response = await Preferences.getUserPreferences();
      console.log("getUserPreferences response", response);
      if (response) {
        setUserPreferences(response);
      } else {
        setUserPreferences([]);
      }
    })();
  }, []);

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(async () => {
      if (prevPreferencesRef.current !== JSON.stringify(userPreferences)) {
        console.log("Preferences changed, updating", userPreferences);
        let response = await Preferences.setUserPreferences({
          preferences: userPreferences,
        });
        console.log("setUserPreferences response", response);
        prevPreferencesRef.current = JSON.stringify(userPreferences);
      }
    }, 750);

    return () => clearTimeout(timerRef.current);
  }, [userPreferences]);

  const handleUpdatePreferences = (id, value) => {
    let newUserPreferences = [...userPreferences];
    let index = newUserPreferences.findIndex((obj) => obj.id === id);
    if (index === -1) {
      newUserPreferences.push({ id: id, value: value });
    } else {
      newUserPreferences[index].value = value;
    }
    setUserPreferences(newUserPreferences);
  };

  const groups = [
    ...new Set(defaultPreferences.map((preference) => preference.group)),
  ];

  return (
    <>
      {groups.map((group) => (
        <PreferencesSection
          key={group}
          title={group}
          bottomDivider={false}
          options={defaultPreferences
            .filter((preference) => preference.group === group)
            .map((preference) => {
              const userPreference = userPreferences.find(
                (userPref) => userPref.id === preference.id
              );
              return {
                id: preference.id,
                name: preference.name,
                description: preference.description,
                value: userPreference ? userPreference.value : preference.value,
                setValue: (value) =>
                  handleUpdatePreferences(preference.id, value),
              };
            })}
        />
      ))}
    </>
  );
}

export default PreferencesPage;
