import React from "react";
import { useState, useEffect } from "react";

import Badge from "./Badge";
import Button from "../elements/Button";

const projects = [
  {
    name: "Graph API",
    initials: "GA",
    href: "#",
    members: 16,
    bgColor: "bg-pink-600",
  },
  {
    name: "Component Design",
    initials: "CD",
    href: "#",
    members: 12,
    bgColor: "bg-purple-600",
  },
  {
    name: "Templates",
    initials: "T",
    href: "#",
    members: 16,
    bgColor: "bg-yellow-500",
  },
  {
    name: "React Components",
    initials: "RC",
    href: "#",
    members: 8,
    bgColor: "bg-green-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleCard(props) {
  const [view, setView] = useState();
  const data = props.data;
  const impediments = data.impediments ? data.impediments : [];
  const name = props.data.Name ? props.data.Name : "No Name";
  const description = props.data.Description;
  const levels = props.data.Levels
    ? props.data.Levels
    : props.data.answer_options;
  const valueDrivers = props.data["Value Drivers"];

  console.log("data", data);

  const setImpedimentModalOpen = () => {
    console.log("setImpedimentModalOpen", data);
    props.setImpedimentModalOpen(true, data);
  };

  useEffect(() => {
    setView(props.view);
  }, [props.view]);

  const getBgColor = (level) => {
    switch (level) {
      case 0:
        return "bg-beginner";
      case 1:
        return "bg-intermediate";
      case 2:
        return "bg-advanced";
      case 3:
        return "bg-expert";
      default:
        return "bg-blue-500";
    }
  };

  const getLevelName = (level) => {
    switch (level) {
      case 0:
        return "Beginner";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      case 3:
        return "Expert";
      default:
        return "Beginner";
    }
  };

  const getProjectInitials = (name) => {
    const words = name.split(" ");
    if (words.length > 1) {
      return words[0][0] + words[1][0];
    }
    return words[0][0];
  };

  return (
    // <div>test</div>
    <div className="shadow-md rounded-md border-t border-r border-l border-b border-gray-200  p-5">
      <div className=" w-full max-w-9xl mx-auto">
        <div className="sm:flex sm:justify-between sm:items-center ">
          <header className=" py-4">
            <h2 className="font-semibold text-slate-800">{name}</h2>
          </header>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Badge
              color="primary"
              className="cursor-pointer"
              onClick={() => setImpedimentModalOpen()}
            >
              {impediments.length + " Impediments"}
            </Badge>
            <Badge color="red">
              Effort: {data.effort !== undefined ? data.effort : 0} days
            </Badge>
            <Badge color="secondary">
              Burden: {data.score !== undefined ? data.score : 0} hours/wk
            </Badge>
            <Button
              color="accent"
              size="xs"
              shadow
              className="ml-6 "
              handleClick={() => setImpedimentModalOpen()}
            >
              Add Impediment
            </Button>
          </div>
        </div>
      </div>

      <span className="flex-1 flex text-sm  border-gray-200 bg-white rounded-r-md truncate">
        {data.Description ? data.Description : "No Description"}
      </span>
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        {levels.map((project, index) => (
          // <div>test</div>
          <li
            key={project.Name}
            className={`col-span-1 flex rounded-md shadow-sm
            }}`}
          >
            <div
              className={classNames(
                // getBgColor(project.Name),
                project == data.your_answer
                  ? "bg-yellow-400"
                  : getBgColor(index),
                // project.Selected ? "bg-brand-600" : getBgColor(index),
                " flex-shrink-0 flex items-center justify-center w-16 text-white text-2xl font-medium rounded-l-md"
              )}
            >
              {/* get initials from name */}
              {getProjectInitials(
                project.Name ? project.Name : getLevelName(index)
              )}
            </div>

            <div
              className={`shadow flex ${view == "grid" && "h-32"} ${
                view == "list" && "h-16"
              }
               ${view == "list" && "truncate"}
              flex-1 items-center justify-between text-xs rounded-r-md border-t border-r border-b  ${
                project == data.your_answer
                  ? " bg-yellow-100 border-yellow-500"
                  : "bg-white border-gray-200"
              }`}
            >
              <div
                className={`flex-1   ${
                  view == "list" && "truncate"
                }  px-4 py-2 text-xs`}
              >
                <a
                  href={project.href}
                  className="font-medium text-slate-900 hover:text-slate-600"
                >
                  {/* {view == "grid" && project.Description && project.Description}
                  {view == "list" && project.Description && project.Name} */}
                  {view == "grid" && project}
                  {view == "list" && project}
                  {/* {view == "list" && project.Name && project.Name} */}
                </a>

                {/* <p className="text-slate-500">
                  {impediments.length} Impediments
                </p> */}
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full  bg-transparent text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Open options</span>
                  {/* <EllipsisOutlined className="h-5 w-5" aria-hidden="true" /> */}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                    />
                  </svg> */}
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
