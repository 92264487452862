// store.js

import React, { createContext, useEffect, useMemo, useState } from "react";
import { utils } from "../modules/_utils";
import { _navigation } from "../modules/_navigation";
import { _auth } from "../modules/_auth";
import { _stripe } from "../modules/_stripe";
export const GlobalStateContext = createContext(null);

export const GlobalStateStore = ({ children }, props) => {
  const [state, setState] = useState({});
  const [cache, setCache] = useState();
  const [flags, setFlags] = useState();

  useEffect(() => {
    (async () => {
      let cache = await utils.getCacheData("flags", "flags");

      if (cache) {
        console.log("USEEFFECT in STORE --> GETTING FLAGS", cache);
        setFlags(cache);
        setCache(cache);
      }
      setState({ ...state, flags: cache.data });
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     let stripeData = await _stripe.getStripeData();

  //     if (stripeData) {
  //       console.log("USEEFFECT in STORE --> GETTING Stripe Data", stripeData);
  //     }
  //     setState({ ...state, stripeData: stripeData });
  //   })();
  // }, []);

  const setProfile = (values, from) => {
    //if values is values.profile, then set the profile to values.profile
    if (values.profile) {
      values = values.profile;
    }
    console.log("setProfile is called with values: ", values, from);
    setState({ ...state, profile: values });
  };

  const getProfile = () => {
    console.log("getProfile is called, returning values: ", state.profile);
    return state.profile;
  };

  // const getStripeData = () => {
  //   console.log(
  //     "getStripeData is called, returning values: ",
  //     state.stripeData
  //   );
  //   return state.stripeData;
  // };

  // Provide setProfile to _auth
  useEffect(() => {
    _auth.setProfileFunction(setProfile);
    _auth.getProfileFunction(getProfile);
  }, [state.profile]);

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <GlobalStateContext.Provider
      value={{ state: state, setProfile, updateState }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
