import React, { useEffect, useState } from "react";
import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/20/solid";
import Button from "../elements/Button";
export default function CopyToClipBoard(props) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    //after 5 seconds set copied to false
    const timer = setTimeout(() => {
      setCopied(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [copied]);
  return (
    <>
      <Button
        color="text-dark"
        // iconButton
        // zoom
        // disabled={copied}
        // handleClick={() => {
        //   props.handleClick();
        // }
        // }
        handleClick={() => {
          if (copied) return;
          navigator.clipboard.writeText(props.inviteLink);
          setCopied(!copied);
        }}
        type="button"
      >
        {copied ? (
          <>
            <span className="flex text-sm font-medium text-emerald-500">
              <ClipboardDocumentCheckIcon
                className=" mr-2 h-5 w-5 "
                aria-hidden="true"
              />
              <span className="text-sm font-medium ">
                {" "}
                Invite Link Copied!{" "}
              </span>
            </span>
          </>
        ) : (
          <>
            <span className="flex text-sm font-medium ">
              <ClipboardDocumentIcon
                className="mr-2 h-5 w-5 "
                aria-hidden="true"
              />
              Copy Invite Link
            </span>
          </>
        )}
      </Button>{" "}
    </>
  );
}
