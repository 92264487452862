/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { Fragment, useEffect, useState } from "react";
//import useLocation
import { useLocation, useHistory } from "react-router-dom";

import { Menu, Popover, Transition } from "@headlessui/react";
import {
  ArrowLongLeftIcon,
  CheckIcon,
  HandThumbUpIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "../../elements/Button";
import { utils } from "../../modules/_utils";
import SolicitationList from "./SolicitationList";
import Leaderboard from "./QuoteListSmall";
import StackedListTwoColumn from "./StackedListTwoColumn";
import NewSolicitation from "./NewSolicitation";
import ModalBasic from "../../components/ModalBasic";
import Badge from "../../components/Badge";
import { Projects } from "../../modules/_projects";
import BidList from "./BidList";
import { Solicitations } from "../../modules/_solicitations";
import DialogBox from "../../components/Dialog";
import { set } from "date-fns";
import StackedList from "../../components/StackedList";
import StackedListButtons from "../../components/StackedListButtons";
import { _auth } from "../../modules/_auth";
import ConnectionSelector from "./ConnectionSelector";
import { remove } from "immutable";

const attachments = [
  { name: "project-description-from-client.pdf", href: "#" },
  { name: "coverletter_from-client.pdf", href: "#" },
];
const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: "bg-gray-400" },
  advanced: { icon: HandThumbUpIcon, bgColorClass: "bg-brand-500" },
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BidProjectDetails(props) {
  const { projectId } = useParams();

  const location = useLocation();
  const history = useHistory();
  const [project, setProject] = useState({});
  const [err, setErr] = useState(null);
  const [solicitations, setSolicitations] = useState([]);
  const [newSolicitationModalOpen, setNewSolicitationModalOpen] =
    useState(false);
  const [removeConnectionmodalOpen, setRemoveConnectionmodalOpen] =
    useState(false);
  const [addConnectionModalOpen, setAddConnectionModalOpen] = useState(false);
  const [bids, setBids] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  const profile = _auth.getUserProfile();
  useEffect(() => {
    (async () => {
      let id = projectId;
      // location.state && location.state.project
      //   ? location.state.project._id
      //   : props.project._id;

      console.log("ProjectDetails useEffect", location.state, props.project);
      const project = await Projects.getById({
        _id: id,
      });
      if (project) {
        //   [
        //     {
        //         "_id": "65c799ca34578fe86a5f659f",
        //         "tenantCompany": "Breachlink",
        //         "status": "active"
        //     }
        // ]

        // let allowedConnections = project.allowedConnections.map((item) => {
        //   return item.tenantCompany;
        // });
        // project.allowedConnections = allowedConnections;
        project.allowedConnections = project.allowedConnections.map(
          (connection) => {
            console.log("ProjectDetails connection", connection);
            if (connection === null) return;
            //if the connection is not an object, return
            if (typeof connection !== "object") return;
            const tenant = profile.tenantInfo.connectedTenants.find(
              (tenant) => tenant._id === connection.tenantId
            );
            return tenant
              ? { ...connection, tenantCompany: tenant.tenantCompany }
              : {};
          }
        );
        console.log("ProjectDetails project", project);
        setProject(project);
      } else {
        setErr("Error getting project");
      }

      // setProject(
      //   location.state && location.state.project
      //     ? location.state.project
      //     : props.project
      // );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const bids = await Projects.getAllProjectBids({
        id: project._id,
      });
      console.log("ProjectDetails bids", bids);
      setBids(bids);
    })();
  }, [project]);

  useEffect(() => {
    (async () => {
      if (!project._id) return;
      console.log("ProjectDetails getting bid requests", project._id);
      const brs = await Solicitations.getByProjectId({
        projectId: project._id,
      });
      if (brs) {
        console.log("ProjectDetails got bid requests", brs);
        setSolicitations(brs);
      } else {
        console.log("ProjectDetails requests failed");
      }
    })();
  }, [project]);

  useEffect(() => {
    console.log("Project updated with solicitation", project);
  }, [project]);

  const showSolicitationDetails = (project, item) => {
    history.push({
      pathname:
        "/projects/project-details/" +
        item.projectId +
        "/solicitation-details/" +
        item._id,
      state: {
        project: project,
        solicitation: item,
        projectId: item.projectId,
      },
    });
  };

  const addConnection = async (data) => {
    console.log("addConnection", data);
    // setInviteLoading(true);
    // setTimeout(async () => {
    const updated = { ...project };
    data.projectId = projectId;
    if (!updated.allowedConnections) {
      updated.allowedConnections = [];
    }
    const connection = {
      tenantId: data.tenantId,
      allowedUsers: [],
      status: "active",
    };
    data.connection = connection;
    updated.allowedConnections.push(connection);
    const updatedProject = await Projects.addConnection(data);

    setProject(updatedProject);
    // setInviteLoading(false);
    // }, 750);
  };

  const removeConnection = async (data) => {
    console.log("removeConnection", data);
    const updatedProject = await Projects.removeConnection(data);
    if (updatedProject) {
      console.log("removeConnection", updatedProject);
      setProject(updatedProject);
    } else {
      setErr("Error removing connection");
    }
  };
  // const handleDeleteProjectClick = (e, id) => {
  //   console.log("handleDeleteProjectClick", id);
  //   setShowDeleteConfirm(true);
  //   setDeletionEvent(e);
  //   setIdToDelete(id);
  // };

  // const deleteProject = async (e) => {
  //   console.log("deleteProject", idToDelete);
  //   // setDeleteConfirmed(true);
  //   deletionEvent.preventDefault();
  //   console.log("Delete Project", idToDelete);
  //   const deleted = await Projects.delete(idToDelete);

  //   if (deleted) {
  //     //remove from data
  //     let oldData = data;
  //     let newData = oldData.filter((item) => item._id !== idToDelete);
  //     setData(newData);
  //     setSuccess(true);
  //     setDeletionEvent(null);
  //     setIdToDelete(null);
  //     setShowDeleteConfirm(false);
  //   }
  // };
  const sideContent = useMemo(() => {
    return (
      <div
        className="col-span-4 h-full overflow-x-hidden overflow-y-hidden overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
      >
        <div
          className="h-full 
        space-y-2 mb-4"
        >
          <div className="pt-4 flex items-center justify-between col-span-full">
            <div className=" ">
              <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
                Bid Requests
              </h2>
            </div>
            <div>
              {/* <Button
                type="button"
                color={"secondary"}
                disabled={project?.status == "Closed"}
                size={"sm"}
                handleClick={() => setNewSolicitationModalOpen(true)}
                // className="inline-flex items-center justify-center rounded-md bg-brand-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
              >
                <PlusIcon className="w-5 h-5 mr-2" /> New bid request
              </Button> */}
            </div>
          </div>
          {/* <div className="block">Badges</div> */}
          {/* <div className="col-span-full mt-0">Callout</div> */}
        </div>
        <div className="border-t border-gray-200"> </div>
        <div
          // style={{ maxHeight: "40vh", minHeight: "40vh" }}
          className="max-h-[calc(100vh-12rem)]  flow-root  
        overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
        >
          <SolicitationList
            onItemClick={showSolicitationDetails}
            project={project}
            items={(solicitations && solicitations) || []}
          />
        </div>
        <div className="mt-6 w-2/3 rounded-md shadow sm:mt-3 sm:ml-3 sm:flex-shrink-0">
          {/* <Button
          loader={loading}
          disabled={loading || saved || response.status == "complete"}
          handleClick={handleSaveServiceClick}
          type="submit"
          color="secondary"
          fullWidth
          className="w-full"
          // disabled={err}
          // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          <Icon icon="mdi:content-save" className="mr-2" />
          Save{" "}
          {cleanUpKeys(
            selectedTab && selectedTab !== undefined
              ? selectedTab
              : "Service"
          )}
        </Button> */}
        </div>
      </div>
    );
  }, [project, solicitations]);

  const handleCreateSolicitationClick = () => {
    setNewSolicitationModalOpen(true);
  };
  const handleCloseReopenProject = async (e) => {
    e.preventDefault();
    console.log(project.deadline, "deadline");
    let data = {
      id: project._id,
      // data: project
      forcedCloseByProject: project.status == "Open" ? true : false,
      updates: {
        status: project.status == "Closed" ? "Open" : "Closed",
      },
    };

    const updatedProject = await Projects.closeAndReopenProject(data);
    if (updatedProject) {
      console.log("handleUpdateProject", updatedProject);
      //update status in state
      setProject({
        ...project,
        status: project.status == "Closed" ? "Open" : "Closed",
      });
    } else {
      setErr("Error updating project");
    }
  };

  const handleAfterCreateProject = async (data) => {
    console.log("handleSaveSolicitation", data);
    let old = { ...project };
    old.solicitations.push(data);
    setProject(old);

    // let oldSol = { ...solicitations };
    // oldSol.push(data);
    // setSolicitations(oldSol);
  };
  const navigateBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleDeleteProjectClick = (e) => {
    // console.log("handleDeleteProjectClick", id);
    setShowDeleteConfirm(true);
    setDeletionEvent(e);
    setIdToDelete(project._id);
  };

  const deleteProject = async () => {
    console.log("deleteProject", idToDelete);
    // setDeleteConfirmed(true);
    deletionEvent.preventDefault();
    console.log("Delete Project", idToDelete);
    const deleted = await Projects.delete(idToDelete);

    if (deleted) {
      //remove from data
      history.push("/projects");
      // let oldData = data;
      // let newData = oldData.filter((item) => item._id !== idToDelete);
      setSuccess(true);
      setDeletionEvent(null);
      setIdToDelete(null);
      setShowDeleteConfirm(false);
    }
  };

  const handleCancelDeleteProjectClick = () => {
    setSuccess(true);
    setDeletionEvent(null);
    setIdToDelete(null);
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <div
        className="overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded bg-white border border-gray-200 shadow-sm relative flex min-h-full max-h-full flex-col"
      >
        <div className="h-full mx-auto w-full max-w-14xl flex-grow lg:flex ">
          <div id="mainContent" className="bg-white lg:min-w-0 lg:flex-1">
            <div className="h-full">
              <div id="topContent" className="h-full ">
                <div
                  id="first_row"
                  className="px-2 py-2 flex flex-col lg:flex-row  justify-between "
                >
                  {/* left side */}
                  <div className="w-2/3  ">
                    {/* header */}
                    <div className="flex items-center ">
                      {/* Welcome banner */}
                      <Button
                        iconButton
                        handleClick={(e) => navigateBack(e)}
                        color="text"
                      >
                        <Icon
                          className="h-6 w-6"
                          //back button

                          icon="ic:baseline-arrow-back"
                        />
                      </Button>{" "}
                      <h2
                        id="applicant-information-title"
                        className="text-lg font-medium leading-6 text-slate-900"
                      >
                        P-
                        {project.GlobalId && project.GlobalId}
                        <span className="ml-1 uppercase">{project.name}</span>
                        {/* {project.projectId && project.projectId}  */}
                        {/* {solicitation && solicitation.name} */}
                      </h2>
                    </div>
                    <div className="pl-10 flex items-center">
                      {/* Deadline: {utils.formatDate(project.deadline, "long")} */}
                    </div>
                  </div>
                  {/* right side */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 pr-2">
                    <div className="flex flex-col lg:flex-row justify-end items-center">
                      {/* Welcome banner */}
                      <div className="mb-0 lg:mb-0 inline-flex space-x-3 ">
                        {/* FUTURE - DELETE REQUEST */}
                        {/* <Button color="outline">
                    <Icon
                      icon="teenyicons:folder-no-access-solid"
                      className="mr-2"
                    />{" "}
                    Edit{" "}
                  </Button> */}
                        {/* {project.allowedConnections &&
                          project.allowedConnections.length > 0 && (
                            <Button
                              size="xs"
                              handleClick={(e) => {
                                console.log("Remove Connection Clicked");
                                setRemoveConnectionmodalOpen(true);
                              }}
                              color="text"
                            >
                              Remove Connections
                            </Button>
                          )} */}
                        {/* {!project.isOwner ||
                          (!project.isOwner == true && ( */}
                        <Button
                          size="xs"
                          handleClick={(e) => {
                            console.log("Add Connection Clicked");
                            setAddConnectionModalOpen(true);
                          }}
                          color="text"
                        >
                          Connections
                        </Button>

                        {project.status == "Closed" && (
                          <Button
                            // loader={isLoading("Archive")}
                            // handleClick={() => manageLoading("Archive", true)}
                            handleClick={(e) => {
                              handleDeleteProjectClick(e);
                              // handleArchiveSolicitation(e);
                            }}
                            color="red"
                          >
                            <Icon
                              className="mr-2"
                              //delete button
                              icon="ic:baseline-delete"
                            />
                            Delete{" "}
                          </Button>
                        )}
                        {project.status !== "Archived" && (
                          <Button
                            size="xs"
                            handleClick={(e) => {
                              console.log(project.deadline, "project");
                              handleCloseReopenProject(e);
                            }}
                            color="outline"
                          >
                            {project.status !== "Closed"
                              ? "Close Project"
                              : "Re-open Project"}
                          </Button>
                        )}
                        <Button
                          type="button"
                          color={"secondary"}
                          disabled={project?.status == "Closed"}
                          size={"sm"}
                          handleClick={() => setNewSolicitationModalOpen(true)}
                          // className="inline-flex items-center justify-center rounded-md bg-brand-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                        >
                          <PlusIcon className="w-5 h-5 mr-2" /> New bid request
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-1">
                  <hr className="border-gray-200" />
                </div>
                <div
                  // style={{ maxHeight: "60vh", minHeight: "60vh" }}
                  className="col-span-8  overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full "
                >
                  <div className=" px-4 py-5 sm:px-6">
                    <div className=" grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-slate-500">
                          Project ID
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900 uppercase">
                          {project.GlobalId && project.GlobalId}{" "}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-slate-500">
                          Project name
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900 uppercase">
                          {project.name}
                        </dd>
                      </div>

                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-slate-500">
                          Create Date
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          {utils.formatDate(project.createdAt, "long")}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-slate-500">
                          Project Deadline
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          {utils.formatDate(project.deadline, "long")}
                        </dd>
                      </div>
                      <div className="h-full sm:col-span-1">
                        <dt className="text-sm font-medium text-slate-500">
                          Client
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          {project.client && project.client.name}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-slate-500">
                          Client Contact
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          {project.clientContact}
                        </dd>
                      </div>
                      <div className=" sm:col-span-2">
                        <dt className="text-sm font-medium text-slate-500">
                          Details
                        </dt>
                        <dd className="mt-1 text-sm text-slate-900">
                          {/* <p className=" white-space-wrap text-sm font-medium text-brand-600"> */}
                          {project.description}
                          {/* </p> */}
                        </dd>
                      </div>

                      {/* FUTURE - SEE TRELLO */}
                      {/* <div className="sm:col-span-2">
                      <dt className="flex text-sm font-medium text-slate-500">
                        Attachments{" "}
                        <Badge className="inline" color="secondary">
                          Coming soon
                        </Badge>
                      </dt>{" "}
                      <dd className="mt-1 text-sm text-slate-900">
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 rounded-md border border-gray-200"
                        >
                          {attachments.map((attachment) => (
                            <li
                              key={attachment.name}
                              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                            >
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon
                                  className="h-5 w-5 flex-shrink-0 text-slate-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 w-0 flex-1 truncate">
                                  {attachment.name}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a
                                  href={attachment.href}
                                  className="font-medium text-brand-600 hover:text-brand-500"
                                >
                                  Download
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </dd>
                    </div> */}
                    </div>
                  </div>
                </div>
                {/* <div id="bottomContent" className="  mx-6 mt-12 ">
                  <dt className="text-sm font-medium text-slate-500 mb-4">
                    Recent bids ({bids && bids.length})
                  </dt>
                  <div
                    className="max-h-96 flex flex-row  border-t border-gray-200 col-span-full px-0 py-3 sm:px-0 
                  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                  scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                  "
                  >
                    <BidList
                      onItemClick={null}
                      project={bids}
                      items={bids || []}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div
            id="sideContent_wrapper"
            className="w-full lg:w-1/3 bg-white pr-4 sm:pr-6 lg:flex-shrink-0 lg:border-l lg:border-gray-200 lg:pr-8 xl:pr-0"
          >
            <div id="sideContent" className="pl-4 pr-4 lg:w-400">
              {sideContent}
            </div>
          </div>
        </div>
      </div>
      {/* {showDeleteConfirm && ( */}
      <ModalBasic
        id="remove-connection-modal"
        modalOpen={removeConnectionmodalOpen}
        setModalOpen={setRemoveConnectionmodalOpen}
        title="Remove Connections"
        showClose={true}
        showFooter={false}
        closeButtonText="Close"
        size="lg"
        scrollable={true}
      >
        {
          <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
            <ConnectionSelector
              id="remove-connection"
              action="remove"
              project={project}
              profile={profile}
              removeConnection={(data) => {
                console.log("Remove Connection", data);
                removeConnection(data);
              }}
            />
          </div>
        }
      </ModalBasic>
      <ModalBasic
        id="add-connection-modal"
        modalOpen={addConnectionModalOpen}
        setModalOpen={setAddConnectionModalOpen}
        title="Connections"
        showClose={true}
        showFooter={false}
        closeButtonText="Close"
        size="custom"
        scrollable={true}
      >
        {
          <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
            <ConnectionSelector
              id="add-connection"
              action="add"
              project={project}
              profile={profile}
              addConnection={(data) => {
                addConnection(data);
              }}
              removeConnection={(data) => {
                console.log("Remove Connection", data);
                removeConnection(data);
              }}
            />
          </div>
        }
      </ModalBasic>
      <DialogBox
        handleConfirmClick={deleteProject}
        open={showDeleteConfirm}
        handleCancelClick={handleCancelDeleteProjectClick}
        loading={false}
        success={success}
        title="Confirm Project Deletion"
        message={"Are you sure you want to delete this project?"}
        OKbuttonText={"Delete"}
        CancelButton={"Cancel"}
      />

      <ModalBasic
        id="assessment-modal"
        modalOpen={newSolicitationModalOpen}
        setModalOpen={setNewSolicitationModalOpen}
        title="Create a new bid request"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        scrollable={true}
      >
        {/* <AssessmentSelect /> */}
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewSolicitation
            projectId={project._id}
            project={newSolicitationModalOpen ? project : {}}
            setModalOpen={setNewSolicitationModalOpen}
            modalOpen={newSolicitationModalOpen}
            updateData={handleAfterCreateProject}
            // updateData={handleAfterCreate}
            stepOptions={4}
            step={0}
          />
        </div>

        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>
    </>
  );
}
