import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import Button from "../elements/Button";
import { _auth } from "../modules/_auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = [
  {
    id: 1,
    name: "I am a business owner",
    description: "I am a business owner",
    href: "/onboarding",
    icon: CheckCircleIcon,
    iconBackground: "bg-primary-500",
    iconForeground: "text-white",
  },
  {
    id: 2,
    name: "I am a consultant",
    description: "I am a consultant",
    href: "/onboarding",
    icon: CheckCircleIcon,
    iconBackground: "bg-primary-500",
    iconForeground: "text-white",
  },
];

function StackedList({
  handleOpenAssessmentModalFromHome,
  setNewSolicitationShortcutModalOpen,
  headingText,
  headingSubtext,
  options,
  // profile,
}) {
  const [selectedOption, setselectedOption] = useState(options[0]);
  const history = useHistory();
  const profile = _auth.getUserProfile();
  return (
    <div className="space-y-10 shadow-lg border border-gray-200 rounded">
      <div className="mx-auto min-w-[33vw] max-w-lg p-10 bg-white rounded  overflow-auto max-w-1/2  max-h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
        <div>
          <div className="text-sm">
            <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 "
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <path
                  fill="#A0041E"
                  d="m1 17l8-7l16 1l1 16l-7 8s.001-5.999-6-12s-12-6-12-6z"
                />
                <path
                  fill="#FFAC33"
                  d="M.973 35s-.036-7.979 2.985-11S15 21.187 15 21.187S14.999 29 11.999 32c-3 3-11.026 3-11.026 3z"
                />
                <circle cx="8.999" cy="27" r="4" fill="#FFCC4D" />
                <path
                  fill="#55ACEE"
                  d="M35.999 0s-10 0-22 10c-6 5-6 14-4 16s11 2 16-4c10-12 10-22 10-22z"
                />
                <path d="M26.999 5a3.996 3.996 0 0 0-3.641 2.36A3.969 3.969 0 0 1 24.999 7a4 4 0 0 1 4 4c0 .586-.133 1.139-.359 1.64A3.993 3.993 0 0 0 30.999 9a4 4 0 0 0-4-4z" />
                <path
                  fill="#A0041E"
                  d="M8 28s0-4 1-5s13.001-10.999 14-10s-9.001 13-10.001 14S8 28 8 28z"
                />
              </svg>

              <h2 className="mt-2 text-lg font-medium text-slate-900">
                {headingText}
              </h2>
              <p className="mt-3 text-sm text-slate-500 mb-10 text-left">
                {headingSubtext}
              </p>
              {/* Create bid request shortcut */}
              {/* 
              When the button is clicked, we first need to create a temporary project
              and return the project and project id.  Then we need to pass that to the 
              NewSolicitation component in the Modal.  When the user saves the solicitation, we need
              to update the project with the proper project name before saving the solicitation.
              */}
              {profile && profile.tenantType !== "vendor" && (
                <>
                  {" "}
                  <div className="pb-6">
                    <Button
                      color="secondary"
                      size="lg"
                      handleClick={(e) => {
                        e.preventDefault();
                        setNewSolicitationShortcutModalOpen(true);
                      }}
                    >
                      Start a new bid request project
                    </Button>
                  </div>
                  <div className="pb-6">or </div>{" "}
                </>
              )}
            </div>

            <div className=" max-h-[calc(100vh_-_30rem)]  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
              <div className="text-sm">
                {/* Options */}

                <ul className="space-y-2 mb-4 w-full">
                  {options.map((a) => {
                    const {
                      id,
                      status,
                      title,
                      subTitle,
                      rightText,
                      handleClick,
                      highlight,
                    } = a;

                    return (
                      a.status != "Disabled" &&
                      a.status !== "Deleted" && (
                        <li key={id}>
                          <button
                            disabled={a.status === "beta"}
                            onClick={(e) => {
                              if (handleClick) {
                                handleClick(e);
                              } else {
                                //push history to a.path
                                history.push(a.path);
                              }
                            }}
                            className={`w-full h-full text-left py-3 px-4 rounded 
                                shadow-sm duration-150 ease-in-out
                               ${
                                 highlight
                                   ? "bg-brand-500/10 border border-brand-500"
                                   : "bg-white border border-gray-300"
                               }
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500
                                ${
                                  status === "beta"
                                    ? "opacity-80 cursor-not-allowed hover-shadow-none hover-border-none"
                                    : " hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 hover:border-gray-300 hover:border-brand-500"
                                }
                                `}
                          >
                            <div className="flex items-center">
                              {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                              <div className="grow">
                                <div className="flex flex-wrap items-center justify-between mb-0.5">
                                  <span className="flex font-semibold text-slate-800">
                                    {title}
                                    {status != "Active" && (
                                      <span className="normal-case text-xs italic text-accent-800 align-top ml-2">
                                        {status == "beta"
                                          ? "    Coming soon"
                                          : null}
                                        {status == "new" ? "    New!" : null}
                                      </span>
                                    )}
                                  </span>
                                  <span>
                                    <span className="font-medium text-accent-600">
                                      {rightText}
                                    </span>
                                  </span>
                                </div>
                                <div className="text-xs">
                                  {subTitle && (
                                    <span className="text-slate-500">
                                      {subTitle}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </button>
                        </li>
                      )
                    );
                  })}
                  {/* Create a new tenant */}
                  {/* We need to check if this user owns a tenant of their own or if they are a guest only */}
                  {/* If they are only a guest, we need to give them the ability to create an account of their own */}
                  {/* <li key={"new_tenant"}>
                        <button
                          // color="white"
                          // disabled={a.status === "Invited"}
                          onClick={() => goToTenant("new", "new")}
                          className="w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-200
                                shadow-sm duration-150 ease-in-out
                                hover:border-gray-300
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                
                               "
                        >
                          Create New Tenant
                        </button>
                      </li> */}
                </ul>
                {/* <div className="text-xs text-slate-500">
                  After selecting your assessment, click Next to begin.
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mx-auto min-w-[33vw] max-w-lg p-10 bg-white rounded shadow-lg overflow-auto max-w-1/2  max-h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
        test
      </div> */}
    </div>
    //   </div>
    // </div>
  );
}

export default StackedList;
