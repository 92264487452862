import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Link, useHistory } from "react-router-dom";
import ArrowSteps from "../components/ArrowSteps";
import AssessmentIntro from "../components/AssessmentIntro";
import ContentSplit from "../components/ContentSplit";
import Empty from "../components/Empty";
import OptionButtons from "../components/OptionButtons";
import YesNoQuestions from "../components/YesNoQuestions";
import Fade from "react-reveal/Fade";
import CustomTooltip from "../components/CustomTooltip";
import "../css/style.css";
import { _assessment } from "../modules/_assessment";
import { qList } from "../modules/_assessment_questions";
import { _onboarding_options } from "../modules/_onboarding_options";
import Tooltip from "../components/Tooltip";

import { utils } from "../modules/_utils";
import { _auth } from "../modules/_auth";
import Spinner from "../images/spinner.png";
import ContactConfirm from "../components/ContactConfirm";
import { audit } from "../modules/_audit";
import { usePersistStore, useStore } from "../store/usestore";
import Badge from "../components/Badge";
import RightActionArea from "../components/ActionAreaRight";
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const assessment_types = _assessment.getAssessmentNames();

function GetAssessmentInfo(preassessment) {
  let steps = null;
  if (preassessment) {
    steps = _assessment.getPreAssessmentSteps();
  } else {
    steps = _assessment.getAssessmentSteps();
  }
  return steps;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Assessment(props) {
  /*
  If new assessment, create new assessment object (if continuing, get assessment by id)
  Add new assessment object to end of assessment_history object with incremented idx
  Save assessment_history object to local storage with "in progress" status
  Create completed question array
  Append each completed question to completed question array with answer
  Update the new assessment object with completed question array after each question 
  Update the assessment_history in local storage after each question
  On finish, if all questions are answered, update the assessment object with "finished" status

   */

  const location = useLocation();
  const history = useHistory();

  console.info("ASSESSMENT STATE -->", location.state);

  const assessment = location.state.thisAssessment;
  const resume = location.state.resume;

  const [preassessment, setPreassessment] = useState(
    location.state.isPreassessment
  );
  const [isPreassessment, setIsPreassessment] = useState(
    location.state.isPreassessment
  );
  const [assessment_type, setAssessment_type] = useState(
    location.state.assessment_type
  );

  const [noCount, setNoCount] = useState(0);
  const [yesCount, setyesCount] = useState(0);
  const [unsureCount, setUnsureCount] = useState(0);
  const [counts, setCounts] = useState([]);
  const [proceed, setProceed] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [confirmed, setConfirmed] = useState(false);
  const [currentStep, setStep] = useState(1);

  // const { getUserAssessmentHistory, userAssessmentHistory } = useStore();
  const { guestProfile } = usePersistStore();
  const [localGuestProfile, setLocalGuestProfile] = useState(null);
  // const steps = GetAssessmentInfo(preassessment);
  const setUpdatedQuestion = (q) => {};

  const [assessment_history, setAssessmentHistory] = useState([]);

  const [id, setId] = useState();
  const [thisAssessment, setThisAssessment] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  //assessment info from useEffect function
  const [assessment_details, setAssessmentDetails] = useState({});

  //end assessment info from useEffect function

  useEffect(() => {
    (async () => {
      console.log("Move assessement_details here ");
      var templates = await _assessment.getTemplates();
      if (templates) {
        let assessment_details = templates.find(
          (x) => x.key === assessment_type
        );
        console.log("assessment_details", assessment_details);
        setAssessmentDetails(assessment_details);
      } else {
        console.log("No templates found");
      }
    })();
  }, []);

  // var assessment_details = _assessment
  //   .getAssessmentNames()
  //   .find((x) => x.key === assessment_type);

  //should be moved to state?
  const assessment_name = assessment_details.name;
  const assessment_key = assessment_details.key;
  console.info("assessment_name", assessment_name);
  console.info("assessment_key", assessment_key);

  const notificationMethods = [
    { id: "Yes", title: "Yes" },
    { id: "No", title: "No" },
    { id: "Unsure", title: "Unsure" },
  ];

  useEffect(() => {
    _assessment.getAssessmentHistory();
  }, []);

  useEffect(() => {
    // console.log(">resppp");
    _auth.getAuth(history);

    let profile = _auth.getGuestProfile();
    if (profile !== null) {
      setLocalGuestProfile(JSON.parse(profile));
    }
  }, []);

  useEffect(() => {
    (async () => {
      // debugger;
      let assessmentHistory = await _assessment.getAssessmentHistory();
      // console.log("assessmentHistory UseEffect", assessment_history);
      setAssessmentHistory(assessmentHistory);
      // // console.log("UseEffect find assessment with id", id);
      // setThisAssessment(
      //   assessmentHistory.find((x) => {
      //     return x.id === id;
      //   })
      // );
      // // console.log("UseEffect found assessment", thisAssessment);
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     // console.log(guestProfile, "profile guest");
  //     debugger
  //     if (Object.keys(guestProfile).length > 0) {
  //        getUserAssessmentHistory(guestProfile);

  //       debugger
  //     } else {
  //        getUserAssessmentHistory();
  //       debugger
  //     }
  //     // setThisAssessment(assessmentHistory.find(x => {
  //     //   return x.id === id
  //     // }))
  //   })();
  // }, []);

  const handleStartAssessment = async () => {
    audit.logAction("Start Assessment");
    // const q = _assessment.getAssessmentQuestions1(assessment_type);
    const q = await _assessment.getAssessmentQuestions1(assessment_type);

    setAssessmentQuestions(q);

    console.info("Got Q!", q);
    // // ////debugger;
    // const resume = true;
    console.info("Getting assessment history before starting assessment");
    console.info("Assessment History", assessment_history);
    // ////debugger
    // assessment.sort((a, b) => {
    //   return a.id.localeCompare(b.id);
    // });
    //added by Oran - sort assessment questions
    assessmentQuestions.sort((a, b) => {
      return a.id.localeCompare(b.id);
    });

    if (resume) {
      audit.logAction("Resume Assessment");
      let assessment_id = assessment.assessment_id;
      // console.info("RESUMING ASSESSMENT", assessment_id);
      // // console.info("Setting assessment state", assessment_id);
      // setId(assessment_id);
      // // console.info("This assessment from state", assessment);
      // console.info("Getting assessment from history");

      // let current_assessment = assessment_history.find(
      //   (x) => x.assessment_id === assessment_id
      // );
      // console.info("Found current assessment", current_assessment);
      console.info("Settting assessment id state", assessment_id);
      setId(assessment_id);
      ////debugger;
      console.info("Setting Assessment History in state", assessment_history);
      ////debugger;
      setAssessmentHistory(assessment_history);
      // console.info("Setting 'this assesment'", current_assessment);
      ////debugger;
      setThisAssessment(assessment);
      setAssessmentQuestions(
        assessment_history.find((x) => x.assessment_id === assessment_id)
          .questions
      );
      //debugger
      console.info("Begin Assessment", assessment);
    } else {
      ////debugger;
      console.info("Creating new assessment");
      let assessment_template = JSON.parse(_assessment.getJsonTemplate());
      if (isPreassessment) {
        setAssessment_type("pre");
      }

      console.info("Adding questions to template", JSON.stringify(q), q);
      // debugger;
      assessment_template.questions = q;

      console.info(assessment_template.questions);
      // debugger;
      const assessment_id = assessment_history.length + 1000;
      console.info("Creating new assessment_id", assessment_id);

      //set assessment information
      console.info("Updating Assessment Template", assessment_id);
      // assessment_template.assessment_id = assessment_id;
      // console.log(guestProfile, "profileeeeeee");
      assessment_template.user_id =
        Object.keys(guestProfile).length > 0 ? guestProfile.userId : "";
      assessment_template.taken_by =
        Object.keys(guestProfile).length > 0
          ? guestProfile.name
          : _auth.getUserProfile().name; //Logged On user
      assessment_template.tenantId =
        Object.keys(guestProfile).length > 0
          ? guestProfile.tenantId
          : _auth.getUserProfile().tenantId;
      assessment_template.assessment_type =
        Object.keys(guestProfile).length > 0
          ? guestProfile.type
          : assessment_type; //Get Type from State
      assessment_template.assessment_status = "In Progress";
      assessment_template.assessment_date = new Date().toLocaleDateString(
        "en-us",
        {
          // weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );
      // console.info("ASSM TMP BEFORE Q", JSON.stringify(assessment_template));
      //add questions to assessment template

      console.info("Template with new questions", assessment_template);
      console.info("Settting assessment id state", assessment_id);
      // ////debugger - this is the main file
      setId(assessment_id);
      console.info("PUSHING Template to History", assessment_template);
      assessment_history.push(assessment_template);
      ////debugger;
      console.info("Assessment History", assessment_history);
      setAssessmentHistory(assessment_history);
      console.info("Setting Assessment History", assessment_history);
      setThisAssessment(assessment_template);
      console.info("This assessment", assessment_template);
      // console.log(q);
      // let question = JSON.parse(
      //   JSON.stringify(
      // assessment_history.find((x) => x.assessment_id === assessment_id)
      //   .questions))
      if (q.length === 10) {
        setPreassessment(true);
      }
      setAssessmentQuestions(JSON.parse(JSON.stringify(q)));
      // debugger;
    }
  };
  const UpdateHistoryObject = async (
    updated_q,
    finished,
    riskLevel,
    counts,
    correct,
    incorrect
  ) => {
    console.info("Updating History Object", _auth.getUserProfile());
    var test = assessment_history;
    console.info("Getting History from State", thisAssessment);
    const id =
      thisAssessment === undefined
        ? assessment_history.assessment_id
        : thisAssessment.assessment_id;
    console.info("Getting current assessment from history", id);
    // debugger;
    let current_assessment;
    if (assessment_history && assessment_history.assessment_id) {
      // console.log("Assessment_history", assessment_history.assessment_id);
      current_assessment = assessment_history;
    } else {
      current_assessment = assessment_history.find((x) => {
        // console.log(x, "x");
        return x.assessment_id === id;
      });
    }

    // debugger;
    // console.log("--> CURRENT ASSESSMENT <--", current_assessment);

    if (updated_q && current_assessment) {
      console.info("Updating Question", updated_q);
      // current_assessment.questions.push(updated_q);
      let current_q = current_assessment.questions.find(
        (x) => x._id.toString() === updated_q._id.toString()
      );
      // console.log(current_q, "Updating Question");
      // debugger;
      // we need each question have an answered_by name
      // console.log("Answeredby", _auth.getUserProfile().name);
      let guestProfile = _auth.getGuestProfile();
      let answered_by =
        guestProfile !== null && Object.keys(guestProfile).length > 0
          ? guestProfile.name
          : _auth.getUserProfile().name;
      current_q.answered_by = answered_by;
      current_q.your_answer = updated_q.your_answer;
      current_q.isCorrect = updated_q.isCorrect;
      // debugger;
      current_assessment.user_id =
        guestProfile !== null && Object.keys(guestProfile).length > 0
          ? guestProfile.userId
          : "";
      console.info("Questin Updated", current_q);
    }
    // debugger;
    console.info("Is this assessment finished?", finished);
    if (finished === "Finished") {
      console.info("FINISHING ASSESSMENT");
      current_assessment.risk_score = riskLevel;
      current_assessment.assessment_status = "Finished";
    }
    if (finished === "Finish Later") {
      console.info("FINISHING LATER");
      current_assessment.assessment_status = "Draft";
    }
    console.info("Correct", correct, "Incorrect", incorrect);
    // console.log(counts, "count");
    current_assessment.counts = counts;
    current_assessment.correct_answers = correct;
    current_assessment.incorrect_answers = incorrect;

    // //////debugger
    // test.find((x) => x.assessment_id === id).questions = q_array;
    console.info("updating state with updated assessment", current_assessment);
    setThisAssessment(current_assessment);
    // thisAssessment.questions = q_array;
    console.info("update local storage and then get assessment history again");
    console.info("Adding Test variable to new_history", test);
    // ////debugger
    let current;
    if (test && test.assessment_id) {
      current = test;
    } else {
      current = test.find((x) => {
        return x.assessment_id === id;
      });
    }

    let Profile = _auth.getGuestProfile();
    let guestProfile = JSON.parse(Profile);
    if (guestProfile !== null && Object.keys(guestProfile).length > 0) {
      // debugger;
      current.user_id = guestProfile.userId;
      const new_history = await _assessment.setAssessmentHistory(current, true);
      // const new_history = _assessment.setAssessmentHistory();
      // console.log(new_history);
      if (new_history) {
        console.info(
          "update local storage with New Histiroy and then get assessment history again",
          new_history.assessment_status
        );
        ////debugger;

        // isCheck.assessment_status !== "Finished"
        if (isPreassessment && finished !== "Finished") {
          ////debugger;
          setStep(2);
        }
        setAssessmentHistory(new_history);
        setProceed(true);
        // ////debugger
        console.info("NEW HISTORY", assessment_history);
        console.info("CURRENT ASSESSMENT FROM STATE", thisAssessment);
        console.info("REAL CURRENT ASSESSMENT", current_assessment);
        // console.log(currentStep, "stepsss");
      }
      if (isPreassessment && new_history && finished !== "Finished") {
        ////debugger;
        setStep(2);
      }
    } else {
      const new_history = await _assessment.setAssessmentHistory(
        current,
        false
      );
      // const new_history = _assessment.setAssessmentHistory();
      // console.log(new_history);
      if (new_history) {
        console.info(
          "update local storage with New Histiroy and then get assessment history again",
          new_history.assessment_status
        );
        ////debugger;

        // isCheck.assessment_status !== "Finished"
        if (isPreassessment && finished !== "Finished") {
          ////debugger;
          setStep(2);
        }
        setAssessmentHistory(new_history);
        setProceed(true);
        // ////debugger
        console.info("NEW HISTORY", assessment_history);
        console.info("CURRENT ASSESSMENT FROM STATE", thisAssessment);
        console.info("REAL CURRENT ASSESSMENT", current_assessment);
        // console.log(currentStep, "stepsss");
      }
      if (isPreassessment && new_history && finished !== "Finished") {
        ////debugger;
        setStep(2);
      }
    }
    // ////debugger
  };

  const HandleFinishLater = () => {
    UpdateHistoryObject(null, "Finish Later", null, null, null, null);
    audit.logAction("Finish Assessment Later");
    let profile = _auth.getGuestProfile();
    let guestProfile = JSON.parse(profile);

    if (guestProfile !== null && Object.keys(guestProfile).length > 0) {
      return history.push({
        pathname: "/",
      });
    } else {
      return history.push({
        pathname: "/assessments",
      });
    }
  };
  const FinishAssessment = (q) => {
    const risk_threshold = _assessment.getRiskThresholds();
    let Profile = _auth.getGuestProfile();
    let guestProfile = JSON.parse(Profile);
    const countOfEach = {};
    for (const num of counts) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }

    let correct = countOfEach["yes"] ? countOfEach["yes"] : 0;
    let incorrect = countOfEach["no"]
      ? countOfEach["no"]
      : 0 + countOfEach["unsure"]
      ? countOfEach["unsure"]
      : 0;
    // assessment_history.find((x) => x.assessment_id === assessment_id).questions;
    // //////debugger

    // console.log("correct", correct);
    // console.log("incorrect", incorrect);

    //this needs to be changed on the database side. we need to add a correct: true or false to the question object
    //and then change item.your_answer to item.correct
    // const yes_count = thisAssessment.questions.filter(
    //   (item) => item.your_answer === "yes"
    // ).length;

    // console.info("YES COUNT", yes_count);

    const riskLevel = _assessment.calculateRisk(
      counts,
      countOfEach,
      risk_threshold
    );
    let is_risk =
      countOfEach["yes"] === undefined || countOfEach["yes"] < counts.length
        ? true
        : false;
    console.info("CALC RISK", countOfEach["yes"], counts.length, is_risk);
    let risk_level = null;
    if (is_risk) {
      risk_level = "High";
    } else {
      risk_level = "Normal";
    }

    UpdateHistoryObject(
      null,
      "Finished",
      risk_level,
      counts,
      correct,
      incorrect
    );
    audit.logAction("Finish Assessment");
    if (guestProfile !== null && Object.keys(guestProfile).length > 0) {
      history.push("/thankyou");
    } else {
      setStep(currentStep + 1);
    }
  };

  const setCount = (count) => {
    setCounts([...counts, count]);

    console.info("countBefore", yesCount, noCount, unsureCount);
    if (count === "yes") {
      setyesCount(yesCount + 1);
      console.info("yesCount", yesCount);
    } else if (count === "no") {
      setNoCount(noCount + 1);
      console.info("noCount", noCount);
    } else if (count === "unsure") {
      setUnsureCount(unsureCount + 1);
      console.info("unsureCount", unsureCount);
    }

    console.info("count", yesCount + noCount + unsureCount);
    console.info("Counts", counts);
  };
  const countOfYes = () => {
    var countOfYes = 0;
    const countOfEach = _assessment.getCountOfEach(counts);
    countOfYes = countOfEach.yes;
    console.info("countOfYes", countOfYes);
    if (
      isNaN(countOfYes) ||
      countOfYes === "undefined" ||
      countOfYes === "" ||
      countOfYes === null
    ) {
      countOfYes = 0;
    }
    console.info("countOfYes", countOfYes);
    return countOfYes;
  };
  // const countOfYes = _assessment.getCountOfEach["yes"];
  const getPreviousQuestion = (q) => {
    const newQ = q - 1;
    setCurrentQuestion(newQ);
  };

  const goToActionPlan = () => {
    return history.push({
      pathname: "/actionPlan",

      state: {
        data: counts,
        thisAssessment: thisAssessment,
      },
    });
  };
  return (
    <main id="main" className="bg-white">
      <div id="top" className="w-full h-screen flex flex-col">
        <div
          id="options_container"
          className={classNames(
            currentStep == 4 || (!preassessment && currentStep == 3)
              ? null //"px-6 sm:px-6 lg:px-6 py-8 w-full"
              : "w-5/12 mx-auto",
            "h-full pt-4"
          )}
        >
          {preassessment
            ? (currentStep === 1 && (
                <div>
                  <Fade right>
                    <AssessmentIntro
                      setStep={setStep}
                      currentStep={currentStep}
                      type="preassessment"
                      name={assessment_name}
                      assessment_key={assessment_key}
                      intro={assessment_details.intro}
                      handleStartAssessment={handleStartAssessment}
                      resume={resume}
                    />{" "}
                  </Fade>
                </div>
              )) ||
              (currentStep == 2 && (
                <div className="space-y-4 mb-10 ">
                  <h1 className="mt-10  text-3xl text-slate-800 font-bold mb-6">
                    <span className="mt-10 uppercase text-3xl text-slate-800 font-bold mb-6">
                      Pre-assessment
                    </span>
                  </h1>
                  {assessmentQuestions // assessment_historythisAssessment.questions //   .find((x) => x.assessment_id === id) // assessment_history
                    .map(
                      (question, idx) =>
                        question.question != "" &&
                        idx == currentQuestion &&
                        currentQuestion < assessmentQuestions.length && (
                          <Fade right>
                            <div
                              id="scroll_wrapper"
                              className="space-y-4 p-10 min-h-full h-full bg-white-100 cursor-pointer grow-0 shrink-0 overflow-hidden"
                            >
                              <div className="flex-1 ">
                                <h3 className="text-md font-bold text-slate-900 mb-4">
                                  Question {idx + 1} of{" "}
                                  {assessmentQuestions.length}
                                </h3>
                              </div>
                              <div className="h-24 text-base  text-slate-800 grow-0 shrink-0 mb-20">
                                {question.question}
                              </div>
                              {!_assessment.loader ? (
                                <div>
                                  <YesNoQuestions
                                    question={question}
                                    setUpdatedQuestion={setUpdatedQuestion}
                                    UpdateHistoryObject={UpdateHistoryObject}
                                    className="space-y-1 position-absolute"
                                    qid={idx + 1}
                                    currentQuestion={currentQuestion}
                                    setCurrentQuestion={setCurrentQuestion}
                                    answerOpts={notificationMethods}
                                    questions={assessmentQuestions}
                                    setCount={setCount}
                                    FinishAssessment={FinishAssessment}
                                  />
                                </div>
                              ) : (
                                <div class="flex justify-center items-center">
                                  <img
                                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                                    src={Spinner}
                                    width="32"
                                    height="32"
                                    alt="loading.."
                                  />
                                </div>
                              )}
                              <div className="flex justify-between">
                                {/* {currentQuestion > 0 ? ( */}
                                <button
                                  className={classNames(
                                    currentQuestion == 0
                                      ? "cursor-not-allowed"
                                      : null,
                                    "btn mt-10 bg-primary-300 text-white float-left"
                                  )}
                                  // className="btn mt-10 bg-primary-300 text-white float-left"
                                  disabled={currentQuestion == 0}
                                  onClick={() =>
                                    setCurrentQuestion(currentQuestion - 1)
                                  }
                                >
                                  &lt;- Previous Question
                                </button>
                                {/* ) : (
                                    " "
                                  )} */}
                                {currentQuestion <
                                assessmentQuestions.length - 1 ? (
                                  <button
                                    className="btn mt-10 bg-brand-500  text-white float-right"
                                    disabled={
                                      currentQuestion ==
                                      assessmentQuestions.length - 1
                                    }
                                    onClick={() =>
                                      // currentQuestion <
                                      //   assessmentQuestions.length() ?
                                      setCurrentQuestion(currentQuestion + 1)
                                    }
                                  >
                                    Next Question -&gt;
                                  </button>
                                ) : thisAssessment.questions.filter(
                                    (item) => "your_answer" in item
                                  ).length >= assessmentQuestions.length ? (
                                  <button
                                    className="btn mt-10 bg-brand-500  text-white float-right"
                                    // to="/onboarding-02"
                                    //onClick={() => setStep(currentStep + 1)}
                                    onClick={() => FinishAssessment()}
                                  >
                                    Finish pre-assessment
                                  </button>
                                ) : (
                                  <>
                                    <Tooltip
                                      size="xl"
                                      bg="dark"
                                      position={"left"}
                                      title={"Where's my button?"}
                                      description={
                                        "It looks like you skipped one or more questions.  Check the current question and if it is answered, go back and check the rest of your assessment.  Once you've answered all your questions, come back here and finish you assessment."
                                      }
                                    >
                                      <button className=" mt-10 text-red-500 text-white float-right">
                                        {assessmentQuestions.length -
                                          thisAssessment.questions.filter(
                                            (item) => "your_answer" in item
                                          ).length}{" "}
                                        question
                                        {assessmentQuestions.length -
                                          thisAssessment.questions.filter(
                                            (item) => "your_answer" in item
                                          ).length >
                                        1
                                          ? "s"
                                          : null}{" "}
                                        skipped
                                      </button>
                                    </Tooltip>{" "}
                                  </>
                                )}
                              </div>
                              <div className="mt-5 flex items-center ">
                                {currentStep < 3 &&
                                  localGuestProfile == null && (
                                    // <button
                                    //   className="text-slate-500 text-sm underline"
                                    //   onClick={() => HandleFinishLater()}
                                    //   // to="/onboarding-02"
                                    // >
                                    //   Finish later
                                    // </button>
                                    <button
                                      className="text-slate-500 text-sm underline"
                                      onClick={() => HandleFinishLater()}
                                      // to="/onboarding-02"
                                    >
                                      Finish later
                                    </button>
                                  )}
                              </div>
                            </div>
                          </Fade>
                        )
                      // : (
                      //   <div>You Finished</div>
                      // )
                    )}
                </div>

                // </div>
              )) ||
              (currentStep == 3 &&
                //BROKEN
                (countOfYes() === assessmentQuestions.length ? (
                  <Empty
                    buttonColor={"green"}
                    title={"You're ready!"}
                    content={
                      "You did a great job on the pre-assessment! You're ready to get started."
                    }
                    buttonText={"Go home"}
                    buttonLink={"/home"}
                  />
                ) : (
                  <div>
                    {confirmed ? (
                      <Empty
                        buttonColor={"green"}
                        title={"You're all set!"}
                        content={
                          "One of our team members will be in touch with you within 48 hours."
                        }
                        scheduling={true}
                        buttonText={"Done"}
                        buttonLink={"/home"}
                      />
                    ) : (
                      <div>
                        <h1 className="text-3xl text-slate-800 font-bold mb-6">
                          Schedule a consultation
                        </h1>
                        <div className="mb-6">
                          Close but...based on your results, it looks like there
                          are some improvement opportunities. We recommend
                          scheduling a consultation with one of our team members
                          to discuss.
                        </div>
                        {!confirmed && (
                          <ContactConfirm
                            setModalOpen={setShowModal}
                            setConfirmed={setConfirmed}
                            company={"Breachlink"}
                            email={_auth.getUserProfile().email}
                            vendor_email={"vendor_email"}
                            type="pre-assessment"
                          />
                        )}
                        {/* <OptionButtons setConfirmed={setConfirmed} /> */}
                      </div>
                    )}
                  </div>
                )))
            : (currentStep === 1 && (
                <div>
                  <Fade right>
                    <AssessmentIntro
                      handleStartAssessment={handleStartAssessment}
                      setStep={setStep}
                      currentStep={currentStep}
                      type="assessment"
                      name={assessment_name}
                      assessment_key={assessment_key}
                      intro={assessment_details.intro}
                      resume={resume}
                    />{" "}
                  </Fade>
                </div>
              )) ||
              (currentStep == 2 && (
                <div id="assessment_container" className="space-y-2 h-screen ">
                  <h1 className="mt-4  text-3xl text-slate-800 font-bold ">
                    <span className=" capitalized text-3xl text-slate-800 font-bold">
                      {assessment_name}
                    </span>{" "}
                    Assessment
                  </h1>

                  {assessmentQuestions.map(
                    (question, idx) =>
                      question.question != "" &&
                      idx == currentQuestion &&
                      currentQuestion < assessmentQuestions.length && (
                        <Fade right>
                          <div className="pt-6 cursor-pointer grow-0 shrink-0 h-full">
                            <div className="space-y-4 ">
                              <div className="grid grid-cols-6   text-md font-bold text-slate-900">
                                {/* Question Number and Tooltip */}
                                <div className="flex col-span-3  text-md font-bold text-slate-900">
                                  Question {idx + 1} of{" "}
                                  {assessmentQuestions.length}
                                  {question.hover_question && (
                                    <Tooltip
                                      size="xl"
                                      bg="dark"
                                      position={"left"}
                                      title={question.hover_question}
                                      description={question.hover_answer}
                                    >
                                      <div className="block ml-3 text-xs text-accent-700 underline">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="inline mr-2 h-4 w-4"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                          />
                                        </svg>
                                        {/* <svg
                                          className=" inline mr-2 w-4 h-4 fill-current text-slate-400"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                                        </svg> */}
                                        {question.hover_question}
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                                {/* Question Name and Area */}
                                <div className="col-span-3 flex justify-end">
                                  <div className="flex">
                                    <div className="mr-3 text-primary-800 text-sm font-bold">
                                      {question.Name}
                                    </div>
                                    <div className=" flex w-min px-4 text-center text-xs align-middle uppercase font-semibold text-white bg-accent-700 rounded-full">
                                      <div className="m-auto">
                                        {question.Area}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Progress Bar */}
                              <div class=" w-full bg-gray-200 h-3  align-middle rounded-full">
                                <div
                                  class="bg-accent-500 h-3 rounded-full"
                                  style={{
                                    width:
                                      ((idx + 1) / assessmentQuestions.length) *
                                        100 +
                                      "%",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="py-6 text-base font-medium text-slate-800 grow-0 shrink-0 ">
                              {question.question}{" "}
                            </div>
                            {!_assessment.loader ? (
                              <div>
                                <YesNoQuestions
                                  question={question}
                                  setUpdatedQuestion={setUpdatedQuestion}
                                  UpdateHistoryObject={UpdateHistoryObject}
                                  className="space-y-1 position-absolute"
                                  qid={idx + 1}
                                  currentQuestion={currentQuestion}
                                  setCurrentQuestion={setCurrentQuestion}
                                  answerOpts={notificationMethods}
                                  questions={assessmentQuestions}
                                  setCount={setCount}
                                  FinishAssessment={FinishAssessment}
                                />
                              </div>
                            ) : (
                              <div class="flex justify-center items-center">
                                <img
                                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                                  src={Spinner}
                                  width="32"
                                  height="32"
                                  alt="loading.."
                                />
                              </div>
                            )}
                            <div className="-bottom-0 flex justify-between">
                              {currentQuestion > 0 ? (
                                <button
                                  className={classNames(
                                    currentQuestion == 0
                                      ? "cursor-not-allowed"
                                      : null,
                                    "btn mt-10 bg-primary-500 text-white float-left"
                                  )}
                                  // className="btn mt-10 bg-primary-300 text-white float-left"
                                  disabled={currentQuestion == 0}
                                  onClick={() =>
                                    setCurrentQuestion(currentQuestion - 1)
                                  }
                                >
                                  &lt;- Previous Question
                                </button>
                              ) : (
                                <div className="flex flex-wrap justify-center sm:justify-start mb-8 sm:mb-0 -space-x-3 -ml-px">
                                  {" "}
                                </div>
                              )}
                              {currentQuestion <
                              assessmentQuestions.length - 1 ? (
                                <button
                                  className="btn mt-10 bg-brand-500  text-white float-right"
                                  disabled={
                                    currentQuestion ==
                                    assessmentQuestions.length - 1
                                  }
                                  onClick={() =>
                                    // currentQuestion <
                                    //   preassessmentQuestions.length() ?
                                    setCurrentQuestion(currentQuestion + 1)
                                  }
                                >
                                  Next Question -&gt;
                                </button>
                              ) : thisAssessment.questions.filter(
                                  (item) => "your_answer" in item
                                ).length >= assessmentQuestions.length ? (
                                <button
                                  className="btn mt-10 bg-brand-500  text-white float-right"
                                  // to="/onboarding-02"
                                  // onClick={() => setStep(currentStep + 1)}
                                  onClick={() => FinishAssessment()}
                                >
                                  Finish Assessment
                                </button>
                              ) : (
                                <>
                                  <Tooltip
                                    size="xl"
                                    bg="dark"
                                    position={"left"}
                                    title={"Where's my button?"}
                                    description={
                                      "It looks like you skipped one or more questions.  Check the current question and if it is answered, go back and check the rest of your assessment.  Once you've answered all your questions, come back here and finish you assessment."
                                    }
                                  >
                                    <button className=" mt-10 text-red-500 text-white float-right">
                                      {assessmentQuestions.length -
                                        thisAssessment.questions.filter(
                                          (item) => "your_answer" in item
                                        ).length}{" "}
                                      question
                                      {assessmentQuestions.length -
                                        thisAssessment.questions.filter(
                                          (item) => "your_answer" in item
                                        ).length >
                                      1
                                        ? "s"
                                        : null}{" "}
                                      skipped
                                    </button>
                                  </Tooltip>{" "}
                                </>
                              )}
                            </div>
                            <div className="mt-5 flex items-center ">
                              {currentStep < 3 && localGuestProfile == null && (
                                <button
                                  className="text-slate-500 text-sm underline"
                                  onClick={() => HandleFinishLater()}
                                  // to="/onboarding-02"
                                >
                                  Finish later
                                </button>
                                // <NavLink
                                //   className="text-slate-500 text-sm underline"
                                //   exact
                                //   to="/home"
                                // >
                                //   Finish later
                                // </NavLink>
                              )}
                            </div>
                            {/* <div className="mt-5 flex items-center ">
                              {currentStep < 3 && (
                                <NavLink
                                  className="text-slate-500 text-sm underline"
                                  exact
                                  to="/home"
                                >
                                  Finish later
                                </NavLink>
                              )}

                              {currentStep > 1 && currentStep < 4 && (
                                <button
                                  //flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400
                                  className="btn bg-primary-500 hover:bg-primary-600 text-white ml-auto float-left"
                                  // to="/onboarding-02"
                                  onClick={() =>
                                    currentStep > 1
                                      ? setStep(currentStep - 1)
                                      : null
                                  }
                                  disabled={currentStep == 1}
                                >
                                  &lt;- Previous Step
                                </button>
                              )}

                              {currentStep < steps.length ? (
                                <button
                                  className="btn bg-brand-500 hover:bg-brand-600 text-white ml-auto"
                                  onClick={() => setStep(currentStep + 1)}
                                >
                                  Step {currentStep + 1} -&gt;
                                </button>
                              ) : preassessment ? (
                                <NavLink
                                  className="btn bg-brand-500 hover:bg-brand-600 text-white ml-auto float-right"
                                  exact
                                  to="/home"
                                >
                                  Finish -&gt;
                                </NavLink>
                              ) : (
                                counts.length > 0 && (
                                  <button
                                    onClick={goToActionPlan}
                                    className="btn bg-brand-500 hover:bg-brand-600 text-white ml-auto float-right"
                                  >
                                    See my full action plan -&gt;
                                  </button>
                                )
                              )}
                            </div> */}
                          </div>
                        </Fade>
                      )
                  )}
                </div>
              )) ||
              (currentStep == 3 &&
              thisAssessment.questions.filter((item) => "your_answer" in item)
                .length >=
                assessmentQuestions.length >
                0 ? (
                <Empty
                  buttonColor={"secondary"}
                  title={"See Results"}
                  content={
                    "Click below to see the results of your self-assessment and begin improving!"
                  }
                  buttonText={"See Results"}
                  buttonLink={"/competencies"}
                />
              ) : (
                // <ContentSplit
                //   thisAssessment={thisAssessment}
                //   counts={counts}
                //   goToActionPlan={goToActionPlan}
                // />
                <div>
                  {/*need to store count after every question */}
                  You have to take the Assessment to see the Action Plan
                </div>
              )) ||
              (currentStep == 4 && (
                <Empty
                  buttonColor={"green"}
                  title={"View Action Plan"}
                  content={"Click below to see your detailed action plan"}
                  buttonText={"View Action Plan"}
                />
              ))}
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* Buttons */}
        {/* <div id="options_container" className="w-6/12 mx-auto">
          <div className="mt-10 flex items-center ">
            {currentStep < 3 && (
              <NavLink
                className="btn bg-primary-200 hover:bg-primary-200 text-white ml-auto"
                exact
                to="/home"
              >
                Finish later
              </NavLink>
            )}

            {currentStep > 1 && currentStep < 4 && (
              <button
                //flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400
                className="btn bg-primary-500 hover:bg-primary-600 text-white ml-auto float-left"
                // to="/onboarding-02"
                onClick={() =>
                  currentStep > 1 ? setStep(currentStep - 1) : null
                }
                disabled={currentStep == 1}
              >
                &lt;- Previous Step
              </button>
            )}

            {currentStep < steps.length ? (
              <button
                className="btn bg-brand-500 hover:bg-brand-600 text-white ml-auto"
                // to="/onboarding-02"
                onClick={() => setStep(currentStep + 1)}
              >
                Step {currentStep + 1} -&gt;
              </button>
            ) : preassessment ? (
              <NavLink
                className="btn bg-brand-500 hover:bg-brand-600 text-white ml-auto float-right"
                exact
                to="/home"
              >
                Finish -&gt;
              </NavLink>
            ) : (
              counts.length > 0 && (
                <button
                  onClick={goToActionPlan}
                  className="btn bg-brand-500 hover:bg-brand-600 text-white ml-auto float-right"
                >
                  See my full action plan -&gt;
                </button>
              )
            )}
          </div>
        </div> */}
      </div>
    </main>
  );
}

export default Assessment;
