import { Fragment, useEffect, useRef, useState } from "react";
import { RadioGroup, Transition } from "@headlessui/react";
import { ArrowDownLeftIcon, CheckIcon } from "@heroicons/react/20/solid";
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import Slide from "react-reveal/Slide";
import Badge from "../../../components/Badge";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import html2canvas from "html2canvas";
import BidCompareTable from "./BidCompareTable";
import { Solicitations } from "../../../modules/_solicitations";
import Tip from "../../../components/Tip";
import PdfViewer from "../../../components/PdfViewer";
import PDFViewerModal from "../../../components/PDFViewerModal";
import { Files } from "../../../modules/_files";
import { openAI } from "../../../modules/openAI";
import { set } from "date-fns";
import { Icon } from "@iconify/react";
import LoadingRocket from "../../../images/rocket_loading.gif";
import { FeatureFlag } from "../../../components/FeatureFlag";
import AssumptionsCalc from "../../../components/AssumptionsCalc";
import TextEditor from "../../../components/TextEditor";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { SELECT_WINNER_OPTIONS } from "../../../constants/constants";
import Card from "../../component/Card";
import { EditorState, convertFromRaw } from "draft-js";
import Drawer from "../../../components/Drawer";
import InputWithDropdown from "../../../components/InputWithDropdown";
import TextInputWithLabel from "../../../components/TextInputWithLabel";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import DropdownMenu from "../../../components/DropdownMenu";
import Skeleton from "../../../components/Skeleton";
import ChatComponent from "../../../components/Chat/ChatComponent";
import { Conversations } from "../../../modules/_conversations";
import CopyComponent from "../../../components/CopyComponent";

const XLSX = require("xlsx");
const ExcelJS = require("exceljs");
const frequencies = [
  { value: "monthly", label: "Flat Rate", priceSuffix: "" },
  { value: "annually", label: "Variable Rate", priceSuffix: "" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const messages = [
  "Hold on while we compare your bids...",
  "We're still working on it...",
  "We'll get there soon...",
  "We're almost there, we promise...",
  "It's gonna be worth the wait...",
  "AI can be slow, but it's game changing...",
  "Hold on while we check with our bots to see what's going on...",
  "If it's any consolation, it would take longer to do it yourself...",
  "Ok, seriously, we're still thinking...",
  "Stick around, it'll be just a little longer...",
  "The good news is, you aren't paying for this....",
  "If you're a little annoyed, our AI Assistant's name is Blair...",
  "Why Blair, you ask?",
  "Breach(l)ink AI Robot...",
  "Creative, right?",
];

export default function BidCompare(props) {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [solicitation, setSolicitation] = useState(props.solicitation);

  const [allResponses, setAllResponses] = useState(
    props.solicitation?.responses
  );
  const [tiers, setTiers] = useState(props.data);
  const [selectingBid, setSelectingBid] = useState(false);
  const [showBidCompareTable, setShowBidCompareTable] = useState(true);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const [viewCalculator, setViewCalculator] = useState(false);
  const [winnerReason, setWinnerReason] = useState(false);
  const [hideSelectDiv, setHideSelectDiv] = useState(false);
  const [hideEmailDiv, setHideEmailDiv] = useState(true);
  const [summary, setSummary] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [summaryLoadingText, setSummaryLoadingText] = useState(messages[0]);
  const [summaryDiv, setSummaryDiv] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);

  const [attachmentFile, setAttachmentFile] = useState({});
  const [hideAttachmentDiv, setAttachmentDiv] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [rtf_message, setRtfMessage] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedReason, setSelectedReason] = useState([]);
  const [selectedOther, setOther] = useState(false);
  const [otherDescription, setOtherDescription] = useState("");

  const [showSendBackToVendorReason, setShowSendBackToVendorReason] =
    useState(false);
  const [showSendBackToVendorMessage, setShowSendBackToVendorMessage] =
    useState(false);
  const [sendBackToVendorReason, setSendBackToVendorReason] = useState("");
  const [sendBackToVendorReasonMessage, setSendBackToVendorReasonMessage] =
    useState("");
  const [
    sendBackToVendorReasonMessageRTF,
    setSendBackToVendorReasonMessageRTF,
  ] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [initialConversation, setInitialConversation] = useState([
    {
      message: "Hi, I'm Claire. I'm here to help you.",
      sender: "bot",
    },
    {
      message: "To get started, select a prompt below.",
      sender: "bot",
    },
  ]);
  const [conversation, setConversation] = useState([
    {
      message: "Hi, I'm Claire. I'm here to help you.",
      sender: "bot",
    },
    {
      message: "To get started, select a prompt below.",
      sender: "bot",
    },
  ]);
  const [initialQuestions, setInitialQuestions] = useState([
    {
      id: 1,
      name: "What clarifying questions should I ask?",
      action_key: "questions",
      action: (e) => {
        updateConversation({
          promptId: 1,
          message: "What clarifying questions should I ask?",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        handleGetAnswer(e, "questions", conversationId, {
          promptId: 1,
          message: "What clarifying questions should I ask?",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
    {
      id: 2,
      name: "Who do you recommend?",
      action_key: "recommend",
      action: (e) => {
        updateConversation({
          promptId: 2,
          message: "Who do you recommend?",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        handleGetAnswer(e, "recommend", conversationId, {
          promptId: 2,
          message: "Who do you recommend?",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
    {
      id: 3,
      name: "Remove the noise",
      action_key: "revision",
      action: (e) => {
        updateConversation({
          promptId: 3,
          message: "Remove the noise",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        handleGetAnswer(e, "revision", conversationId, {
          promptId: 3,
          message: "Remove the noise",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
    {
      id: 4,
      name: "Summarize the bids.",
      action_key: "summarize",
      action: (e) => {
        updateConversation({
          promptId: 4,
          message: "Summarize the bids.",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        console.log("Current ConversationId", conversationId);
        handleGetAnswer(e, "summarize", conversationId, {
          promptId: 4,
          message: "Summarize the bids.",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
  ]);
  const [questionsForClaire, setQuestionsForClaire] = useState([
    {
      id: 1,
      name: "What clarifying questions should I ask?",
      action_key: "questions",
      action: (e) => {
        updateConversation({
          promptId: 1,
          message: "What clarifying questions should I ask?",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        handleGetAnswer(e, "questions", conversationId, {
          promptId: 1,
          message: "What clarifying questions should I ask?",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
    {
      id: 2,
      name: "Who do you recommend?",
      action_key: "recommend",
      action: (e) => {
        updateConversation({
          promptId: 2,
          message: "Who do you recommend?",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        handleGetAnswer(e, "recommend", conversationId, {
          promptId: 2,
          message: "Who do you recommend?",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
    {
      id: 3,
      name: "Remove the noise",
      action_key: "revision",
      action: (e) => {
        updateConversation({
          promptId: 3,
          message: "Remove the noise",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        handleGetAnswer(e, "revision", conversationId, {
          promptId: 3,
          message: "Remove the noise",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
    {
      id: 4,
      name: "Summarize the bids.",
      action_key: "summarize",
      action: (e) => {
        updateConversation({
          promptId: 4,
          message: "Summarize the bids.",
          sender: "user",
        });
        console.log("Ask Claire:", "questions", allResponses);
        console.log("Current ConversationId", conversationId);
        handleGetAnswer(e, "summarize", conversationId, {
          promptId: 4,
          message: "Summarize the bids.",
          sender: "user",
        });
      },
      show: true,
      group: "Select",
    },
  ]);

  const [gettingAnswer, setGettingAnswer] = useState(false);
  const [nextTransitionToShow, setNextTransitionToShow] = useState("");
  const [transitionToShow, setTransitionToShow] = useState("bid-compare-table");
  // options: message-winning-vendor-transition-container, winner-reason-transition-container, send-back-to-vendor-transition-container, send-back-to-vendor-reason-transition-container
  const printRef = useRef();
  const tableRef = useRef();
  const radioOptions = SELECT_WINNER_OPTIONS;
  useEffect(() => {
    console.log("props.loading changed", props.loading);
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    console.log(
      "DEBUG props.solicitation changed",
      props.solicitation,
      props.data,
      summaryLoading
    );

    // Check if props.solicitation is different from the current solicitation

    setSolicitation(props.solicitation);

    // Only update allResponses and tiers if they are different
    if (props.data) {
      setAllResponses(props.data);
      setTiers(props.data);
    }
  }, [props.solicitation]);

  useEffect(() => {
    (async () => {
      let exists = false;

      // if (!solicitation) return;
      //check to see if conversationIds exist and is not empty
      let conversations;
      let conversation;
      let conversationId;
      let updatedSolicitation = solicitation;
      if (
        solicitation.conversationIds &&
        solicitation.conversationIds.length > 0
      ) {
        conversations = solicitation.conversationIds;
        console.log(
          "DEBUG - conversationIds exist, check for conversation",
          conversations
        );
        conversation = conversations.find((item) => {
          return item.type == "bid-compare";
        });

        if (conversation) {
          exists = true;
          conversationId = conversation.conversationId;
          console.log(
            "DEBUG - conversationIds exist, conversation found",
            conversation
          );
        } else {
          exists = false;
          console.log("DEBUG - conversationIds exist, conversation not found");
        }
      } else {
        console.log("DEBUG - conversationIds do not exist");
        exists = false;
      }

      if (!exists) {
        console.log("DEBUG - conversationIds do not exist, create it");
        conversationId = await Solicitations.CreateConversation({
          solicitationId: solicitation._id,
          type: "bid-compare",
        });

        updatedSolicitation = await Solicitations.getById({
          id: solicitation._id,
          projectId: solicitation.projectId,
        });
        console.log(
          "DEBUG - conversationIds do not exist, updatedSolicitation",
          JSON.stringify(updatedSolicitation.conversationIds)
        );

        conversation = updatedSolicitation.conversationIds.find((item) => {
          return item.type == "bid-compare";
        });

        console.log("DEBUG - conversationIds Conversation", conversation);
        console.log("DEBUG - conversationIds ConversationId", conversationId);
        console.log(
          "DEBUG - conversationIds UpdatedSoliciation",
          JSON.stringify(updatedSolicitation.conversationIds)
        );
      }
      console.log(
        "DEBUG - conversationIds getting conversationHistory",
        conversationId
      );
      const conversationHistory = await Conversations.getConversationHistory({
        conversationId: conversationId,
        solicitationId: solicitation._id,
        type: "bid-compare",
      });
      console.log(
        "DEBUG - conversationIds conversationHistory",
        conversationHistory
      );
      console.log(
        "DEBUG - conversationIds conversationHistory.messages",
        conversationHistory.messages
      );
      console.log(
        "DEBUG - conversationIds updatedSolicitation",
        updatedSolicitation
      );

      setConversation(conversationHistory.messages);
      setConversationId(conversationHistory._id);
      setSolicitation(updatedSolicitation);
    })();
  }, []);

  // useEffect(() => {
  //   const questions =

  //   setQuestionsForClaire(questions);
  // }, []);

  useEffect(() => {
    console.log("DEBUG3 - solicitation updated", solicitation);
  }, [solicitation]);

  useEffect(() => {
    console.log("DEBUG2 - conversations changed", conversation, conversationId);
  }, [conversation, conversationId]);

  // useEffect(() => {
  //   (async () => {
  //     let solicitation = await Solicitations.getById({
  //       id: solicitation._id,
  //       projectId: solicitation.projectId,
  //     });
  //     setSolicitation(solicitation);
  //   })();
  // }, [conversationId]);

  useEffect(() => {
    console.log("props.errMessage changed", props.errMessage);
    setErrMessage(props.errorMessage);
  }, [props.errorMessage]);

  useEffect(() => {
    console.log("allResponses solicitation changed", solicitation);
    if (solicitation?.responses !== allResponses) {
      setAllResponses(solicitation?.responses);
      setTiers(solicitation?.responses);
    }
  }, [solicitation]);

  useEffect(() => {
    console.log("Conversation changed, updating questions", conversation);
    //remove prompts from questionsForClaire
    //if they arein the conversatin
    let newQuestions = questionsForClaire.filter((question) => {
      let found = conversation.find((item) => {
        return item.promptId == question.id;
      });
      return !found;
    });
    setQuestionsForClaire(newQuestions);
  }, [conversation]);

  useEffect(() => {
    if (showDrawer) {
      executeScroll("conversation-end");
    }
  }, [conversation]);

  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById("last-message");
    if (element) {
      element.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    }
  };

  const handleDeleteConversationHistory = async () => {
    const deleted = await Conversations.deleteConversationHistory({
      conversationId: conversationId,
      solicitationId: solicitation._id,
      type: "bid-compare",
    });

    if (deleted) {
      setConversation([...initialConversation]);
      // setConversation([...initialConversation, { key: Date.now() }]); // Ensure a new array reference with a unique key
      setQuestionsForClaire([...initialQuestions, { key: Date.now() }]); // Ensure a new array reference with a unique key
    }
  };
  // END CAN I REMOVE THIS???? I THINK SO

  // useEffect(() => {
  //   let timeouts = [];

  //   if (summaryLoading && props.modalOpen && props.modalOpen == true) {
  //     messages.forEach((message, index) => {
  //       let timeout = setTimeout(() => {
  //         setSummaryLoadingText(message);
  //       }, index * 4000);
  //       timeouts.push(timeout);
  //     });
  //   } else {
  //     timeouts.forEach((timeout) => clearTimeout(timeout));
  //     setSummaryLoadingText("");
  //   }
  //   return () => timeouts.forEach((timeout) => clearTimeout(timeout)); // cleanup function
  // }, [summaryLoading]);

  // useEffect(() => {
  //   let messageIndex = 1; // Start from the second message
  //   let intervalId;

  //   if (summaryLoading) {
  //     intervalId = setInterval(() => {
  //       setSummaryLoadingText(messages[messageIndex]);
  //       messageIndex++;
  //       if (messageIndex >= messages.length) {
  //         messageIndex = 0; // Reset the index if it exceeds the length of messages
  //       }
  //     }, 4000);
  //   } else {
  //     setSummaryLoadingText("");
  //   }

  //   return () => clearInterval(intervalId); // cleanup function
  // }, [summaryLoading]);

  const updateConversation = (message) => {
    setConversation((prevConversation) => [...prevConversation, message]);
  };

  const handleNavigateBack = () => {
    // debugger
    props.handleNavigate(); // call the function passed as a prop
  };

  const handleDownloadHTMLPdf = async () => {
    // manageLoading("pdf", true);
    const elementHTML = printRef.current;
    var doc = new jsPDF();

    // Source HTMLElement or a string containing HTML.
    // var elementHTML = document.querySelector("#contentToPrint");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("document-html.pdf");
      },
      margin: [10, 10, 10, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 190, //target width in the PDF document
      windowWidth: 675, //window width in CSS pixels
    });

    // manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };
  const handleDownloadPdf = async () => {
    // manageLoading("pdf", true);
    //ORAN
    // const root = parse(printRef.current);
    // console.log("ROOT HTML PARSED", root);
    // manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };
  const handleDownloadPdfImage = async () => {
    // let name = `Bid Comparison for ${solicitation?.projectId?.projectId}-${solicitation.incrementId}  ${solicitation?.name}`;
    // const element = document.getElementById("response_table");
    // const canvas = await html2canvas(element, {
    //   ignoreElements: (element) =>
    //     element.getAttribute("data-html2canvas-ignore") === "true",
    // });

    // const doc = new jsPDF("l", "in");
    // doc.setFont("Helvetica");
    // doc.setFontSize("12");
    // doc.text(0.5, 0.33, name);
    // doc.text(10.5, 0.33, utils.formatDate(new Date(), "short"));

    // // Calculate the width and height of the image to fit the page with 0.5 inch margin
    // const pageWidth = doc.internal.pageSize.getWidth() - 1; // Subtracting margins
    // const pageHeight = doc.internal.pageSize.getHeight() - 1; // Subtracting margins
    // let imgWidth = pageWidth;
    // let imgHeight = (canvas.height * imgWidth) / canvas.width;

    // // Check if the height exceeds the page height
    // if (imgHeight > pageHeight) {
    //   imgHeight = pageHeight;
    //   imgWidth = (canvas.width * imgHeight) / canvas.height;
    // }

    // const xPosition = (doc.internal.pageSize.getWidth() - imgWidth) / 2;
    // const yPosition = (doc.internal.pageSize.getHeight() - imgHeight) / 2;

    // doc.addImage(
    //   canvas,
    //   "PNG",
    //   xPosition,
    //   yPosition,
    //   imgWidth,
    //   imgHeight,
    //   "",
    //   "FAST"
    // );
    // doc.save("Bid Responses.pdf");

    const table = document.getElementById("response_table");
    const rows = Array.from(table.querySelectorAll("tr"));
    const data = rows.map((row) => {
      return Array.from(row.querySelectorAll("td, th")).map(
        (cell) => cell.innerText
      );
    });
    const numColumns = data[0].length;
    const cellWidth = 110;
    const margin = 20;
    // Set document header with the current date on the right side
    const columnStyles = {};
    for (let i = 0; i < numColumns; i++) {
      columnStyles[i] = { cellWidth: cellWidth }; // Set the width for each column
    }

    const pageWidth = numColumns * cellWidth + 2 * margin;
    //if the pageWidth is less than A4, set it to A4
    if (pageWidth < 210) {
      pageWidth = 210;
    }

    const pdf = new jsPDF({
      format: [pageWidth, 400], // "a4", // You can choose a different format
      unit: "mm",
      orientation: "landscape",
    });
    let name = `Bid Comparison for ${solicitation?.projectId?.projectId}-${solicitation.incrementId}  ${solicitation?.name}`;
    pdf.setFont("Helvetica");
    pdf.setFontSize("12");

    // Set document header
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    // Get the width of the date text
    const dateWidth = pdf.getStringUnitWidth(formattedDate);

    pdf.text(250 - dateWidth - 1, 10, formattedDate);
    pdf.text(20, 10, name);
    // Define table headers and data
    const tableConfig = {
      startY: 20,
      //head: [headers],
      body: data,
      columnStyles: columnStyles,
      cellStyles: {
        rowEven: { fillColor: [255, 0, 0] }, // Red background color for even-numbered rows
        // Add more custom styling as needed
      },
    };

    // Generate the table using autoTable
    pdf.autoTable(tableConfig);

    // Save the PDF
    pdf.save("Bid Responses.pdf");
  };
  const handleCalculateBids = async () => {
    setViewCalculator(true);
  };
  const handleGetAnswer = async (e, action, cidd, message) => {
    // e.preventDefault();
    let cid;
    setGettingAnswer(true);
    console.log(
      "handleGetAnswer Find conversationId",
      conversationId,
      solicitation
    );

    // if (!conversationId) {
    //   cid = solicitation?.conversationIds?.find((item) => {
    //     return item.type == "bid-compare";
    //   })?.conversationId;
    //   if (cid) {
    //     console.log("handleGetAnswer conversationId", cid);
    //   }
    // } else {
    //   console.log("handleGetAnswer conversationId exists", cidd);
    //   cid = conversationId;
    // }
    let myResponses = solicitation.responses;
    console.log("handleGetAnswer allResponses", allResponses, solicitation);
    console.log("handleGetAnswer myResponses", myResponses);
    let responses;
    if (!allResponses) {
      responses = solicitation.responses;
    } else {
      responses = allResponses;
    }
    console.log("handleGetAnswer responses", responses);
    try {
      responses = JSON.parse(JSON.stringify(myResponses));
    } catch (error) {
      console.log("Error parsing responses", error);
      updateConversation({
        message: error,
        sender: "bot",
      });
      setGettingAnswer(false);
    }
    console.log("handleGetAnswer", responses);
    responses.map((response) => {
      console.log("handleSummarizeBids removing response", response.services);
      delete response.accept_nda;
      delete response.answers;
      delete response.answers;
      delete response.comments;
      delete response.companyId;
      delete response.createdAt;
      delete response.inviteId;
      delete response.projectId;
      delete response.solicitationId;
      // delete response.status;

      delete response.submittedByEmail;

      delete response.submittedOn;
      delete response.tenantId;
      delete response.updatedAt;
      delete response.vendorCompanyId;
      delete response._id;
      delete response.vendorId;
      delete response.vendorNotified;
      Object.keys(response.services).map((service) => {
        let contentState = {};
        if (response.services[service].additional_comments_object) {
          let rawEditorState =
            response.services[service].additional_comments_object;
          let contentState;
          if (rawEditorState instanceof EditorState) {
            console.log(
              "rawEditorState instanceof EditorState",
              rawEditorState
            );
            contentState = convertFromRaw(rawEditorState);
          } else if (typeof rawEditorState === "object") {
            console.log("rawEditorState typeof object", rawEditorState);

            if (!rawEditorState.entityMap) {
              console.log("rawEditorState entityMap", rawEditorState.entityMap);
              rawEditorState.entityMap = {};
            }
            contentState = convertFromRaw(rawEditorState);
          } else {
            console.log("rawEditorState else", rawEditorState);
            contentState = {};
            contentState.entityMap = {};
          }
          // Convert ContentState to EditorState before using getCurrentContent
          let editorState = EditorState.createWithContent(contentState);
          response.services[service].comments =
            editorState.getCurrentContent().getPlainText() || "";
        }

        delete response.services[service].additional_comments_object;
      });
    });
    console.log("Modified handleGetAnswer", responses);

    console.log(
      "handleGetAnswer getting answer",
      action,
      conversationId,
      message
    );
    const result = await openAI.getBidAnswer({
      responses: responses,
      solicitation: solicitation,
      action: action,
      conversationId: conversationId,
      message: message,
    });

    if (result) {
      setGettingAnswer(false);

      updateConversation({
        message: result,
        sender: "bot",
      });
    } else {
      console.log("Error summarizing bids");
      setErr(true);
      setErrMessage(
        "Actually, it looks like we hit a snag. Go back and try again."
      );
      updateConversation({
        message: "Looks like we encountered a problem.",
        sender: "bot",
      });
    }
  };
  const handleSummarizeBids = async () => {
    setSummaryLoadingText(messages[0]);
    // setViewSummary(true);
    setTransitionToShow("summary");
    setSummaryLoading(true);
    setGettingAnswer(true);
    console.log("handleSummarizeBids", allResponses, solicitation);
    let responses = JSON.parse(JSON.stringify(allResponses));
    console.log("handleSummarizeBids", responses);
    responses.map((response) => {
      console.log("handleSummarizeBids removing response", response.services);
      delete response.accept_nda;
      delete response.answers;
      delete response.answers;
      delete response.comments;
      delete response.companyId;
      delete response.createdAt;
      delete response.inviteId;
      delete response.projectId;
      delete response.solicitationId;
      // delete response.status;

      delete response.submittedByEmail;

      delete response.submittedOn;
      delete response.tenantId;
      delete response.updatedAt;
      delete response.vendorCompanyId;
      delete response._id;
      delete response.vendorId;
      delete response.vendorNotified;
      Object.keys(response.services).map((service) => {
        let contentState = {};
        if (response.services[service].additional_comments_object) {
          let rawEditorState =
            response.services[service].additional_comments_object;
          let contentState;
          if (rawEditorState instanceof EditorState) {
            console.log(
              "rawEditorState instanceof EditorState",
              rawEditorState
            );
            contentState = convertFromRaw(rawEditorState);
          } else if (typeof rawEditorState === "object") {
            console.log("rawEditorState typeof object", rawEditorState);

            if (!rawEditorState.entityMap) {
              console.log("rawEditorState entityMap", rawEditorState.entityMap);
              rawEditorState.entityMap = {};
            }
            contentState = convertFromRaw(rawEditorState);
          } else {
            console.log("rawEditorState else", rawEditorState);
            contentState = {};
            contentState.entityMap = {};
          }
          // Convert ContentState to EditorState before using getCurrentContent
          let editorState = EditorState.createWithContent(contentState);
          response.services[service].comments =
            editorState.getCurrentContent().getPlainText() || "";
        }

        delete response.services[service].additional_comments_object;
      });
    });
    console.log("Modified handleSummarizeBids", responses);
    const result = await openAI.summarize({
      responses: responses,
      solicitation: props.solicitation,
    });

    // const result = ""; //testing only
    // console.log("Getting ready to summarize bids", result);
    if (result) {
      setSummary(result);
      setGettingAnswer(false);
      setSummaryLoading(false);
      // updateConversation({
      //   message: "Here is a summary of the bids.",
      //   sender: "bot",
      // });
      updateConversation({
        message: result,
        sender: "bot",
      });
      //testing only
      //   setTimeout(() => {
      //     setSummary(result);
      //     setSummaryLoading(false);
      //   }, 30000);

      // console.log("handleSummarizeBids results", result);
    } else {
      console.log("Error summarizing bids");
      setErr(true);
      setErrMessage(
        "Actually, it looks like we hit a snag. Go back and try again."
      );
    }

    //call the summarize api
    // when results are returned, create a pdf and download it
  };

  const downloadBidSummary = async () => {
    const element = document.getElementById("summary-container-html");

    // Adjust the scale for better quality (you might need to experiment with this value)
    const canvas = await html2canvas(element, { scale: 2 });

    const imgData = canvas.toDataURL("image/png");

    // Create a jsPDF instance: [orientation, unit, format]
    const pdf = new jsPDF("p", "mm", "a4");

    // Calculate the number of pages and content placement based on element size
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0; // top position of the image

    // Add image to the first page
    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add new pages if the content overflows
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight; // top position for the next page
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save the PDF
    pdf.save("Bid Response.pdf");
  };

  const createSpreadsheetFromTableData = async () => {
    const table = document.getElementById("response_table");
    const rows = Array.from(table.querySelectorAll("tr"));
    const data = rows.map((row) => {
      return Array.from(row.querySelectorAll("td, th")).map(
        (cell) => cell.innerText
      );
    });

    const result = await Files.convertToExcel(data);
    if (result) {
      console.log("createSpreadsheetFromTableData", result);
      window.open(result);
    }
  };

  // const createSpreadsheetFromTableData = () => {
  //   const worksheet = XLSX.utils.table_to_sheet(
  //     document.getElementById("response_table"),
  //     { cellStyles: true, raw: true }
  //   );
  //   worksheet["!cols"] = [
  //     { width: 20 },
  //     { width: 20 },
  //     { width: 20 },
  //     { width: 20 },
  //     { width: 20 },
  //   ];
  //   worksheet["!rows"] = [{ height: 80 }];

  //   const ws_name = "Responses";
  //   const ws = XLSX.utils.json_to_sheet(XLSX.utils.sheet_to_json(worksheet));
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, ws_name);
  //   const cellStyle = {
  //     alignment: {
  //       wrapText: true,
  //     },
  //     font: {
  //       name: "Calibri",
  //       size: 12,
  //     },
  //     border: {
  //       top: { style: "thin", color: { rgb: "000000" } },
  //       bottom: { style: "thin", color: { rgb: "000000" } },
  //       left: { style: "thin", color: { rgb: "000000" } },
  //       right: { style: "thin", color: { rgb: "000000" } },
  //     },
  //   };

  //   const range = XLSX.utils.decode_range(ws["!ref"]);
  //   for (let R = range.s.r; R <= range.e.r; ++R) {
  //     for (let C = range.s.c; C <= range.e.c; ++C) {
  //       const cell_address = { c: C, r: R };
  //       const cell_ref = XLSX.utils.encode_cell(cell_address);
  //       if (!ws[cell_ref]) continue;

  //       XLSX.utils.format_cell(ws[cell_ref], cellStyle);
  //       // book_set_style(wb, cellStyle, ws[cell_ref]);
  //     }
  //   }
  //   XLSX.writeFile(wb, "responses.xlsx");
  // };

  const handleDownloadPdfImage1 = async () => {
    const html = document.getElementById("response_table").outerHTML;
    const doc = new jsPDF("l", "in");
    doc.setFont("Helvetica");
    // doc.setTextColor(60, 142, 201);
    doc.setFontSize("20");
    doc.text(4.25, 0.33, "Bid Comparison");
    doc.autoTable({ html: html });

    doc.save("response_table.pdf");
  };

  const handleSelectWinnerClick = async (e, bid) => {
    e.preventDefault();
    // alert("handleSelectWinnerClick");
    console.log("handleSelectWinnerClick", bid);
    // setWinnerReason(true);
    // setHideSelectDiv(true);
    // setHideEmailDiv(true);
    // // setSelectedTier(tier);
    // setSelectingBid(false);
    setSelectedTier(bid);

    setTransitionToShow("decision-reason");
    // setSelectingBid(true);

    // e.preventDefault();
    // console.log("Selecting winner", e, bid);
    // setSelectWinnerLoading(true);
    // setErrMessage(null);
  };
  const handleNotifyVendorClick = (e, tier) => {
    e.preventDefault();
    setSelectedTier(tier);
    setSelectingBid(true);
    setTransitionToShow("message-winning-vendor");
  };
  const handleSendBackForRevisionClick = (bid) => {
    console.log("handleSendBackForRevisionClick", bid);
    setSelectedTier(bid);
    setTransitionToShow("send-back-to-vendor");
  };
  const handleNotifyVendorOfSendingBackForRevision = async (e) => {
    console.log("handleNotifyVendorOfSendingBackForRevision", e);
  };

  const handleViewPDF = async (e, attachment, fileType) => {
    e.preventDefault();
    console.log("handleViewPDF", attachment, fileType);
    const file = await Files.getFileById(
      attachment._id,
      fileType ? fileType : "response",
      solicitation._id
    );
    attachment.url = file.url;
    console.log("Got file download link from server", file);
    // let name = file.url.split("%5c");
    // name = name[name.length - 1];
    setViewAttachments(true);
    setAttachmentFile(attachment);
    setShowBidCompareTable(false);
    setTransitionToShow("view-attachment");
  };
  const handleSelectWinnerConfirmation = async (e) => {
    // e.preventDefault();
    let bid = selectedTier;
    // setLoading(true);
    console.log("handleSelectWinnerConfirmation", selectedTier, message);
    // props.notifyWinner(e, selectedTier, message);
    // Move notifyWinner to here
    console.log("Notifying winner", bid, message);
    // setSelectWinnerLoading(true);
    setErrMessage(null);
    if (message == "" || message == null) {
      setTimeout(() => {
        setErrMessage("Please enter a message to the vendor");

        // setSelectWinnerLoading(false);
      }, 500);
      return false;
    } else {
      let result = await Solicitations.notifyWinner({
        solicitationId: solicitation._id,
        bid: bid,
        bidId: bid._id,
        solicitationId: bid.solicitationId,
        vendorEmail: bid.submittedByEmail,
        message: message,
      });

      if (result) {
        console.log("selectWinner result", result);
        // console.log("Winner selected and confirmed", result);
        setSolicitation(result);
        setAllResponses(result.responses);

        // setSelectWinnerLoading(false);
        setMessage("");
        setSelectingBid(false);
        setHideSelectDiv(false);
        setErrMessage(null);
        props.notifyWinner(e, result) && props.notifyWinner(e, result);
        props.setCompareModalOpen(false);
      } else {
        console.log("Error selecting winner");
        // setSelectWinnerLoading(false);
        setErrMessage("Error selecting winner");
      }
    }
    // End Move notifyWinner to here
  };
  const handleSendBackForRevisionMessage = async (e) => {
    // e.preventDefault();
    let bid = selectedTier;

    setErrMessage(null);
    if (
      sendBackToVendorReasonMessage == "" ||
      sendBackToVendorReasonMessage == null
    ) {
      setTimeout(() => {
        setErrMessage("Please enter a message to the vendor");

        // setSelectWinnerLoading(false);
      }, 500);
      return false;
    } else {
      let result = await Solicitations.sendBackForRevision({
        // let result = await Solicitations.notifyWinner({
        solicitationId: solicitation._id,
        bid: bid,
        bidId: bid._id,
        solicitationId: bid.solicitationId,
        vendorEmail: bid.submittedByEmail,
        message: sendBackToVendorReasonMessage,
      });

      if (result) {
        console.log("SendBackToVendor result", result);
        // console.log("Winner selected and confirmed", result);
        // setSolicitation(result);
        // setAllResponses(result.responses);
        //update the response status to include status and isReturnedToVendor
        let responses = JSON.parse(JSON.stringify(allResponses));
        responses.map((response) => {
          if (response._id == bid._id) {
            response.status = "returned";
            response.isReturnedToVendor = true;
          }
        });
        setAllResponses(responses);

        // setSelectWinnerLoading(false);
        setSendBackToVendorReasonMessage("");
        setSendBackToVendorReasonMessageRTF({});
        setSelectingBid(false);
        setHideSelectDiv(false);
        setErrMessage(null);
        setShowBidCompareTable(true);
        setTransitionToShow("bid-compare-table");
        // props.notifyWinner(e, result) && props.notifyWinner(e, result);
        // props.setCompareModalOpen(false);
      } else {
        console.log("SendBackToVendor Error sending back for revision", result);
        // setSelectWinnerLoading(false);
        setErrMessage("Error sending back for revision");
      }
    }
    // End Move notifyWinner to here
  };
  const onFieldChangeRTF = (field, e, content) => {
    if (field === "rtf_message") {
      setMessage(content);
      setRtfMessage(e.target.value);
    } else if (field === "send_back_to_vendor_reason") {
      setSendBackToVendorReasonMessage(content);
      setSendBackToVendorReasonMessageRTF(e.target.value);
    }
  };

  const handleRadioSelect = async (e, data) => {
    console.log(e, selectedOptions, "check");
    if (data === "Other") {
      setOther(true);
    }

    let selectedOption = radioOptions
      .filter((val) => val.value === data)
      .map((val) => {
        return {
          title: val.title,
          description: val.description ? val.description : "",
        };
      });

    // Check if the option is already selected
    const isOptionSelected = selectedReason.some(
      (option) => option.title === selectedOption[0].title
    );
    console.log(isOptionSelected, "check-else-optionSelected");
    if (!isOptionSelected) {
      setSelectedReason([...selectedReason, ...selectedOption]);
    } else {
      console.log(data, "check-else-optionSelected");
      if (data === "Other") {
        setOther(false);
      }
      // If the option is already selected, remove it from the array

      setSelectedReason(
        selectedReason.filter(
          (option) => option.title !== selectedOption[0].title
        )
      );
    }
  };

  const onSaveReason = async (e) => {
    let result = await Solicitations.selectWinner({
      solicitationId: solicitation._id,
      bid: selectedTier,
      bidId: selectedTier._id,
      solicitationId: selectedTier.solicitationId,
      winnerReason: selectedReason,
    });

    if (result) {
      console.log("selectWinner result", result);
      // props.selectWinner(e, result);
      setSolicitation(result);

      setAllResponses(result.responses);
      setErrMessage(null);
      // setCompareModalOpen(true);
      // setSelectWinnerLoading(false);
      props.selectWinner(e, selectedTier) && props.selectWinner(e, result);
      setWinnerReason(false);
    } else {
      console.log("Error selecting winner");
      // setSelectWinnerLoading(false);
      setErrMessage("Error selecting winner");
    }
  };

  const onReturnToVendor = async (e) => {
    let result = await Solicitations.selectWinner({
      solicitationId: solicitation._id,
      bid: selectedTier,
      bidId: selectedTier._id,
      solicitationId: selectedTier.solicitationId,
      winnerReason: selectedReason,
    });

    if (result) {
      console.log("selectWinner result", result);
      // props.selectWinner(e, result);
      setSolicitation(result);

      setAllResponses(result.responses);
      setErrMessage(null);
      // setCompareModalOpen(true);
      // setSelectWinnerLoading(false);
      props.selectWinner(e, selectedTier) && props.selectWinner(e, result);
      setWinnerReason(false);
    } else {
      console.log("Error selecting winner");
      // setSelectWinnerLoading(false);
      setErrMessage("Error selecting winner");
    }
  };

  const setDisabled = () => {
    if (selectedReason.length === 0) {
      return true;
    } else {
      if (selectedOther && !otherDescription) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div
      id="bid-compare-page-container"
      className="h-full flex flex-col justify-between rounded bg-white border border-gray-200 shadow-sm  overflow-x-hidden overflow-y-hidden bg-white "
    >
      <div id="content" className="h-full overflow-hidden pb-8 ">
        {/* Bid Compare Table */}
        <Transition
          id="bid-compare-table-transition-container"
          enter="transform transition ease-in-out duration-500 sm:duration-500 "
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={"h-full"}
          show={
            // showBidCompareTable !== false &&
            transitionToShow === "bid-compare-table" &&
            showBidCompareTable !== false
            // &&
            // (nextTransitionToShow === "" ||
            //   nextTransitionToShow === "bid-compare-table")

            // ||
            // (!selectingBid &&
            //   !viewAttachments &&
            //   !viewSummary &&
            //   !viewCalculator &&
            //   !showSendBackToVendorMessage)
          }
          as={"div"}
          afterLeave={() => {
            // setHideSelectDiv(true);
            // setHideEmailDiv(false);
            setShowBidCompareTable(false);
            // setTransitionToShow(nextTransitionToShow);
          }}
        >
          {/* {transitionToShow === "bid-compare-table" && showBidCompareTable && ( */}
          <div
            id="bid-compare-content"
            className=" w-full h-full mx-auto px-6 lg:px-8"
          >
            <div
              id="bid-compare-header-row"
              className="flex items-center justify-between py-1 border-b border-gray-200"
            >
              <div>
                <span className="flex items-center">
                  <Button
                    iconButton
                    handleClick={(e) => handleNavigateBack(e)}
                    color="text"
                  >
                    <Icon
                      className="h-6 w-6"
                      //back button

                      icon="ic:baseline-arrow-back"
                    />
                  </Button>{" "}
                  <h2 className="text-lg font-medium leading-6 text-gray-900">
                    {solicitation && solicitation?.name}
                  </h2>
                </span>
              </div>
              <div className="space-x-6">
                <FeatureFlag
                  id="bid-calculator-button"
                  featureName="Bid Calculator"
                  type="flag"
                >
                  <Button
                    // loader={props.isLoading("pdf")}
                    color="text"
                    handleClick={handleCalculateBids}
                  >
                    <Icon
                      icon="uil:calculator-alt"
                      className="h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    Calculate bids
                  </Button>
                </FeatureFlag>
                {/* <FeatureFlag
                  id="summarize-bids-button"
                  featureName="Summarize"
                  type="flag"
                >
                  <Button
                    // loader={props.isLoading("pdf")}
                    color="text"
                    handleClick={handleSummarizeBids}
                  >
                    <Icon
                      icon="uil:newspaper"
                      className="h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    Summarize bids
                  </Button>
                </FeatureFlag> */}
                <Button
                  // loader={props.isLoading("pdf")}
                  color="text"
                  handleClick={handleDownloadPdfImage}
                >
                  <Icon icon="fa:file-pdf-o" className="h-5 w-5 mr-2" />
                  Download PDF
                </Button>
                <Button
                  // loader={props.isLoading("pdf")}
                  color="text"
                  handleClick={createSpreadsheetFromTableData}
                >
                  <Icon icon="mdi:microsoft-excel" className="h-5 w-5 mr-2" />
                  Download Spreadsheet
                </Button>
                <FeatureFlag
                  id="ask-claire-button"
                  featureName="Summarize"
                  type="flag"
                >
                  <Button
                    color="text-green"
                    handleClick={() => setShowDrawer(true)}
                  >
                    <Icon
                      icon="mdi:question-answer"
                      className="h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    Ask Claire
                  </Button>
                </FeatureFlag>
              </div>
            </div>

            <div
              id="bid-compare-table-container"
              ref={printRef}
              className="w-full isolate mx-auto pb-4 h-full grid max-w-md grid-cols-1 gap-0 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4"
            >
              <BidCompareTable
                responses={
                  allResponses &&
                  allResponses.filter(
                    (response) => response.status !== "declined"
                  )
                }
                solicitation={solicitation}
                handleSelectWinnerClick={handleSelectWinnerClick}
                handleNotifyVendorClick={handleNotifyVendorClick}
                handleSendBackForRevisionClick={handleSendBackForRevisionClick}
                handleViewPDF={handleViewPDF}
                handleNotifyVendorOfSendingBackForRevision={
                  handleNotifyVendorOfSendingBackForRevision
                }
              />
            </div>
          </div>
        </Transition>
        {/* Decision Reason */}
        <Transition
          id="decision-reason-transition-container"
          className={"h-full"}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={transitionToShow === "decision-reason" && !showBidCompareTable}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
            setShowBidCompareTable(true);
          }}
        >
          <div
            className="flex flex-col jusify-between items-center h-full space-y-10 overflow-y-auto
        overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
          >
            <div className="px-3 py-3 w-full">
              <div className="">
                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 ">
                  Decision Reason
                </p>
                Please let us know why you selected this vendor. This
                information helps all vendors understand the decision-making
                process. Select all that apply.
              </div>
              {/* <Tip color="emerald">This is tip</Tip> */}
            </div>
            <div className="w-3/4 p-8 overflow-y-scroll scrollbar-default">
              {/* <Card title="Please Select the reason" className=""> */}
              {/* <div className="space-y-2"> */}
              <RadioGroupCards
                name="reasons"
                defaultValue={selectedOptions}
                value={selectedOptions}
                type={"multiple"}
                className="grid grid-cols-1 gap-y-6"
                // className="grid gap-y-6 md:grid-cols-12  lg:grid-cols-3 xl:grid-cols-4 sm:gap-x-3"
                onChange={(e) => {
                  console.log(e, "here");
                  handleRadioSelect("services", e);
                }}
                options={radioOptions}
              />
              {selectedOther && (
                <div className="mt-5 flex items-center justify-between">
                  <textarea
                    id="other"
                    // ref={ref}
                    rows="3"
                    className="px-6 pl-8 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    placeholder="Write your reason here..."
                    value={otherDescription}
                    required
                    //disable resizing
                    style={{ resize: "none" }}
                    // onInput={(e) => handleInput(e)}
                    onChange={(e) => {
                      if (selectedReason && selectedReason.length > 0) {
                        let newSelectedReason = selectedReason.map((val) => {
                          if (val.title === "Other") {
                            return {
                              ...val,
                              description: e.target.value,
                            };
                          } else {
                            return {
                              ...val,
                            };
                          }
                        });
                        setSelectedReason(newSelectedReason);
                        setOtherDescription(e.target.value);
                      } else {
                        setSelectedReason([
                          { title: "Other", description: e.target.value },
                        ]);
                        setOtherDescription(e.target.value);
                      }
                    }}
                  ></textarea>
                </div>
              )}

              <div className="mt-5 flex items-center justify-end">
                <Button
                  disabled={setDisabled()}
                  // color={""}
                  handleClick={(e) => {
                    onSaveReason(e);
                    setTransitionToShow("bid-compare-table");
                    // setHideSelectDiv(true)
                  }}
                >
                  Save
                </Button>
              </div>
              {/* </div> */}
              {/* </Card> */}
            </div>

            <div className="w-full px-10  flex items-center justify-between">
              <div className="flex items-center justify-between">
                <Button
                  color={"text"}
                  handleClick={() => {
                    // setLoading(false);
                    // setErrMessage("");
                    setViewSummary(false);
                    setSummary("");
                    setErr(false);
                    setErrMessage("");
                    setSummaryLoadingText("");
                    setSummaryLoading(false);
                    setSummaryDiv(false);
                    setWinnerReason(false);
                    setOther(false);
                    setOtherDescription("");
                    setTransitionToShow("bid-compare-table");
                    // setHideSelectDiv(true)
                  }}
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Back to Bids
                </Button>
              </div>
            </div>
          </div>
        </Transition>
        {/* Message winning vendor */}
        <Transition
          id="message-winning-vendor-transition-container"
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={"h-full"}
          show={
            transitionToShow === "message-winning-vendor" &&
            !showBidCompareTable
          }
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
            setShowBidCompareTable(true);
          }}
        >
          {/* {!hideEmailDiv && ( */}
          <div className="flex flex-col jusify-between items-center h-full space-y-10">
            <div className="px-6 w-full">
              <div className=" max-w-4xl">
                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 ">
                  Congratulations on selecting your winner!
                </p>
                Use the box below to send them a message with next steps.
              </div>
              <Tip color="emerald">
                Please be sure to include the Bid Request Name, your company
                name, and contact details such as email addresses where
                contracts should be sent.
              </Tip>
            </div>

            <div className="mt-10 h-full px-10 sm:mt-0 w-full">
              <TextEditor
                value={message}
                html={true}
                placeholder="Use this area to notify your winner of next steps."
                handleFieldChange={(e, rawContent, content) => {
                  console.log("handleFieldChangeRTF", e, rawContent, content);
                  onFieldChangeRTF(
                    "rtf_message",
                    {
                      target: {
                        name: "rtf_message",
                        value: rawContent,
                        type: "rtf",
                      },
                    },
                    content
                  );
                }}
              />
            </div>
            <div className="w-full px-10 flex items-center justify-between">
              <div className="flex items-center justify-between">
                <Button
                  color={"text"}
                  handleClick={() => {
                    setLoading(false);
                    setErrMessage("");
                    setSelectingBid(false);
                    setTransitionToShow("bid-compare-table");
                  }}
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Back to Bids
                </Button>
              </div>
              <div className="flex items-center justify-between">
                <Button
                  loader={loading}
                  color="secondary"
                  handleClick={(e) => {
                    handleSelectWinnerConfirmation(e);
                    setTransitionToShow("bid-compare-table");
                  }}
                  // audit={true}
                  // action="Confirm Bid Winner"
                  // details={{}}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </div>
        </Transition>
        {/* Send back to vendor message */}
        <Transition
          id="send-back-to-vendor-message-transition-container"
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={"h-full"}
          show={
            transitionToShow == "send-back-to-vendor" && !showBidCompareTable
          }
          as={"div"}
          afterLeave={() => {
            setShowBidCompareTable(true);
          }}
        >
          <div className="flex flex-col jusify-between items-center h-full space-y-10">
            <div className="px-6 w-full">
              <div className=" max-w-4xl">
                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 ">
                  Send Back to Vendor
                </p>
                Use the box below to send them a message so they know what needs
                to be changed.
              </div>
              <Tip color="emerald">
                Please be sure to include the reason you're sending it back as
                well as details on what needs to be changed for them to be
                considered.
              </Tip>
            </div>

            <div className="mt-10 h-full px-10 sm:mt-0 w-full">
              <TextEditor
                value={sendBackToVendorReasonMessage}
                html={true}
                placeholder="Use this area for your message."
                handleFieldChange={(e, rawContent, content) => {
                  console.log("handleFieldChangeRTF", e, rawContent, content);
                  onFieldChangeRTF(
                    "send_back_to_vendor_reason",
                    {
                      target: {
                        name: "rtf_message",
                        value: rawContent,
                        type: "rtf",
                      },
                    },
                    content
                  );
                }}
              />
            </div>
            <div className="w-full px-10 flex items-center justify-between">
              <div className="flex items-center justify-between">
                <Button
                  color={"text"}
                  handleClick={() => {
                    setLoading(false);
                    setErrMessage("");

                    setTransitionToShow("bid-compare-table");
                  }}
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Back to Bids
                </Button>
              </div>
              <div className="flex items-center justify-between">
                <Button
                  loader={loading}
                  color="secondary"
                  handleClick={(e) => handleSendBackForRevisionMessage(e)}
                  // audit={true}
                  // action="Confirm Bid Winner"
                  // details={{}}
                >
                  Send Message
                </Button>
              </div>
            </div>
          </div>
        </Transition>
        {/* View Attachments */}
        <Transition
          className={"h-full"}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={transitionToShow == "view-attachment" && !showBidCompareTable}
          as={"div"}
          afterLeave={() => {
            setShowBidCompareTable(true);
          }}
        >
          <div id="pdf-viewer-container" className="h-full">
            <PdfViewer
              file={attachmentFile ? attachmentFile : {}}
              filename={attachmentFile ? attachmentFile.name : "Test"}
              url={attachmentFile ? attachmentFile.url : ""}
              // selectedFileName={selectedFileName ? selectedFileName : "Test"} />
            />
          </div>

          <div className="w-full px-10 flex items-center justify-between">
            <div className="flex items-center justify-between">
              <Button
                color={"text"}
                handleClick={() => {
                  // setLoading(false);
                  // setErrMessage("");
                  setViewAttachments(false);
                  setAttachmentDiv(false);
                  // setHideSelectDiv(true)

                  setTransitionToShow("bid-compare-table");
                }}
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Back to Bids
              </Button>
            </div>
          </div>
        </Transition>
        {/* View Summary */}
        <Transition
          className={"h-full "}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={transitionToShow === "summary"}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
            setShowBidCompareTable(true);

            // setTransitionToShow("bid-compare-table");
          }}
        >
          {summaryLoading ? (
            <div className="flex pt-12 flex-col h-full items-center text-center justify-center ">
              <div className="text-xl  font-medium flex">
                {/* Give us a few minutes to summarize your bids...
                 */}
                {summaryLoadingText}
              </div>
              <img
                className="flex"
                src="/static/media/rocket_loading.15e68c95fb64b90e1803.gif"
                alt="Loading Rocket"
              />
            </div>
          ) : err ? (
            <div className="flex pt-12 flex-col h-full items-center text-center justify-center ">
              <div className="text-xl font-medium flex text-red-500">
                {errMessage}
              </div>
            </div>
          ) : (
            <div
              id="summary-container"
              className="p-6 h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
            >
              <div className="flex items-center justify-between">
                <div className="text-xl font-semibold">
                  Bid Summary and Comparison
                </div>
                {/* <Button color="text" handleClick={downloadBidSummary}>
                  Download Summary
                </Button> */}
              </div>
              <Tip color="amber">
                <span className="font-semibold">
                  AI can make mistakes so please review thoroughly.
                </span>
              </Tip>
              <div
                id="summary-container-html"
                className="h-full whitespace-pre-wrap  text-md"
                dangerouslySetInnerHTML={{ __html: summary }}
              ></div>
            </div>
          )}

          <div className="w-full px-10  flex items-center justify-between">
            <div className="flex items-center justify-between">
              <Button
                color={"text"}
                handleClick={() => {
                  // setLoading(false);
                  // setErrMessage("");
                  // setViewSummary(false);

                  setSummary("");
                  setErr(false);
                  setErrMessage("");
                  setSummaryLoadingText("");
                  setSummaryLoading(false);
                  // setSummaryDiv(false);
                  setTransitionToShow("bid-compare-table");
                  // setHideSelectDiv(true)
                }}
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Back to Bids
              </Button>
            </div>
          </div>
        </Transition>
        {/* View Calculator */}
        <Transition
          className={"h-full "}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={viewCalculator}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
          }}
        >
          <div
            id="calculator-container"
            className="p-6 h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
          >
            <div
              id="calculator-container-html"
              className="h-full flex justify-between w-full"
              // style={{
              //   whiteSpace: "pre-wrap",
              //   wordWrap: "break-word",
              //   overflowWrap: "break-word",
              // }}
            >
              <AssumptionsCalc bids={allResponses} />
            </div>
          </div>

          <div className="w-full px-10 flex items-center justify-between">
            <div className="flex items-center justify-between">
              <Button
                color={"text"}
                handleClick={() => {
                  // setLoading(false);
                  // setErrMessage("");
                  setViewCalculator(false);
                }}
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Back to Bids
              </Button>
            </div>
          </div>
        </Transition>
      </div>
      {/* <ChatComponent
        show={showDrawer}
        setShow={setShowDrawer}
        questionChoices={questionsForClaire}
        initialConversation={[
          {
            message: "Hi, I'm Claire. I'm here to help you with your bid.",
            sender: "bot",
          },
          {
            message: "To get started, select a prompt below.",
            sender: "bot",
          },
        ]}
      /> */}

      <Drawer
        show={showDrawer}
        setShow={setShowDrawer}
        title="Ask Claire"
        expandable={true}
        showFooter
        // scrollable
        footerContent={
          questionsForClaire && questionsForClaire.length > 0 ? (
            <div className="flex items-center justify-between space-x-4 text-brand-700">
              <DropdownMenu
                id="ask-claire-dropdown"
                title="Select a topic"
                buttonText="Ask Claire"
                buttonType="text"
                buttonColor="text"
                direction="up"
                menuItems={questionsForClaire.map((question) => ({
                  ...question,
                  action: (e) => {
                    updateConversation({
                      promptId: question.id,
                      message: question.name,
                      sender: "user",
                    });
                    handleGetAnswer(e, question.action_key, conversationId, {
                      promptId: question.id,
                      message: question.name,
                      sender: "user",
                    });
                  },
                }))}
              />{" "}
              {conversation && conversation.length > 2 && (
                <div>
                  <Button
                    color="text-secondary"
                    className={`${
                      gettingAnswer
                        ? "cursor-not-allowed bg-white shadow-none"
                        : ""
                    }`}
                    disabled={gettingAnswer}
                    handleClick={() => handleDeleteConversationHistory()}
                  >
                    <Icon icon="streamline:delete-1-solid" className="mr-2" />
                    Delete conversation history
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <Button
                className={`${
                  gettingAnswer ? "cursor-not-allowed bg-white shadow-none" : ""
                }`}
                color="text-secondary"
                handleClick={() => handleDeleteConversationHistory()}
              >
                <Icon icon="streamline:delete-1-solid" className=" mr-2" />
                Delete conversation history
              </Button>
            </div>
          )
        }
      >
        <div className="flex flex-col h-full">
          {/* <div className="flex-grow px-6 mx-auto my-auto"></div> */}
          <div
            id="conversation-container"
            className="h-full overflow-y-scroll scrollbar-default px-6 py-4 pb-14  mb-24 bg-gray-100 border-t border-gray-200"
          >
            <div className="space-y-4 flex flex-col">
              {conversation && conversation.length > 0 ? (
                conversation.map((message, index) => {
                  const isBot = message.sender === "bot";
                  const textContent = new DOMParser().parseFromString(
                    message.message,
                    "text/html"
                  ).body.innerText;
                  const handleCopy = (text) => {
                    navigator.clipboard
                      .writeText(text)
                      .then(() => {
                        console.log("Copied to clipboard");
                      })
                      .catch((err) => {
                        console.error("Failed to copy: ", err);
                      });
                  };

                  return (
                    <div
                      id={
                        index === conversation.length - 1
                          ? "last-message"
                          : `chat-bubble-${index}`
                      }
                      key={index}
                      className={`${
                        isBot ? "w-[80%]" : "w-[60%]"
                      } space-y-6 p-4 rounded-lg shadow-md ${
                        isBot ? "bg-indigo-100 self-start" : "bg-white self-end"
                      } `}
                    >
                      <div id="flex-bubble" className="">
                        {isBot && (
                          <div className="relative flex items-center justify-end">
                            <CopyComponent
                              handleClick={() => handleCopy(textContent)}
                              text={null}
                              confirmText={"Copied!"}
                            />

                            {/* <button
                              onClick={() => handleCopy(textContent)}
                              className="flex justify-end ml-2 text-brand-500 hover:text-brand-700"
                            >
                              <Icon icon="ic:outline-content-copy" />
                            </button> */}
                          </div>
                        )}
                        <div
                          className="text-sm text-gray-700 space-y-6"
                          dangerouslySetInnerHTML={{ __html: message.message }}
                        ></div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="bg-white p-4 rounded-lg shadow-md">
                  <p className="text-sm text-gray-700">
                    How can I help you today?
                  </p>
                </div>
              )}
              {gettingAnswer && (
                <div
                  className={`w-[80%] p-4 rounded-lg shadow-md bg-indigo-100 self-start`}
                >
                  <div className="flex items-center">
                    <Skeleton type="spiner" forButton className="inline" />{" "}
                    <p className="flex text-sm text-gray-700">
                      One moment while I check on that.
                    </p>
                  </div>
                </div>
              )}
              {/* <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="text-sm text-gray-700">
                  How can I help you today?
                </p>
                d
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="text-sm text-gray-700">
                  Do you need assistance with your bids?
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </Drawer>

      <div id="footer" className="h-6"></div>
    </div>
  );
}
