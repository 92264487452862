import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";

import { Component } from "react";
import PropTypes from "prop-types";
import { usePersistStore } from "../store/usestore";
import jwtDecode from "jwt-decode";
import { Store } from "../layouts/store";
import { _auth } from "../modules/_auth";
import { SocketProvider } from "../config/socketContext";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isMfaSuccess, getAuthAsync } = usePersistStore();
  const location = useLocation();
  const history = useHistory();
  let token = _auth.getUserToken("jwtToken");
  let profile = _auth.getUserProfile();

  getAuthAsync();

  let decoded;
  let currentTime;
  let expired;
  if (token && token !== "undefined" && token !== null) {
    decoded = jwtDecode(token);
    console.log("decoded", decoded);
    currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      expired = true;
    }
  } else {
    expired = true;
  }

  let mfaAuthenticated = !_auth.getUserProfile()?.MFA ? true : isMfaSuccess;
  const authenticated =
    isAuthenticated &&
    mfaAuthenticated &&
    token !== null &&
    !expired &&
    profile !== null &&
    profile !== "undefined";
  return authenticated ? (
    // <SocketProvider>
      <Route {...rest} render={(props) => <Component {...props} />} />
    // </SocketProvider>
  ) : (
    history.push({
      pathname: "/signin",
      state: {
        from: location.pathname,
      },
    })
    // <Redirect
    //   to={{
    //     pathname: "/signin",
    //   }}
    //   sta
    // />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
};

export default PrivateRoute;
