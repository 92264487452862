import React, { useEffect, useState } from "react";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Input from "./Input";
import { utils } from "../modules/_utils";
import { PricingInput } from "../pages/projects/components/DataEntry";
import ComboboxSelector from "./ComboBoxSelector";
import Button from "../elements/Button";
import { set } from "date-fns";
import ToggleButtons from "./ToggleButtons";
import { BILLING_INTERVAL } from "../constants/constants";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "./QuestionToolTip";
import { Icon } from "@iconify/react";

export default function InputForVolumePricing(props, ...rest) {
  const {
    indicator,
    response,
    field,
    lineItem,
    unitOptions,
    value,
    selectedTab,
    item,
    handleFieldChange,
    fullQuote,
    handleDeselect,
    handleClearAllSelections,
    handlePriceChange,
    handleSelectLineItem,
    service,
    lineItemOptions,
  } = props;

  const [lineItemType, setLineItemType] = React.useState("");
  const [inputDropdownValue, setInputDropdownValue] = React.useState();
  const [pricing, setPricing] = useState({});
  const [selected, setSelected] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [validationMessage, setValidationMessage] = useState(
    "Please select a unit, price, and service."
  );

  const tierTemplate = {
    min: "1",
    max: "∞",
    price: "",
    currency: "USD",
    price_type: null,
  };

  useEffect(() => {
    console.log("USEEFFECT Inputwithgrad price", JSON.stringify(value));
    if (value && value !== undefined) {
      setPricing(value);
      if (value.tiers && value.tiers.length > 0) {
        console.log("USEEFFECT Inputwithgrad price", JSON.stringify(value));
        setTiers(value.tiers);
      } else {
        console.log(
          "USEEFFECT Inputwithgrad price using template",
          JSON.stringify(tierTemplate)
        );
        setTiers([tierTemplate]);
      }
    } else {
      console.log(
        "USEEFFECT Inputwithgrad price UNDEFINED using template",
        JSON.stringify(tierTemplate)
      );
      setPricing({ tiers: [] });
      setTiers([tierTemplate]);
    }
  }, []);

  useEffect(() => {
    console.log(
      "USEEFFECT Inputwithgrad price",
      JSON.parse(JSON.stringify(value)),
      lineItem,
      tiers
      // pricing,
      // inputDropdownValue
    );
    let pricingOk = true;
    if (tiers.length > 0) {
      tiers.forEach((tier) => {
        if (
          tier.price == null ||
          tier.price == undefined ||
          tier.price == "" ||
          tier.price_type == null ||
          tier.price_type == undefined ||
          tier.price_type == "" ||
          tier.min == null ||
          tier.min == undefined ||
          tier.min == "" ||
          tier.max == null ||
          tier.max == undefined ||
          tier.max == ""
        )
          pricingOk = false;
      });
    } else pricingOk = false;
    console.log("pricingOk", pricingOk);

    if (
      value &&
      value.unit &&
      lineItem &&
      //line item doesn't start with tmp
      lineItem.substring(0, 3) !== "tmp" &&
      pricingOk
    ) {
      setIsValid(true);
    } else setIsValid(false);
  }, [value, inputDropdownValue, lineItem, tiers, pricing]);

  const handleChangeInputDropdownValue = (field, value) => {
    let values = { ...inputDropdownValue };
    values[field] = value;

    setInputDropdownValue(values);
    handlePriceChange(values, service, lineItemType);
  };

  useEffect(() => {
    setLineItemType(lineItem);
  }, [lineItem]);

  const selectLineItem = (v, item) => {
    setLineItemType(v);
    handleSelectLineItem(v, item);
  };

  const insertNewTier = () => {
    let newTiers = [...tiers];
    //insert new tier at next to last position
    console.log("insertNewTier", lineItem, newTiers);
    if (newTiers.length == 1 && newTiers[newTiers.length - 1].max == "∞") {
      let newTier = {
        min: 3,
        max: "∞",
        price: "",
        currency: "USD",
        price_type: null,
      };

      // Update the min and max properties of the first tier
      newTiers[0].min = 1;
      newTiers[0].max = 2;
      // newTiers[0].price = 0.0;

      // Insert the new tier after the first tier
      newTiers.splice(1, 0, newTier);

      setTiers(newTiers);
      return;
    } else {
      let newTier = {
        min: newTiers[newTiers.length - 2].max + 1,
        max: newTiers[newTiers.length - 2].max + 2,
        price: "",
        currency: "USD",
        price_type: null,
      };
      newTiers[newTiers.length - 1].min = newTier.max + 1;
      newTiers.splice(newTiers.length - 1, 0, newTier);
      setTiers(newTiers);
    }
    let newPricing = { ...pricing };
    newPricing.tiers = newTiers;
    setPricing(newPricing);
    handlePriceChange(newPricing, service, item);
  };

  const deleteTier = (index) => {
    let newTiers = [...tiers];
    newTiers.splice(index, 1);
    //fix min and max values for the rest of the tiers
    let fixed = fixTierNumbering(newTiers, index);
    // newTiers = fixTierNumbering(newTiers, index);

    setTiers(fixed);
    let newPricing = { ...pricing };
    newPricing.tiers = fixed;
    setPricing(newPricing);
    handlePriceChange(newPricing, service, item);
  };

  const fixTierNumbering = (newTiers, index) => {
    for (let i = index; i < newTiers.length; i++) {
      if (i == 0) {
        newTiers[i].min = 1;
        newTiers[i].max = newTiers[i + 1].min - 1;
      } else if (i == newTiers.length - 1) {
        newTiers[i].min = newTiers[i - 1].max + 1;
        newTiers[i].max = "∞";
      } else {
        console.log(
          "fixing tier numbering, setting min",
          newTiers[i].min,
          "to",
          newTiers[i - 1].max + 1
        );
        newTiers[i].min = newTiers[i - 1].max + 1;
        console.log(
          "fixing tier numbering, setting max",
          newTiers[i].max,
          "to",
          newTiers[i + 1].min - 1,
          newTiers[i + 1].min
        );
        //if max is < min, set max to min + 1
        if (newTiers[i + 1].min - 1 < newTiers[i].min)
          newTiers[i].max = newTiers[i].min + 1;
        else newTiers[i].max = newTiers[i + 1].min - 1;
      }
    }
    console.log("fixTierNumbering", newTiers);
    return newTiers;
  };

  const handleTierValueChange = (e, index, field, priceType) => {
    //if priceType is not passed, get it from the pricing object
    if (!priceType) priceType = pricing.tiers[index].price_type;
    console.log("handleTierValueChange", e);
    let newTiers = [...tiers];
    //if field contains a dot, it is a nested object
    if (field.includes(".")) {
      let fields = field.split(".");
      newTiers[index][fields[0]][fields[1]] = e; //e to number
    } else
      newTiers[index][field] =
        field == "max" || field == "min" ? parseInt(e) : e;
    newTiers[index]["price_type"] = priceType ? priceType : null;
    if (priceType) {
      newTiers[index]["price_type"] = priceType;
    } else if (!newTiers[index]["price_type"]) {
      newTiers[index]["price_type"] = null;
    }
    if (field == "max" && e !== "∞" && e !== "" && e !== null) {
      console.log("handleTierValueChange fix", e);
      newTiers = fixTierNumbering(newTiers, index + 1);
    }
    setTiers(newTiers);
    let newPricing = { ...pricing };
    newPricing.tiers = newTiers;
    setPricing(newPricing);
    handlePriceChange(newPricing, service, item);
  };

  //   const handlePriceChange = (value, service, item, line_item_type) => {
  //     // value is the value of the input
  //     // service is the name of the service
  //     // item is the name of the pricing item
  //     // line_item_type is the type of line item, standard, graduated, volume

  //     item = item ? item : "base";

  //     let newPricing = { ...pricing };

  //     newPricing[item] = {
  //       type: line_item_type,
  //       price: value.price,
  //       currency: "USD",
  //       measure: "per_unit", //flat, per_unit
  //       unit: value.unit, //document, page, hour, GB
  //     };
  //     console.log("handlePriceChange", value, service, item, line_item_type);
  //     setPricing(newPricing);
  //   };
  const addLineItem = (e, service, type) => {
    let newPricing = { ...pricing };
    newPricing["tmp"] = {
      type: e,
    };
    console.log("addLineItem", e, service, field, type);
    setPricing(newPricing);
  };
  const handleSetLineItem = (e, service, field, type) => {
    //rename tmp to the name of the line item
    let newPricing = { ...pricing };
    let keys = Object.keys(newPricing);
    let tmp = newPricing["tmp"];
    delete newPricing["tmp"];
    newPricing[e] = tmp;
    console.log("handleSetLineItem", e, service, field, type);
    setPricing(newPricing);
  };

  const handleSelectedQuoteTypes = (e, service) => {
    console.log("handleSelectedQuoteTypes", e, service);
    let newSelected = [...selected];
    newSelected.push(e);
    setSelected(newSelected);
  };

  return props.version == 1 ? (
    <div className="w-full bg-gray-50 border-2 border-dashed border-gray-200 p-6 rounded-sm">
      {/* <div className="flex items-center text-lg font-medium">
        {pricing?.type}
      </div> */}
      <div className="flex space-x-8 items-center text-lg font-medium">
        <div>
          {" "}
          <label
            htmlFor={field}
            className="block whitespace-nowrap text-lg font-medium text-gray-700"
          >
            {" "}
            {item.substring(0, 3) !== "tmp" ? (
              <span className="block text-lg font-medium text-gray-700">
                {item}
              </span>
            ) : (
              <span className="block text-sm font-medium text-gray-700">
                <ComboboxSelector
                  name={"unit"}
                  options={lineItemOptions}
                  textbutton={true}
                  buttonType={"pricing_item"}
                  fieldType={"single"}
                  open={true}
                  //   handleSelect={(v) => handleSelect(v, service, "unit")}
                  //   handleSelect={(v) => {
                  //     return handleChangeInputDropdownValue("line_item", v);
                  //   }}
                  handleSelect={(v) => {
                    selectLineItem(v, item);
                  }}
                  // handleDeselect={handleDeselect}
                  handleClearAllSelections={(value) =>
                    console.log("handleClearAllSelections", value)
                  } //{handleClearAllSelections}
                  // value={opts2[field] ? opts2[field] : null}
                  value={item}
                />
              </span>
            )}
          </label>
        </div>
        <div className="flex">
          {/* badge */}
          <div className="flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            {pricing?.type}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-6">
          {/* <label
            htmlFor={field}
            className="block whitespace-nowrap text-sm font-medium text-gray-700"
          >
            {" "}
            {item.substring(0, 3) !== "tmp" ? (
              <span className="block text-sm font-medium text-gray-700">
                {item}
              </span>
            ) : (
              <span className="block text-sm font-medium text-gray-700">
                <ComboboxSelector
                  name={"unit"}
                  options={lineItemOptions}
                  textbutton={true}
                  buttonType={"pricing_item"}
                  fieldType={"single"}
                  open={true}
                  //   handleSelect={(v) => handleSelect(v, service, "unit")}
                  //   handleSelect={(v) => {
                  //     return handleChangeInputDropdownValue("line_item", v);
                  //   }}
                  handleSelect={(v) => {
                    selectLineItem(v, item);
                  }}
                  // handleDeselect={handleDeselect}
                  handleClearAllSelections={(value) =>
                    console.log("handleClearAllSelections", value)
                  } //{handleClearAllSelections}
                  // value={opts2[field] ? opts2[field] : null}
                  value={item}
                />
              </span>
            )}
          </label> */}
          <label
            htmlFor={field}
            className="block whitespace-nowrap text-sm font-medium text-gray-700"
          >
            {" "}
            Units
          </label>
          <ComboboxSelector
            name={"unit"}
            options={unitOptions}
            textbutton={true}
            buttonType={"unit"}
            fieldType={"single"}
            open={true}
            // handleSelect={(v) => handleSelect(v, service, "unit")}
            handleSelect={(v) => {
              return handleChangeInputDropdownValue("unit", v);
            }}
            handleDeselect={handleDeselect}
            handleClearAllSelections={(value) =>
              handleClearAllSelections(value, service, "unit")
            } //{handleClearAllSelections}
            // value={opts2[field] ? opts2[field] : null}
            value={value && value["unit"]}
          />
          <QuestionToolTip
            field={"Units"}
            text="Select the units you are billing for."
          />
        </div>
        <div className="flex items-center">
          <Button color="text" handleClick={() => insertNewTier(item)}>
            Insert Tier
          </Button>
          <Button
            handleClick={(e) => props.deleteLineItem(e)}
            color="link"
            size="small"
          >
            Delete
          </Button>
        </div>
      </div>
      <table class="w-full text-sm text-left text-gray-500 ">
        <thead class="text-xs text-gray-700  bg-gray-50  ">
          <tr>
            <th scope="col" class="px-6 py-3 uppercase">
              {" "}
              Tier
              {/* {pricing?.type?.toUpperCase()} */}
            </th>
            <th scope="col" class="px-6 py-3 uppercase">
              Start
            </th>
            <th scope="col" class="px-6 py-3 uppercase">
              End
            </th>
            <th scope="col" class="px-6 py-3 uppercase whitespace-nowrap">
              Unit{" "}
              <QuestionToolTip
                className="flex "
                field={"Unit Pricing"}
                text={
                  "Select this option if you charge per unit for the items in this tier."
                }
              />
            </th>
            <th scope="col" class="flex px-6 py-3 uppercase">
              Flat Fee{" "}
              <QuestionToolTip
                className="flex font-normal text-xs"
                field={"Flat Fee"}
                text={
                  "Select this option if you charge a set price for all items in this tier."
                }
              />
            </th>{" "}
            <th scope="col" class=""></th>
          </tr>
        </thead>
        <tbody>
          {/* <tr class="bg-white border-b  dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        1-5000
                      </th>
                      <td class="px-6 py-4">1</td>
                      <td class="px-6 py-4">5000</td>
                      <td class="px-6 py-4">$0.00</td>
                      <td class="px-6 py-4">$0.00</td>
                    </tr> */}
          {tiers &&
            tiers.map((tier, idx) => {
              return (
                <tr
                  class={`${
                    tier.price_type !== null && tier.price !== ""
                      ? "bg-white"
                      : "bg-red-50"
                  }  border-b  border-gray-200`}
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {tier.min}-{tier.max ? tier.max : "∞"}
                  </th>
                  <td class="px-6 py-4">{tier.min}</td>
                  <td class="px-6 py-4">
                    <Input
                      value={
                        idx == tiers.length - 1
                          ? "∞"
                          : tier.max == "∞" ||
                            tier.max == null ||
                            tier.max == undefined ||
                            tier.max == NaN ||
                            tier.max == "NaN" ||
                            isNaN(tier.max)
                          ? ""
                          : tier.max
                      }
                      disabled={
                        idx == tiers.length - 1 ||
                        fullQuote.status == "submitted"
                      }
                      onChange={(e) =>
                        handleTierValueChange(e.target.value, idx, "max")
                      }
                    />
                  </td>
                  <td class="px-6 py-4">
                    <Input
                      id="unit_pricing"
                      value={tier.price || ""}
                      indicator="$"
                      disabled={
                        (tier.price > 0 && tier.price_type == "flat") ||
                        fullQuote.status == "submitted"
                      }
                      onChange={(e) =>
                        handleTierValueChange(
                          e.target.value,
                          idx,
                          "price",
                          "unit_pricing"
                        )
                      }
                    />
                  </td>
                  <td class="px-6 py-4 relative">
                    <Input
                      id="flat"
                      indicator="$"
                      value={tier.price_type === "flat" ? tier.price || "" : ""}
                      disabled={
                        (tier.price > 0 && tier.price_type == "unit_pricing") ||
                        fullQuote.status == "submitted"
                      }
                      onChange={(e) =>
                        handleTierValueChange(
                          e.target.value,
                          idx,
                          "price",
                          "flat"
                        )
                      }
                    />
                  </td>
                  <td class="px-1 py-4">
                    <span className="">
                      <Button
                        iconButton
                        color="text"
                        handleClick={() => deleteTier(idx)}
                      >
                        <XMarkIcon className="w-5 h-5" />
                      </Button>
                    </span>
                  </td>
                </tr>
              );
            })}
          {/* <tr class="bg-white border-b  dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        5001-
                      </th>
                      <td class="px-6 py-4">5001</td>
                      <td class="px-6 py-4"></td>
                      <td class="px-6 py-4">$0.00</td>
                      <td class="px-6 py-4">$0.00</td>
                    </tr> */}
        </tbody>
      </table>
      <div className=" flex items-center  space-x-20">
        <div>
          {/* <Tabs tabs={BILLING_RECURRENCE} /> */}
          <ToggleButtons
            text1={"One Time"}
            text2={"Recurring"}
            value={inputDropdownValue && inputDropdownValue["recurrence"]}
            onToggleChange={(selection) => {
              console.log("selection", selection);
              handleChangeInputDropdownValue("recurrence", selection);
            }}
          />
        </div>
        <div className="flex space-x-3">
          {inputDropdownValue?.recurrence == "Recurring" && (
            <>
              <div>per</div>
              <ComboboxSelector
                name={"billing_interval"}
                options={BILLING_INTERVAL}
                textbutton={true}
                buttonType={"interval"}
                fieldType={"single"}
                open={true}
                // handleSelect={(v) => handleSelect(v, service, "unit")}
                handleSelect={(v) => {
                  return handleChangeInputDropdownValue("billing_interval", v);
                }}
                handleDeselect={handleDeselect}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "billing_interval")
                } //{handleClearAllSelections}
                // value={opts2[field] ? opts2[field] : null}
                value={() => {
                  return (
                    inputDropdownValue && inputDropdownValue["billing_interval"]
                  );
                }}
              />
            </>
          )}
        </div>
        <div className="flex items-center">
          {!isValid && (
            <div className="flex items-center text-sm text-red-500">
              <ExclamationCircleIcon className="h-5 w-5 mr-1" />
              {validationMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full bg-gray-50 border-2 border-dashed border-gray-200 pt-2 px-6 pb-2 rounded-sm">
      <div
        id="top_line"
        className="flex justify-between items-center text-lg  mb-2"
      >
        <div className="flex">
          {/* badge */}
          <div className="flex  space-x-8">
            <div className="flex">
              <div className="flex items-center text-sm  ">
                {value?.type} Pricing
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="action-line" className="flex items-center justify-between p-1">
        <div className="flex items-center justify-start space-x-6">
          <label
            htmlFor={"pricing_item"}
            className="block whitespace-nowrap text-md font-medium text-gray-700"
          >
            {item.substring(0, 3) !== "tmp" ? (
              <span className="block font-medium text-gray-700">{item}</span>
            ) : (
              <span className="block text-sm font-medium text-gray-700">
                <ComboboxSelector
                  name={"pricing_item"}
                  options={lineItemOptions}
                  textbutton={true}
                  buttonType={"pricing_item"}
                  fieldType={"single"}
                  open={true}
                  //   handleSelect={(v) => handleSelect(v, service, "unit")}
                  //   handleSelect={(v) => {
                  //     return handleChangeInputDropdownValue("line_item", v);
                  //   }}
                  handleSelect={(v) => {
                    selectLineItem(v, item);
                  }}
                  // handleDeselect={handleDeselect}
                  handleClearAllSelections={(value) =>
                    console.log("handleClearAllSelections", value)
                  } //{handleClearAllSelections}
                  // value={opts2[field] ? opts2[field] : null}
                  value={item}
                />
              </span>
            )}
          </label>

          <div className="flex whitespace-nowrap items-center">
            {value && value["unit"] && (
              <label
                htmlFor={field}
                className="mr-2 block whitespace-nowrap text-sm font-medium text-gray-700"
              >
                Units:
              </label>
            )}
            <ComboboxSelector
              name={"unit"}
              options={unitOptions}
              textbutton={true}
              buttonType={"unit"}
              fieldType={"single"}
              open={true}
              // handleSelect={(v) => handleSelect(v, service, "unit")}
              handleSelect={(v) => {
                return handleChangeInputDropdownValue("unit", v);
              }}
              handleDeselect={handleDeselect}
              handleClearAllSelections={(value) =>
                handleClearAllSelections(value, service, "unit")
              } //{handleClearAllSelections}
              // value={opts2[field] ? opts2[field] : null}
              value={value && value["unit"]}
            />
            <QuestionToolTip
              field={"Units"}
              text="Because tier-based pricing requires you to count units, you need to select what you are counting.  Within each tier you will have the opportunity to set the price per unit of fixed cost per number of units. "
            />
          </div>

          <div
            id="toggles-line-top"
            className="w-full flex items-center space-x-4 "
          >
            <div className="block">
              <ToggleButtons
                text1={"One Time"}
                text2={"Recurring"}
                value={inputDropdownValue && inputDropdownValue["recurrence"]}
                onToggleChange={(selection) => {
                  console.log("selection", selection);
                  handleChangeInputDropdownValue("recurrence", selection);
                }}
              />
            </div>
            <div className="flex items-center">
              {inputDropdownValue?.recurrence == "Recurring" && (
                <>
                  <div className="block mr-2">per</div>
                  <div className="ml-3 block">
                    <ComboboxSelector
                      name={"billing_interval"}
                      options={BILLING_INTERVAL}
                      textbutton={true}
                      buttonType={"interval"}
                      fieldType={"single"}
                      open={true}
                      // handleSelect={(v) => handleSelect(v, service, "unit")}
                      handleSelect={(v) => {
                        return handleChangeInputDropdownValue(
                          "billing_interval",
                          v
                        );
                      }}
                      handleDeselect={handleDeselect}
                      handleClearAllSelections={(value) =>
                        handleClearAllSelections(
                          value,
                          service,
                          "billing_interval"
                        )
                      } //{handleClearAllSelections}
                      // value={opts2[field] ? opts2[field] : null}
                      value={() => {
                        return (
                          inputDropdownValue &&
                          inputDropdownValue["billing_interval"]
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {/* <Button color="text" handleClick={() => insertNewTier(item)}>
            Insert Tier
          </Button> */}
          <Button
            handleClick={(e) => props.deleteLineItem(e)}
            color="link"
            size="small"
          >
            <Icon icon="mdi:remove" className="h-5 w-5 mr-1" />
            <span className="">Delete this line item</span>
          </Button>
        </div>
      </div>
      <div className="relative">
        <table id="table-line" class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700  bg-gray-50  ">
            <tr>
              <th scope="col" class="px-6 py-3 uppercase">
                {" "}
                Tier
                {/* {pricing?.type?.toUpperCase()} */}
              </th>
              <th scope="col" class="px-6 py-3 uppercase">
                Start
              </th>
              <th scope="col" class="px-6 py-3 uppercase">
                End
              </th>
              <th scope="col" class="px-6 py-3 uppercase whitespace-nowrap">
                Price per Unit{" "}
                <QuestionToolTip
                  className="flex "
                  field={"Unit Pricing"}
                  text={
                    "Select this option if you charge per unit for the items in this tier."
                  }
                />
              </th>
              <th scope="col" class="flex px-6 py-3 uppercase">
                Flat Fee{" "}
                <QuestionToolTip
                  className="flex font-normal text-xs"
                  field={"Flat Fee"}
                  text={
                    "Select this option if you charge a set price for all items in this tier."
                  }
                />
              </th>{" "}
              <th scope="col" class=""></th>
            </tr>
          </thead>
          <tbody>
            {tiers &&
              tiers.map((tier, idx) => {
                return (
                  <tr
                    class={`${
                      tier.price_type !== null && tier.price !== ""
                        ? "bg-white"
                        : "bg-red-50"
                    }  border-b  border-gray-200`}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {tier.min}-{tier.max ? tier.max : "∞"}
                    </th>
                    <td class="px-6 py-4">{tier.min}</td>
                    <td class="px-6 py-4">
                      <Input
                        value={
                          idx == tiers.length - 1
                            ? "∞"
                            : tier.max == "∞" ||
                              tier.max == null ||
                              tier.max == undefined ||
                              tier.max == NaN ||
                              tier.max == "NaN" ||
                              isNaN(tier.max)
                            ? ""
                            : tier.max
                        }
                        disabled={
                          idx == tiers.length - 1 ||
                          fullQuote.status == "submitted"
                        }
                        onChange={(e) =>
                          handleTierValueChange(e.target.value, idx, "max")
                        }
                      />
                    </td>
                    <td class="px-6 py-4">
                      <Input
                        id="unit_pricing"
                        value={
                          tier.price_type === "unit_pricing"
                            ? tier.price || ""
                            : ""
                        }
                        indicator="$"
                        disabled={
                          (tier.price > 0 && tier.price_type == "flat") ||
                          fullQuote.status == "submitted"
                        }
                        onChange={(e) =>
                          handleTierValueChange(
                            e.target.value,
                            idx,
                            "price",
                            "unit_pricing"
                          )
                        }
                      />
                    </td>
                    <td class="px-6 py-4 relative">
                      <Input
                        id="flat"
                        indicator="$"
                        value={
                          tier.price_type === "flat" ? tier.price || "" : ""
                        }
                        disabled={
                          (tier.price > 0 &&
                            tier.price_type == "unit_pricing") ||
                          fullQuote.status == "submitted"
                        }
                        onChange={(e) =>
                          handleTierValueChange(
                            e.target.value,
                            idx,
                            "price",
                            "flat"
                          )
                        }
                      />
                    </td>
                    <td class="px-1 py-4">
                      <span className="">
                        <Button
                          iconButton
                          color="text"
                          handleClick={() => deleteTier(idx)}
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </Button>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div
          id="last-line"
          className=" absolute  left-0 right-0 -bottom-5 flex items-center justify-center text-lg"
        >
          <span className="">
            <button
              size="lg"
              rounded={true}
              color="accent"
              className="rounded-full px-2 py-1 text-md font-semibold text-white bg-brand-700 hover:bg-brand-600 border border-gray-200 flex items-center rounded-full"
              onClick={() => insertNewTier(item)}
            >
              <Icon
                icon="material-symbols:cell-merge-rounded"
                className="w-5 h-5 mr-2"
              />
              Add another tier
            </button>
          </span>
        </div>
      </div>

      <div className="flex items-center mt-10">
        {!isValid && (
          <div className="flex items-center text-sm text-red-500">
            <ExclamationCircleIcon className="h-5 w-5 mr-1" />
            {validationMessage}
          </div>
        )}
      </div>
    </div>
  );
}

// {lineItemType ? (
//     <span className="block text-sm font-medium text-gray-700">
//       {label ? label : lineItemType}
//     </span>
//   ) : (
//     <span className="block text-sm font-medium text-gray-700">
//       {label ? (
//         label
//       ) : (
//         <ComboboxSelector
//           name={"unit"}
//           options={[
//             { name: "PDF", value: "pdf" },
//             { name: "Processing", value: "processing" },
//             { name: "Project Management", value: "project_management" },
//           ]}
//           textbutton={true}
//           buttonType={"pricing_item"}
//           fieldType={"single"}
//           open={true}
//           //   handleSelect={(v) => handleSelect(v, service, "unit")}
//           //   handleSelect={(v) => {
//           //     return handleChangeInputDropdownValue("line_item", v);
//           //   }}
//           handleSelect={(v) => {
//             selectLineItem(v);
//           }}
//           handleDeselect={handleDeselect}
//           handleClearAllSelections={(value) =>
//             handleClearAllSelections(value, service, "line_item")
//           } //{handleClearAllSelections}
//           // value={opts2[field] ? opts2[field] : null}
//           value={() => {
//             return (
//               inputDropdownValue && inputDropdownValue["line_item"]
//             );
//           }}
//         />
//       )}
//     </span>
//   )}
