import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Logo from "../images/logo.png";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../elements/Button";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { Audit } from "../components/AuditFeature";
import { usePersistStore } from "../store/usestore";
import { _stripe } from "../modules/_stripe";
import Skeleton from "../components/Skeleton";
import { _navigation } from "../modules/_navigation";

function SelectTenant() {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [passwordMatched, isPasswordMatched] = useState(true);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(true);
  const [companyTenants, setCompanyTenants] = useState([]);
  const [tenants1, setTenants] = useState([]);
  //const {tenants, tenantId} = usePersistStore()

  let profile = history.location.state.profile;
  console.log(
    "Profile in SelectTenant history.location.state.profile",
    profile
  );
  let location_tenantId = history.location.state.tenantId;

  useEffect(() => {
    (async () => {
      setLoader(true);
      let path = "all";
      let data = {
        companyId: profile.companyId,
      };
      //this needs to be changed.  Depending on the user's role, we need
      //to either get tenants by company, get all tenants, or get tenants by user
      if (profile.role == "Administrator") {
        let tenants = await _auth.getTentants(path, data);
        console.info("GET TENANTS", tenants);
        if (tenants) {
          tenants.sort((a, b) => (a.tenantTitle > b.tenantTitle ? 1 : -1));
          setCompanyTenants(tenants);
          let showTenants =
            profile.role == "Administrator" ? tenants : profile.tenants;
          setTenants(showTenants);
          setLoader(false);
        } else {
          let showTenants = profile.tenants;
          setTenants(showTenants);
          setLoader(false);
        }
      } else {
        let showTenants = profile.tenants;
        setTenants(showTenants);
        setLoader(false);
      }
    })();
  }, []);

  console.log("Profile Get Tenants", profile.tenants);

  const tenants = history.location.state.profile.tenants;
  // tenants1.length > 0 ? tenants1 : history.location.state.profile.tenants;

  console.log(
    "Profile Tenants from state",
    history.location.state.profile.tenants
  );

  console.log("All Tenants", tenants);

  const allowedRoles = [
    "Admin",
    "Manager",
    "Administrator",
    "SuperAdmin",
    "Super Admin",
  ];

  const goToTenant = async (tenant) => {
    setLoader(true);
    console.info("goToTenant", tenant);
    console.info("gotoTenant profile", tenant);

    // let userProfile = profile;
    // userProfile.tenants = [tenant];
    // console.log("Build user profile", JSON.stringify(userProfile));
    // userProfile = _auth.buildProfile(profile);
    let id = tenant.tenantId;

    // profile.type = tenant.type;
    // profile.tenantId = tenant.tenantId; //add selected tenant id to profile
    // profile.tenantName = tenant.tenantTitle; //add selected tenant id to profile
    // profile.tenantType = tenant.type;
    // profile.company = tenant.company;
    // profile.companyId = tenant.companyId;
    // profile.tenantCompanyId = tenant.companyId
    //   ? tenant.companyId
    //   : tenant.tenantCompanyID; //add selected tenant id to profile
    // //roles
    // profile.tenantRole = tenant.role;
    profile.tenants = tenants; // [tenant];
    profile.tenants[0].tenantId = tenant.tenantInfo;
    profile.tenantInfo = tenant.tenantInfo;
    profile.allTenants = tenants;

    // profile.role =
    //   profile.companyRole &&
    //   (profile.companyRole == "Administrator" ||
    //     profile.companyRole == "Observer")
    //     ? profile.companyRole
    //     : profile.tenants[0].role;
    // profile = _auth.setProfileRole(profile);
    profile = _auth.buildProfile(profile);
    // profile.role =
    //   profile.sysRole && profile.sysRole == "Super Admin"
    //     ? (profile.role = "Super Admin")
    //     : profile.companyRole &&
    //       (profile.companyRole == "Administrator" ||
    //         profile.companyRole == "Observer")
    //     ? profile.companyRole
    //     : profile.tenants[0].role;
    let isRenamed = tenant.isRenamed;
    let tmpArr = [];
    if (profile != false) {
      // console.log("goToTenant -> Profile", JSON.stringify(profile));
      // console.log("setProfile from goToTenant", profile);
      console.log("setProfile from goToTenant", profile);
      _auth.setUserProfile(profile, "sign in select");

      // _auth.setProfileRole();

      // if (userProfile != false) {

      if (process.env.REACT_APP_NODE_ENV !== "production") {
        _stripe.getStripeData(); //get stripe data and set it in the cache. this also creates websocket connection
      }
      // figure out which experience to send them to
      if (history.location.state?.from) {
        let urlParts = history.location.state?.from.split("/");
        let projectIndex = urlParts.indexOf("project-details");
        let projectId = urlParts[projectIndex + 1];
        //if connections is in the url, we need to redirect the connections page
        //which should be in the from: history.location.state?.from
        if (urlParts.indexOf("connections") > -1) {
          history.push({
            state: { profile: profile },
            pathname: history.location.state?.from,
          });
        } else if (projectIndex > -1 && projectId) {
          history.push({
            state: { profile: profile },
            pathname: history.location.state?.from,
          });
        } else {
          history.push({
            state: { profile: profile },
            pathname: _navigation.getPath(profile),
          });
        }
      } else {
        history.push({
          state: { profile: profile },
          pathname: _navigation.getPath(profile),
        });
      }
    } else {
      setLoader(false);
      setIsAuthenticated(false);
    }
  };

  return (
    <main className="bg-gray-50">
      <div className="relative md:flex justify-center">
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="mx-auto max-w-lg p-10 bg-white rounded shadow-lg overflow-auto max-w-1/2  max-h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
              <div>
                <div className="text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-slate-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  <h2 className="mt-2 text-lg font-medium text-slate-900">
                    Select an account
                  </h2>
                  <p className="mt-3 text-sm text-slate-500 mb-10 text-left">
                    Looks like you have multiple accounts. That's cool. Just
                    select the one you want to collaborate on and we'll get you
                    on your way.
                  </p>
                </div>
                <div className="p-6 max-h-[calc(100vh_-_30rem)]  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
                  <div className="text-sm">
                    {/* Options */}
                    <ul className="space-y-2 mb-4 w-full">
                      {tenants.map((a) => {
                        console.log("a Tenant", a);
                        let id = a.tenantId ? a.tenantId._id : a._id;

                        let tenantTitle = a.tenantId
                          ? a.tenantId.tenantTitle
                          : a.tenantTitle;
                        let role = a.role ? a.role : "Administrator";
                        let status = a.status ? a.status : "Active";

                        let company = a.tenantId
                          ? a.tenantId.tenantCompany
                          : null;
                        let companyId = a.companyId
                          ? a.companyId
                          : a.tenantId
                          ? a.tenantId.tenantCompanyID
                          : null;
                        let isRenamed = a.tenantId
                          ? a.tenantId.isRenamed
                          : a.isRenamed;
                        const tenant = {
                          tenantId: id,
                          tenantTitle: tenantTitle,
                          role: role,
                          status: status,
                          company: company,
                          companyId: companyId,
                          isRenamed: isRenamed,
                          type:
                            a.tenantId && a.tenantId.type
                              ? a.tenantId.type
                              : "admin",
                          tenantInfo: a.tenantId ? a.tenantId : null,
                        };
                        console.info("TENANT MAPPED", tenant);

                        return (
                          a.status != "Disabled" &&
                          a.status !== "Deleted" && (
                            <li key={id}>
                              <button
                                disabled={
                                  a.status === "Invited" ||
                                  (location_tenantId &&
                                    location_tenantId != a.tenantId._id)
                                }
                                onClick={() => goToTenant(tenant, id)}
                                className={`w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-300
                                shadow-sm duration-150 ease-in-out
                               
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500
                                ${
                                  a.status === "Invited"
                                    ? "opacity-80 cursor-not-allowed hover-shadow-none hover-border-none"
                                    : " hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 hover:border-gray-300 hover:border-brand-500"
                                }
                                `}
                              >
                                <div className="flex items-center">
                                  <div className="grow">
                                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                                      <span className="flex font-semibold text-slate-800">
                                        {tenantTitle
                                          ? tenantTitle
                                          : profile.company
                                          ? profile.company
                                          : profile.email}
                                        {status != "Active" && (
                                          <span className="normal-case text-xs italic text-accent-800 align-top ml-2">
                                            {a.status == "Invited"
                                              ? "    Accept your invitation to continue"
                                              : null}
                                          </span>
                                        )}
                                      </span>
                                      <span>
                                        <span className="font-medium text-accent-600">
                                          {role}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="text-xs">
                                      {company && (
                                        <span className="text-slate-500">
                                          {company}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </li>
                          )
                        );
                      })}
                      {/* Create a new tenant */}
                      {/* We need to check if this user owns a tenant of their own or if they are a guest only */}
                      {/* If they are only a guest, we need to give them the ability to create an account of their own */}
                      {/* <li key={"new_tenant"}>
                        <button
                          // color="white"
                          // disabled={a.status === "Invited"}
                          onClick={() => goToTenant("new", "new")}
                          className="w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-200
                                shadow-sm duration-150 ease-in-out
                                hover:border-gray-300
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                
                               "
                        >
                          Create New Tenant
                        </button>
                      </li> */}
                    </ul>
                    {/* <div className="text-xs text-slate-500">
                  After selecting your assessment, click Next to begin.
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SelectTenant;
