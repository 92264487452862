import React, { useEffect, useState } from "react";
import { Solicitations } from "../../modules/_solicitations";
import BidCompare from "./components/BidCompare";
import { useLocation, useParams, useHistory } from "react-router-dom";

const BidComparePage = () => {
  const location = useLocation();
  const state = location.state;
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [selectWinnerLoading, setSelectWinnerLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const [solicitation, setSolicitation] = useState(location.state.solicitation);
  const { solicitationId, projectId } = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      let item = await Solicitations.getById({
        id: solicitationId,
        projectId: projectId,
      });
      if (item) {
        console.log("Got solicitation", item);
        setSolicitation(item);
      } else {
        setSolicitation([]);
      }
    })();
  }, [solicitationId]);

  useEffect(() => {
    if (state) {
      setSolicitation(state.solicitation);
    }
  }, [state]);
  const handleNavigateBack = async (e) => {
    history.goBack();
  };
  const selectWinner = async (e, bid) => {
    // e.preventDefault();
    console.log("Selecting winner", e, bid);
    setSelectWinnerLoading(true);
    setErrMessage(null);

    let result = await Solicitations.selectWinner({
      solicitationId: solicitation._id,
      bid: bid,
      bidId: bid._id,
      solicitationId: bid.solicitationId,
    });
    console.log("selectWinner result", result);
    if (result) {
      setSolicitation(result);
      setCompareModalOpen(true);
      setSelectWinnerLoading(false);
    } else {
      console.log("Error selecting winner");
      setSelectWinnerLoading(false);
      errMessage("Error selecting winner");
    }
  };

  const notifyWinner = async (e, bid, message) => {
    e.preventDefault();
    console.log("Notifying winner", bid, message);
    setSelectWinnerLoading(true);
    setErrMessage(null);
    if (message == "" || message == null) {
      setTimeout(() => {
        setErrMessage("Please enter a message to the vendor");
        setSelectWinnerLoading(false);
      }, 500);
      return false;
    } else {
      let result = await Solicitations.notifyWinner({
        solicitationId: solicitation._id,
        bid: bid,
        bidId: bid._id,
        solicitationId: bid.solicitationId,
        vendorEmail: bid.submittedByEmail,
        message: message,
      });
      console.log("selectWinner result", result);
      if (result) {
        console.log("Winner selected and confirmed", result);
        setSolicitation(result);
        // setCompareModalOpen(false);
        setSelectWinnerLoading(false);
      } else {
        console.log("Error selecting winner");
        setSelectWinnerLoading(false);
        errMessage("Error selecting winner");
      }
    }
  };

  return (
    <div id="page_container" className="flex flex-col h-full overflow-hidden">
      {/* {solicitation &&
      solicitation.responses &&
      solicitation.responses.length > 0 ? ( */}
      <BidCompare
        data={solicitation?.responses ? solicitation.responses : []}
        selectWinner={selectWinner}
        notifyWinner={notifyWinner}
        loading={selectWinnerLoading}
        errorMessage={errMessage}
        solicitation={solicitation}
        handleNavigate={handleNavigateBack}
      />
      {/* ) : (
        <div className="alert alert-warning">
          No bids have been submitted for this solicitation
        </div>
      )} */}
    </div>
  );
};

export default BidComparePage;
