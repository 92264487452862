import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const TileCard = (props) => {
  const disabled =
    props.disabled ||
    props.status === "pending" ||
    props.status === "inactive" ||
    false;
  return (
    <div
      key={props.id}
      className={`border border-gray-200 ${
        disabled
          ? "bg-gray-50"
          : "bg-white hover:cursor-pointer hover:border hover:border-brand-500"
      } flex flex-col items-center p-4 h-48 w-48  shadow-md rounded-md justify-center text-center`}
      onClick={() => {
        props.onClick(props.id) || console.log(props.id);
      }}
    >
      {props.icon ? (
        <div className="p-0 rounded-full h-12 w-12 ">
          <Icon icon={props.icon} className="h-12 w-12" />
        </div>
      ) : (
        <div className="p-4 rounded-full h-12 w-12 bg-purple-200"></div>
      )}

      <div className="mt-4">
        <h2 className="font-semibold">{props.title}</h2>
        <p className="text-sm text-gray-500">{props.description}</p>
        {props.status ? (
          <p
            className={`text-sm capitalize ${
              props.status === "active"
                ? "text-green-500"
                : props.status === "pending"
                ? "text-brand-500"
                : "text-red-500"
            }`}
          >
            {props.status}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TileCard;
