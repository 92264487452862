import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Conversations = {
  async getConversationHistory(data) {
    console.log("getConversationHistory Call API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;

    console.log("getConversationHistory API body", data);
    let response = await postRequest("conversations/get-history", data);
    if (response) {
      console.log("getConversationHistory response", response);
      return response?.data?.data?.data;
    } else {
      return false;
    }
  },
  async deleteConversationHistory(data) {
    console.log("deleteConversationHistory Call API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;

    console.log("deleteConversationHistory API body", data);
    let response = await postRequest("conversations/delete-history", data);
    if (response) {
      console.log("deleteConversationHistory response", response);
      return response;
    } else {
      return false;
    }
  },
};
