import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";
import MenuTag from "./MenuTag";
import Button from "../elements/Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownMenu({
  menuItems,
  buttonText,
  buttonColor,
  showCheveron,
  buttonType,
  direction,
}) {
  console.log("Menu Items", menuItems);
  const groupedMenuItems = menuItems.reduce((groupedItems, item) => {
    if (item.show) {
      // check if item is visible
      const key = item.group;
      if (!groupedItems[key]) {
        groupedItems[key] = [];
      }
      groupedItems[key].push(item);
    }
    return groupedItems;
  }, {});
  direction = direction || "down";

  return Object.keys(groupedMenuItems).length > 0 ? (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-full  px-0 py-0  ">
          {buttonType && buttonType == "icon" ? (
            <Button iconButton>
              <Icon //three vertical dots
                className="h-5 w-5 text-slate-500 "
                icon="mdi:dots-vertical"
              />
              {/* <svg
               className="w-5 h-5 fill-current"
               viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg"
             >
               <rect x="4" y="5" width="16" height="2" />
               <rect x="4" y="11" width="16" height="2" />
               <rect x="4" y="17" width="16" height="2" />
             </svg> */}
            </Button>
          ) : (
            <Button color={buttonColor || "outline"} size="sm">
              {/* <Icon //three vertical dots
             className="h-5 w-5 text-slate-500 "
             icon="mdi:dots-vertical"
           /> */}
              {buttonText ? buttonText : "Menu"}
              <Icon //dropdown icon
                className="h-5 w-5 ml-2  "
                icon="mdi:chevron-down"
              />
            </Button>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            direction === "up" ? "bottom-full mb-2" : "top-full mt-2"
          }`}
        >
          <div className="py-1.5">
            {Object.entries(groupedMenuItems).map(([group, items], index) => {
              console.log("Group", group);
              console.log("Items", items);
              console.log("Index", index);
              return (
                <div
                  key={group}
                  className={`${
                    index != 0 &&
                    Object.entries(groupedMenuItems).length > 1 &&
                    "border-t"
                  } border-gray-200`}
                >
                  {items.map((item) => {
                    // console.log(item.name, item.show, "show name");
                    // console.log(item.show === true, "show conditon");
                    return (
                      item.show === true && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                item.action(e);
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                !item.disabled
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed",
                                "group flex items-center px-4 py-3 text-sm"
                              )}
                            >
                              <Icon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                icon={item.icon}
                                aria-hidden="true"
                              />
                              {item.name}
                              {item.status && <MenuTag status={item.status} />}
                            </a>
                          )}
                        </Menu.Item>
                      )
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : null;
}
