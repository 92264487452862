import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
import jwt_decode from "jwt-decode";
import { _auth } from "./_auth";
import { usePersistStore } from "../store/usestore";

export const templates = {
  async saveFormTemplate(data) {
    console.log("Call API and pass data", data);
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const body = {};
    body.name = "Template Name";
    body.description = "Template Description";
    body.json = data;
    body.form = data;
    body.tenantId = profile.tenantId;
    body.companyId = profile.companyId;
    console.log("API body", body);
    let response = await postRequest("templates/create", body);
    if (response) {
      return response;
    } else {
      return response;
    }
  },
  async getAllTemplates() {
    const body = {};
    let profile = _auth.profile();
    body.tenantId = profile.tenantId;

    body.companyId = profile.companyId;
    console.log("Getting templates list");
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("templates/list", body);
    if (response) {
      return response.data.data;
    } else {
      return response;
    }
  },
  async deleteTemplate(templateId) {
    const body = {};
    let profile = _auth.profile();
    body.tenantId = profile.tenantId;
    body.id = templateId;

    body.companyId = profile.companyId;
    console.log("Getting templates list");
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest(
      "/solicitations/templates/delete-template",
      body
    );
    if (response) {
      return response.data.data;
    } else {
      return response;
    }
  },

  async getFormData(id) {
    const body = {};
    body.id = id;
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("templates/get-by-id", body);
    if (response) {
      return response.data.data;
    } else {
      return response;
    }
  },
};
