import React from "react";
import Tip from "../../../components/Tip";
import { Label } from "../../../components/Typography/FieldLabel";
import CreditMonitoringInfo from "./CreditMonitoringInfo";
import JurisdictionInput from "./JurisdictionInput";
import EmailingInfo from "./EmailingInfo";
import MailingInfo from "./MailingInfo";
import CallCenterInfo from "./CallCenterInfo";
import Assumptions from "./Assumptions";
import DataMiningInfo from "./DataMiningInfo";
import ForensicsInfo from "./ForensicsInfo";
import BettermentInfo from "./BettermentInfo";
import { utils } from "../../../modules/_utils";

const ServiceDetails = ({
  solicitation,
  serviceDrawerOpen,
  selectedTab,
  service,
}) => {
  const renderServiceDetails = () => {
    if (selectedTab === "credit_monitoring" && service == "credit_monitoring") {
      if (service == "credit_monitoring") {
        return (
          <div className="py-6 border-b border-gray-200">
            <Tip>
              Credit monitoring is broken into groups. Each group contains
              different set of requirements.
            </Tip>
            {service.opts2 &&
              Object.entries(service.opts2).map(([group, entries], index) => {
                return (
                  <div className="py-6 border-b border-gray-200">
                    <CreditMonitoringInfo
                      create={false}
                      newProject={solicitation}
                      project={entries}
                      service={"credit_monitoring"}
                      onFieldChange={null}
                      handleDeleteCohort={null}
                      groupIndex={index}
                      cohort={entries}
                      handleDeselectedLanguage={null}
                      handleSelectedLanguage={null}
                      handleClearAllSelections={null}
                    />
                    <div className="w-full mt-12 mb-6 pr-6 border-t border-primary-300 " />
                  </div>
                );
              })}
          </div>
        );
      }
    } else if (selectedTab === "emailings" && service === "emailings") {
      return (
        <div className="py-6 border-b border-gray-200">
          <div className="w-3/4">
            <Label className="mb-2">Jurisdictions</Label>
            <p className="text-slate-500 mb-3">
              This section shows the total number of affected individuals in
              each jurisdiction.
            </p>
            <JurisdictionInput
              create={false}
              scope="solicitation"
              field={"jurisdiction"}
              service={"emailings"}
              newProject={solicitation}
              project={solicitation}
              onFieldChange={null}
              selectedJurisdictions={
                solicitation &&
                solicitation.services[
                  solicitation.services.findIndex(
                    (el) => el.service === "emailings"
                  )
                ].options &&
                solicitation.services[
                  solicitation.services.findIndex(
                    (el) => el.service === "emailings"
                  )
                ].options[0].jurisdictions
                  ? solicitation.services[
                      solicitation.services.findIndex(
                        (el) => el.service === "emailings"
                      )
                    ].options[0].jurisdictions
                  : []
              }
              setSelectedJurisdictions={null}
            />
          </div>
          <EmailingInfo
            create={false}
            scope="solicitation"
            service={"emailings"}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (selectedTab === "mailings" && service === "mailings") {
      return (
        <div className="py-6 border-b border-gray-200">
          <div className="w-3/4">
            <Label className="mb-2">Jurisdictions</Label>
            <p className="text-slate-500 mb-3">
              This section shows the total number of affected individuals in
              each jurisdiction.
            </p>
            <JurisdictionInput
              create={false}
              scope="solicitation"
              field={"jurisdiction"}
              service={"mailings"}
              newProject={solicitation}
              project={solicitation}
              onFieldChange={null}
              selectedJurisdictions={
                solicitation &&
                solicitation.services[
                  solicitation.services.findIndex(
                    (el) => el.service === "mailings"
                  )
                ].options &&
                solicitation.services[
                  solicitation.services.findIndex(
                    (el) => el.service === "mailings"
                  )
                ].options[0].jurisdictions
                  ? solicitation.services[
                      solicitation.services.findIndex(
                        (el) => el.service === "mailings"
                      )
                    ].options[0].jurisdictions
                  : []
              }
              setSelectedJurisdictions={null}
            />

            {/* {solicitation.services[
                            solicitation.services.findIndex(
                              (el) => el.service === "data_mining"
                            )
                          ]?.options &&
                            solicitation.services[
                              solicitation.services.findIndex(
                                (el) => el.service === "data_mining"
                              )
                            ]?.options[0]?.jurisdictions?.map(
                              (jurisdiction) => {
                                return (
                                  <div>
                                    <h1 className="text-xl font-medium text-slate-900">
                                      {jurisdiction?.name}
                                    </h1>
                                    <div className="w-full mt-12 mb-6 pr-6 border-t border-primary-300 " />
                                  </div>
                                );
                              }
                            )} */}
          </div>
          {/* <Chips values={solicitation.jurisdiction} /> */}

          <div className="py-6 border-b border-gray-200">
            <MailingInfo
              create={false}
              scope="solicitation"
              service={"mailings"}
              newProject={solicitation}
              project={solicitation}
              onFieldChange={null}
              onSelectionChange={null}
              languageOptions={null}
              handleDeselectedLanguage={null}
              handleSelectedLanguage={null}
              handleClearAllSelections={null}
            />
          </div>
        </div>
      );
    } else if (selectedTab === "call_center" && service === "call_center") {
      return (
        <div className="py-6 border-b border-gray-200">
          <CallCenterInfo
            create={false}
            scope="solicitation"
            service={"call_center"}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (selectedTab === "data_mining" && service === "data_mining") {
      return (
        <div className="py-6 border-b border-gray-200">
          <Assumptions service={"data_mining"} solicitation={solicitation} />
          <DataMiningInfo
            create={false}
            scope="solicitation"
            service={"data_mining"}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            handleSelect={null}
            handleSingleSelect={null}
            languageOptions={null}
            callCenterLocationOptions={null}
            handleDeselectedLanguage={null}
            handleMultiSelect={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (
      selectedTab === "document_review" &&
      service === "document_review"
    ) {
      return (
        <div className="py-6 border-b border-gray-200">
          <Assumptions
            service={"document_review"}
            solicitation={solicitation}
          />
          <DataMiningInfo
            create={false}
            scope="solicitation"
            service={"document_review"}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (
      selectedTab === "ediscovery_document_review" &&
      service === "ediscovery_document_review"
    ) {
      return (
        <div className="py-6 border-b border-gray-200">
          <Assumptions
            service={"ediscovery_document_review"}
            solicitation={solicitation}
          />
          <DataMiningInfo
            create={false}
            scope="solicitation"
            service={"ediscovery_document_review"}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (
      selectedTab === "incident_response" &&
      service === "incident_response"
    ) {
      return (
        <div className="py-6 border-b border-gray-200">
          <ForensicsInfo
            id={"incident_response"}
            create={false}
            scope="solicitation"
            service={"incident_response"}
            serviceDrawerOpen={serviceDrawerOpen}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (selectedTab === "ransomware" && service === "ransomware") {
      return (
        <div className="py-6 border-b border-gray-200">
          <ForensicsInfo
            id={"ransomware"}
            create={false}
            scope="solicitation"
            service={"ransomware"}
            serviceDrawerOpen={serviceDrawerOpen}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (
      selectedTab === "managed_detection_and_response" &&
      service === "managed_detection_and_response"
    ) {
      return (
        <div className="py-6 border-b border-gray-200">
          <ForensicsInfo
            id={"managed_detection_and_response"}
            create={false}
            scope="solicitation"
            service={"managed_detection_and_response"}
            serviceDrawerOpen={serviceDrawerOpen}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (selectedTab === "betterment" && service === "betterment") {
      return (
        <div className="py-6 border-b border-gray-200">
          <BettermentInfo
            create={false}
            scope="solicitation"
            service={"betterment"}
            newProject={solicitation}
            project={solicitation}
            onFieldChange={null}
            onSelectionChange={null}
            languageOptions={null}
            handleDeselectedLanguage={null}
            handleSelectedLanguage={null}
            handleClearAllSelections={null}
          />
        </div>
      );
    } else if (selectedTab === "landing_page" && service === "landing_page") {
      return null;
    }
  };
  return (
    selectedTab === service && (
      <div
        id={`scrollable-render-service-details-components-${service}`}
        className=" px-6 pb-48 h-96 overflow-y-auto scrollbar-default"
      >
        {renderServiceDetails()}
      </div>
    )
  );
};

export default ServiceDetails;
