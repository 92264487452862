import { Icon } from "@iconify/react";
import Button from "../elements/Button";
import { useEffect, useState } from "react";

export default function CommentsBar(props) {
  const {
    replies,
    commentCount,
    thanksCount,
    replyCount,
    commentId,
    replyId,
    audit,
    action,
    auditDetails,
    handleLike,
    profile,
  } = props;
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [comment, setComment] = useState(props.comment);

  useEffect(() => {
    console.log("Comments comment changed in comment bar", comment);
    console.log("User profile in CommentsBar", profile);
    //if comment.likes includes profile.user_id, setAlreadyLiked(true)
    let liked = comment?.likes?.filter((like) =>
      like?.userId == profile.user_id ? profile.user_id : profile.userId
    );
    if (liked && liked.length > 0) {
      console.log("Comments Already liked", liked);
      setAlreadyLiked(true);
    } else {
      console.log("Comments You have not liked this comment", liked);
      setAlreadyLiked(false);
    }
  }, [comment.likes]);

  useEffect(() => {
    console.log("Comments comment changed in comment bar", comment);
    setComment(props.comment);
  }, [props.comment]);

  return (
    <div>
      <div class="flex border-t border-gray-200 mt-4 py-2 items-center justify-between  space-x-4">
        <div className="flex  text-xs text-gray-500  items-center">
          {/* <Button size="lg" color="text-hover"> */}
          {/* <span class="inline-flex items-center text-xs text-gray-500 "> */}
          <Button
            iconButton
            handleClick={(e) => {
              e.preventDefault();
              handleLike(e);
            }}
          >
            {alreadyLiked ? (
              <Icon icon="mdi:like" className={`flex h-4 w-4`} />
            ) : (
              <Icon icon="mdi:like-outline" className={`flex h-4 w-4`} />
            )}
          </Button>
          {comment?.likes?.length > 0 &&
            `${
              alreadyLiked
                ? comment.likes.length > 1
                  ? `You and ${comment.likes.length - 1} other ${
                      comment.likes.length - 1 > 1 ? "s" : ""
                    } like this`
                  : "You like this"
                : comment.likes.length == 1
                ? comment.likes.length + " like"
                : comment.likes.length + " like this"
            }
            
            
           
           `}
          {/* ${comment.likes.length !== 1 ? "likes" : "like this"} */}
        </div>
        {/* <div className="flex  text-xs text-gray-500 items-center"> */}
        {/* <Button size="lg" color="text-hover"> */}
        {/* <span class="inline-flex items-center text-xs text-gray-500 "> */}
        {/* <Icon icon="mdi:comment-outline" className="flex h-6 mr-2" />
          <span> {replies.length || 0} Replies</span> */}
        {/* </Button> */}
        {/* </div> */}
      </div>
      {/* <div class="flex items-center justify-between space-x-4 mt-3"> */}
      {/* <div className="flex items-center">
          <Button
            size="sm"
            color="text-hover"
            handleClick={(e) => {
              e.preventDefault();
              handleLike(e);
            }}
            // class="flex items-center  text-gray-500 hover:underline  font-medium"
          >
            <span className="flex text-sm">Like</span>
          </Button>
        </div> */}
      {/* <div> */}
      {/* <Button
            size="sm"
            color="text-hover"
            audit={true}
            action={action ? action : ""}
            details={auditDetails}
            // class="flex items-center  text-gray-500 hover:underline  font-medium"
          >
            <span className="flex text-sm">Reply</span>
          </Button> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}
