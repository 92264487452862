// import { PlusIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "../elements/Button";
import ComboboxSelector from "./ComboBoxSelector";

export default function Divider(props, { children }) {
  const { options, selectedTab, addLineItem } = props;
  const handleClick = props.handleClick;
  const button = props.button ? props.button : true;
  return (
    <div className="relative my-8">
      <div
        className="absolute inset-0 flex items-center px-3"
        aria-hidden="true"
      >
        <div id="divider" className="w-full border-t border-gray-200 " />
      </div>

      <div className="relative flex justify-center">
        <button
          type="button"
          onClick={() => {
            handleClick();
          }}
          className={`inline-flex items-center rounded-full border 
          bg-brand-100 text-slate-600
          border-gray-300 bg-white px-4 py-1.5 text-sm font-medium leading-5  
          shadow-sm
          hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 
             
          hover:bg-gray-50  hover:border-brand-500 
          focus:outline-none focus:ring-1 focus:ring-brand-500 `}
        >
          <PlusIcon
            className="-ml-1.5 mr-1 h-5 w-5 text-slate-500"
            aria-hidden="true"
          />
          <span>{props.buttonText ? props.buttonText : "Button text"}</span>
        </button>
      </div>
      {children}
    </div>
  );
}
