import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _user_profile = {
  async updateShowHome(data) {
    let profile = await _auth.getUserProfile();
    let id = profile.user_id;

    let body = {
      id: id,
      updates: data,
    };
    // console.info("updateShowHome", data);
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("users/update-profile", body);
    ////debugger
    // this.loader = true;
    console.log(response, "responsesss");
    if (response) {
      // console.log("updateShowHome", response.data.data.data);
      return true;
    } else {
      return false;
    }
  },
};
