import { Fragment, useState } from "react";
import { useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronUpIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function handleResetListBox() {
  ListBoxSelector.setSelected("All");
}

export default function ListBoxSelector({
  options,
  label,
  handleFilter,
  filters,
}) {
  const [selected, setSelected] = useState("Filtery by " + label);

  useEffect(() => {
    console.log("reset", filters, filters.length);
    if (filters.length === 0) {
      setSelected("Filter by " + label);
    }
  }, [filters]);

  const handleChange = (e) => {
    console.log(e, "e");
    setSelected(e);
    handleFilter(e);
    console.log("Filters", filters);
  };

  return (
    // <div className="inline-block w-full h-8">
    <Listbox
      className={"cursor-pointer "}
      value={selected}
      onChange={handleChange}
      defaultValue={"Filter by " + label}
    >
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-slate-700">
              {label}
            </Listbox.Label> */}
          <div className="relative mt-1 w-full">
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md 
              border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm 
              hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 hover:border-brand-500
              ${open ? "border-brand-500" : "border-gray-300"}
              focus:border-brand-500 focus:outline-none  sm:text-sm`}
            >
              <span className="block truncate">{selected}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-slate-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`pl-3 pr-4 pb-2
                scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                {options.map((person) => (
                  <Listbox.Option
                    key={person}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-brand-600" : "text-slate-900",
                        "relative cursor-pointer rounded select-none py-2 pl-8 pr-4 transition ease-in-out duration-100"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {person}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-primary-600",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    //</div>
  );
}
