import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import RightActionArea from "../../../components/ActionAreaRight";
import Button from "../../../elements/Button";
import { Icon } from "@iconify/react";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import Tooltip from "../../../components/Tooltip";
import QuestionToolTip from "../../../components/QuestionToolTip";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import Tip from "../../../components/Tip";

export default function CreditMonitoringInfo({
  create,
  readOnly,
  newProject,
  groupIndex,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleDeselectedLanguage,
  handleClearAllSelections,
  handleDeleteCohort,
  service,
  cohort,
  languageOptions,
  callCenterLocationOptions,
  handleSelect,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,

  scope,
  isChecked,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState([]);
  const [jurisdictionOptions, setJurisdictionoptions] = useState([]);
  useEffect(() => {
    create && create == false ? (create = false) : (create = true);
    create == true ? (readOnly = false) : (readOnly = true);
  }, [create]);

  useEffect(() => {
    setData(newProject);
  }, [newProject]);

  useEffect(() => {
    console.log(
      "Credit Monitoring project updatedddd",
      JSON.stringify(newProject)
    );
  }, [newProject]);

  useMemo(() => {
    let jurisdictionOptions = [];
    project &&
      project.jurisdictions &&
      project.jurisdictions.map((service) => {
        jurisdictionOptions.push({ name: service.key });
      });

    setJurisdictionoptions(jurisdictionOptions);
    console.log("Solication JD Options", project, jurisdictionOptions);
  }, []);

  useEffect(() => {
    if (
      newProject &&
      newProject.services &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOptions(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    } else {
      console.log("UNDEFINED");
    }
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      let opts2 =
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2;
      setOpts2(opts2);
    }
    console.log("SETTING VENDOR opts2", opts2);
  }, [newProject]);

  const deleteCohort = (e, groupIndex) => {
    e.preventDefault();
    handleDeleteCohort(e, groupIndex);
  };

  const handleClearChildSelections = (
    value,
    service,
    field,
    group,
    selectType,
    dependencyValue
  ) => {
    //specifically handle custom_pricing
    if (field === "pricing_options") {
      let e = {
        target: {
          name: "custom_pricing",
          value: "",
        },
      };
      if (!value.includes("Other")) {
        onFieldChange(
          "options",
          e,
          "custom_pricing",
          "credit_monitoring",
          group
        );
      }
    }
  };

  return (
    <div className="mt-8">
      <div className="pb-6 flex items-center justify-between">
        <h3 className="inline-flex text-base font-semibold leading-6 text-slate-900">
          Group {groupIndex + 1}
        </h3>
        <RightActionArea>
          {create && (
            <Button iconButton handleClick={(e) => deleteCohort(e, groupIndex)}>
              <TrashIcon className="h-5 w-5" />
            </Button>
          )}
        </RightActionArea>
      </div>
      <div>
        {create && (
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Basic information
          </h3>
        )}
        {create ? (
          <Paragraph>
            Use the form below to specify your needs for this specific group of
            individuals. When you're done, you can create a new group.
          </Paragraph>
        ) : (
          ""
        )}
      </div>

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
        {/*SINGLE: JURISDICTION */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label sm bold htmlFor="group" className="my-auto col-span-4 block ">
            What jurisdiction does this group of individuals live in?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  cohort && cohort.jurisdiction ? cohort.jurisdiction : ""
                }
              />
            </div>
          )}
          {create == true && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="jurisdiction"
                options={jurisdictionOptions}
                fieldType="single"
                open={true}
                handleSelect={(e) =>
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "jurisdiction",

                    "group_" + groupIndex + 1,
                    "single"
                  )
                }
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(
                    value,
                    "credit_monitoring",
                    "jurisdiction"
                  )
                } //{handleClearAllSelections}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1]
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].jurisdiction
                    : "";
                }}
              />
            </div>
          )}
        </div>
        {/*TEXT: Number of people */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label sm bold htmlFor="group" className="my-auto col-span-4 block ">
            How many in this group require adult credit monitoring?
          </Label>

          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"text"}
                answer={cohort && cohort.group_total ? cohort.group_total : ""}
              />
            </div>
          )}

          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <input
                type="text"
                name="group_total"
                id="group_total"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                value={
                  // opts2.group_total ? opts2.group_total : []
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].group_total
                    ? opts2["group_" + groupIndex + 1].group_total
                    : ""
                }
                onChange={(e) =>
                  onFieldChange(
                    "options",
                    e,
                    "group_total",
                    "credit_monitoring",
                    "group_" + groupIndex + 1
                  )
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-base"
              />
            </div>
          )}
        </div>
        {/*TEXT: Number of minors */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label sm bold htmlFor="group" className="my-auto col-span-4 block ">
            How many individuals in this group require minor credit monitoring?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"text"}
                answer={cohort && cohort.minors ? cohort.minors : ""}
              />
            </div>
          )}

          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <input
                type="text"
                name="minors"
                id="minors"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                value={
                  // opts2.group_total ? opts2.group_total : []
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].minors
                    ? opts2["group_" + groupIndex + 1].minors
                    : ""
                }
                onChange={(e) =>
                  onFieldChange(
                    "options",
                    e,
                    "minors",
                    "credit_monitoring",
                    "group_" + groupIndex + 1
                  )
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-base"
              />
            </div>
          )}
        </div>
        {/* SINGLE: Number of Bureaus */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-4 block "
          >
            How many Credit Bureaus would you like to offer?
            <QuestionToolTip
              field="Number of Bureaus"
              text={
                "In the United States, counsel typically uses one or three burueas.  In Canada, UK, and Australia, counsel typically uses one buruea."
              }
            />
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  cohort && cohort.number_of_bureaus
                    ? cohort.number_of_bureaus
                    : ""
                }
              />
            </div>
          )}

          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="number_of_bureaus"
                options={[{ name: 1 }, { name: 2 }, { name: 3 }]}
                fieldType="single"
                open={true}
                handleSelect={(e) => {
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "number_of_bureaus",

                    "group_" + groupIndex + 1,
                    "single"
                  );
                }}
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(
                    value,
                    "credit_monitoring",
                    "number_of_bureaus"
                  )
                } //{handleClearAllSelections}
                // value={
                //   opts2["group_" + groupIndex + 1] &&
                //   opts2["group_" + groupIndex + 1].number_of_bureaus
                //     ? opts2["group_" + groupIndex + 1].number_of_bureaus
                //     : ""
                // }
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1]
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].number_of_bureaus
                    : "";
                }}
                // value={() => {
                //   return cohort && cohort.number_of_bureaus
                //     ? cohort?.number_of_bureaus
                //     : "";
                // }}
              />
            </div>
          )}
        </div>
        {/*TEXT: Number of years */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label sm bold htmlFor="group" className="my-auto col-span-4 block ">
            How many years of credit monitoring would you like to offer?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  cohort && cohort.years_of_credit_monitoring
                    ? cohort.years_of_credit_monitoring
                    : ""
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <input
                type="text"
                name="years_of_credit_monitoring"
                id="years_of_credit_monitoring"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                value={
                  // opts2.group_total ? opts2.group_total : []
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].years_of_credit_monitoring
                    ? opts2["group_" + groupIndex + 1]
                        .years_of_credit_monitoring
                    : ""
                }
                onChange={(e) =>
                  onFieldChange(
                    "options",
                    e,
                    "years_of_credit_monitoring",
                    "credit_monitoring",
                    "group_" + groupIndex + 1
                  )
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-base"
              />
            </div>
          )}
        </div>
        {/* MULTI: PRICING OPTIONS */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-4 block "
          >
            Which pricing options do you want the vendor to provide?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"chips"}
                answer={
                  cohort && cohort.pricing_options ? cohort.pricing_options : ""
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="pricing_options"
                options={[
                  { name: "Fixed Fee" },
                  { name: "Redemption" },
                  { name: "Other" },
                ]}
                fieldType="multiSelect"
                open={true}
                handleSelect={(e) => {
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "pricing_options",

                    "group_" + groupIndex + 1,
                    "multiSelect"
                  );
                  handleClearChildSelections(
                    newProject &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1] &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].pricing_options &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].pricing_options,
                    "credit_monitoring",
                    "pricing_options",
                    "group_" + groupIndex + 1,
                    "multiSelect",
                    "Other" //dependency value
                  );
                }}
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(value) => {
                  handleClearAllSelections(
                    value,
                    "credit_monitoring",
                    "pricing_options"
                  );
                  ["Fixed Fee", "Redemption", "Other"].forEach((value) => {
                    handleSelectedLanguage(
                      value,
                      "credit_monitoring",
                      "pricing_options",
                      "group_" + groupIndex + 1,
                      "multiSelect"
                    );
                    //clear custom_pricing
                    let e = {
                      target: {
                        name: "custom_pricing",
                        value: "",
                      },
                    };
                    onFieldChange(
                      "options",
                      e,
                      "custom_pricing",
                      "credit_monitoring",
                      "group_" + groupIndex + 1
                    );
                  });
                }}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1].pricing_options
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].pricing_options
                    : [];
                }}
              />
            </div>
          )}
          {(newProject &&
            newProject.services[
              newProject.services.findIndex((el) => el.service === service)
            ].opts2["group_" + groupIndex + 1] &&
            newProject.services[
              newProject.services.findIndex((el) => el.service === service)
            ].opts2["group_" + groupIndex + 1].pricing_options &&
            newProject.services[
              newProject.services.findIndex((el) => el.service === service)
            ].opts2["group_" + groupIndex + 1].pricing_options.includes(
              "Other"
            )) ||
          //or if cohort && cohort.custom_pricing
          (cohort && cohort.custom_pricing) ? (
            <div className="sm:col-span-full mt-4">
              <Label
                htmlFor="about"
                className="block text-sm font-medium text-slate-700"
              >
                Custom Pricing Request
              </Label>

              {!create && (
                <div className="col-span-full mb-6">
                  <ReadOnlyAnswer
                    type={"text"}
                    answer={
                      cohort && cohort.custom_pricing
                        ? cohort.custom_pricing
                        : "No custom pricing request provided."
                    }
                  />
                </div>
              )}
              {create && (
                <p className="mt-2 text-sm text-slate-500">
                  Write a few sentences to describe your custom pricing request.
                </p>
              )}
              {create && (
                <div className="mt-1">
                  <textarea
                    id="custom_pricing"
                    name="custom_pricing"
                    rows={3}
                    value={
                      opts2["group_" + groupIndex + 1] &&
                      opts2["group_" + groupIndex + 1].custom_pricing
                    }
                    onChange={(e) =>
                      onFieldChange(
                        "options",
                        e,
                        "custom_pricing",
                        "credit_monitoring",
                        "group_" + groupIndex + 1
                      )
                    }
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>

        {/* Required Languages */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-4 block "
          >
            What languages do you require credit bureau agents to speak?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"chips"}
                answer={
                  cohort && cohort.required_language
                    ? cohort.required_language
                    : ""
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="required_langage"
                options={[
                  { name: "English" },
                  { name: "French" },
                  { name: "Spanish" },
                ]}
                fieldType="multiSelect"
                open={true}
                handleSelect={(e) => {
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "required_language",

                    "group_" + groupIndex + 1,
                    "multiSelect"
                  );
                }}
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(v) =>
                  handleClearAllSelections(
                    v,
                    "credit_monitoring",
                    "required_language"
                  )
                } //{handleClearAllSelections}
                // value={
                //   opts2["group_" + groupIndex + 1] &&
                //   opts2["group_" + groupIndex + 1].required_language
                //     ? opts2["group_" + groupIndex + 1].required_language
                //     : ""
                // }
                // value={() => {
                //   return (
                //     (newProject &&
                //       newProject.services[
                //         newProject.services.findIndex(
                //           (el) => el.service === service
                //         )
                //       ].opts2["group_" + groupIndex + 1].required_language) ||
                //     []
                //   );
                // }}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1].required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].required_language
                    : [];
                }}
              />
            </div>
          )}
        </div>
        {/* MULTIPLE: Additional Services */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-4 block "
          >
            What additional features should the service include?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"chips"}
                answer={
                  cohort && cohort.additional_services
                    ? cohort.additional_services
                    : "No additional services required"
                }
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="additional_services"
                options={[
                  { name: "ID Theft Insurance" },
                  { name: "ID Restoration" },
                  { name: "Dark Web monitoring" },
                ]}
                fieldType="multiSelect"
                open={true}
                handleSelect={(e) =>
                  handleSelectedLanguage(
                    e,
                    "credit_monitoring",
                    "additional_services",

                    "group_" + groupIndex + 1,
                    "multiSelect"
                  )
                }
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(
                    value,
                    "credit_monitoring",
                    "additional_services"
                  )
                } //{handleClearAllSelections}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2["group_" + groupIndex + 1].additional_services
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2["group_" + groupIndex + 1].additional_services
                    : [];
                }}

                // value={
                //   opts2["group_" + groupIndex + 1] &&
                //   opts2["group_" + groupIndex + 1].additional_services
                //     ? opts2["group_" + groupIndex + 1].additional_services
                //     : ""
                // }
              />
            </div>
          )}
        </div>
        {/* SINGLE: INSURANCE OPTIONS */}
        {opts2["group_" + groupIndex + 1] &&
          opts2["group_" + groupIndex + 1].additional_services &&
          opts2["group_" + groupIndex + 1].additional_services.includes(
            "ID Theft Insurance"
          ) && (
            <div className="sm:col-span-6 grid grid-cols-6">
              <Label
                sm
                bold
                htmlFor="call_center_location"
                className="my-auto col-span-4 block "
              >
                Which identity theft insurance coverage would you like to offer?
              </Label>
              {!create && (
                <div className="col-span-full mb-6">
                  <ReadOnlyAnswer
                    type={"chips"}
                    answer={
                      cohort && cohort.insurance_options
                        ? cohort.insurance_options
                        : "No insurance required"
                    }
                  />
                </div>
              )}

              {create && (
                <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
                  <ComboboxSelector
                    name="insurance_options"
                    options={[
                      { name: "Less than $100K" },
                      { name: "Between $100k and $1 million" },
                      { name: "Greater than $1 million" },
                    ]}
                    fieldType="single"
                    open={true}
                    handleSelect={(e) => {
                      handleSelectedLanguage(
                        e,
                        "credit_monitoring",
                        "insurance_options",

                        "group_" + groupIndex + 1,
                        "single"
                      );
                    }}
                    handleDeselect={handleDeselectedLanguage}
                    handleClearAllSelections={(value) =>
                      handleClearAllSelections(
                        value,
                        "credit_monitoring",
                        "insurance_options"
                      )
                    } //{handleClearAllSelections}
                    value={
                      opts2["group_" + groupIndex + 1] &&
                      opts2["group_" + groupIndex + 1].insurance_options
                        ? opts2["group_" + groupIndex + 1].insurance_options
                        : ""
                    }
                  />
                </div>
              )}
            </div>
          )}
        {/* SINGLE: CREDIT RESTORATION OPTIONS */}
        {/* {opts2["group_" + groupIndex + 1] &&
          opts2["group_" + groupIndex + 1].additional_services &&
          opts2["group_" + groupIndex + 1].additional_services.includes(
            "ID Restoration"
          ) && (
            <div className="sm:col-span-6 grid grid-cols-6">
              <Label
                sm
                bold
                htmlFor="call_center_location"
                className="my-auto col-span-4 block "
              >
                Which credit restoration options would you like to provide?
              </Label>

              <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
                <ComboboxSelector
                  name="credit_resoration_options"
                  options={[
                    { name: "Basic" },
                    { name: "White Glove" },
                    { name: "I don't care" },
                    //cheapest? most comprehensive?
                  ]}
                  fieldType="single"
                  open={true}
                  handleSelect={(e) => {
                    handleSelectedLanguage(
                      e,
                      "credit_monitoring",
                      "credit_restoration_options",

                      "group_" + groupIndex + 1,
                      "single"
                    );
                  }}
                  handleDeselect={handleDeselectedLanguage}
                  handleClearAllSelections={(value) =>
                    handleClearAllSelections(
                      value,
                      "credit_monitoring",
                      "credit_restoration_options"
                    )
                  } //{handleClearAllSelections}
                  value={
                    opts2["group_" + groupIndex + 1] &&
                    opts2["group_" + groupIndex + 1].credit_restoration_options
                      ? opts2["group_" + groupIndex + 1]
                          .credit_restoration_options
                      : ""
                  }
                />
              </div>
            </div>
          )} */}

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {create && (
            <p className="mt-2 text-base text-slate-500">
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </p>
          )}
          <div className="mt-1">
            {create ? (
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].additional_details
                    ? opts2["group_" + groupIndex + 1].additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "credit_monitoring",

                    "group_" + groupIndex + 1
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-base"
              />
            ) : (
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  opts2["group_" + groupIndex + 1] &&
                  opts2["group_" + groupIndex + 1].additional_details
                    ? opts2["group_" + groupIndex + 1].additional_details
                    : "No answer given"
                }
              ></ReadOnlyAnswer>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
