import React, { useEffect, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import UploadComponent from "./UploadComponent";

export default function AttachmentComponent({
  subtitle,
  description,
  fileList,
  updateFileList,
  type,
  fullQuote,
}) {
  const [attachments, setAttachments] = useState([]);
  console.log(fileList, "filelist", JSON.stringify(fileList));
  useEffect(() => {
    if (fileList?.length > 0) {
      setAttachments(fileList);
    } else {
      let attachments = sessionStorage.getItem("attachments");
      if (attachments) {
        setAttachments(JSON.parse(attachments));
      }
    }
  }, [updateFileList]);
  return (
    <div className="sm:col-span-6 ml-5 mr-5 mt-5 mb-5">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium text-slate-700"
      >
        {subtitle}
      </label>
      <p className=" text-sm text-slate-500">
        {fullQuote?.status?.toLowerCase() == "submitted" ? (
          <span className="text-red-500 font-mediume">
            This form has been disabled because your response has been
            submitted.
          </span>
        ) : (
          description
        )}
      </p>

      <div className="w-full divide-x mt-1 flex justify-between  rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
        <div className="space-y-1 col-span-2">
          <div className="flex justify-center text-sm text-gray-600">
            {fileList && fileList.length > 0 ? (
              <div>
                <div className="flex text-sm text-gray-600">Attachments</div>
                <div className="flex flex-col space-y-1">
                  {fileList.map((file) => (
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <PaperClipIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>{file.name}</p>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          disabled={
                            fullQuote?.status?.toLowerCase() == "submitted"
                              ? true
                              : false
                          }
                          onClick={
                            (e) => {
                              updateFileList(e, file, "remove");
                            }
                            // updateFileList(e, file, "remove")
                          }
                          className="text-red-500 hover:text-red-700"
                        >
                          <XMarkIcon
                            className="ml-3 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col space-y-1">No attachments</div>
            )}
          </div>
        </div>
        <div className="flex flex-col px-6 space-y-1 col-span-2 text-center justify end">
          <svg
            className="mx-auto h-12 w-12 text-slate-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex flex-col text-center text-sm text-slate-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-medium text-brand-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-500 focus-within:ring-offset-2 hover:text-brand-500"
            >
              <span>Upload a file</span>
              <UploadComponent
                disabled={
                  fullQuote?.status &&
                  fullQuote?.status?.toLowerCase() == "submitted"
                    ? true
                    : false
                }
                fileList={attachments}
                type={type}
                updateFileList={(e, done, action) =>
                  updateFileList(e, done, action)
                }
              />
              {/* <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
            /> */}
            </label>
            {/* <p className="pl-1">or drag and drop</p> */}
          </div>
          <p className="text-xs text-slate-500">PDF up to 10MB</p>
        </div>
      </div>
    </div>
  );
}
