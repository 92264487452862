import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { utils } from "../../../modules/_utils";
// import { Solicitation } from "../../../modules/_sols";
import { Paragraph } from "../../../components/Typography/Paragraph";
import RightActionArea from "../../../components/ActionAreaRight";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { HeadingTwo } from "../../../components/Typography/HeadingTwo";
import PDFViewerModal from "../../../components/PDFViewerModal";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
import DataMiningInfo from "./BettermentInfo";
import Assumptions from "./Assumptions";
// import constants from "../../../modules/constants";
import * as constants from "../../../constants/constants";
import ServiceDetails from "./ServiceDetails";
import Tabs from "../../component/Tabs";
export default function SolicitationSummary({
  solicitation,
  project,
  id,
  setSelectedService,
  selectedService,
  selectedTab,
  tabs,
  response,
  cleanUpKeys,
  handleSetPdfModalOpen,
}) {
  //   const [sol, setsol] = useState({});
  const tabOrder = constants.SOLICITATION_TAB_ORDER;
  const [sol, setSol] = useState({});
  const [serviceTabs, setServiceTabs] = useState([]);
  const [selectedServiceTab, setSelectedServiceTab] = useState({});

  useEffect(() => {
    if (
      solicitation &&
      solicitation.services &&
      solicitation.services.length > 0
    ) {
      setSol(solicitation);
    }
  }, [solicitation]);

  const orderedServiceTabs = useMemo(() => {
    if (
      !solicitation ||
      !solicitation.services ||
      !solicitation.services.length > 0
    ) {
      return [];
    }

    // Map services to tabs
    const tempServiceTabs = solicitation?.services?.map((service) => ({
      name: service.service,
      id: service.id,
      active: false,
    }));

    // Order tabs based on tabOrder
    const orderedTabs = [];
    utils.orderTabs(tempServiceTabs, tabOrder, orderedTabs);

    return orderedTabs;
  }, [solicitation.services, tabOrder]); // Dependencies

  // Set the ordered service tabs and the selected tab
  useEffect(() => {
    setServiceTabs(orderedServiceTabs);
    if (orderedServiceTabs.length > 0) {
      setSelectedServiceTab(orderedServiceTabs[0].name);
      // setSelectedService(orderedServiceTabs[0].id); // Uncomment if you need to set selected service ID
    }
  }, [orderedServiceTabs]); // Dependency on the memoized value

  return (
    <div id="solicitation-summary-items-container" className="h-full">
      {selectedTab == "Services" && (
        <div
          id="tabs-container"
          className="flex items-center mt-4 mb-4 pl-8 space-x-4"
        >
          <div className="flex font-medium ">Service Details</div>
          <div className="">
            <Tabs
              tabs={serviceTabs && serviceTabs.length > 0 ? serviceTabs : []}
              setSelectedTab={setSelectedServiceTab}
              selectedTab={selectedServiceTab}
              cleanUpKeys={cleanUpKeys}
            />
          </div>
        </div>
      )}

      {selectedTab === "Details" && (
        <div
          id="solictation-details"
          className="overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                scrollbar-thumb-rounded-full scrollbar-track-rounded-full
              
              "
        >
          <div className=" px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="grid grid-cols-6 gap-x-6 gap-y-1 col-span-full">
                <div className="col-span-full">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h2
                        id="applicant-information-title"
                        className="text-lg font-medium leading-6 text-slate-900"
                      >
                        {solicitation && solicitation.name}
                      </h2>
                      <p className="ml-2 capitalize flex-shrink-0 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                        {solicitation && solicitation.status}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-span-4">
                  <div className=" grid col-span-full flex items-center  justify-bewtween">
                    <div className="flex items-center justify-between">
                      <div className="flex col-span-1 ">
                        <p className="text-sm font-medium text-slate-500">
                          <a href="#" className="text-slate-900">
                            Created on
                          </a>{" "}
                          <time dateTime="2020-08-25">
                            {utils.formatDate(solicitation.createdAt, "long")}
                          </time>
                        </p>
                      </div>{" "}
                      <div className="flex col-span-1 ">
                        <p className="text-sm font-medium text-slate-500">
                          <a href="#" className="text-slate-900">
                            Created by
                          </a>{" "}
                          <time dateTime="2020-08-25">
                            {solicitation &&
                              solicitation.createdBy &&
                              solicitation.createdBy.name}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-full">
                <h3
                  id="notes-title"
                  className="border-b border-gray-200 pb-2 text-md uppercase font-bold text-brand-600"
                >
                  Basic Details
                </h3>

                <div className=" justify-end "></div>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-slate-500">
                  Request ID
                </dt>
                <dd className="mt-1 text-sm text-slate-900">
                  {solicitation && solicitation.GlobalId}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-slate-500">
                  Bid Request Increment
                </dt>
                <dd className="mt-1 text-sm text-slate-900">
                  {solicitation && solicitation.incrementId}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-slate-500">Client</dt>
                <dd className="mt-1 text-sm text-slate-900">
                  {project.client && project.client.name}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-slate-500">
                  Client Contact
                </dt>
                <dd className="mt-1 text-sm text-slate-900">
                  {project.client && project.client.email}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-slate-500">Details</dt>

                {solicitation && solicitation.rtf_description ? (
                  <WYSIWYGViewer
                    editorState={
                      solicitation.rtf_description
                        ? solicitation.rtf_description[0]
                          ? solicitation.rtf_description[0]
                          : solicitation.rtf_description
                        : {}
                    }
                  />
                ) : (
                  solicitation &&
                  solicitation.description && (
                    <dd className="mt-1 text-sm text-slate-900">
                      {solicitation.description}{" "}
                    </dd>
                  )
                )}
              </div>
              <div className="sm:col-span-full">
                {sol && sol.attachments && sol.attachments.length > 0 && (
                  <div className="sm:col-span-2">
                    <div className="col-span-full">
                      <h3
                        id="notes-title"
                        className="border-b border-gray-200 pb-2 text-md uppercase font-bold text-brand-600"
                      >
                        Attachments
                      </h3>
                      <div className=" justify-end "></div>
                    </div>
                    <dd className="mt-1 text-sm text-slate-900">
                      <ul
                        role="list"
                        className="divide-y divide-gray-200 rounded-md border border-gray-200"
                      >
                        {sol &&
                          sol.attachments &&
                          sol.attachments.map((attachment) => (
                            <PDFViewerModal
                              attachment={attachment}
                              solicitation={solicitation}
                              fileType={"solicitation"}
                            />
                          ))}
                      </ul>
                    </dd>
                  </div>
                )}
              </div>
            </dl>
          </div>
        </div>
      )}

      {selectedTab === "Services" && (
        <div id="solicitationDetails-container" className="px-4 h-full ">
          {sol &&
            sol.services &&
            sol.services.map((service) => {
              console.log("SolicSummaryDetails", service.service);
              return (
                <ServiceDetails
                  solicitation={sol}
                  id={service.service}
                  service={service.service}
                  selectedTab={selectedServiceTab}
                />
              );
              // <ServiceDetails
              //   id={service}
              //   solicitation={sol}
              //   selectedTab={selectedServiceTab}
              //   service={service}
              // />;
            })}
          {/* // else if (service.service == "credit_monitoring"){" "} */}
          {
            //   return (
            //     <div className="py-4 border-b border-gray-200">
            //       <div className="space-y-4 mb-6">
            //         <div className="flex items-center justify-between col-span-full">
            //           <div className="inline-flex ">
            //             <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
            //               {cleanUpKeys(service.service)}
            //             </h2>
            //           </div>
            //           <RightActionArea>
            //             {/* <Button size="small" rounded color="accent">
            //               Decline {cleanUpKeys(service.service)}
            //             </Button> */}
            //           </RightActionArea>
            //         </div>
            //         <div className="col-span-full mt-0">
            //           <Paragraph>
            //             Below you will see all the details of your bid
            //             request.
            //           </Paragraph>
            //         </div>
            //       </div>
            //       <div className="col-span-full grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
            //         <div className="col-span-full"></div>
            //         {/* Map groups */}
            //         {service.opts2 &&
            //           Object.entries(service.opts2).map(
            //             ([group, entries]) => {
            //               return (
            //                 <div className="col-span-full grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
            //                   <div className="sm:col-span-full">
            //                     <dt className="text-sm font-medium text-slate-500">
            //                       {cleanUpKeys(group)}
            //                     </dt>
            //                     <dd className="mt-1 text-sm text-slate-900">
            //                       {service.opts2.required_language &&
            //                         service.opts2.required_language.length >
            //                           0 &&
            //                         service.opts2.required_language.map(
            //                           (j) => (
            //                             <span
            //                               key={j}
            //                               className="mr-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
            //                             >
            //                               {j}
            //                             </span>
            //                           )
            //                         )}
            //                     </dd>
            //                   </div>
            //                   {/* <div className="sm:col-span-full">
            //                       <dt className="text-sm font-medium text-slate-500">
            //                         Jurisdictions
            //                       </dt>
            //                       <dd className="mt-1 text-sm text-slate-900">
            //                         {service.options[0].jurisdictions &&
            //                           service.options[0].jurisdictions
            //                             .length > 0 &&
            //                           service.options[0].jurisdictions.map(
            //                             (j) => (
            //                               <span
            //                                 key={j.key}
            //                                 className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
            //                               >
            //                                 {j.key + ": " + j.value}
            //                               </span>
            //                             )
            //                           )}
            //                       </dd>
            //                     </div> */}
            //                   {/* map the entries of service.opts object */}
            //                   {Object.entries(entries).map(([key, value]) => {
            //                     console.log(key, value);
            //                     return (
            //                       <div
            //                         id={key}
            //                         // className="sm:col-span-1"
            //                         className={`${
            //                           typeof value === "object"
            //                             ? "sm:col-span-2"
            //                             : "sm:col-span-1"
            //                         }`}
            //                       >
            //                         <dt className="text-sm font-medium text-slate-500">
            //                           {key !== "required_language" &&
            //                             cleanUpKeys(key)}
            //                         </dt>
            //                         <dd className="mt-1 text-sm text-slate-900">
            //                           {Array.isArray(value) &&
            //                             key !== "required_language" &&
            //                             value.map((val) => (
            //                               <span
            //                                 key={val}
            //                                 className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
            //                               >
            //                                 {val}
            //                               </span>
            //                             ))}
            //                           {typeof value === "object"
            //                             ? Object.entries(value).map(
            //                                 ([key, value]) =>
            //                                   value &&
            //                                   value === true && (
            //                                     <span
            //                                       key={key}
            //                                       className="inline-flex items-center mr-1.5 my-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-200 text-brand-700"
            //                                     >
            //                                       {cleanUpKeys(key)}
            //                                       {value}
            //                                     </span>
            //                                   )
            //                               )
            //                             : typeof value === "boolean"
            //                             ? value === true
            //                               ? "Yes"
            //                               : value === false
            //                               ? "No"
            //                               : value
            //                             : typeof value == "string"
            //                             ? value
            //                             : null}
            //                         </dd>
            //                       </div>
            //                     );
            //                   })}
            //                 </div>
            //               );
            //             }
            //           )}
            //       </div>
            //     </div>
            //   );
            // } else {
            //   return (
            //     <div className="py-4 border-b border-gray-200">
            //       <div className="space-y-4 mb-6">
            //         <div className="flex items-center justify-between col-span-full">
            //           <div className="inline-flex ">
            //             <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
            //               {cleanUpKeys(service.service)}
            //             </h2>
            //           </div>
            //           <RightActionArea>
            //             {/* <Button size="small" rounded color="accent">
            //                 Decline {cleanUpKeys(service.service)}
            //               </Button> */}
            //           </RightActionArea>
            //         </div>
            //         <div className="col-span-full mt-0">
            //           <Paragraph>
            //             Below you will see all the details of your bid
            //             request.
            //           </Paragraph>
            //         </div>
            //       </div>
            //       <div className="col-span-full grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
            //         <div className="sm:col-span-full">
            //           <dt className="text-sm font-medium text-slate-500">
            //             Required Languages
            //           </dt>
            //           <dd className="mt-1 text-sm text-slate-900">
            //             {service.opts2 &&
            //               service.opts2.required_language &&
            //               service.opts2.required_language.length > 0 &&
            //               service.opts2.required_language.map((j) => (
            //                 <span
            //                   key={j}
            //                   className="mr-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
            //                 >
            //                   {j}
            //                 </span>
            //               ))}
            //           </dd>
            //         </div>
            //         <div className="sm:col-span-full">
            //           <dt className="text-sm font-medium text-slate-500">
            //             Jurisdictions
            //           </dt>
            //           <dd className="mt-1 text-sm text-slate-900">
            //             {service.options &&
            //               service.options.length > 0 &&
            //               service.options[0].jurisdictions &&
            //               service.options[0].jurisdictions.length > 0 &&
            //               service.options[0].jurisdictions.map((j) => (
            //                 <span
            //                   key={j.key}
            //                   className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
            //                 >
            //                   {j.key + ": " + j.value}
            //                 </span>
            //               ))}
            //           </dd>
            //         </div>
            //         {/* map the entries of service.opts object */}
            //         {service.opts2 &&
            //           Object.entries(service.opts2).map(([key, value]) => {
            //             console.log(key, value);
            //             return (
            //               <div
            //                 id={key}
            //                 // className="sm:col-span-1"
            //                 className={`${
            //                   typeof value === "object"
            //                     ? "sm:col-span-2"
            //                     : "sm:col-span-1"
            //                 }`}
            //               >
            //                 <dt className="text-sm font-medium text-slate-500">
            //                   {key !== "required_language" &&
            //                     cleanUpKeys(key)}
            //                 </dt>
            //                 <dd className="mt-1 text-sm text-slate-900">
            //                   {Array.isArray(value) &&
            //                     key !== "required_language" &&
            //                     value.map((val) => (
            //                       <span
            //                         key={val}
            //                         className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
            //                       >
            //                         {val}
            //                       </span>
            //                     ))}
            //                   {typeof value === "object"
            //                     ? Object.entries(value).map(
            //                         ([key, value]) =>
            //                           value &&
            //                           value === true && (
            //                             <span
            //                               key={key}
            //                               className="inline-flex items-center mr-1.5 my-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-200 text-brand-700"
            //                             >
            //                               {cleanUpKeys(key)}
            //                               {value}
            //                             </span>
            //                           )
            //                       )
            //                     : typeof value === "boolean"
            //                     ? value === true
            //                       ? "Yes"
            //                       : value === false
            //                       ? "No"
            //                       : value
            //                     : typeof value == "string"
            //                     ? value
            //                     : null}
            //                 </dd>
            //               </div>
            //             );
            //           })}
            //         {/* <solList
            //         onItemClick={null}
            //         project={sol}
            //         items={
            //           sol.responses ? sol.responses : []
            //         }
            //       /> */}
            //       </div>
            //     </div>
            //   );
            // }
          }
          {/* )}{" "} */}
        </div>
      )}
    </div>
  );
}
