import { useState, useEffect } from "react";
import {
  CalendarIcon,
  MapPinIcon,
  MusicalNoteIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { utils } from "../../modules/_utils";
import Badge from "../../components/Badge";

export default function SolicitationList(props) {
  const { project } = props;
  const [items, setItems] = useState(props.items);

  const handleItemClick = (e, item) => {
    if (project.status !== "Open") {
      return null;
    } else {
      e.preventDefault();
      props.onItemClick(project, item);
    }
  };
  useEffect(() => {
    setItems(props.items);
    console.log("Solicitation List", props.items);
  }, [props.items]);

  return (
    <div className="w-full overflow-x-hidden max-h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full bg-white ">
      <ul role="list" className="divide-y divide-gray-200  ">
        {items.map((item) => (
          <li
            key={item.id}
            className="hover:bg-gray-50 hover:no-underline cursor-pointer"
            onClick={(e) => handleItemClick(e, item)}
          >
            <div className="  justify-between pr-2 py-4 space-y-1 ">
              <div className=" flex items-center justify-between">
                <div className="flex items-center">
                  <p className=" truncate text-sm font-medium text-brand-600">
                    P-{project && project.GlobalId}{" "}
                    {item && item.incrementId && "BR-" + item.incrementId + " "}
                    {item.name}
                  </p>
                </div>

                {/* <div className="flex items-center mt-2  sm:justify-between"> */}
                <div className="flex items-center text-xs text-gray-500 sm:mt-0 gap-x-1">
                  {item.workflow_status.some(
                    (statusItem) => statusItem.status === "current"
                  ) && (
                    <Badge
                      color="secondary"
                      case="uppercase"
                      shape="square"
                      className="ml-2 "
                    >
                      {(item.workflow_status &&
                        item.workflow_status.length > 0 &&
                        item.workflow_status.find(
                          (item) => item.status === "current"
                        )?.name) ||
                        "No Status"}
                    </Badge>
                  )}
                  <Badge
                    color="primary"
                    case="uppercase"
                    shape="square"
                    className="ml-2 "
                  >
                    {item.status}
                  </Badge>
                </div>

                {/* </div> */}
              </div>
              <div className=" flex items-center text-xs justify-between">
                <div className="flex items-center">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="flex items-center">
                    <span>
                      {/* {(!item.deadline ||
                        item.deadline === null ||
                        (item.deadline === undefined &&
                          item.deadline === "")) &&
                        "No Deadline Specified"} */}
                      {item.deadline &&
                      new Date().setHours(0, 0, 0, 0) >
                        new Date(item.deadline) ? (
                        <span className="text-red-500">{`Expired ${utils.formatDate(
                          item.deadline,
                          "short"
                        )}`}</span>
                      ) : item.deadline ? (
                        `Expires ${utils.formatDate(item.deadline, "short")}`
                      ) : (
                        "No Deadline Specified"
                      )}
                    </span>
                  </p>
                </div>
                {/* total responses */}
                <div className="flex items-center">
                  <DocumentCheckIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="flex items-center">
                    {item.responses && item.responses.length}{" "}
                    {(item.responses && item.responses.length > 1) ||
                    (item.responses && item.responses.length == 0)
                      ? "responses"
                      : "response"}
                  </p>
                </div>
              </div>
              <div className="flex items-center pt-2">
                {item &&
                  item.services &&
                  item.services.map((service) => {
                    return (
                      <div className="ml-2 flex flex-shrink-0">
                        {/* <p className="inline-flex capitalize rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"> */}
                        <Badge shape="pill" color="gray" case="capitalize">
                          {utils.cleanUpKeys(service.service)}
                        </Badge>
                      </div>
                    );
                  })}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    // </div>
  );
}
