const OrderSummary = ({
  products,
  selectedProductId,
  selectedPriceId,
  selectedAddons,
  getProductByPriceId,
  getProductPrice,
}) => {
  // Find the selected product and price
  const selectedProduct = products.find(
    (product) => product.id === selectedProductId
  );
  console.log("Selected Product: ", selectedProduct);
  const selectedPrice = selectedProduct
    ? selectedProduct.prices.find((price) => price.id === selectedPriceId)
    : null;
  console.log("Selected Price: ", selectedPrice);
  // If no product or price was found, return null
  if (!selectedProduct || !selectedPrice) return null;

  const addons = selectedAddons.map((addonId) => {
    let foundPrice = null;
    let addonName = null;

    for (let addon of selectedProduct.addons) {
      const price = addon.prices.find((price) => price.id === addonId);

      if (price) {
        foundPrice = price;
        addonName = addon.name;
        break;
      }
    }

    if (foundPrice) {
      return { ...foundPrice, name: addonName };
    } else {
      console.log("Price not found for addon id: ", addonId);
      return null;
    }
  });
  // Filter out null values from the addons array
  const validAddons = addons.filter((addon) => addon !== null);

  // Calculate the total cost
  let totalCost = parseFloat(selectedPrice.cost.replace(/,/g, ""));

  if (validAddons && validAddons.length > 0) {
    let addonTotal = validAddons.reduce(
      (total, addon) => total + parseFloat(addon.cost.replace(/,/g, "")),
      0
    );
    addonTotal = parseFloat(addonTotal);
    totalCost += addonTotal;
  }

  // Format the total cost as a string with a dollar sign and commas
  let formattedTotalCost = totalCost;
  if (!isNaN(totalCost)) {
    formattedTotalCost =
      "$" + totalCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="flex flex-col space-y-4 ">
      <div className="flex justify-between">
        <span>{selectedProduct.name}</span>
        <span>
          {selectedPrice.cost}/{selectedPrice.recurrence}
        </span>
      </div>

      {/* Display the selected addons */}
      {validAddons &&
        validAddons.map((addon) => (
          <div key={addon.id} className="flex justify-between">
            <span>{addon.name}</span>
            <span>
              {addon.cost}/{addon.recurrence}
            </span>
          </div>
        ))}

      {/* Display the total cost */}
      <div className="flex justify-between font-medium">
        <span>Total</span>
        <span>
          {formattedTotalCost}/{selectedPrice.recurrence}
        </span>
      </div>
    </div>
  );
};

// Path: partials/settings/OrderSummary.js
export default OrderSummary;
