import { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Transition } from "@headlessui/react";
import Button from "../elements/Button";
import { Icon } from "@iconify/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ExpandButton(props) {
  let iconName = props.expanded
    ? "tabler:arrows-diagonal-minimize-2"
    : "tabler:arrows-diagonal-2";

  let setExpandedValue = props.expanded ? false : true;
  return (
    <Icon
      icon={iconName}
      className="flex text-slate-400 h-6 w-6 hover:text-slate-500 hover:cursor-pointer"
      onClick={() => {
        props.onClick(setExpandedValue);
      }}
    />
  );
}

export default function Drawer(props) {
  const [show, setShow] = useState(props.show);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    console.log("show changed", props.show);
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    console.log("expanded changed", expanded);
  }, [expanded]);

  const drawerContent = (
    <Fragment>
      <Transition
        className="top-0 bottom-0 left-0 right-0 fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={show && show === true ? true : false}
        onClick={(e) => {
          setShow(false);
          props.setShow(false);
        }}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        aria-hidden="true"
      />
      <aside
        className={`${
          props.width
            ? "w-" + props.width
            : `${
                expanded
                  ? "w-[100vw] md:w-[90vw] lg:w-[90vw] xl:w-[90vw]"
                  : "w-[100vw] md:w-[90vw] lg:w-[70vw] xl:w-[40vw]"
              }`
        } pt-0 pb-2 z-50 transition-all duration-500 ease-in-out transform ${
          show && show === true ? "translate-x-0" : "translate-x-full"
        } bg-white fixed bottom-0 right-0 top-0 overflow-x-hidden overflow-y-hidden border-l border-gray-200 xl:block scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
      >
        <div className="sticky px-5 py-3 border-b border-gray-200">
          <div className="relative flex justify-between items-center">
            <div className="relative text-lg leading-6 font-medium text-slate-900">
              {props.title}
            </div>
            <div className="flex items-center space-x-5">
              {props.expandable && (
                <ExpandButton expanded={expanded} onClick={setExpanded} />
              )}
              <button
                className="text-slate-400 hover:text-slate-500"
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  setShow(false);
                  props.setShow(false);
                }}
              >
                <div className="sr-only">Close</div>
                <svg className="w-4 h-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            props.scrollable
              ? props.showFooter === true
                ? "h-[calc(100vh-150px)] pb-12 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
                : "h-[calc(100vh-120px)] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
              : "h-full"
          }`}
        >
          {props.children}
        </div>
        {props.showFooter && (
          <div className="absolute w-full bottom-0 px-5 py-4 bg-white border-t border-gray-200">
            {props.footerContent ? (
              props.footerContent
            ) : (
              <div className="flex flex-wrap justify-end space-x-2">
                <Button
                  color="primary"
                  handleClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.setShow(false);
                  }}
                >
                  {props.closeButtonText || "Close"}
                </Button>
                {props.saveButton && (
                  <Button
                    color="primary"
                    handleClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.saveButtonClick();
                    }}
                  >
                    {props.saveButtonText || "Save"}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </aside>
    </Fragment>
  );

  return ReactDOM.createPortal(drawerContent, document.body);
}
