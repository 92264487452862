import { Fragment, useEffect, useState } from "react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { pricing_explanations } from "../../../modules/pricing_explanations";
import Badge from "../../../components/Badge";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
import PDFViewerModal from "../../../components/PDFViewerModal";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Solicitations } from "../../../modules/_solicitations";
import DropdownMenu from "../../../components/DropdownMenu";
import { Icon } from "@iconify/react";
import { VENDOR_NOTIFIED_ICON } from "../../../constants/icon_constants";
import Tooltip from "../../../components/Tooltip";

export default function BidCompareTable({
  responses,
  solicitation,
  handleSelectWinnerClick,
  handleNotifyVendorClick,
  handleSendBackForRevisionClick,
  handleViewPDF,
}) {
  const [allResponses, setAllResponses] = useState([]);
  // const [solicitation, setSolicitation] = useState(props.solicitation);
  const [allLineItems, setAllLineItems] = useState([]);
  const [allPricingItems, setAllPricingItems] = useState([]);
  const [colWidth, setColWidth] = useState("w-1/3");
  const [displayedPricingItems, setDisplayedPricingItems] = useState([]);
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [winnerSelected, setWinnerSelected] = useState(false);
  const [winnerNotified, setWinnerNotified] = useState(false);
  useEffect(() => {
    console.log("Props.responses changed allResponses", responses);
    setAllResponses(responses);
    if (responses && responses.length > 0) {
      setColWidth(`w-1/4`);
    }
  }, [responses]);

  useEffect(() => {
    let allLineItems = [];
    let allPrices = [];
    let allServicePrices = [];
    // if (responses && responses.length > 0) {
    responses &&
      responses.length > 0 &&
      responses.map((response) => {
        console.log("BidCompareTable response", response);
        response?.services &&
          Object.entries(response.services)?.map(([k, v]) => {
            if (allLineItems && !allLineItems.includes(k)) {
              allLineItems.push(k);
            }
            console.log("BidCompareTable Services", k, v);
            v.pricing &&
              Object.entries(v.pricing) &&
              Object.entries(v.pricing).map(([key, v]) => {
                if (allPrices && !allPrices.includes(key)) {
                  allPrices.push(key);
                }
                //NOT WORKING YET
                if (allServicePrices && !allServicePrices[k]) {
                  //if allServicePrices is empty, add allServicePrices[v] first
                  allServicePrices[k] = [];
                }
                allServicePrices[k].push(key);
              });
          });
      });

    // }
    console.log("AllServicePrices", allServicePrices);
    setAllPricingItems(allServicePrices);
    setAllLineItems(allLineItems);
    console.log("allLineItems", allLineItems);
  }, [allResponses]);

  useEffect(() => {
    console.log("Checking if a winner has been selected", solicitation);
    let selected = "";
    let notified = "";
    if (solicitation && solicitation.workflow_status) {
      selected = Solicitations.findWorkflowStatus(
        "Winner Selected",
        solicitation.workflow_status
      )?.status;
      console.log("Checking if a winner has been selected", selected);
    }
    if (selected == "current" || selected == "complete") {
      setWinnerSelected(true);
    }
    if (notified == "current" || notified == "complete") {
      setWinnerNotified(true);
    }
  }, [responses, solicitation]);

  useEffect(() => {
    console.log(solicitation, "workflow");
    if (solicitation && solicitation.workflow_status) {
      setWorkflowStatus(
        Solicitations.getCurrentWorkflow(solicitation.workflow_status)?.name
      );
    }
  }, [solicitation?.workflow_status]);

  const generateRows = () => {
    // each row is a service
    // each column is a response
    // each cell is a pricing item (e.g. Base, Processing, Excel Review, PDF Review, Project Management)
    // each cell has a price, a unit, and a tier (if applicable)
    // each cell has a type (Standard or Volume)
    // each cell has a price_type (unit_pricing or flat)
    // each cell has a currency (USD)
    const rows = [];
    return <th scope="col">Service Name</th>;
  };

  return (
    // <table class="table-auto overflow-scroll col-span-full  	 text-sm text-left text-gray-500 ">
    <div
      id="bid-compare-table-scrollable-div"
      className="h-full col-span-full  overflow-x-auto overflow-y-auto scrollbar-default 
      border border-gray-200 "
    >
      <table
        id="response_table"
        className="table-auto h-full overflow-x-scroll w-full text-sm text-left text-gray-500 "
      >
        {/* <div className="w-full overflow-x-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"> */}
        <thead class="sticky  z-10 top-0 w-full text-xs text-gray-700  bg-gray-50  ">
          <tr className="align-top">
            {/* the above tr: flex messes up the rest of the layout but in the way 
            I was hoping for */}
            <th
              scope="col"
              class={`sticky left-0 h-fit  px-4 py-3  bg-gray-50  border-r border-gray-200`}
              style={{
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px",
              }}
            ></th>
            {allResponses &&
              allResponses.length > 0 &&
              allResponses.map((response, idx) => {
                return (
                  <th
                    scope="col"
                    style={{
                      width: "300px",
                      maxWidth: "300px",
                      minWidth: "300px",

                      // Don't forget this, required for the stickiness
                    }}
                    class={`border-r max-w-96   whitespace-nowrap text-base pl-2 pr-6 pb-2 `}
                  >
                    <div className="pt-2 flex items-center justify-between space-y-0">
                      <div>
                        {" "}
                        {response.vendorCompanyId
                          ? response.vendorCompanyId.companyName
                          : response.submittedByCompany}
                        {/* {response.requestorDecision &&
                        response.requestorDecision == "winner" ? (
                          <div className="mr-2 mx-auto items-center">
                            <CheckIcon className="inline-flex mr-1.5 h-6 w-6 text-green-500" />{" "}
                            <div className="inline-flex">Winning bid</div>
                          </div>
                        ) : [
                            "Archived",
                            "Closed",
                            "Winner Selected",
                            "Winner Notified",
                          ].includes(workflowStatus) ? (
                          <div className="px-auto mx-auto items-center cursor-not-allowed">
                            <XMarkIcon className="inline-flex mr-1.5 h-6 w-6 text-red-500" />{" "}
                            <div className="inline-flex">Not selected</div>
                          </div>
                        ) : null} */}
                      </div>
                      <div className="flex items-center space-x-4 ">
                        <div>
                          {response.requestorDecision &&
                          response.requestorDecision == "winner" ? (
                            <div className=" mx-auto items-center">
                              <Tooltip
                                // className="cursor-default"
                                // title="Winner"
                                description="This is the winning bid."
                              >
                                <Icon
                                  icon="mdi:trophy"
                                  className="cursor-default text-emerald-600"
                                />
                              </Tooltip>
                            </div>
                          ) : [
                              "Archived",
                              "Closed",
                              "Winner Selected",
                              "Winner Notified",
                            ].includes(workflowStatus) ? (
                            <Tooltip
                              // title="Winner"
                              description="This bid did not win."
                            >
                              <div className="px-auto mx-auto items-center">
                                <XMarkIcon className="cursor-default inline-flex mr-1.5 h-6 w-6 text-red-500" />{" "}
                                <div className="inline-flex"></div>
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                        {response.vendorNotified &&
                          response.vendorNotified == true && (
                            <div>
                              <div className="items-center">
                                <Tooltip description="The winning bidder has been notified.">
                                  {" "}
                                  <Icon
                                    icon="mdi:envelope"
                                    className="cursor-default text-emerald-600"
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        {response.isReturnedToVendor &&
                          response.isReturnedToVendor == true && (
                            <div>
                              <div className="items-center">
                                <Tooltip description="Pending vendor revision.">
                                  {" "}
                                  <Icon
                                    icon="fluent:archive-arrow-back-24-filled"
                                    className="cursor-default text-red-600"
                                  />
                                  {/* <Icon
                                    icon="mdi:envelope"
                                    className="cursor-default text-emerald-600"
                                  /> */}
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        <div className="font-normal">
                          <DropdownMenu
                            id="menu"
                            showChevron={false}
                            buttonType="icon" //could be text
                            buttonText="Actions"
                            buttonColor="transparent"
                            menuItems={[
                              {
                                name: "Ask a question",
                                icon: "ri:questionnaire-line",

                                action: (e) => {
                                  console.log("Ask a question");
                                },
                                disabled: false,
                                status: "Beta",
                                show: false,
                                group: "",
                              },
                              {
                                name: "Ask for revision",
                                // icon: "mdi:library-remove-outline",
                                icon: "bi:send-slash-fill",

                                action: (e) => {
                                  console.log("Send back for revision");
                                  handleSendBackForRevisionClick(response);
                                },
                                disabled: false,
                                status: "Beta",
                                show:
                                  response.requestorAction &&
                                  response.requestorAction === "pending" &&
                                  response.requestorDecision &&
                                  response.requestorDecision === "pending",
                                // show:
                                //   !response.removedFromConsideration &&
                                //   response.requestorAction !==
                                //     "winner selected",

                                group: "",
                              },

                              {
                                name: !response.isShortlisted
                                  ? "Add to shortlist"
                                  : "Remove from shortlist",
                                icon: !response.isShortlisted
                                  ? "icon-park-outline:list-add"
                                  : "fluent:channel-arrow-left-20-regular",

                                action: (e) => {
                                  console.log("Shortlist button clicked");
                                },
                                show: false,
                                // show:
                                //   response.requestorAction !==
                                //   "winner selected",
                                group: "",
                                status: "Beta",
                              },
                              {
                                name: "Select Winner",
                                icon: "mdi:trophy",
                                action: (e) => {
                                  !response.requestorDecision !== "winner" &&
                                    handleSelectWinnerClick(e, response);
                                },
                                show:
                                  response.requestorAction &&
                                  response.requestorAction === "pending" &&
                                  response.requestorDecision &&
                                  response.requestorDecision === "pending",
                                group: "Select",
                              },
                              {
                                name: "Undo Select Winner",
                                icon: "mdi:undo-variant",

                                action: (e) => {
                                  !response.requestorDecision !== "winner" &&
                                    handleSelectWinnerClick(e, response);
                                },
                                show: false,
                                // show:
                                //   response.isWinner && !response.vendorNotified,
                                group: "Select",
                              },
                              {
                                name: "Notify Winner",
                                icon: "mdi:envelope",
                                action: (e) => {
                                  console.log("Notify Winner");
                                  handleNotifyVendorClick(e, response);
                                },
                                show:
                                  response.isWinner && !response.vendorNotified,
                                group: "Select",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="block mt-0 text-sm font-normal text-gray-600">
                        ID:{" "}
                        {response.GlobalId ? response.GlobalId : response._id}
                      </div>
                      <div className="mt-0 text-sm font-normal text-gray-600">
                        Submitted on{" "}
                        {utils.formatDate(response.submittedOn, "short")}
                      </div>
                      {/* APP-364 */}
                      {/* <p className=" text-sm leading-6 text-gray-600">
                        by{" "}
                        <span className="font-semibold">
                          {response.submittedByEmail}
                        </span>
                      </p> */}

                      <div className="font-normal">
                        <div>
                          {response.attachments &&
                          response.attachments.length > 0 ? (
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 rounded-md border border-gray-200"
                            >
                              {response.attachments &&
                                response.attachments.map((attachment) => (
                                  <li
                                    key={attachment.name}
                                    onClick={(e) => {
                                      handleViewPDF(e, attachment);
                                      // console.log("Downloading attachment", attachment);
                                      // handleSetPdfModalOpen(attachment, "response");
                                    }}
                                    className="bg-white flex cursor-pointer items-center justify-between py-3 pl-3 pr-4 text-sm"
                                  >
                                    <div className="flex w-0 flex-1 items-center">
                                      <PaperClipIcon
                                        className="h-5 w-5 flex-shrink-0 text-slate-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-2 w-0 flex-1 truncate">
                                        {attachment.name}
                                      </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                      <a
                                        // href={attachment.href}
                                        onClick={(e) => {
                                          handleViewPDF(e, attachment);
                                          // handleSetPdfModalOpen(attachment, "response");
                                        }}
                                        className="font-medium text-brand-600 hover:text-brand-500"
                                      >
                                        View
                                      </a>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="w-full">
          {allLineItems &&
            allLineItems.length > 0 &&
            allLineItems.map((lineItem) => {
              let tmp = [];
              return (
                <Fragment>
                  <tr
                    colSpan={allResponses?.length + 1}
                    className="  bg-brand-100 relative  py-2 uppercase text-sm leading-6 text-gray-900"
                  >
                    <th
                      // scope="col"
                      colSpan={1}
                      // class={`sticky left-0 h-fit  px-6 py-3`}
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        minWidth: "300px",
                      }}
                      class={`w-full col-span-full sticky left-0 h-fit px-6 py-3 border-r border-gray-200`}
                    >
                      {utils.cleanUpKeys(lineItem)}
                    </th>
                    {/* for 0 to allResponses.length, return <th */}
                    {allResponses &&
                      allResponses.length > 0 &&
                      allResponses.map((response) => {
                        return (
                          <th
                            colSpan={1}
                            style={{
                              width: "300px",
                              maxWidth: "300px",
                              minWidth: "300px",
                            }}
                            class={`border-r w-full col-span-full sticky left-0 h-fit px-6 py-3 `}
                          >
                            <div className="flex items-center">
                              {response.services &&
                              response.services[lineItem] &&
                              response.services[lineItem]?.price
                                ? "Estimated:" +
                                  parseFloat(
                                    response.services[lineItem]?.price || 0
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                : "No estimated price provided"}{" "}
                              {response.services &&
                                response.services[lineItem] &&
                                response.services[lineItem]?.price && (
                                  <QuestionToolTip
                                    // position="bottom"
                                    field="Estimated price"
                                    text="This is the estimated price based on the information you provided in your bid request and is subject to change based on the final scope of work."
                                  />
                                )}
                            </div>
                          </th>
                        );
                      })}
                  </tr>

                  {allPricingItems &&
                    allPricingItems[lineItem] &&
                    allPricingItems[lineItem].length > 0 &&
                    allPricingItems[lineItem].map((key, idx) => {
                      if (tmp.includes(key)) {
                        return;
                      } else {
                        tmp.push(key);
                      }
                      return (
                        <>
                          <tr class="py-6 bg-white border-b  dark:border-gray-700">
                            <th
                              scope="col"
                              class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200`}
                              style={{
                                width: "300px",
                                maxWidth: "300px",
                                minWidth: "300px",
                              }}
                            >
                              {key}
                            </th>

                            {responses &&
                              responses.length > 0 &&
                              responses.map((response) => {
                                if (!response.services) {
                                  return (
                                    <td className="text-gray-600 font-normal align-top "></td>
                                  );
                                }
                                let line_Item = response.services[lineItem];
                                let pricingItem =
                                  response?.services[lineItem]?.key;
                                let recurrence = pricingItem?.recurrence;
                                let unit = pricingItem?.unit;
                                let billing_interval =
                                  pricingItem?.billing_interval;
                                let type = pricingItem?.type;
                                return (
                                  <td className="border-r text-gray-600 font-normal align-top ">
                                    <li
                                      key={key}
                                      className=" font-medium flex gap-x-3 py-2 px-2"
                                    >
                                      <div className="space-y-2">
                                        {response.services[lineItem] &&
                                        response.services[lineItem].pricing &&
                                        response.services[lineItem].pricing[
                                          key
                                        ] &&
                                        response.services[lineItem].pricing &&
                                        response.services[lineItem].pricing[key]
                                          .type === "Standard" ? (
                                          <>
                                            <div className="flex items-center white-space-nowrap space-x-2">
                                              Standard Pricing{" "}
                                              <QuestionToolTip
                                                field="Standard pricing"
                                                text={pricing_explanations.StandardPricing(
                                                  response.services[lineItem]
                                                    .pricing?.[key]
                                                )}
                                              />
                                              {response.services[lineItem]
                                                .pricing?.[key]?.recurrence ==
                                              "Recurring" ? (
                                                <Badge
                                                  color="secondary"
                                                  className=" "
                                                >
                                                  {" "}
                                                  RECURRING{" "}
                                                </Badge>
                                              ) : (
                                                <Badge
                                                  color="secondary"
                                                  className=" "
                                                >
                                                  {" "}
                                                  ONE-TIME FEE{" "}
                                                </Badge>
                                              )}
                                            </div>
                                            <div className="text-gray-600 font-normal ">
                                              <span className="lowercase">
                                                {" $"}
                                                {response.services[lineItem]
                                                  .pricing &&
                                                  response.services[lineItem]
                                                    .pricing[key] &&
                                                  response.services[lineItem]
                                                    .pricing[key].price}{" "}
                                                {response.services[lineItem]
                                                  ?.pricing[key]?.unit !==
                                                  "Fixed Price" && " per "}
                                                {response.services[lineItem]
                                                  .pricing &&
                                                  response.services[lineItem]
                                                    .pricing[key] &&
                                                  response.services[lineItem]
                                                    .pricing[key].unit !==
                                                    undefined &&
                                                  response.services[lineItem]
                                                    .pricing[key].unit}
                                                {response.services[lineItem]
                                                  .pricing?.[key]?.recurrence ==
                                                  "Recurring" &&
                                                  " per " +
                                                    response.services[lineItem]
                                                      .pricing?.[key]
                                                      ?.billing_interval}
                                              </span>
                                            </div>{" "}
                                          </>
                                        ) : (
                                          <div className="text-gray-600 space-y-2">
                                            {" "}
                                            <div className="flex items-center white-space-nowrap space-x-2">
                                              {response.services[lineItem] &&
                                                response.services[lineItem]
                                                  .pricing &&
                                                response.services[lineItem]
                                                  .pricing[key] &&
                                                response.services[lineItem]
                                                  .pricing[key].type}{" "}
                                              {/* <QuestionToolTip
                                                field={
                                                  response?.services[lineItem]
                                                    ?.pricing[key]?.type +
                                                  " pricing"
                                                }
                                                text={pricing_explanations.GetVolumeAndGraduatedPricing(
                                                  response.services[lineItem]
                                                    .pricing?.[key]
                                                )}
                                              />{" "} */}
                                              {response &&
                                              response.services &&
                                              response.services[lineItem] &&
                                              response?.services[lineItem]
                                                .pricing?.[key]?.recurrence ==
                                                "Recurring" ? (
                                                <Badge
                                                  color="secondary"
                                                  className=" "
                                                >
                                                  {" "}
                                                  RECURRING{" "}
                                                </Badge>
                                              ) : (
                                                response &&
                                                response.services &&
                                                response.services[lineItem] &&
                                                response.services[lineItem]
                                                  .pricing?.[key]
                                                  ?.recurrence && (
                                                  <Badge
                                                    color="secondary"
                                                    className=" "
                                                  >
                                                    {" "}
                                                    ONE-TIME FEE{" "}
                                                  </Badge>
                                                )
                                              )}
                                            </div>
                                            <div className="text-gray-600 font-normal space-y-2">
                                              {
                                                // if type is volume, loop through the volume tiers
                                                // and display the volume tier, price, and unit

                                                response.services[lineItem] &&
                                                  response.services[lineItem]
                                                    .pricing &&
                                                  response.services[lineItem]
                                                    .pricing[key] &&
                                                  (response.services[lineItem]
                                                    .pricing[key].type ==
                                                    "Volume" ||
                                                    response.services[lineItem]
                                                      .pricing[key].type ==
                                                      "Graduated") &&
                                                  response.services[lineItem]
                                                    .pricing[key].tiers &&
                                                  response.services[
                                                    lineItem
                                                  ].pricing[key].tiers.map(
                                                    (tier) => {
                                                      return (
                                                        <div
                                                          key={tier._id}
                                                          className="flex mt-2 gap-x-3 lowercase"
                                                        >
                                                          {tier.min} -{" "}
                                                          {tier.max}: {" $"}
                                                          {tier.price &&
                                                            tier.price}
                                                          {tier.price_type ==
                                                          "Recurring"
                                                            ? " per " +
                                                              response
                                                                ?.services[
                                                                lineItem
                                                              ]?.pricing[key]
                                                                ?.unit
                                                            : " fixed"}
                                                          {response.services[
                                                            lineItem
                                                          ].pricing?.[key]
                                                            ?.recurrence ==
                                                            "Recurring" &&
                                                            " per " +
                                                              response.services[
                                                                lineItem
                                                              ].pricing?.[key]
                                                                ?.billing_interval}
                                                        </div>
                                                      );
                                                    }
                                                  )
                                              }
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  </td>
                                );
                              })}
                          </tr>
                        </>
                      );
                    })}
                  <tr className="h-4">
                    <th
                      scope="col"
                      class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200 align-top`}
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        minWidth: "300px",
                      }}
                    >
                      Additional Comments
                    </th>
                    {responses &&
                      responses.length > 0 &&
                      responses.map((response) => {
                        if (!response.services) {
                          return (
                            <td className="text-gray-600 font-normal align-top"></td>
                          );
                        }
                        return (
                          <td className="border-r text-gray-600 text-sm py-2 px-2 align-top">
                            {response.services[lineItem] &&
                            response.services[lineItem]
                              .additional_comments_object ? (
                              <WYSIWYGViewer
                                editorState={
                                  response.services[lineItem]
                                    .additional_comments_object
                                }
                              />
                            ) : (
                              response.services[lineItem] &&
                              response.services[lineItem].additional_comments
                            )}
                          </td>
                        );
                      })}
                  </tr>
                </Fragment>
              );
            })}
          {/* {response.services &&
          Object.entries(response.services).map(([k, v]) => {
            return (
              <tr class="bg-white border-b  dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {idx} Service Name
                </th>
                <td class="px-6 py-4">{tier.min}</td>
                <td class="px-6 py-4"></td>
                <td class="px-6 py-4"></td>
                <td class="px-6 py-4 relative">
                  <span className="bottom-5 absolute -right-4"></span>
                </td>
              </tr>
            );
          })} */}
        </tbody>
        {/* </div> */}
      </table>
    </div>
  );
}
