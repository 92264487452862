import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Connections = {
  async inviteConnection(connection) {
    let data = { connection: connection };
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.createdBy = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;
    console.log("Call invite connections API", data);
    let response = await postRequest("connections/invite", data);
    if (response) {
      console.log("Invite Connection got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async acceptConnection(connection, tenantId) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let data = { connection: connection };
    data.tenantId = tenantId; //requestor tenant id
    data.companyId = profile.tenantCompanyId;

    console.log("Call accept connections API", data);
    let response = await postRequest("connections/accept", data);
    if (response && response.data.status === 200) {
      console.log("Accept Connection got response", response);
      return response.data.data;
    } else {
      return false;
    }
  },
};
