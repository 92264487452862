import { useHistory } from "react-router-dom";
import Button from "../elements/Button";
import { Icon } from "@iconify/react";

export default function AccessDenied() {
  const history = useHistory();
  const handleClick = () => {
    history.push("/signin");
  };
  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
      {/* <main className="grid flex flex-col h-full min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"> */}
      <div className="flex flex-col my-auto text-center justify-center h-screen bg-white">
        {/* <p className="text-base font-semibold text-brand-600">404</p> */}
        <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {history.location.state.text
            ? history.location.state.text
            : "Access Denied"}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          {history.location.state.text
            ? history.location.state.description
            : "You don't have permission to access this page."}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            color={"secondary"}
            size="sm"
            className="ml-3 "
            handleClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            <Icon icon="uil:arrow-left" className="h-5 w-5 mr-2" /> Go back to
            login
          </Button>
        </div>
      </div>
      {/* </main> */}
    </>
  );
}
