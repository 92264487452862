import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const System = {
  async GetSystemMessages() {
    let data = {};
    console.log("Call API and pass data for LogFrontEnd", data);
    let response = await getRequest("system/get-system-messages", data);
    if (response) {
      console.log("/system/get-system-messages", response.data);
      return response.data;
    } else {
      console.log("/system/get-system-messagesfailed", response);
      return false;
    }
  },
};
