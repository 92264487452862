import React from "react";
import Button from "../elements/Button";
import { Icon } from "@iconify/react";

const SubscriptionListItem = (props) => {
  // itemName="Base Subscription"
  // renewalDate="1/2/2025"
  // productName="Vendor Standard"
  // price="$2000/month"
  // numberOfProducts="3 products"
  // buttonText="Manage"
  const {
    itemName,
    renewalDate,
    productName,
    price,
    numberOfProducts,
    buttonText,
    buttonIcon,
    buttonColor,
    icon,
  } = props;
  return (
    <div className="p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
      <div className="grid grid-cols-12 items-center gap-x-2">
        {/* Card */}
        <div className="col-span-6 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
          {icon}
          <div>
            <div className="text-sm font-medium text-slate-800">{itemName}</div>
            <div className="text-xs">{renewalDate}</div>
          </div>
        </div>
        {/* Name */}
        <div className="col-span-6 order-2 sm:order-none sm:col-span-3 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
          <div className="text-sm font-medium text-slate-800 truncate">
            {productName}
          </div>
          <div className="text-xs">{price}</div>
        </div>
        {/* Card limits */}
        <div className="col-span-6 order-1 sm:order-none sm:col-span-4 text-right sm:text-center lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-4">
          <div className="text-sm">{numberOfProducts}</div>
        </div>
        {/* Card status */}
        <div className="col-span-6 order-2 sm:order-none sm:col-span-2 text-right lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
          <Button handleClick={() => props.onClick()} color={buttonColor}>
            {buttonIcon && <span className="mr-1">{buttonIcon}</span>}
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionListItem;
