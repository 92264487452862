import { update } from "immutable";
import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Bids = {
  // async updateBid(id, updates) {
  //   let profile = _auth.profile();
  //   let token = _auth.getUserToken("jwtToken");
  //   if (!token) {
  //     token = profile.token;
  //   }
  //   setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
  //   // updates = {
  //   //   ...updates,
  //   //   decisionWorkflow: "submitted",
  //   // };
  //   data.id = id;
  //   data.tenantType = profile.tenantType;
  //   data.tenantId = profile.tenantId;
  //   data.companyId = profile.tenantCompanyId;
  //   data.solicitationId = solicitationId;
  //   data.updates = updates;
  //   console.log("API body", data);
  //   let response = await postRequest("bids/update", data);
  //   if (response) {
  //     console.log("bids/update", response.data.data);
  //     return response.data.data;
  //   } else {
  //     return response;
  //   }
  // },
  async list(scope) {
    let data = {};

    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.vendorCompanyId;
    data.scope = scope;
    console.log("API body - list  bids", data);
    let response = await postRequest("bids/get-all-bids", data);
    if (response) {
      console.log("Get bid reqeust got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },

  async deleteBid(solicitationId) {
    try {
      let data = {
        solicitationId,
      };
      console.log("Call API and pass data", data);
      let profile = _auth.profile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("bids/delete-bid");
      if (response) {
        console.log("Get bid reqeust got response", response);
        return response.data.data;
      } else {
        return response;
      }
    } catch (err) {
      return err;
    }
  },
};
