import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import {
  Checkboxes,
  Dropdown,
  RadioGroupSingle,
  Section,
  SectionDivider,
  TextArea,
  TextInput,
} from "./DataEntry";
import JurisdictionInput from "./JurisdictionInput";
import { Jurisdictions } from "../../../modules/_jurisdiction_list";
import Divider from "../../../components/Divider";
import SingleOptions from "../../../components/SingleOptions";
import { object } from "prop-types";
import Tip from "../../../components/Tip";
import Assumptions from "./Assumptions";

export default function DataMiningInfo({
  create,
  newProject,
  languageOptions,
  callCenterLocationOptions,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleSingleSelect,
  handleMultiSelect,
  handleMultiDeSelect,
  handleDeselectedLanguage,
  handleClearAllSelections,
  handleSelect,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});
  const [opts2, setOpts2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jurisidctions, setJurisdictionOptions] = useState([]);

  useEffect(() => {
    console.log(
      "DataMiningInfo useEffect",
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ]
    );
    setData(newProject);
  }, [newProject]);

  useMemo(() => {
    const jurisdictions = Jurisdictions.getJurisdictions();
    const priority = [
      { id: 124, alpha2: "ca", alpha3: "can", name: "Canada" },
      {
        id: 826,
        alpha2: "gb",
        alpha3: "gbr",
        name: "United Kingdom",
      },
      { id: 840, alpha2: "us", alpha3: "usa", name: "United States" },
    ];
    //combine the two arrays
    const combined = [...priority, ...jurisdictions];

    setJurisdictionOptions(combined);
  }, []);

  useEffect(() => {
    //setOpts2
    console.log("UseEffect DataMiningInfo opts2");
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOpts2(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOptions(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    } else {
      console.log("UNDEFINED");
    }
  }, [newProject]);

  useEffect(() => {
    if (opts2.weekday_hours !== "custom" && create) {
      console.log("Remove custom hours");
      onFieldChange(
        "options",
        {
          target: {
            value: "",
          },
        },

        "custom_weekday_hours",
        service
      );
    }
  }, [opts2.weekday_hours]);

  useEffect(() => {
    if (opts2.weekend_hours !== "custom" && create) {
      console.log("Remove custom hours");
      onFieldChange(
        "options",

        {
          target: {
            value: "",
          },
        },

        "custom_weekend_hours",
        service
      );
    }
  }, [opts2.weekend_hours]);

  return (
    <div className="mt-8">
      {/* <Assumptions service={service} solicitation={newProject} /> */}
      <div className=" mt-6 grid grid-cols-6 gap-y-6 gap-x-4 ">
        {create && (
          <Tip colSpan={"full"} noMargin>
            Complete the following form to the best of your ability. These are
            the requirements that you will expect vendors to meet. Giving them
            all the information up front will ensure that you get the most
            accurate quotes.
          </Tip>
        )}

        <Section
          label="General Information"
          description={`${
            create
              ? "Let's start by gathering information related to the project. "
              : ""
          }`}
          create={create}
        />

        <TextInput
          label="How many employees does the insured have?"
          // tooltip={{
          //   title: "Employees",
          //   text: "",
          // }}
          service={service}
          field="number_of_employees"
          create={create}
          opts2={opts2}
          placeholder=""
          onFieldChange={onFieldChange}
        />
        {/* <Dropdown
          label="Has this data been processed and culled already?"
          tooltip={{
            title: "Processing",
            text: "If the data has been processed in the past, the data provided will likely be more accurate.",
          }}
          service={service}
          field="outside_it_provider"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          handleDeselect={handleDeselectedLanguage}
          handleClearAllSelections={(value) =>
            handleClearAllSelections(value, service, "outside_it_provider")
          }
          options={[
            { name: "Yes" },
            {
              name: "No",
            },
            { name: "Unsure" },
          ]}
        /> */}
        <RadioGroupSingle
          // maybe use dropdown instead?
          cols={3}
          rows={2}
          type="single"
          label="Outside IT Provider / MSP"
          description="Does your insured contract with an outside IT provider / MSP?"
          tooltip="What is the project scope?"
          service={service}
          field="outside_it_provider"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleSingleSelect}
          options={[
            {
              id: 1,
              title: "Yes",
              description: "",
              value: "Yes",
            },
            {
              id: 2,
              title: "No",
              description: "",
              value: "No",
            },
            {
              id: 3,
              title: "Unsure",
              description: "",
              value: "Unsure",
            },
          ]}
        />
        <RadioGroupSingle
          // maybe use dropdown instead?
          cols={3}
          rows={2}
          type="multiSelect"
          label="Email Services"
          description="Does the insured utilize any of the following services / software? (select any that apply, leave blank if unknown) "
          tooltip="What is the project scope?"
          service={service}
          field="email_services"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          options={[
            {
              id: 1,
              title: "Microsoft Office 365",
              description: "",
              value: "office_365",
            },
            {
              id: 2,
              title: "Google Workspace",
              description: "",
              value: "google_workspace",
            },
            {
              id: 4,
              title: "Microsoft Exchange (hosted)",
              description: "",
              value: "microsoft_exchange_hosted",
            },
            {
              id: 5,
              title: "Microsoft Exchange (on-premise)",
              description: "",
              value: "microsof_exchange_on_premise",
            },
          ]}
        />
        <RadioGroupSingle
          // maybe use dropdown instead?
          cols={3}
          rows={2}
          type="multiSelect"
          label="Cybersecurity controls."
          description="What cybersecurity controls exist currently (select any that apply, leave blank if unknown) "
          tooltip="Cybersecurity controls?"
          service={service}
          field="cybersecurity_controls"
          create={create}
          opts2={opts2}
          placeholder=""
          fieldType="single"
          open={true}
          newProject={newProject}
          handleSelect={handleMultiSelect}
          options={[
            {
              id: 1,
              title: "MFA on email",
              description: "",
              value: "mfa_on_email",
            },
            {
              id: 2,
              title: "MFA on remote access",
              description: "",
              value: "mfa_on_remote_access",
            },
            {
              id: 3,
              title: "EDR or MDR",
              description: "",
              value: "edr_or_mdr",
            },
            {
              id: 4,
              title: "VPN or SASE",
              description: "",
              value: "vpn_or_sase",
            },

            {
              id: 6,
              title: "Backups (Saas-provided or self-completed)",
              description: "",
              value: "backups",
            },
            {
              id: 7,
              title: "Device security policy management",
              description:
                "Such as with Active Directory GPOs, Jumpcloud, or JAMF",
              value: "device_security_policy_management",
            },
            {
              id: 8,
              title: "Cyber awareness training",
              description:
                "Cyber awareness training and/or phishing simulations",
              value: "cyber_awareness_training",
            },

            {
              id: 9,
              title: "Malicious email scanning",
              description: "",
              value: "malicious_email_scanning",
            },
            {
              id: 10,
              title: "Vulnerability scanning",
              description: "",
              value: "vulnerability_scanning",
            },
            {
              id: 11,
              title: "Application whitelisting",
              description: "",
              value: "application_whitelisting",
            },
            {
              id: 12,
              title: "Privileged Access Management ",
              description:
                "Controlling admin functions separate from everyday users",
              value: "privileged_access_management",
            },
          ]}
        />

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {!create && (
            <ReadOnlyAnswer type="text" answer={opts2.additional_details} />
          )}
          {create && (
            <Paragraph>
              Please briefly describe the reason for the claim event as it is
              known today. Specific suspected attack vectors, technologies,
              and/or loss impacts are helpful.
            </Paragraph>
          )}
          {create && (
            <div className="mt-1">
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",

                    e,

                    "additional_details",
                    service,

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
