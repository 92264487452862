import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormPage from "../pages/component/FormPage";
import { InviteUser } from "./FormPageInviteUser";

function UserAddPanel(props) {
  const history = useHistory();
  const profile = props.profile;

  return (
    <div id="settings" className=" h-full w-full max-w-9xl mx-auto">
      <div id="user-add-panel" className="h-full">
        <div className="grid grid-cols-2 divide-x  h-full">
          <InviteUser
            profile={profile}
            type={props.type}
            inviteType={props.inviteType}
          />

          {/* <FormPage type={props.type} inviteType={props.inviteType} /> */}
        </div>
      </div>
    </div>
  );
}

export default UserAddPanel;
