import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";

export default function CallCenterInfo({
  create,
  newProject,
  languageOptions,
  callCenterLocationOptions,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleMultiDeSelectNew,
  handleDeselectedLanguage,
  handleClearAllSelections,
  handleSelect,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState([]);

  useEffect(() => {
    setData(newProject);
  }, [newProject]);

  useEffect(() => {
    console.log("CallCenter project updates", newProject);
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOptions(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    } else {
      console.log("UNDEFINED");
    }
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOpts2(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  useEffect(() => {
    if (opts2.weekday_hours !== "custom" && create) {
      console.log("Remove custom hours");
      onFieldChange(
        "options",
        {
          target: {
            value: "",
          },
        },

        "custom_weekday_hours",
        "call_center"
      );
    }
  }, [opts2.weekday_hours]);

  useEffect(() => {
    if (opts2.weekend_hours !== "custom" && create) {
      console.log("Remove custom hours");
      onFieldChange(
        "options",

        {
          target: {
            value: "",
          },
        },

        "custom_weekend_hours",
        "call_center"
      );
    }
  }, [opts2.weekend_hours]);

  return (
    <div className="mt-8">
      {create && (
        <div>
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Standup
          </h3>
          <Paragraph className="mt-1 text-sm text-slate-500">
            Now, let's gather your specific requirements related to your call
            center, including turnaround time to get it operational.
          </Paragraph>
        </div>
      )}

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {/* 
        
         */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            bold
            sm
            htmlFor=" my-auto delay_mail"
            className="col-span-6 block text-slate-700 items-center"
          >
            Maximum days required to commence call center operation from receipt
            of final FAQ's?
          </Label>
          {!create && (
            <div className="col-span-6 mb-6">
              <ReadOnlyAnswer
                answer={opts2.stand_up_days ? opts2.stand_up_days : ""}
                type="text"
              />
            </div>
          )}
          {create && (
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="stand_up_days"
                id="stand_up_days"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                value={opts2.stand_up_days ? opts2.stand_up_days : ""}
                onChange={(e) =>
                  onFieldChange("options", e, "stand_up_days", "call_center")
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        {/* Call Center Buttons */}
        {/* <div className="col-span-full ">
          <div className="col-span-full">
            <label
              htmlFor="is_insured"
              className="block mb-6 text-sm font-medium text-slate-700"
            >
              Where do you require the call center to be located?
            </label>

            <RadioGroupCards
              name="services"
              defaultValue={null} //{newProject.mailing}
              value={null} //{newProject.mailing}
              className="grid  gap-y-6 grid-cols-3  sm:gap-x-12"
              onChange={null} //{(e) => handleSelect("mailing", e)}
              options={[
                {
                  id: 1,
                  title: "North America",
                  description: "",
                  value: "9to9",
                },
                {
                  id: 2,
                  title: "Europe",
                  description: "",
                  value: "24hours",
                },
                {
                  id: 3,
                  title: "UK",
                  description: "",
                  value: "custom",
                },
                {
                  id: 4,
                  title: "Australia/NZ",
                  description: "",
                  value: "9to9",
                },
                {
                  id: 5,
                  title: "Asia",
                  description: "",
                  value: "24hours",
                },
                {
                  id: 6,
                  title: "Other",
                  description: "",
                  value: "custom",
                },
              ]}
            />
          </div>
        </div> */}
        {/* Required Languages */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="call_center_location"
            className="my-auto col-span-6 block"
          >
            Where do you require the call center to be located?
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                type={"chips"}
                answer={
                  newProject &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2.call_center_location
                }
              />
            </div>
          )}

          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="call_center_location"
                options={
                  callCenterLocationOptions ? callCenterLocationOptions : []
                }
                fieldType="single"
                open={true}
                handleSelect={(v) =>
                  handleSelectedLanguage(
                    v,
                    "call_center",
                    "call_center_location"
                  )
                }
                handleDeselect={handleDeselectedLanguage}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(
                    value,
                    "call_center",
                    "call_center_location"
                  )
                } //{handleClearAllSelections}
                value={() => {
                  return (
                    newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.call_center_location
                  );
                }}
                // value={
                //   opts2.call_center_location ? opts2.call_center_location : []
                // }
              />
            </div>
          )}
        </div>

        {/* <div className="col-span-full ">
          <div className="col-span-full">
            <label
              htmlFor="is_insured"
              className="block mb-6 text-sm font-medium text-slate-700"
            >
              Where do you require the call center to be located?
            </label>
          
            <RadioGroupCards
              name="services"
              defaultValue={null} //{newProject.mailing}
              value={null} //{newProject.mailing}
              className="grid  gap-y-6 grid-cols-3  sm:gap-x-12"
              onChange={null} //{(e) => handleSelect("mailing", e)}
              options={[
                {
                  id: 1,
                  title: "Fixed",
                  description: "",
                  value: "9to9",
                },
                {
                  id: 2,
                  title: "Variable",
                  description: "",
                  value: "24hours",
                },
                {
                  id: 3,
                  title: "Both",
                  description: "",
                  value: "custom",
                },
              ]}
            />
           
          </div>
        </div> */}
        {/* <div className="sm:col-span-6 grid grid-cols-6">
          <label
            htmlFor="forms_of_letter"
            className="my-auto  col-span-6 block text-sm font-medium text-slate-700"
          >
            Pricing Options
          </label> */}
        {/* - Multiple Selections: Pricing Options
	              - OPTIONS: Fixed, Variable, Both */}
        {/* <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name="forms_of_letter"
              id="forms_of_letter"
              autoComplete="none"
              placeholder="0"
              value={opts2.forms_of_letter ? opts2.forms_of_letter.value : ""}
              onChange={(e) =>
                onFieldChange("options", e, "forms_of_letter", "mailings")
              }
              className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
            />
          </div>
        </div> */}
        {/* Required Languages */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="required_languages"
            className="my-auto col-span-6 block "
          >
            What languages do you require to be spoken by call center staff?
          </Label>
          {!create && (
            <div className="col-span-6 mb-6">
              <ReadOnlyAnswer
                answer={
                  newProject &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : []
                }
                type="chips"
              />
            </div>
          )}

          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="required_langages"
                options={languageOptions ? languageOptions : []}
                fieldType="multiSelect"
                open={true}
                handleSelect={(v) =>
                  handleSelectedLanguage(v, "call_center", "required_language")
                }
                handleDeselect={(value) =>
                  handleMultiDeSelectNew(
                    value,
                    "call_center",
                    "required_language"
                  )
                }
                handleClearAllSelections={(v) =>
                  handleClearAllSelections(
                    v,
                    "call_center",
                    "required_language"
                  )
                } //{handleClearAllSelections}
                // value={opts2.required_language ? opts2.required_language : []}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : [];
                }}
              />
            </div>
          )}
        </div>

        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Operation
          </h3>
          {create && (
            <Paragraph>
              The below questions relate to how you want the call center
              operated.
            </Paragraph>
          )}
        </div>
        {/* Required Weekday Hours */}
        <div className="col-span-full ">
          <div className="col-span-full">
            <Label sm bold htmlFor="is_insured" className="block mb-6 ">
              What hours of operation do you require Monday - Friday?
            </Label>

            <RadioGroupCards
              name="services"
              deselect={true}
              // defaultValue={opts2.weekday_hours ? opts2.weekday_hours : ""}
              // value={opts2.weekday_hours ? opts2.weekday_hours : ""}
              defaultValue={() => {
                return (
                  (newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.weekday_hours) ||
                  ""
                );
              }}
              value={() => {
                return (
                  (newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.weekday_hours) ||
                  ""
                );
              }}
              disabled={!create}
              className="grid  gap-y-6 grid-cols-3  sm:gap-x-12"
              onChange={(e) =>
                handleSelect("options", e, "weekday_hours", "call_center")
              }
              options={[
                {
                  id: 1,
                  title: "9am - 9pm EST",
                  description: "",
                  value: "9to9",
                },
                {
                  id: 2,
                  title: "24 hours",
                  description: "",
                  value: "24hours",
                },
                {
                  id: 3,
                  title: "Custom",
                  description: "",
                  value: "custom",
                },
              ]}
            />
          </div>
          {newProject &&
            newProject.services[
              newProject.services.findIndex((el) => el.service === service)
            ].opts2.weekday_hours == "custom" && (
              <div className="sm:col-span-full mt-4">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-slate-700"
                >
                  Custom Weekday Hours
                </label>
                {create && (
                  <div>
                    <p className="mt-2 text-sm text-slate-500">
                      Write a few sentences to describe your required schedule.
                    </p>
                    <div className="mt-1">
                      <textarea
                        id="custom_weekday_hours"
                        name="custom_weekday_hours"
                        rows={3}
                        value={
                          newProject.services[
                            newProject.services.findIndex(
                              (el) => el.service === service
                            )
                          ].opts2.custom_weekday_hours
                        }
                        onChange={(e) => {
                          onFieldChange(
                            "options",
                            //if checked, true, else false
                            e,

                            "custom_weekday_hours",
                            "call_center"
                          );
                        }}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                )}
                {!create && (
                  <div className="mt-1">
                    <ReadOnlyAnswer
                      type="text"
                      answer={
                        newProject.services[
                          newProject.services.findIndex(
                            (el) => el.service === service
                          )
                        ].opts2.custom_weekday_hours
                      }
                    />
                  </div>
                )}
              </div>
            )}
        </div>
        {/* Required Weekend Hours */}
        <div className="col-span-full ">
          <div className="col-span-full">
            <Label sm bold htmlFor="is_insured" className="block mb-6">
              What hours of operation do you require on Saturday and Sunday?
            </Label>
            {/* <div className="mt-1 flex rounded-md  "> */}
            <RadioGroupCards
              name="services"
              deselect={true}
              // defaultValue={null} //{newProject.mailing}
              // value={opts2.weekend_hours ? opts2.weekend_hours : ""}
              disabled={!create}
              defaultValue={() => {
                return (
                  (newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.weekend_hours) ||
                  ""
                );
              }}
              value={() => {
                return (
                  (newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.weekend_hours) ||
                  ""
                );
              }}
              className="grid  gap-y-6 grid-cols-3  sm:gap-x-12"
              onChange={(e) =>
                handleSelect("options", e, "weekend_hours", "call_center")
              }
              options={[
                {
                  id: 1,
                  title: "9am - 9pm EST",
                  description: "",
                  value: "9to9",
                },
                {
                  id: 2,
                  title: "24 hours",
                  description: "",
                  value: "24hours",
                },
                {
                  id: 3,
                  title: "Custom",
                  description: "",
                  value: "custom",
                },
              ]}
            />
            {/* </div> */}
          </div>
          {newProject &&
            newProject.services[
              newProject.services.findIndex((el) => el.service === service)
            ].opts2.weekend_hours == "custom" && (
              <div className="sm:col-span-full mt-4">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-slate-700"
                >
                  Custom Weekend Hours
                </label>
                {create && (
                  <div>
                    {" "}
                    <p className="mt-2 text-sm text-slate-500">
                      Write a few sentences to describe your required schedule.
                    </p>
                    <div className="mt-1">
                      <textarea
                        id="custom_weekend_hours"
                        name="custom_weekend_hours"
                        rows={3}
                        value={opts2.custom_weekend_hours}
                        onChange={(e) => {
                          onFieldChange(
                            "options",
                            //if checked, true, else false
                            e,

                            "custom_weekend_hours",
                            "call_center"
                          );
                        }}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                )}
                {!create && (
                  <div className="mt-1">
                    <ReadOnlyAnswer
                      type="text"
                      answer={
                        newProject.services[
                          newProject.services.findIndex(
                            (el) => el.service === service
                          )
                        ].opts2.custom_weekend_hours
                      }
                    />
                  </div>
                )}
              </div>
            )}
        </div>
        {/* Project Reporting */}
        <div className="sm:col-span-6">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="project_reporting"
                name="project_reporting"
                type="checkbox"
                disabled={!create}
                className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                // value={opts2.project_reporting && opts2.project_reporting}\
                value={
                  newProject &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2.project_reporting
                }
                defaultChecked={
                  newProject &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2.project_reporting
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",

                    e,

                    "project_reporting",
                    "call_center",
                    null,
                    true,
                    "project_reporting_options"
                  );
                }}
              />
            </div>
            <div className="ml-3 text-sm">
              <Label sm bold htmlFor="offers" className="">
                Project Reporting
              </Label>
              <p className="text-slate-500">
                Check this box if you require the vendor to provide project
                reporting.
              </p>
              {opts2 &&
                opts2.project_reporting &&
                opts2.project_reporting == true && (
                  <div className="mt-2 space-y-2">
                    {/* Project Confirmation */}
                    <div className="relative flex items-start">
                      <div className="relative flex ">
                        <div className="flex h-5 items-center">
                          <input
                            id="average_speed_of_answer"
                            name="average_speed_of_answer"
                            type="checkbox"
                            disabled={!create}
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                            value={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .average_speed_of_answer
                                ? opts2.project_reporting_options
                                    .average_speed_of_answer
                                : false
                            }
                            defaultChecked={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .average_speed_of_answer
                                ? opts2.project_reporting_options
                                    .average_speed_of_answer
                                : false
                            }
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "average_speed_of_answer",
                                "call_center",
                                "project_reporting_options"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="ml-3 text-sm">
                        <Label sm normal htmlFor="offers" className="">
                          Average speed of answer
                        </Label>
                      </div>
                    </div>
                    {/* Printing Confirmation */}
                    <div className="relative flex items-start">
                      <div className="relative flex ">
                        <div className="flex h-5 items-center">
                          <input
                            id="number_of_calls_handled"
                            name="number_of_calls_handled"
                            type="checkbox"
                            disabled={!create}
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                            value={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .number_of_calls_handled
                                ? opts2.project_reporting_options
                                    .number_of_calls_handled
                                : false
                            }
                            defaultChecked={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .number_of_calls_handled
                                ? opts2.project_reporting_options
                                    .number_of_calls_handled
                                : false
                            }
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "number_of_calls_handled",
                                "call_center",
                                "project_reporting_options"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="candidates" className="text-slate-700">
                          Number of calls handled
                        </label>
                      </div>
                    </div>
                    {/* Mailing Confirmation */}
                    <div className="relative flex items-start">
                      <div className="relative flex ">
                        <div className="flex h-5 items-center">
                          <input
                            id="number_of_calls_dropped"
                            name="number_of_calls_dropped"
                            type="checkbox"
                            disabled={!create}
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                            value={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .number_of_calls_dropped
                                ? opts2.project_reporting_options
                                    .number_of_calls_dropped
                                : false
                            }
                            defaultChecked={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .number_of_calls_dropped
                                ? opts2.project_reporting_options
                                    .number_of_calls_dropped
                                : false
                            }
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "number_of_calls_dropped",
                                "call_center",

                                "project_reporting_options"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="candidates" className="text-slate-700">
                          Number of calls dropped
                        </label>
                      </div>
                    </div>
                    {/* Escalations */}
                    <div className="relative flex items-start">
                      <div className="relative flex ">
                        <div className="flex h-5 items-center">
                          <input
                            id="number_of_escalations"
                            name="number_of_escalations"
                            type="checkbox"
                            disabled={!create}
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                            value={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .number_of_escalations
                                ? opts2.project_reporting_options
                                    .number_of_escalations
                                : false
                            }
                            defaultChecked={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options
                                .number_of_escalations
                                ? opts2.project_reporting_options
                                    .number_of_escalations
                                : false
                            }
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "number_of_escalations",
                                "call_center",

                                "project_reporting_options"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="candidates" className="text-slate-700">
                          Escalations
                        </label>
                      </div>
                    </div>

                    {/* Other Confirmation */}
                    <div className="relative flex items-start">
                      <div className="relative flex ">
                        <div className="flex h-5 items-center">
                          <input
                            id="other_confirmation"
                            name="other_confirmation"
                            type="checkbox"
                            disabled={!create}
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                            value={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options.other_confirmation
                                ? opts2.project_reporting_options
                                    .other_confirmation
                                : false
                            }
                            defaultChecked={
                              opts2.project_reporting_options &&
                              opts2.project_reporting_options.other_confirmation
                                ? opts2.project_reporting_options
                                    .other_confirmation
                                : false
                            }
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "other_confirmation",
                                "call_center",

                                "project_reporting_options"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="candidates" className="text-slate-700">
                          Other Project Reporting
                        </label>
                      </div>
                    </div>
                    {opts2.project_reporting_options &&
                      opts2.project_reporting_options.other_confirmation && (
                        <div className="sm:col-span-full">
                          <label
                            htmlFor="about"
                            className="block text-sm font-medium text-slate-700"
                          >
                            Other reporting
                          </label>
                          {!create && (
                            <ReadOnlyAnswer
                              type="text"
                              answer={
                                opts2.project_reporting_options
                                  .other_confirmation_details
                              }
                            />
                          )}
                          {create && (
                            <p className="mt-2 text-sm text-slate-500">
                              Write a few sentences to describe your required
                              reporting.
                            </p>
                          )}
                          {create && (
                            <div className="mt-1">
                              <textarea
                                id="description"
                                name="description"
                                rows={3}
                                value={
                                  opts2.project_reporting_options
                                    .other_confirmation_details
                                }
                                onChange={(e) => {
                                  onFieldChange(
                                    "options",
                                    //if checked, true, else false
                                    e,

                                    "other_confirmation_details",
                                    "call_center",

                                    "project_reporting_options"
                                  );
                                }}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                                defaultValue={""}
                              />
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Automation
          </h3>
          {create && (
            <Paragraph>
              Check all the automation options that you want your vendor to use.
            </Paragraph>
          )}
          <div className="mt-4 space-y-4">
            <div className="mt-4 space-y-4">
              {/* IVR */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="interactive_voice_response"
                    name="interactive_voice_response"
                    type="checkbox"
                    disabled={!create}
                    className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                    placeholder="required_languages_checkbox"
                    value={
                      opts2.interactive_voice_response &&
                      opts2.interactive_voice_response
                    }
                    defaultChecked={
                      opts2.interactive_voice_response &&
                      opts2.interactive_voice_response
                    }
                    onChange={(e) => {
                      onFieldChange(
                        "options",
                        //if checked, true, else false
                        e,

                        "interactive_voice_response",
                        "call_center"
                      );
                    }}
                  />
                </div>

                <div className="ml-3 text-sm">
                  <Label sm bold htmlFor="offers" className="">
                    Interactive Voice Response
                  </Label>
                  <p className="text-slate-500">
                    Check this box if you want the vendor to use IVR to
                    automate.
                  </p>
                </div>
              </div>
              {/* Bots */}
              {/* <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="bots"
                    name="bots"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                    placeholder="required_languages_checkbox"
                    value={opts2.bots && opts2.bots}
                    defaultChecked={opts2.bots && opts2.bots}
                    onChange={(e) => {
                      onFieldChange(
                        "options",
                        //if checked, true, else false
                        e,

                        "bots",
                        "call_center"
                      );
                    }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <Label sm bold htmlFor="offers" className="">
                    AI Bots
                  </Label>
                  <p className="text-slate-500">
                    Check this box if you want the vendor to use Bots to
                    automate.
                  </p>
                </div>
              </div> */}
              {/* Auto Callback */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="auto_callback"
                    name="auto_callback"
                    type="checkbox"
                    disabled={!create}
                    className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                    placeholder="required_languages_checkbox"
                    value={opts2.auto_callback && opts2.auto_callback}
                    defaultChecked={opts2.auto_callback && opts2.auto_callback}
                    onChange={(e) => {
                      onFieldChange(
                        "options",
                        //if checked, true, else false
                        e,

                        "auto_callback",
                        "call_center"
                      );
                    }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <Label sm bold htmlFor="offers" className="">
                    Auto Callback
                  </Label>
                  <p className="text-slate-500">
                    Check this box if you want the vendor to use Auto Callback
                    to automate.
                  </p>
                </div>
              </div>
              {/* Voicemail */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="voicemail"
                    name="voicemail"
                    type="checkbox"
                    disabled={!create}
                    className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                    placeholder="required_languages_checkbox"
                    value={opts2.voicemail && opts2.voicemail}
                    defaultChecked={opts2.voicemail && opts2.voicemail}
                    onChange={(e) => {
                      onFieldChange(
                        "options",
                        //if checked, true, else false
                        e,

                        "voicemail",
                        "call_center"
                      );
                    }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <Label sm bold htmlFor="offers" className="">
                    Voicemail
                  </Label>
                  <p className="text-slate-500">
                    Check this box if you want the vendor to use Voicemail to
                    automate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {!create && (
            <ReadOnlyAnswer type="text" answer={opts2.additional_details} />
          )}
          {create && (
            <Paragraph>
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </Paragraph>
          )}
          {create && (
            <div className="mt-1">
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "call_center",

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
