import React, { useEffect } from "react";
import { Icon } from "@iconify/react";

export default function RadioGroupLarge({
  options,
  onClickFunction,
  ...props
}) {
  const [selectedOption, setSelectedOption] = React.useState();
  const [disabledActions, setDisabledActions] = React.useState([]);
  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props.selectedOption]);
  useEffect(() => {
    console.log("DisabledActions Changed", props.disabledActions);
    setDisabledActions(props.disabledActions);
  }, [props.disabledActions]);

  const isDisabled = (action) => {
    console.log("isDisabled", action, disabledActions);
    let disabled = disabledActions.includes(action);
    console.log("isDisabled found", action, disabledActions, disabled);
    return disabled;
  };

  return (
    <ul class="grid w-full gap-6 grid-cols-1 lg:grid-cols-2">
      {/* map options  */}
      {options.map((item) => {
        return (
          <li className="min-h-full">
            <input
              type="radio"
              id={item.value}
              name={item.value}
              value={item.value}
              disabled={isDisabled(item.value)}
              checked={selectedOption === item.value}
              onChange={() => {
                console.log("clicked", item.value, isDisabled(item.value));
                setSelectedOption(item.value);
                onClickFunction(item.value);
              }}
              class="hidden peer"
            />

            <label
              for={item.value}
              // className="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-100"
              // class="inline-flex min-h-full items-center justify-between w-full p-5

              // text-gray-500 bg-white
              // border border-gray-200 rounded-lg cursor-pointer
              // peer-checked:border-brand-600 peer-checked:text-brand-600
              // hover:text-gray-600 hover:bg-gray-100 "
              class={`inline-flex min-h-full items-center justify-between w-full p-5 rounded-lg 
              ${
                isDisabled(item.value)
                  ? "text-gray-300 bg-gray-100 border border-gray-100 cursor-not-allowed focus:none "
                  : "text-gray-500 bg-white border border-gray-200 cursor-pointer peer-checked:border-brand-600  hover:text-gray-600 hover:bg-gray-100"
              }
              `}
            >
              <div className="w-full grid grid-cols-6 gap-6">
                <div className="col-span-1 flex items-center">
                  <Icon
                    icon="heroicons:document-plus"
                    className="h-full w-full"
                  />
                </div>
                <div className="flex col-span-5  justify-between">
                  <div class="block">
                    <div class=" w-full text-lg font-semibold">
                      {item.label}
                    </div>
                    <div class="">{item.description}</div>
                  </div>
                  <div className="flex items-center justify-ends"></div>
                </div>
              </div>
            </label>
          </li>
        );
      })}

      {/* <li className="min-h-full">
        <input
          type="radio"
          id="create-new"
          name="new-solicitation"
          value="create-new"
          checked={creationMethod === "create-new"}
          onClick={() => handleSelectCreationMethodClick("create-new")}
          class="hidden peer"
          required
        />

        <label
          for="create-new"
          class="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-100 "
        >
          <div className="w-full grid grid-cols-6 gap-6">
            <div className="col-span-1 flex items-center">
              <Icon icon="heroicons:document-plus" className="h-full w-full" />
            </div>
            <div className="flex col-span-5  justify-between">
              <div class="block">
                <div class=" w-full text-lg font-semibold">
                  Start from scratch
                </div>
                <div class="">
                  Get started right away if your needs are unique.
                </div>
              </div>
              <div className="flex items-center justify-ends"></div>
            </div>
          </div>
        </label>
      </li>

      <li className="min-h-full">
        <input
          type="radio"
          id="template"
          name="new-solicitation"
          value="template"
          checked={creationMethod === "template"}
          class="hidden peer"
          required
          onClick={() => {
            handleSelectCreationMethodClick("template");
          }}
        />

        <label
          for="template"
          class="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-100 "
        >
          <div className="w-full grid grid-cols-6 gap-6">
            <div className="col-span-1 flex items-center">
              <Icon
                icon="heroicons:document-duplicate"
                className="h-full w-full"
              />
            </div>
            <div className="flex col-span-5  justify-between">
              <div class="block">
                <div class=" w-full text-lg font-semibold">
                  Create from a saved template
                </div>
                <div class="">The fastest option if you have templates.</div>
              </div>
            </div>
          </div>
        </label>
      </li> */}
    </ul>
  );
}
