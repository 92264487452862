import React from "react";
import { useTable } from "react-table";
import { utils } from "../../modules/_utils";
import { useHistory } from "react-router-dom";
// const data = [
//   // your data here
// ];

// const columns = Object.keys(data[0]).map((key) => ({
//   Header: key,
//   accessor: key,
// }));

function Table({ columns, data, navigateTo, clickable }) {
  const history = useHistory();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div
      className="scrollbar-default pb-20 overflow-y-auto overflow-x-auto h-[calc(100%-120px)] mb-12"
      // style={{ overflow: "auto", height: "500px" }}
    >
      <table
        {...getTableProps()}
        className="border border-gray-200 h-full "
        style={{ width: "100%", textAlign: "left" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="bg-slate-50 border-b border-gray-200"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  {utils.cleanUpKeys(column.render("Header"))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`text-sm ${
                  navigateTo && "cursor-pointer hover:bg-gray-50"
                }`}
                onClick={() => {
                  navigateTo(row.original);
                }}
                // onClick={() => {
                //   if (navigateTo) {
                //     return navigateTo(row.original);
                //   } else {
                //     return null;
                //   }
                // }} // Use history.push instead of navigate
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 border-r border-l border-gray-200"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
