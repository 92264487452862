import { useState, useEffect } from "react";
import {
  CalendarIcon,
  CheckIcon,
  MapPinIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { utils } from "../../modules/_utils";

import Badge from "../../components/Badge";
import { PaperClipIcon } from "@heroicons/react/24/outline";

export default function BidList(props) {
  const [items, setItems] = useState([]);
  const [showBidCompareModal, setShowBidCompareModal] = useState(false);
  const { project, solicitation, from } = props;

  useEffect(() => {
    //should we filter this list to only show submitted and declined? not winners, losers, drafts?
    if (props.items && props.items.length > 0) {
      let filteredItems = props.items.filter(
        (item) =>
          ((item.status && item.status === "submitted") ||
            (item.status && item.status === "declined") ||
            (item.status &&
              item.status === "draft" &&
              item.isReturnedToVendor &&
              item.isReturnedToVendor === true)) &&
          item.solicitationId?.status !== "Closed" &&
          item.solicitationId?.status !== "Canceled"
      );

      setItems(filteredItems);
    }
    // props.items && setItems(props.items);
    // console.log("Solicitation List changed", items);
  }, [props.items]);

  const handleItemClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Bid list item clicked", item, props);
    props.onItemClick(project, item, solicitation);
    // props.handleItemClick(project, item, solicitation);
  };
  const getTotalPrice = (services) => {
    let total = 0;
    Object.keys(services).forEach((key) => {
      if (services[key].price && services[key].price > 0) {
        total += +services[key].price;
      }
    });

    return total.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  // Object.keys(response).forEach((key) => {
  //   if (
  //     response[key].price &&
  //     response[key].price > 0 &&
  //     !isNaN(response[key].price)
  //   ) {
  //     total += +response[key].price;
  //   }
  // });

  return (
    <div className="w-full h-full  bg-white ">
      <ul
        role="list"
        className="h-full overflow-y-auto scrollbar-default divide-y divide-gray-200  bg-white "
      >
        {items.map((item) => (
          <li
            key={item.id}
            className={`${" cursor-pointer hover:bg-gray-50 "} `}
            onClick={(e) => handleItemClick(e, item)}
          >
            {/* <a href="#" className="block hover:bg-gray-50 hover:no-underline"> */}
            <div
              className={`${
                item.requestorDecision &&
                item.requestorDecision == "winner" &&
                " ml-2 mr-2 my-2 rounded-md border border-2 border-green-400 bg-green-100 bg-opacity-25"
              }
              justify-between px-4 py-4 `}
            >
              <div className="flex items-center justify-between">
                <div className=" flex items-center justify-between">
                  <div className="flex items-center">
                    {item.requestorDecision &&
                      item.requestorDecision == "winner" && (
                        <CheckIcon
                          className="mr-1.5 h-5 w-5 flex items-center flex-shrink-0 text-green-400"
                          aria-hidden="true"
                        />
                      )}{" "}
                    <div className="truncate text-md font-semibold text-slate-600">
                      {from && from == "dashboard"
                        ? solicitation && solicitation.name
                          ? solicitation.name
                          : item.solicitationId && item.solicitationId.name
                          ? item.solicitationId.name
                          : ""
                        : item.vendorCompanyId &&
                          item.vendorCompanyId.companyName
                        ? item.vendorCompanyId &&
                          item.vendorCompanyId.companyName
                        : "Missing company name"}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="ml-2 flex text-xs space-x-2">
                    {item.isReturnedToVendor &&
                      item.isReturnedToVendor === true && (
                        <Badge color="red" className="uppercase mr-2">
                          <span className="uppercase text-xs">Returned</span>
                        </Badge>
                      )}
                    <Badge
                      color={
                        item.requestorDecision == "winner"
                          ? "green"
                          : "secondary"
                      }
                      className="uppercase"
                    >
                      <span className="uppercase text-xs">
                        {item.requestorDecision == "winner"
                          ? item.requestorDecision
                          : item.status}
                      </span>
                    </Badge>
                  </div>
                  {/* {item.status == "winner" && (
                    <Badge color="green" text="Selected Winner">
                      Winner
                    </Badge>
                  )} */}
                </div>
              </div>
              {/* <div className="sm:flex">
                  {" "}
                  {item.projectId && item.projectId.name && (
                    <p className="ml-2 inline-flex capitalize rounded-full bg-slate-100 px-2 text-xs font-semibold leading-5 text-slate-800">
                      {item.projectId &&
                        item.projectId.projectId &&
                        "BL-" + item.projectId.projectId.toString()}{" "}
                      {item.projectId && item.projectId.name}
                    </p>
                  )}
                </div> */}

              <div className="flex items-center text-xs  justify-between ">
                <div className="flex items-center">
                  {item.submittedByName && (
                    <>
                      {item.submittedOn && (
                        <>
                          <span className="text-slate-500 mr-1">
                            {" "}
                            Submitted on{" "}
                          </span>
                          <span className="text-brand-500 font-semibold">
                            {utils.formatDate(item.submittedOn, "short")}
                          </span>
                        </>
                      )}{" "}
                      {/* <span className="text-slate-500 ml-1 mr-1"> by </span>{" "}
                      <span className="text-brand-500 font-semibold">
                        {" "}
                        {item.submittedByName}
                      </span>{" "} */}
                    </>
                  )}
                </div>
              </div>
              <div className=" flex items-center text-xs">
                {item.vendorCompanyId && (
                  <>
                    {" "}
                    <span className="text-slate-500  mr-1"> Vendor: </span>{" "}
                    <span className="text-brand-500 font-semibold">
                      {item.vendorCompanyId && item.vendorCompanyId.companyName}
                    </span>
                  </>
                )}
              </div>

              <div className="flex items-center justify-between pt-4 ">
                {item.attachments && (
                  <div className="flex items-center text-sm text-slate-500 ">
                    <PaperClipIcon className="w-4 h-4 " />
                    <span className="">Attachments:</span>
                    <span className="text-brand-500 font-semibold ml-2">
                      {" "}
                      {item.attachments?.length}
                    </span>{" "}
                  </div>
                )}

                <div id="project-name" className="flex items-center">
                  {" "}
                  {item.projectId && item.projectId.name && (
                    <p className="uppercase inline-flex uppercase rounded-full bg-slate-100 px-2 text-xs font-semibold leading-5 text-slate-800">
                      {item.projectId &&
                        item.projectId.projectId &&
                        "P-" + item.projectId.GlobalId.toString()}{" "}
                      {item.projectId && item.projectId.name}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* </a> */}
          </li>
        ))}
      </ul>
    </div>
  );
}
