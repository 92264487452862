import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ArrowSteps from "../components/ArrowSteps";
import LandingIntro from "../components/LandingIntro";
import SingleOptions from "../components/SingleOptions";
import StackedList from "../components/StackedList";
import ContactForm from "../components/ContactForm";
import Spinner from "../images/spinner.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import Fade from "react-reveal/Fade";
import { _auth } from "../modules/_auth";
import Skeleton from "../components/Skeleton";
import Input from "../components/Input";
import { _user } from "../modules/_user";
import InputList from "../components/InputList";
import ListBoxSelector from "../components/ListBoxSelector";
import MultiSelectListBox from "../components/MultiSelectListBox1";
import Button from "../elements/Button";
import { _teams } from "../modules/_teams";
import DoNotShowAgain from "../components/DoNotShowAgain";
import { Preferences } from "../modules/_user_preferences";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = _onboarding_options.getAllSettingsOptions();

const mailingLists = [
  {
    id: 1,
    name: "Food & Beverage",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    name: "Hospitality",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    name: "Retail",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 4,
    name: "Education",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 5,
    name: "Healthcare",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 6,
    name: "Financial",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 7,
    name: "Consumer Services",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 8,
    name: "Manufacturing",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 9,
    name: "Government",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 10,
    name: "Transportation",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 11,
    name: "Media",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 12,
    name: "Communications",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 13,
    name: "Energy",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 14,
    name: "Technology",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 15,
    name: "Other",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Onboard() {
  const history = useHistory();
  const [selectedMailingLists, setSelectedMailingLists] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [currentStep, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [company, setCompany] = useState("");
  const [tenant, setTenant] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState(history.location.state.profile);
  const [buttonText, setButtonText] = useState("Let's Go!");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function handleCheckboxClick(clickType) {
    if (clickType === "dontshowagain") {
      setChecked(!checked);
    }
    if (clickType === "onboardFinished") {
      if (checked) {
        let ans = await _onboarding_options.setDontShowAgain(!checked);
        console.log(ans, "ansss");
        if (ans) {
          return history.push({
            pathname: "/dashboard",
          });
        }
      } else {
        onFinish();
      }
    }
  }

  const onFinish = async () => {
    setLoader(true);
    return history.push({
      pathname: "/home",
      state: { profile },
    });
  };

  return (
    <div className="flex h-screen bg-white">
      {/* Content */}
      <div className="w-full">
        <div className="min-h-screen  bg-white flex flex-col after:flex-1">
          {/*New Progress Bar*/}
          <div className="md:flex h-48 flex-col after:flex-1 hidden">
            {/* <ArrowSteps
              setStep={setStep}
              steps={steps}
              currentStep={currentStep}
            /> */}
          </div>
          {/*Each step's component */}
          {/* <Fade right> */}
          <div className="px-4 py-8">
            {/* This div controls the width of the main content */}

            <div
              id="options_container"
              // style={{
              //   padding: "25px",
              //   minHeight: "60vh",
              //   maxHeight: "60vh",
              // }}
              className={classNames(
                currentStep == 7
                  ? "w-4/12 mx-auto" // null //"px-6 sm:px-6 lg:px-6 py-8 w-full"
                  : "w-4/12 mx-auto",
                "max-h-min"
              )}
            >
              {/* <div className="max-w-md mx-auto"> */}
              {/* <div> */}
              {/*Onboard step 1 - Introduction */}
              {currentStep == 1 && (
                <Fade right>
                  <div className="flex flex-col w-full after:flex-1">
                    <div className="overflow-y-none">
                      <LandingIntro tenantType={profile.tenantType} />
                    </div>
                    <div className="sm:flex sm:justify-between sm:items-center">
                      <div className="mb-4 sm:mb-0"></div>
                    </div>
                    {/* <div className=" items-center">
                    <p className="mt-4  ">
                      For consistency, select your team from the list below. We
                      will populate the details for you.
                    </p>
                  </div> */}
                    {/* <div className="mt-6 mb-6">
                      <MultiSelectListBox
                        key={1}
                        options={teams}
                        fieldType="single"
                        valueField="Key"
                        displayField="Team Name"
                        label="Team Names"
                        name="teamlist"
                        impedimentId={null}
                        // handleSelect={null}
                        handleDeselect={null}
                        handleSelect={handleSelect}
                        value={tenant}
                      />
                      
                    </div> */}
                    <div class="flex justify-end items-right w-full ">
                      <Button
                        color="secondary"
                        fullWidth
                        shadow
                        size="lg"
                        // disabled={
                        //   buttonText !== "Done" && !error ? true : false
                        // }
                        // className="btn mt-6 w-36 rounded-md
                        // bg-white text-brand-600
                        // border border-brand-500
                        // shadow-md shadow-inner shadow-amber-600
                        // border-1.4
                        // hover:shadow-inner  hover:shadow-brand-600  hover:text-brand-600
                        // mx-2 my-2 shrink-0 "
                        exact
                        handleClick={onFinish}
                      >
                        {loader && (
                          <Skeleton
                            type="spinner"
                            color="gray"
                            fill="purple"
                            forButton={true}
                          />
                        )}{" "}
                        {buttonText}
                      </Button>
                    </div>

                    <DoNotShowAgain
                      preferenceId="show-onboarding"
                      setProfile={setProfile}
                      profile={profile}
                      value={Preferences.getPreferenceValue(
                        profile.preferences,
                        "show-onboarding"
                      )}
                    />

                    {error && (
                      <div className="mt-4">
                        <p className="text-red-500 text-sm">{errorMessage}</p>
                      </div>
                    )}
                  </div>
                </Fade>
              )}
              {/*Onboard step 2 - Your data */}
              {currentStep == 2 && (
                <Fade right>
                  <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Let's help you get started.
                      </h1>
                    </div>
                  </div>
                  <div className=" items-center">
                    <p className="mt-4  ">
                      At some point we will use this onboarding wizard to
                      capture information about the user, let the build their
                      profile, or to provide information to the user about the
                      app. For now, I just have a placeholder.
                    </p>
                  </div>
                  <div className="mt-20">
                    {/* <Input
                      name="tenant"
                      placeholder="Team Name"
                      onChange={(e) => {
                        onChangeValue(e);
                      }}
                      value={tenant}
                      defaultValue={profile.tenantName}
                      className="mt-4"
                    /> */}
                  </div>
                </Fade>
              )}

              {/*Onboard step 3 - Industry */}
              {currentStep == 3 && (
                <Fade right>
                  <SingleOptions
                    title={"Role"}
                    options={roles}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedRole}
                    onSelectOption={(ans) => {
                      setSelectedRole(ans);
                      console.log(selectedRole, "role");
                    }}
                  />
                </Fade>
              )}
              {/*Onboard step 3 - Department */}
              {currentStep == 4 && (
                <Fade right>
                  <SingleOptions
                    title={"Department"}
                    options={departments}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedDepartment}
                    onSelectOption={(ans) => setSelectedDepartment(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 4 - Industry */}
              {currentStep == -5 && (
                <Fade right>
                  <SingleOptions
                    title={"Industry"}
                    options={mailingLists}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedMailingLists}
                    onSelectOption={(ans) => setSelectedMailingLists(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 5 - Company Size */}
              {currentStep == -5 && (
                <Fade right>
                  <SingleOptions
                    title={"Company Size"}
                    options={companySize}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedSize}
                    onSelectOption={(ans) => setSelectedSize(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 6 - Location */}
              {currentStep == -6 && (
                <Fade right>
                  <SingleOptions
                    title={"Location"}
                    options={companyLocation}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedLocation}
                    onSelectOption={(ans) => setSelectedLocation(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 7 - Resources */}
              {currentStep == -8 && (
                <Fade right>
                  <StackedList
                    title={"Other actions"}
                    options={options}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    onSelectOption={(ans) => setSelectedLocation(ans)}
                  />
                </Fade>
              )}

              {/* Checkbox - Don't show again */}
              {/* {currentStep == 1 && (
                <div className="mr-1">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleCheckboxClick("dontshowagain");
                      }}
                      // onClick={_onboarding_options.setDontShowAgain(this)}
                      className="form-checkbox"
                    />
                    <span className="text-sm ml-2">
                      Don't show this wizard again.
                    </span>
                  </label>
                </div>
              )} */}
            </div>

            {/* Buttons */}
            <div id="buttons" className="w-4/12 mx-auto justify-end">
              <div
                className="mt-20 flex-none md:flex  
               items-center  gap-4 "
              >
                {/* {!loader && (
                  <button
                    className="btn bg-primary-200 hover:bg-primary-200 text-white mx-2 my-2 shrink-0  w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                    onClick={() => {
                      if (!loader) {
                        history.push("/home");
                      }
                    }}
                  >
                    Finish later
                  </button>
                )} */}

                {currentStep > 1 && !loader && (
                  <button
                    //flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400
                    className="btn bg-primary-500 hover:bg-primary-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                    // to="/onboarding-02"
                    onClick={() =>
                      currentStep > 1 ? setStep(currentStep - 1) : null
                    }
                  >
                    &lt;- Previous Step
                  </button>
                )}
                {!checked ? (
                  <>
                    {currentStep < steps.length && currentStep > 1 ? (
                      <button
                        className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                        // to="/onboarding-02"
                        onClick={() => {
                          setStep(currentStep + 1);
                        }}
                      >
                        Step {currentStep + 1} -&gt;
                      </button>
                    ) : (
                      <>
                        {/* {loader ? ( */}
                        <div class="flex justify-center items-center">
                          {/* <img
                              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                              src={Spinner}
                              width="32"
                              height="32"
                              alt="loading.."
                            /> */}
                        </div>

                        {/* <button
                          className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                          exact
                          onClick={onFinish}
                        >
                          {loader && (
                            <Skeleton
                              type="spinner"
                              color="gray"
                              fill="purple"
                              forButton={true}
                            />
                          )}{" "}
                          Finish -&gt;
                        </button> */}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {
                      loader ? (
                        <div class="flex justify-center items-center">
                          {/* <img
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          src={Spinner}
                          width="32"
                          height="32"
                          alt="loading.."
                        /> */}
                          <Skeleton type="spinner" color="gray" fill="purple" />
                        </div>
                      ) : null
                      // <button
                      //   className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                      //   exact
                      //   onClick={onFinish}
                      // >
                      //   <Skeleton
                      //     type="spinner"
                      //     color="gray"
                      //     fill="purple"
                      //     forButton={true}
                      //   />{" "}
                      //   Finish -&gt;
                      // </button>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
          {/* </Fade> */}
        </div>
      </div>
    </div>
  );
}

export default Onboard;
