import React from "react";
import Tip from "../components/Tip";

const PaymentAgreement = () => {
  const introMessage = "Dear Vendor Community,";
  const paymentMessage = (
    <>
      {
        "In order to continue providing our services, we have begun charging a small commission on winning bids for any bid request created on or after June 10, 2024.  Please note that this commission is separate from our Featured Vendor product.  Any fees or commissions paid for the Featured Vendor product is separate from the commission described below. Please review the following payment terms and conditions. If you have any questions, please reach out to us at "
      }
      <a className="text-brand-800" href="mailto:support@breachlink.com">
        support@breachlink.com
      </a>
      {"."}
    </>
  );

  return (
    <div className="p-6">
      <html>
        <head></head>
        <body>
          <div className="pb-4">
            <Tip noIcon>
              {" "}
              <p> {introMessage}</p>
              <p className="mt-4 leading-relaxed">{paymentMessage}</p>
            </Tip>
          </div>
          <div className="">
            <p>
              <span className="font-semibold text-lg mb-2">Definitions:</span>
            </p>
            <ol className="space-y-4" start="1">
              <li>
                <span className="font-semibold">Commission: </span>
                <span>
                  The commission is a fee that Breachlink earns for facilitating
                  the opportunity between the requestor and the vendor for a
                  particular{" "}
                </span>
                <span className="font-semibold">Event</span>
                <span>. Breachlink charges a commission on the total </span>
                <span className="font-semibold">Billable Amounts</span>
                <span>&nbsp;arising from the </span>
                <span className="font-semibold">Event</span>
                <span>
                  , which includes the bid request and any future services
                  rendered by the vendor related to the{" "}
                </span>
                <span className="font-semibold">Event </span>
                <span>that triggered this bid request.</span>
              </li>
              <li>
                <span className="font-semibold">Commission Amount:</span>
                <span>
                  &nbsp;The specific percentage owed to Breachlink based on the{" "}
                </span>
                <span className="font-semibold">Billable Amounts</span>
                <span>&nbsp;for a particular </span>
                <span className="font-semibold">Event</span>
                <span>. &nbsp;Currently, the commission amount is</span>
                <span className="font-semibold">&nbsp;</span>
                <span className="font-semibold">5%</span>
                <span>&nbsp;for all </span>
                <span className="font-semibold">Billable Amounts</span>
                <span>.</span>
              </li>
              <li>
                <span className="font-semibold">Event:</span>
                <span>
                  &nbsp;An &ldquo;event&rdquo; refers to any specific occurrence
                  that prompts a requestor to need services. Examples include
                  specific occurrences of cyber incidents, breaches, legal
                  matters, class actions, regulatory enforcement actions, or
                  other needs or desires for services.
                </span>
              </li>
              <li>
                <span className="font-semibold">Quoted Amount:</span>
                <span>
                  &nbsp;The total amount of the bid related to this bid request.
                  &nbsp;This is not the Billable Amounts and is not used when
                  Breachlink bills the vendor.
                </span>
              </li>
              <li>
                <span className="font-semibold">Billable Amounts:</span>
                <span>
                  &nbsp;The total value of all fees billed by the vendor or on
                  behalf of the vendor as a result of the facilitation of this
                  opportunity or{" "}
                </span>
                <span className="font-semibold">Event</span>
                <span>. This amount may be higher or lower than the</span>
                <span className="font-semibold">&nbsp;Quoted Amount</span>
                <span>.</span>
              </li>
              <li>
                <span className="font-semibold">Breachlink Invoice Date:</span>
                <span>
                  &nbsp;The date that Breachlink initially bills for any amount.
                </span>
              </li>
              <li>
                <span className="font-semibold">Vendor Invoice Date: </span>
                <span>
                  The date that the Vendor initially invoices its client.{" "}
                </span>
              </li>
            </ol>
            <p>
              <span></span>
            </p>
            <p>
              <span></span>
            </p>
            <p>
              <span></span>
            </p>
          </div>
          <div className="mt-12">
            <p>
              <span className="font-semibold text-lg mb-2">Payment Terms:</span>
            </p>
            <ol className="space-y-4" start="1">
              <li>
                <span className="font-semibold">Invoicing: &nbsp;</span>
                <span>Breachlink invoices vendors the </span>
                <span className="font-semibold">Commission Amount</span>
                <span>&nbsp;for all reported </span>
                <span className="font-semibold">Billable Amounts</span>
                <span>&nbsp;at the end of each month. </span>
              </li>
              <li>
                <span className="font-semibold">Payment Schedule:</span>
                <span>&nbsp;Payment must be made within 14 days of the </span>
                <span className="font-semibold">Breachlink Invoice Date </span>
                <span>
                  unless otherwise noted in the invoice. &nbsp;Payments not made
                  within this period may incur a late fee of{" "}
                </span>
                <span className="font-semibold">1.5% per month</span>
                <span>&nbsp;on the outstanding balance.</span>
              </li>
              <li>
                <span className="font-semibold">Reporting</span>
                <span className="font-semibold">:</span>
                <span>
                  &nbsp;It is the vendor&#39;s responsibility to provide
                  accurate reporting on the{" "}
                </span>
                <span className="font-semibold">Billable Amounts </span>
                <span>and </span>
                <span className="font-semibold">Vendor Invoice Date</span>
                <span>
                  . &nbsp;Vendor is required to report to Breachlink the{" "}
                </span>
                <span className="font-semibold">Billable Amounts</span>
                <span>&nbsp;at the time of </span>
                <span>invoicing the client</span>
                <span>&nbsp;</span>
                <span className="font-semibold">(Vendor Invoice Date)</span>
                <span>.</span>
                <span>
                  &nbsp;Failure to report both accurately and on time may result
                  in the{" "}
                </span>
                <span className="font-semibold">Billable Amount</span>
                <span>&nbsp;being treated as late from the original </span>
                <span className="font-semibold">Vendor Invoice Date</span>
                <span>
                  . &nbsp;To simplify this, the vendor may opt to include{" "}
                </span>
                <span>
                  <a>billable_reporting@breachlink.com</a>
                </span>
                <span>&nbsp;when emailing an invoice. &nbsp;</span>
              </li>
              <li>
                <span className="font-semibold">
                  Currency and Payment Method:
                </span>
                <span>
                  &nbsp;The currency and payment method are indicated in the
                  invoice along with payment instructions.
                </span>
              </li>
              <li>
                <span className="font-semibold">Audit Rights:</span>
                <span>
                  &nbsp;Breachlink reserves the right to audit and verify the
                  final{" "}
                </span>
                <span className="font-semibold">Billable Amounts</span>
                <span>&nbsp;and </span>
                <span className="font-semibold">Vendor Invoice Date</span>
                <span>
                  &nbsp;with the requestor, vendor, and vendor&rsquo;s client.
                </span>
              </li>
              <li>
                <span className="font-semibold">Disputes</span>
                <span className="font-semibold">:</span>
                <span>&nbsp;Any disputes regarding the </span>
                <span className="font-semibold">Invoice Amount</span>
                <span>&nbsp;or </span>
                <span className="font-semibold">Breachlink Invoice Date</span>
                <span>
                  &nbsp;must be reported to Breachlink within 10 days of
                  receiving the first invoice on a specific event.
                  &nbsp;Breachlink does not get involved in disputes between
                  Vendor and its Client.{" "}
                </span>
              </li>
            </ol>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span></span>
            </p>
          </div>
        </body>
      </html>
    </div>
  );
};

export default PaymentAgreement;
