import { Icon } from "@iconify/react";
import React, { useState } from "react";

const RadioOptionButtonsLarge = ({
  creationMethod,
  setCreationMethod,
  //   handleSelectCreationMethodClick,
  templates,
  options,
}) => {
  //   const [creationMethod, setCreationMethod] = useState("create-new");

  const handleSelectCreationMethodClick = (id) => {
    console.log("handleSelectCreationMethodClick called", id);
    setCreationMethod(id);
  };

  return (
    <ul className="grid w-full gap-6 grid-cols-1 lg:grid-cols-2">
      {options &&
        options.map((option) => (
          <li
            id={`radio-group-buttons-large-new-solicitation-${option.id}`}
            key={option.id}
            className="relative flex items-center p-4 bg-white rounded-lg "
            //   onClick={() => handleSelectCreationMethodClick(option.id)}
          >
            <input
              type="radio"
              id={option.id}
              name="new-solicitation"
              value={option.id}
              checked={creationMethod === option.id}
              onClick={() => handleSelectCreationMethodClick(option.id)}
              className="hidden peer"
              required
            />
            <label
              htmlFor={option.id}
              className="inline-flex min-h-full items-center justify-between w-full p-5 hover:shadow-xl text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50 "
            >
              <div className="w-full grid grid-cols-6 gap-6">
                <div className="col-span-1 flex items-center">
                  <Icon icon={option.icon} className="h-full w-full" />
                </div>
                <div className="flex col-span-5  justify-between">
                  <div className="block">
                    <div className=" w-full text-lg font-semibold ">
                      {option.name}
                    </div>
                    <div className="">{option.description}</div>
                  </div>
                  <div className="flex items-center justify-ends"></div>
                </div>
              </div>
            </label>
            {/* //   <span className="absolute inset-0" aria-hidden="true"></span>
        //   <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-white bg-blue-500 rounded-md">
        //     <Icon icon={option.icon} />
        //   </div>
        //   <div className="ml-4">
        //     <p className="text-sm font-medium text-gray-900">{option.name}</p>
        //     <p className="text-sm text-gray-500">{option.description}</p>
        //   </div> */}
          </li>
          //   <li className="min-h-full">
          //   <input
          //     type="radio"
          //     id="create-new"
          //     name="new-solicitation"
          //     value="create-new"
          //     checked={creationMethod === "create-new"}
          //     onClick={() => handleSelectCreationMethodClick("create-new")}
          //     className="hidden peer"
          //     required
          //   />

          //   <label
          //     htmlFor="create-new"
          //     className="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50 "
          //   >
          //     <div className="w-full grid grid-cols-6 gap-6">
          //       <div className="col-span-1 flex items-center">
          //         <Icon icon="heroicons:document-plus" className="h-full w-full" />
          //       </div>
          //       <div className="flex col-span-5  justify-between">
          //         <div className="block">
          //           <div className=" w-full text-lg font-semibold">
          //             Start from scratch
          //           </div>
          //           <div className="">
          //             Get started right away if your needs are unique.
          //           </div>
          //         </div>
          //         <div className="flex items-center justify-ends">

          //         </div>
          //       </div>
          //     </div>
          //   </label>
          // </li>

          // <li className="min-h-full">
          //   <input
          //     type="radio"
          //     id="template"
          //     name="new-solicitation"
          //     value="template"
          //     checked={creationMethod === "template"}
          //     className="hidden peer"
          //     required
          //     onClick={() => {
          //       handleSelectCreationMethodClick("template");
          //     }}
          //   />

          //   <label
          //     htmlFor="template"
          //     className="inline-flex min-h-full items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50 "
          //   >
          //     <div className="w-full grid grid-cols-6 gap-6">
          //       <div className="col-span-1 flex items-center">
          //         <Icon
          //           icon="heroicons:document-duplicate"
          //           className="h-full w-full"
          //         />
          //       </div>
          //       <div className="flex col-span-5  justify-between">
          //         <div className="block">
          //           <div className=" w-full text-lg font-semibold">
          //             Create from a saved template
          //           </div>
          //           <div className="">
          //             The fastest option if you have templates.
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </label>
          // </li>
        ))}
    </ul>
  );
};

export default RadioOptionButtonsLarge;
