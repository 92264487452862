import { useHistory } from "react-router-dom";

// Inside your component

import PageHeader from "../../components/PageHeader";

export default function Reports(props) {
  const history = useHistory();
  const reports = [
    {
      id: 1,
      name: "Win Report",
      description: "This report shows all the vendor wins in the system.",
      url: "/reports/win-report",
    },
    {
      id: 2,
      name: "Bid Requests",
      description: "Report on bid requests",
      url: "/reports/bid-request-report",
    },
  ];
  return (
    <div
      id="page_container"
      className=" w-full max-w-14xl max-h-[calc(100vh-100px)]"
    >
      {" "}
      {/* Page header */}
      <div className="pb-4">
        <div className="pb-2 md:flex md:items-center md:justify-between md:space-x-5  ">
          <div className="flex items-center">
            <div>
              <PageHeader>Reports</PageHeader>
            </div>
          </div>
          {/* <Filters
              filterOptions={filterOptions}
              filters={filters}
              setFilters={setFilters}
              handleChangeFilter={handleChangeFilter}
            /> */}
        </div>
        {/* <div className="flex items-center  w-full">
          {tabsContainer &&
            tabsContainer.length > 0 &&
            tabsContainer.map((tabs, index) => {
              return (
                <div className="flex text-sm items-center" key={index}>
                  <Tabs
                    key={index}
                    tabs={tabs.tabs}
                    //sort tabs.tabs alphabetically by name
                    // .sort((a, b) => (a.name > b.name ? 1 : -1))}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                  />
                </div>
              );
            })}
        </div> */}
      </div>
      <div className="flex items-center gap-x-4 gap-y-4">
        {reports.map((report) => (
          <div
            key={report.id}
            className="hover:cursor-pointer hover:border hover:border-brand-500 flex flex-col items-center p-4 h-48 w-48 bg-white shadow-md rounded-md justify-center text-center"
            onClick={() => history.push(report.url)}
          >
            <div className="p-4 rounded-full h-12 w-12 bg-purple-200"></div>
            <div className="mt-4">
              <h2 className="font-semibold">{report.name}</h2>
              <p className="text-sm text-gray-500">{report.description}</p>
              {/* <a href={report.url}>View Report</a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
