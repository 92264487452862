import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useEffect, useRef, useState } from "react";
import Button from "../elements/Button";
import Tip from "./Tip";

import { _impediments } from "../modules/impediments";
import { _auth } from "../modules/_auth";
import { _assessment } from "../modules/_assessment";
import { valid } from "node-html-parser";

const SearchSuggest = (props) => {
  const {
    // question,
    // handleFieldChange,
    // updated,
    // setUpdated,
    // showAutoSuggest,
    // setShowAutoSuggest,
    // autoSuggestResults,
    // setAutoSuggestResults,
    // activeOption,
    // setActiveOption,
    // dropdown,
    // timer,
    type,
    initialData,
    executeSearch,
    handleListMouseOver,
    isClaimed,
    handleClaimImpediment,
    // setSearchValue,
  } = props;

  const [updated, setUpdated] = useState({});
  const [modified, setModified] = useState(false);
  // const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  const [toggleValue, setToggleValue] = useState(false);
  const [autoSuggestResults, setAutoSuggestResults] = useState([]);
  const [autoSuggestLoading, setAutoSuggestLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showAutoSuggest, setShowAutoSuggest] = useState(false);
  // const [question, setQuestion] = useState({});
  const question = props.question;
  const index = props.index;
  const create = props.create ? props.create : false;
  const createQuestion = props.createQuestion ? props.createQuestion : false;
  const competencies = props.competencies ? props.competencies : [];
  const [activeOption, setActiveOption] = useState({});
  const [validEmail, setValidEmail] = useState(false);
  // const [claimed, setClaimed] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const profile = _auth.getUserProfile();
  let timer;
  let autoSuggestTimer;

  useEffect(() => {
    // (async () => {
    console.log("Autosuggest searchValue", searchValue);
    let data;
    if (autoSuggestTimer) {
      clearTimeout(autoSuggestTimer);
    }

    if (searchValue.length < 3) {
      setAutoSuggestResults([]);
      setShowAutoSuggest(false);
      setActiveOption({});
      return;
    }

    if (autoSuggestTimer < 300) {
      return;
    }
    // setClaimed(false);

    data = //filter initialData
      initialData &&
      initialData.filter((item) => {
        return (
          item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.email.toLowerCase().includes(searchValue.toLowerCase())
        );
      });

    if (data) {
      console.log("autocomplete", data);
      autoSuggestTimer = setTimeout(() => {
        data && data.length > 0 && setAutoSuggestResults(data);
        setActiveOption(data[0]);
        setShowAutoSuggest(true);
      }, 300);
    } else {
      autoSuggestTimer = setTimeout(() => {
        console.log("No data");
        setActiveOption({});
        setAutoSuggestResults([]);
        showAutoSuggest(false);
      }, 300);
    }
    // })();
  }, [searchValue]);
  useEffect(() => {
    setValidEmail(props.isValidEmail);
  }, [props.isValidEmail]);
  useEffect(() => {
    setSearchValue(props.searchInput);
  }, [props.searchInput]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      console.log("clickHandler", target, dropdown.current);
      if (!dropdown.current) return;
      if (
        !showAutoSuggest ||
        dropdown.current.contains(target)
        // ||
        // trigger.current.contains(target)
      )
        return;
      setShowAutoSuggest(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!showAutoSuggest || keyCode !== 27) return;
      setShowAutoSuggest(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    console.log("Initial data changed", props.initialData);
  }, [props.initialData]);

  useEffect(() => {
    setUpdated(props.question);
    // setQuestion(props.question);
  }, [props.question]);

  useEffect(() => {
    if (JSON.stringify(question) != JSON.stringify(updated)) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [updated]);

  useEffect(() => {
    console.log("updated changed", updated);
  }, [updated]);

  useEffect(() => {
    if (deleteConfirmed == true) {
    }
  }, [deleteConfirmed]);

  const handleSelect = (value, name) => {
    // console.log("handleSelect handleSelect", value, name);
    let options = [];
    if (name == "type") {
      if (value == "Multiple Choice") {
        console.log("handleSelect MC", value, name);
        options = [null, "", "", ""];
      }
    }
    if (name == "best_answer") {
      if (type == "True/False") {
        options = [true, false];
      }

      if (type == "Yes/No") {
        options = ["Yes", "No", "Unsure"];
      }
    }
    console.log("Updating questions", options);
    props.updateQuestion(props.index, {
      answer_options: options,
    });

    setUpdated({
      ...updated,
      [name]: value,
      answer_options: options,
    });
  };

  const handleFieldChange = (e, q) => {
    // console.log("handleFieldChange", e.target, index);
    console.log("handleFieldChange", e.target, index);
    let { name, value, type, f } = e.target;
    if (name == "Title") {
      console.log("autocomplete setSearchValue", value);
      setSearchValue(value);
    }
    // save updated question in updated state
    setUpdated({
      ...updated,
      [name]: value,
    });
  };

  return (
    <div className="relative w-full">
      <div className="absolute z-10 bg-white">
        {/* <label
        htmlFor="name"
        className="block text-sm font-medium text-slate-700"
      >
        Title
      </label>
      <div className="relative">
        <input
          type="text"
          name={`Title`}
          id={`${question?._id}`}
          onChange={(e) => handleFieldChange(e, question)}
          defaultValue={question?.description}
          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm"
        />

        <div className="absolute inset-y-0 right-3 flex items-center">
          {showAutoSuggest && (
            <XMarkIcon
              className="cursor-pointer inline h-5 w-5 text-gray-400"
              onClick={(e) => {
                setSearchValue("");
                setUpdated({
                  ...updated,
                  Title: "",
                });
                setUpdated({
                  ...updated,
                  Description: "",
                });
                setShowAutoSuggest(false);
                setAutoSuggestResults([]);
              }}
            />
          )}
        </div>
      </div>{" "} */}
        {showAutoSuggest && (
          <Transition
            show={showAutoSuggest}
            tag="div"
            className="z-10 max-h-100 top-full left-0 w-full bg-white border border-gray-200 py-3 rounded shadow-lg  mt-1"
            enter="transition ease-out duration-100 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-100"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
          >
            {
              <div
                ref={dropdown}
                className="font-medium text-sm text-slate-600"
                // onFocus={() => setShowAutoSuggest(true)}
                // onBlur={() => setShowAutoSuggest(false)}
              >
                {" "}
                <ul
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-item-3"
                  // className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  className="flex  focus:outline-none sm:text-sm"
                >
                  <div
                    className="flex flex-col max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
                  >
                    {" "}
                    {autoSuggestResults && autoSuggestResults.length > 0 ? (
                      <>
                        <div className="border-b mx-3 pb-2">
                          <h2 className="flex flex-row mb-2 mt-2 text-xs font-semibold text-gray-500">
                            POSSIBLE MATCHES
                          </h2>

                          <Tip className="" color="secondary">
                            To view the details of a search result, click on the
                            result. To add the vendor to your invite list, click
                            the Add to Invite button.
                          </Tip>
                        </div>
                      </>
                    ) : (
                      <h2 className="flex flex-row mx-3 mb-2 mt-2 text-xs font-semibold text-gray-500">
                        NO MATCHES FOUND IN OUR MARKETPLACE, TYPE AN EMAIL TO
                        INVITE A VENDOR
                      </h2>
                    )}
                    {autoSuggestResults.map((result, resultIdx) => (
                      <li
                        id="listbox-item-0"
                        role="option"
                        className={`text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 space-y-3 cursor-pointer hover:bg-gray-50
                    bg-${
                      activeOption &&
                      activeOption._id &&
                      result &&
                      result._id &&
                      result._id == activeOption._id
                        ? "gray-100"
                        : "white"
                    }
                    `}
                        key={result.id}
                        // onMouseEnter={() => {
                        //   setActiveOption(result);
                        // }}
                        onMouseOver={(e) => {
                          handleListMouseOver(e, result);
                        }}
                        onMouseOut={() => {
                          clearTimeout(timer);
                        }}
                        onClick={() => setActiveOption(result)}
                      >
                        <div className="flex justify-between">
                          <div id="list" className=" items-center ">
                            <div className=" font-normal  font-semibold">
                              {result.title}
                            </div>
                            <div className="font-normal   text-gray-500 ">
                              {result.previewText || result.content}
                            </div>
                          </div>
                          {/* <div>
                        <PlusIcon
                          className=" h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                      </div> */}
                        </div>
                      </li>
                    ))}
                  </div>
                  {activeOption &&
                    Object.keys(activeOption).length > 0 &&
                    autoSuggestResults &&
                    autoSuggestResults?.length > 0 && (
                      <div className=" w-1/2 flex-none flex-col border-l border-gray-200 divide-y divide-gray-100 overflow-y-auto sm:flex">
                        <div className="flex flex-auto flex-col justify-between px-4">
                          <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                            <div className="flex justify-between">
                              {/* <dt>IM-{"activeOption.Index"}</dt> */}
                              {/* {activeOption.Competency.length > 0 && (
                                <dd className="flex items-center bg-accent-600 bg-opacity-50 px-1 text-accent-800 rounded text-xs">
                                  <span>
                                    {"activeOption.Competency[0].key" + "  "}{" "}
                                    &nbsp;&nbsp;
                                    {"activeOption.Competency[0].Name"}
                                  </span>
                                </dd>
                              )} */}
                            </div>

                            <h2 className="text-base font-semibold">
                              {activeOption.title}
                            </h2>
                            <div className="font-normal   text-gray-500 ">
                              {activeOption.content}
                            </div>
                          </dl>
                          <Button
                            color={
                              "secondary"
                              //   isClaimed(activeOption) ? "gray" : "secondary"
                            }
                            // disabled={claimed ? true : false}
                            size="small"
                            handleClick={(e) => {
                              e.preventDefault();
                              props.handleSearchSuggestClick(
                                activeOption.email
                              );
                              console.log(
                                "handleSearchSuggestClick",
                                activeOption.email
                              );
                            }}
                            // type="button"
                            // className="mt-6 w-full rounded-md bg-brand-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                          >
                            {/* {isClaimed(activeOption)
                              ? "Remove from my team"
                              : "Add to my team"} */}
                            {props.mainButtonText}
                          </Button>
                        </div>
                      </div>
                    )}
                </ul>
                <div className="pt-3 mt-4 border-t border-gray-200 text-center">
                  {validEmail && autoSuggestResults.length == 0 && (
                    <Button
                      color="link"
                      handleClick={(e) => {
                        setSearchValue("");
                        //   setUpdated({
                        //     ...updated,
                        //     Title: "",
                        //   });
                        //   setUpdated({
                        //     ...updated,
                        //     Description: "",
                        //   });
                        props.onAdd();
                        setShowAutoSuggest(false);
                        setAutoSuggestResults([]);
                      }}
                    >
                      {props.bottomButtonText}
                    </Button>
                  )}
                </div>
              </div>
            }
          </Transition>
        )}
      </div>
    </div>
  );
};

export default SearchSuggest;
