import { Fragment, useEffect, useMemo, useState } from "react";

export default function JurisdictionInput({
  create,
  newProject,
  project,
  onFieldChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,

  scope,
}) {
  const [selectedJurisdictions1, setSelectedJurisdictions1] = useState([]);
  const [jurisdiction, setJurisdiction] = useState("");
  const [total, setTotal] = useState(0);
  const [totalError, setTotalError] = useState(false);
  const [jurisdictionError, setJurisdictionError] = useState(false);
  const [jurisdictionList, setJurisdictionList] = useState([]);
  const [jurisdictionListError, setJurisdictionListError] = useState(false);
  const [jurisdictionListLoading, setJurisdictionListLoading] = useState(false);
  useEffect(() => {
    if (selectedJurisdictions) {
      setSelectedJurisdictions1(selectedJurisdictions);
    }
  }, [selectedJurisdictions]);
  useEffect(() => {
    create !== false ? (create = true) : (create = false);
  }, [create]);

  return (
    <div className="grid grid-cols-2 gap-4">
      {selectedJurisdictions1 &&
        selectedJurisdictions1.map((jurisdiction, index) => {
          console.log("jurisdiction", jurisdiction);
          return (
            <div className="sm:col-span-1 col-span-1">
              <fieldset>
                <div className=" -space-y-px rounded-md bg-white shadow-sm">
                  <div></div>
                  <div className="flex -space-x-px">
                    <div className="w-1/2 min-w-0 flex-1">
                      <label htmlFor="affected-individuals" className="sr-only">
                        Jurisidiction
                      </label>
                      <input
                        type="text"
                        name="affected-individuals"
                        id="affected-individuals"
                        className="relative block w-full rounded-none rounded-bl-md rounded-tl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                        placeholder={
                          jurisdiction.key ? jurisdiction.key : jurisdiction
                        }
                        value={
                          jurisdiction.key ? jurisdiction.key : jurisdiction
                        }
                        disabled
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <label htmlFor="card-cvc" className="sr-only">
                        CVC
                      </label>
                      <input
                        type="text"
                        name="card-cvc"
                        id="card-cvc"
                        disabled={!create}
                        className="relative block w-full rounded-none rounded-br-md rounded-tr-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                        placeholder="Total"
                        value={jurisdiction.value}
                        onChange={(e) =>
                          onFieldChange(field, e, jurisdiction, service)
                        }
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          );
        })}
    </div>
  );
}
