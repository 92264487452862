import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _competencies = {
  async getCompetencies() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      companyId: profile.companyId,
    };

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/competencies/get", data);
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/competencies/get", response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },

  async getCompetencyList() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      team_id: profile.teamId ? profile.teamId : "63c0494e5c0c7375425c061c",
    };

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "competencies/get-competency-list",
        data
      );
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/get-competency-list", response.data.data);
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },

  async getTeamCompetencies() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      // team_id: profile.teamId ? profile.teamId : "63c0494e5c0c7375425c061c",
    };

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-team-competencies",
        data
      );
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/competencies/get-team-competencies", response.data.data);
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getTeamCompetenciesFromSurvey() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
      team_id: profile.tenantId,
    };

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-latest-team-competencies",
        data
      );
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log(
          "/competencies/get-latest-team-competencies",
          response.data.data
        );
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getGlobalCompetenciesFromSurvey() {
    let profile = _auth.getUserProfile();
    let data = {
      companyId: profile.companyId,
    };

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "/competencies/get-global-competencies-summary",
        data
      );
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log(
          "/competencies/get-global-competencies-summary",
          response.data.data
        );
        return response.data.data.resArray;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getCompetencyAreas() {
    let profile = _auth.getUserProfile();

    let data = {
      tenantId: profile.tenantId,
    };

    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/competencies/get", data);
      //   console.log("/impediments/get", response);
      if (response && response.data) {
        console.log("/competencies/get", response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
};
