import React, { useEffect } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import Input from "./Input";
import { utils } from "../modules/_utils";
import {
  PricingInput,
  RadioGroupSingle,
} from "../pages/projects/components/DataEntry";
import ComboboxSelector from "./ComboBoxSelector";
import Button from "../elements/Button";
import RadioGroupCards from "./RadioGroupCards";
import { BILLING_INTERVAL, BILLING_RECURRENCE } from "../constants/constants";
import Tabs from "../pages/component/Tabs";
import ToggleButtons from "./ToggleButtons";
import { set } from "date-fns";
import { valid } from "node-html-parser";
import { Icon } from "@iconify/react";

export default function InputWithDropdown(props, ...rest) {
  const {
    indicator,
    response,
    field,
    lineItem,
    lineItemOptions,
    unitOptions,
    value,
    label,
    fullQuote,
    handleDeselect,
    handleClearAllSelections,
    handlePriceChange,
    handleSelectLineItem,
    service,
  } = props;

  const className = props.className ? props.className : "";
  let inputClassName = `appearance-none block w-full px-3 py-2 border border-gray-300 
  rounded shadow-sm placeholder-primary-400 
  focus:outline-none focus:border-1 focus:border-brand-500  
  focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500
  focus:ring-0
  sm:text-sm 
  hover:border-1 hover:border-brand-500  sm:text-sm hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 ${className}`;
  const [lineItemType, setLineItemType] = React.useState("");
  const [inputDropdownValue, setInputDropdownValue] = React.useState();
  const [value2, setValue2] = React.useState();
  const [billingRecurrence, setBillingRecurrence] = React.useState("One Time");
  const [billingInterval, setBillingInterval] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState("");

  useEffect(() => {
    console.log("USEEFFECT setInputDropdownValue", value);
    if (value && value !== undefined) {
      setInputDropdownValue(value);
    } else {
      setInputDropdownValue({
        unit: "",
        price: "",
      });
    }
  }, [value]);

  useEffect(() => {
    console.log("USEEFFECT checkinputDropdownValue", inputDropdownValue);
    //validate that it has a unit and a price
    if (
      inputDropdownValue &&
      inputDropdownValue.unit &&
      inputDropdownValue.price &&
      lineItem &&
      //line item doesn't start with tmp
      lineItem.substring(0, 3) !== "tmp"
    ) {
      console.log(
        "USEEFFECT checkinputDropdownValue PASSED",
        inputDropdownValue,
        lineItem,
        lineItemType
      );
      setIsValid(true);
      // handlePriceChange(inputDropdownValue, service, lineItemType);
    } else {
      console.log(
        "USEEFFECT checkinputDropdownValue FAILED",
        inputDropdownValue,
        lineItem,
        lineItemType
      );
      setIsValid(false);
      setValidationMessage("Please select a unit, price, and service.");
    }
  }, [inputDropdownValue, lineItem, lineItemType]);

  useEffect(() => {
    setLineItemType(lineItem);
  }, [lineItem]);

  useEffect(() => {
    inputDropdownValue?.recurrence &&
      inputDropdownValue?.recurrence == "One Time" &&
      //remove billing interval if one time
      setInputDropdownValue({
        ...inputDropdownValue,
        billing_interval: "",
      });
  }, [inputDropdownValue?.recurrence]);

  const handleChangeInputDropdownValue = (field, value) => {
    console.log("handleChangeInputDropdownValue", field, value);
    let values = { ...inputDropdownValue };
    values[field] = value;

    setInputDropdownValue(values);
    handlePriceChange(values, service, lineItemType);
  };

  const selectLineItem = (v) => {
    setLineItemType(v);
    handleSelectLineItem(v);
  };

  return props.version == 1 ? (
    <div className="w-full bg-gray-50 border-2 border-dashed border-gray-200 p-6 rounded-sm">
      <div className="flex justify-between items-center text-lg font-medium">
        <div className="flex  space-x-8">
          <label
            htmlFor={field}
            className="flex items-center text-sm font-medium text-gray-700"
          >
            {" "}
            {lineItemType &&
            //first three characters are not tmp
            lineItemType.substring(0, 3) !== "tmp" ? (
              // !== "tmp"

              <span className="block text-lg font-medium text-gray-700">
                {label ? label : lineItemType}
              </span>
            ) : (
              <span className="block w-full text-sm font-medium text-gray-700">
                {label ? (
                  label
                ) : (
                  <ComboboxSelector
                    name={"unit"}
                    options={lineItemOptions}
                    textbutton={true}
                    buttonType={"pricing_item"}
                    fieldType={"single"}
                    open={true}
                    //   handleSelect={(v) => handleSelect(v, service, "unit")}
                    //   handleSelect={(v) => {
                    //     return handleChangeInputDropdownValue("line_item", v);
                    //   }}
                    handleSelect={(v) => {
                      selectLineItem(v);
                    }}
                    handleDeselect={handleDeselect}
                    handleClearAllSelections={(value) =>
                      handleClearAllSelections(value, service, "line_item")
                    } //{handleClearAllSelections}
                    // value={opts2[field] ? opts2[field] : null}
                    value={() => {
                      return (
                        inputDropdownValue && inputDropdownValue["line_item"]
                      );
                    }}
                  />
                )}
              </span>
            )}
          </label>

          <div className="flex">
            <div className="flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              {value?.type}
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {lineItemType !== "base" && (
            <Button
              handleClick={(e) => props.deleteLineItem(e)}
              color="link"
              size="small"
            >
              Delete
            </Button>
          )}
        </div>
      </div>

      <div className="mt-1 col-span-2 flex rounded-md shadow-sm relative">
        {indicator && (
          <div className="pb-2 items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
            $
          </div>
        )}
        <div className="w-1/2 pb-1 items-end text-xl  absolute inset-y-0 right-20 flex pl-3">
          <div className="w-full py-2 flex items-center px-2 text-gray-500 text-base divide-x">
            <div className="px-3 w-1/4">per</div>
            <div className="px-3 w-3/4">
              <ComboboxSelector
                name={"unit"}
                options={unitOptions}
                textbutton={true}
                buttonType={"dropdown"}
                fieldType={"single"}
                open={true}
                // handleSelect={(v) => handleSelect(v, service, "unit")}
                handleSelect={(v) => {
                  return handleChangeInputDropdownValue("unit", v);
                }}
                handleDeselect={handleDeselect}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "unit")
                } //{handleClearAllSelections}
                // value={opts2[field] ? opts2[field] : null}
                value={() => {
                  return inputDropdownValue && inputDropdownValue["unit"];
                }}
              />
            </div>
          </div>
        </div>
        <input
          id="price"
          name="price"
          type="text"
          autoComplete="price"
          disabled={fullQuote && fullQuote.status == "submitted"}
          required
          className="block w-full min-w-full max-w-full pl-10 pr-40 py-3 placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
          //   placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
          value={inputDropdownValue && inputDropdownValue["price"]}
          onChange={(e) => {
            handleChangeInputDropdownValue("price", e.target.value);
            // onFieldChange(e, service);
          }}
        />
      </div>
      <div className=" flex items-center  space-x-20">
        <div>
          {/* <Tabs tabs={BILLING_RECURRENCE} /> */}
          <ToggleButtons
            text1={"One Time"}
            text2={"Recurring"}
            value={inputDropdownValue && inputDropdownValue["recurrence"]}
            onToggleChange={(selection) => {
              console.log("selection", selection);
              handleChangeInputDropdownValue("recurrence", selection);
            }}
          />
        </div>
        <div className="flex space-x-3">
          {inputDropdownValue?.recurrence == "Recurring" && (
            <>
              <div>per</div>
              <ComboboxSelector
                name={"billing_interval"}
                options={BILLING_INTERVAL}
                textbutton={true}
                buttonType={"interval"}
                fieldType={"single"}
                open={true}
                // handleSelect={(v) => handleSelect(v, service, "unit")}
                handleSelect={(v) => {
                  return handleChangeInputDropdownValue("billing_interval", v);
                }}
                handleDeselect={handleDeselect}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "billing_interval")
                } //{handleClearAllSelections}
                // value={opts2[field] ? opts2[field] : null}
                value={() => {
                  return (
                    inputDropdownValue && inputDropdownValue["billing_interval"]
                  );
                }}
              />
            </>
          )}
        </div>
        <div className="flex items-center">
          {!isValid && (
            <div className="flex items-center text-sm text-red-500">
              <ExclamationCircleIcon className="h-5 w-5 mr-1" />
              {validationMessage}
            </div>
          )}
        </div>
      </div>
      {/* <div>{JSON.stringify(inputDropdownValue)}</div> */}
    </div>
  ) : props.version == 2 ? (
    <div className="w-full bg-gray-50 border-2 border-dashed border-gray-200 px-6 pb-2 rounded-sm">
      <div
        id="top_line"
        className="flex justify-between items-center text-lg  mb-2"
      >
        <div className="flex  space-x-8">
          <div className="flex">
            <div className="flex items-center text-sm  ">
              {value?.type} Pricing
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {lineItemType !== "base" && (
            <Button
              handleClick={(e) => props.deleteLineItem(e)}
              color="link"
              size="small"
            >
              <Icon icon="mdi:remove" className="h-5 w-5 mr-1" />
              <span className="">Delete this line item</span>
            </Button>
          )}
        </div>
      </div>
      <div
        id="pricing_line"
        className="flex flex-col xl:grid xl:grid-cols-12 gap-x-4"
      >
        <div id="line_item" className="flex col-span-2">
          <label
            htmlFor={field}
            className="flex items-center text-sm font-medium text-gray-700"
          >
            {" "}
            {lineItemType &&
            //first three characters are not tmp
            lineItemType.substring(0, 3) !== "tmp" ? (
              // !== "tmp"

              <span className="block text-md font-semibold text-gray-700">
                {label ? label : lineItemType}
              </span>
            ) : (
              <span className="block w-full text-sm font-medium text-gray-700">
                {label ? (
                  label
                ) : (
                  <ComboboxSelector
                    name={"pricing_item"}
                    options={lineItemOptions}
                    textbutton={true}
                    buttonType={"pricing_item"}
                    fieldType={"single"}
                    open={true}
                    //   handleSelect={(v) => handleSelect(v, service, "unit")}
                    //   handleSelect={(v) => {
                    //     return handleChangeInputDropdownValue("line_item", v);
                    //   }}
                    handleSelect={(v) => {
                      selectLineItem(v);
                    }}
                    handleDeselect={handleDeselect}
                    handleClearAllSelections={(value) =>
                      handleClearAllSelections(value, service, "line_item")
                    } //{handleClearAllSelections}
                    // value={opts2[field] ? opts2[field] : null}
                    value={() => {
                      return (
                        inputDropdownValue && inputDropdownValue["line_item"]
                      );
                    }}
                  />
                )}
              </span>
            )}
          </label>
        </div>
        <div
          id="input-bundle"
          className="flex mt-1 h-10 col-span-3 flex items-center rounded-md shadow-sm relative"
        >
          {indicator && (
            <div className="col-start-1 pb-2 items-end text-xl pointer-events-none absolute -inset-y-1 left-0 flex pl-3">
              $
            </div>
          )}
          <div className="pb-1 items-end text-xl  absolute -inset-y-1  right-2 flex pl-3">
            <div className="w-full py-2 flex items-center px-2 text-gray-500 text-base divide-x">
              <div className="px-3 ">per</div>
              <div className="px-3">
                <ComboboxSelector
                  name={"unit"}
                  options={unitOptions}
                  textbutton={true}
                  buttonType={"unit"}
                  fieldType={"single"}
                  open={true}
                  // handleSelect={(v) => handleSelect(v, service, "unit")}
                  handleSelect={(v) => {
                    return handleChangeInputDropdownValue("unit", v);
                  }}
                  handleDeselect={handleDeselect}
                  handleClearAllSelections={(value) =>
                    handleClearAllSelections(value, service, "unit")
                  } //{handleClearAllSelections}
                  // value={opts2[field] ? opts2[field] : null}
                  value={() => {
                    return inputDropdownValue && inputDropdownValue["unit"];
                  }}
                />
              </div>
            </div>
          </div>
          <input
            id="price"
            name="price"
            type="text"
            autoComplete="price"
            disabled={fullQuote && fullQuote.status == "submitted"}
            required
            className="block h-full col-span-full w-full min-w-full max-w-full pl-10 pr-40  placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
            //   placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
            value={inputDropdownValue && inputDropdownValue["price"]}
            onChange={(e) => {
              handleChangeInputDropdownValue("price", e.target.value);
              // onFieldChange(e, service);
            }}
          />
        </div>

        <div className="col-span-6 h-10  flex items-center ">
          <ToggleButtons
            size={"md"}
            text1={"One Time"}
            text2={"Recurring"}
            value={inputDropdownValue && inputDropdownValue["recurrence"]}
            onToggleChange={(selection) => {
              console.log("selection", selection);
              handleChangeInputDropdownValue("recurrence", selection);
            }}
          />

          {inputDropdownValue?.recurrence == "Recurring" && (
            <>
              <div className="mx-6">per</div>
              <ComboboxSelector
                name={"billing_interval"}
                options={BILLING_INTERVAL}
                textbutton={true}
                buttonType={"interval"}
                fieldType={"single"}
                open={true}
                className="block w-full"
                // handleSelect={(v) => handleSelect(v, service, "unit")}
                handleSelect={(v) => {
                  return handleChangeInputDropdownValue("billing_interval", v);
                }}
                handleDeselect={handleDeselect}
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "billing_interval")
                } //{handleClearAllSelections}
                // value={opts2[field] ? opts2[field] : null}
                value={() => {
                  return (
                    inputDropdownValue && inputDropdownValue["billing_interval"]
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
      <div id="bottom_line" className="mt-2">
        <div className="flex items-center">
          {!isValid && (
            <div className="flex items-center text-sm text-red-500">
              <ExclamationCircleIcon className="h-5 w-5 mr-1" />
              {validationMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : props.version == 3 ? (
    <div className="bg-gray-50 w-full">
      <div
        id="top_line"
        className="flex justify-between items-center text-lg  mb-2"
      >
        <div className="flex  space-x-8">
          <div className="flex">
            <div className="flex items-center text-sm  ">
              {value?.type} Pricing
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {lineItemType !== "base" && (
            <Button
              handleClick={(e) => props.deleteLineItem(e)}
              color="link"
              size="small"
            >
              <Icon icon="mdi:remove" className="h-5 w-5 mr-1" />
              <span className="">Delete this line item</span>
            </Button>
          )}
        </div>
      </div>
      <table className="w-full">
        {/* <tr className="w-full">
          <th className="border border-gray-200">Line Item</th>
          <th className="border border-gray-200">Qty</th>
          <th className="border border-gray-200">Price</th>
          <th className="border border-gray-200">Units</th>
          <th className="border border-gray-200">Recurring</th>
          <th className="border border-gray-200">Billing Interval</th>
          <th className="border border-gray-200">Total Invervals</th>
          <th className="border border-gray-200">Total Price</th>
        </tr>{" "} */}
        <tr className="w-full">
          <td className="px-1 border border-gray-200">
            {" "}
            <label
              htmlFor={field}
              className="flex items-center text-sm font-medium text-gray-700"
            >
              {" "}
              {lineItemType &&
              //first three characters are not tmp
              lineItemType.substring(0, 3) !== "tmp" ? (
                // !== "tmp"

                <span className="block text-md font-semibold text-gray-700">
                  {label ? label : lineItemType}
                </span>
              ) : (
                <span className="block w-full text-sm font-medium text-gray-700">
                  {label ? (
                    label
                  ) : (
                    <ComboboxSelector
                      name={"pricing_item"}
                      options={lineItemOptions}
                      textbutton={true}
                      buttonType={"pricing_item"}
                      fieldType={"single"}
                      open={true}
                      //   handleSelect={(v) => handleSelect(v, service, "unit")}
                      //   handleSelect={(v) => {
                      //     return handleChangeInputDropdownValue("line_item", v);
                      //   }}
                      handleSelect={(v) => {
                        selectLineItem(v);
                      }}
                      handleDeselect={handleDeselect}
                      handleClearAllSelections={(value) =>
                        handleClearAllSelections(value, service, "line_item")
                      } //{handleClearAllSelections}
                      // value={opts2[field] ? opts2[field] : null}
                      value={() => {
                        return (
                          inputDropdownValue && inputDropdownValue["line_item"]
                        );
                      }}
                    />
                  )}
                </span>
              )}
            </label>
          </td>
          <td className="px-1 border border-gray-200">Qty</td>
          <td className="px-1 border border-gray-200">
            {" "}
            <div
              id="input-bundle"
              className="flex mt-1 h-10 col-span-3 flex items-center rounded-md shadow-sm relative"
            >
              {indicator && (
                <div className="col-start-1 pb-2 items-end text-xl pointer-events-none absolute -inset-y-1 left-0 flex pl-3">
                  $
                </div>
              )}

              <input
                id="price"
                name="price"
                type="text"
                autoComplete="price"
                disabled={fullQuote && fullQuote.status == "submitted"}
                required
                className="block h-full col-span-full w-full min-w-full max-w-full pl-10 placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                //   placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
                value={inputDropdownValue && inputDropdownValue["price"]}
                onChange={(e) => {
                  handleChangeInputDropdownValue("price", e.target.value);
                  // onFieldChange(e, service);
                }}
              />
            </div>
          </td>
          <td className="px-1 border border-gray-200">
            {" "}
            <div className="pb-1 items-end text-xl flex pl-3">
              <div className="w-full py-2 flex items-center px-2 text-gray-500 text-base divide-x">
                <div className="px-3">
                  <ComboboxSelector
                    name={"unit"}
                    options={unitOptions}
                    textbutton={true}
                    buttonType={"dropdown"}
                    fieldType={"unit"}
                    open={true}
                    // handleSelect={(v) => handleSelect(v, service, "unit")}
                    handleSelect={(v) => {
                      return handleChangeInputDropdownValue("unit", v);
                    }}
                    handleDeselect={handleDeselect}
                    handleClearAllSelections={(value) =>
                      handleClearAllSelections(value, service, "unit")
                    } //{handleClearAllSelections}
                    // value={opts2[field] ? opts2[field] : null}
                    value={() => {
                      return inputDropdownValue && inputDropdownValue["unit"];
                    }}
                  />
                </div>
              </div>
            </div>
          </td>
          <td className="px-1 border border-gray-200">
            <ToggleButtons
              size={"sm"}
              text1={"One Time"}
              text2={"Recurring"}
              value={inputDropdownValue && inputDropdownValue["recurrence"]}
              onToggleChange={(selection) => {
                console.log("selection", selection);
                handleChangeInputDropdownValue("recurrence", selection);
              }}
            />
          </td>
          <td className="px-1 border border-gray-200">
            {" "}
            {inputDropdownValue?.recurrence == "Recurring" && (
              <>
                <div className="mx-6">per</div>
                <ComboboxSelector
                  name={"billing_interval"}
                  options={BILLING_INTERVAL}
                  textbutton={true}
                  buttonType={"interval"}
                  fieldType={"single"}
                  open={true}
                  className="block w-full"
                  // handleSelect={(v) => handleSelect(v, service, "unit")}
                  handleSelect={(v) => {
                    return handleChangeInputDropdownValue(
                      "billing_interval",
                      v
                    );
                  }}
                  handleDeselect={handleDeselect}
                  handleClearAllSelections={(value) =>
                    handleClearAllSelections(value, service, "billing_interval")
                  } //{handleClearAllSelections}
                  // value={opts2[field] ? opts2[field] : null}
                  value={() => {
                    return (
                      inputDropdownValue &&
                      inputDropdownValue["billing_interval"]
                    );
                  }}
                />
              </>
            )}
          </td>
          <td className="px-1 border border-gray-200">Total Invervals</td>
          <td className="px-1 border border-gray-200">Total Price</td>
        </tr>
      </table>
    </div>
  ) : (
    <div>None</div>
  );
}
