import React, { useEffect, useState } from "react";
import TileCard from "../../components/TileCard";
import DialogBox from "../../components/Dialog";
import Input from "../../components/Input";
import { set } from "date-fns";
import Tabs from "../component/Tabs";
import { Connections } from "../../modules/connections";

const BusinessConnections = ({ profile }) => {
  const [connections, setConnections] = useState(
    profile.tenantInfo.connectedTenants
  );
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Connections");
  console.log("Connections", profile.tenantInfo.connectedTenants);
  useEffect(() => {
    console.log("Email", email);
  }, [email]);
  //   const connections = profile.tenantInfo.connectedTenants;
  const handleSendConnectionInvite = async (email) => {
    console.log("Sending invite to", email);
    let connection = {
      email: email,
      status: "pending",
    };
    // let response = true;
    let response = await Connections.inviteConnection(connection);
    if (response) {
      //   setConnections([
      //     ...connections,
      //     {
      //       _id: "123",
      //       tenantCompany: "Test Company",
      //       description: "This is a test company",
      //     },
      //   ]);
      setInviteSuccess(true);
      //   return true;
    }
  };
  return (
    <div className="">
      <div className="text-xl font-bold text-slate-800 mb-4">
        Breachlink Connect
      </div>
      <div className="text-base leading-relaxed text-slate-600">
        Connect is a powerful feature that enables you to work with people
        outside your company. To start, you will send an email to a contact at
        another company. Once they accept your invite, you will be able to share
        access to project.
      </div>
      {/* <div className="py-2">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={[
            {
              name: "Connections",
              href: "#",
              current: true,
            },
            {
              name: "Invitations",
              href: "#",
              current: true,
            },
          ]}
        />
      </div> */}
      <div className="mt-12 flex items-center gap-x-4 gap-y-4">
        <TileCard
          key={"add-new-connection"}
          title={"New Connection"}
          description={""}
          onClick={() => setInviteDialogOpen(true)}
          icon={"line-md:plus"}
        >
          Test
        </TileCard>
        {connections && connections.length > 0 ? (
          // &&
          // connections.some((tenant) => tenant.status === "active")
          connections.map((connection) => (
            <TileCard
              key={connection._id}
              title={connection.tenantCompany}
              status={connection.status}
              description={connection.description}
              onClick={() => console.log("Clicked on", connection._id)}
              icon={"carbon:collaborate"}
            />
          ))
        ) : (
          <div className="mt-12 text-xl text-slate-600"></div>
        )}
      </div>
      <DialogBox
        open={inviteDialogOpen}
        setOpen={setInviteDialogOpen}
        title="Invite a Business Partner"
        message="Enter the email address of the person you would like to invite to collaborate with you."
        OKbuttonText="Send Invite"
        CancelButton={true}
        handleConfirmClick={async (e) => {
          console.log("Confirm Clicked", email);
          handleSendConnectionInvite(email);
          //   setEmail("");
          //   return true;
        }}
        handleCancelClick={(e) => {
          setEmail("");
          setInviteSuccess(false);
          setInviteDialogOpen(false);
        }}
        icon={"line-md:plus"}
        iconColor={"text-brand-600"}
        iconBgColor={"bg-brand-100"}
        success={inviteSuccess}
        successMessage={
          "Invite sent. After the recipient accepts, they will appear in your list of business partners."
        }
      >
        {!inviteSuccess && (
          <Input
            type="text"
            autofill="off"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            label="Email address"
            placeholder="Email address"
            id="input"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            //   errorText={errorMessageEmail ? errorMessageEmail : undefined}
          />
        )}
      </DialogBox>
    </div>
  );
};

export default BusinessConnections;
