import React, { useState, useEffect } from "react";
import ModalBasic from "./ModalBasic";
import PdfViewer from "./PdfViewer";
import Skeleton from "./Skeleton";
import { Files } from "../modules/_files";
import { PaperClipIcon } from "@heroicons/react/24/outline";

export default function PDFViewerModal({ attachment, solicitation, fileType }) {
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileBlob, setSelectedFileBlob] = useState(null);
  const [loadFile, setLoadFile] = useState(false);

  const handleSetPdfModalOpen = (file) => {
    if (pdfModalOpen) {
      setPdfModalOpen(false);
      setSelectedFile(null);

      setSelectedFileName(null);
      setSelectedFileBlob(null);
      setLoadFile(false);
    } else {
      console.log("Setting selected file", file);
      setSelectedFile(file);
      getSelectedFile(file._id);
      setPdfModalOpen(true);
      setTimeout(() => {
        setLoadFile(true);
      }, 750);
    }
  };

  const getSelectedFile = async (id) => {
    if (id) {
      console.log("Getting file download link from server", id);
      const file = await Files.getFileById(
        id,
        fileType ? fileType : "solicitation",
        solicitation._id
      );
      console.log("Got file download link from server", file);
      // let name = file.url.split("%5c");
      // name = name[name.length - 1];
      let name = file.name;
      setSelectedFileName(name);
      console.log("Setting selected fileName", name);
      setSelectedFileBlob(file.url);
      return file.url;
    }
  };

  return (
    <>
      <li
        key={attachment && attachment.name}
        onClick={() => {
          handleSetPdfModalOpen(attachment);
        }}
        className="cursor-pointer hover:bg-gray-50 flex items-center justify-between py-3 pl-3 pr-4 text-sm"
      >
        <div className="flex w-0 flex-1 items-center">
          <PaperClipIcon
            className="h-5 w-5 flex-shrink-0 text-slate-400"
            aria-hidden="true"
          />
          <span className="ml-2 w-0 flex-1 truncate">
            {attachment && attachment.name}
          </span>
        </div>
        <div className="ml-4 flex-shrink-0"></div>
        <a
          // href={attachment.href}
          onClick={() => {
            handleSetPdfModalOpen(attachment);
          }}
          className="cursor-pointer font-medium text-brand-600 hover:text-brand-500"
        >
          View
        </a>
      </li>

      <ModalBasic
        id="pdf-viewer"
        modalOpen={pdfModalOpen}
        setModalOpen={(e) => handleSetPdfModalOpen(e)}
        title={selectedFile ? selectedFile.name : null}
        size={"lg"}
        scrollable
        showClose={true}
        // saveButtonText={"Save"}
        // saveButton={false}
        // saveButtonClick={saveTemplate}
        // handleCancelClick={(e) => handleShowCompareModalClick(e)}
        // showFooter={true}
      >
        {pdfModalOpen &&
          (!loadFile ? (
            <Skeleton type="loader" />
          ) : (
            <PdfViewer
              file={selectedFile ? selectedFile : {}}
              filename={selectedFileName ? selectedFileName : "Test"}
              url={selectedFileBlob ? selectedFileBlob : ""}
              selectedFileName={selectedFileName ? selectedFileName : "Test"}
              // file={selectedFile ? selectedFile : {}}
            />
          ))}
      </ModalBasic>
    </>
  );
}
