import React from "react";

export default function Badge(props) {
  const { children, color, className, ...rest } = props;
  //   const classes = useStyles();
  const getColor = (color) => {
    switch (color) {
      case "primary":
        return " bg-primary-100 text-primary-700";
      case "secondary":
        return " bg-brand-100  text-brand-700";
      case "tertiary":
        return " bg-tertiary-100  text-tertiary-700";
      case "accent":
        return " bg-accent-500  text-accent-700";
      case "purple":
        return " bg-purple-100 text-purple-700";
      case "green":
        return " bg-green-100  text-green-700";
      case "gray":
        return " bg-gray-100  text-gray-700";
      case "orange":
        return " bg-orange-100  text-orange-700";
      case "red":
        return " bg-red-100  text-red-700";
      case "light-purple":
        return " bg-primary-100  text-purple-500";
      case "lime":
        return " bg-lime-500  text-lime-700 ";
      case "white":
        return " bg-white border-gray-100  text-slate-100 ";
      case "text":
        return "text-slate-500  mx-3 p-1";
      case "link":
        return "text-blue-500 ";
      default:
        return " bg-primary-500 text-primary-700" + props.className;
    }
  };
  const getShape = (shape) => {
    switch (shape) {
      case "rounded":
        return "rounded";
      case "pill":
        return "rounded-full";
      case "square":
        return "rounded-sm";
      default:
        return "rounded-md";
    }
  };
  return (
    <div className="mb-2">
      <div
        className={`${getColor(color)} 
        ${getShape(props.shape ? props.shape : "rounded-md")}
        ${props.case ? props.case : null}
        
         whitespace-nowrap inline-flex items-center gap-x-1.5  px-1.5 py-0.5 text-xs font-medium `}
      >
        {children}
      </div>
    </div>
  );
}
