import { Icon } from "@iconify/react";
import Button from "../../elements/Button";
import { useEffect, useState } from "react";

export default function ConnectionSelector(props) {
  const { profile, action, removeConnection, addConnection } = props;
  const [project, setProject] = useState(props.project);
  useEffect(() => {
    console.log("ConnectionSelector useEffect", props.project);
    setProject(project);
  }, [props.project]);

  const showAddConnection = () => {
    return (
      <div className="mt-6">
        {
          //map profile.tenantInfo.connectedTenants
          profile &&
            profile.tenantInfo &&
            profile.tenantInfo.connectedTenants &&
            profile.tenantInfo.connectedTenants.length > 0 &&
            profile.tenantInfo.connectedTenants.some(
              (tenant) => tenant.status === "active"
            ) &&
            profile.tenantInfo.connectedTenants.map((el, i) => {
              console.log("ConnectionSelector el", el);
              return (
                <div key={i} className="flex items-center space-x-3">
                  <div className="flex items-center">
                    <span className="ml-2 mr-4 text-sm font-semibold text-slate-700">
                      {el.tenantCompany}
                    </span>
                  </div>
                  {props.project &&
                    console.log("project.allowedConnections", props.project)}
                  {props.project.allowedConnections &&
                  props.project.allowedConnections.find((item) => {
                    console.log(
                      "ConnectionSelector item.tenantId",
                      item.tenantId
                    );
                    console.log("ConnectionSelector el._id:", el._id);
                    return item.tenantId === el._id;
                  }) ? (
                    <div className="flex items-center ">
                      <Icon
                        icon="tabler:user-check"
                        className="h-5 w-5 text-green-500"
                      />
                      <span className="ml-2 mr-4 font-medium text-xs text-slate-500">
                        Added
                      </span>
                      <Button
                        size="xs"
                        rounded
                        color="secondary"
                        loader={false}
                        disabled={false}
                        handleClick={(e) => {
                          e.preventDefault();
                          console.log("Calling removeConnection", {
                            tenantId: el._id,
                            projectId: props.project._id,
                          });
                          removeConnection({
                            tenantId: el._id,
                            projectId: props.project._id,
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ) : (
                    <Button
                      size="xs"
                      rounded
                      color="secondary"
                      loader={false}
                      disabled={
                        //if project.allowedConnections contains el._id, disable the button
                        project.allowedConnections &&
                        project.allowedConnections.find(
                          (item) => item.tenantId === el._id
                        )
                          ? true
                          : false
                      }
                      handleClick={(e) => {
                        e.preventDefault();
                        console.log(
                          "Calling addConnection",
                          project._id,
                          el._id
                        );
                        addConnection({
                          tenantId: el._id,
                          projectId: props.project._id,
                        });
                        // addConnection(el._id);
                        // alert("Invite Connection");
                      }}
                    >
                      Add Connection
                    </Button>
                  )}
                </div>
              );
            })
        }
      </div>
    );
  };
  const showRemoveConnection = () => {
    return (
      <div className="mt-6">
        {props.project.allowedConnections &&
          props.project.allowedConnections.map((el, i) => {
            return (
              <div key={i} className="flex items-center space-x-3">
                <div className="flex items-center">
                  <span className="ml-2 mr-4 text-sm font-semibold text-slate-700">
                    {el.tenantCompany}
                  </span>
                </div>

                <Button
                  size="xs"
                  rounded
                  color="text"
                  loader={false}
                  disabled={
                    false
                    //   //if project.allowedConnections contains el._id, disable the button
                    //   project.allowedConnections &&
                    //   project.allowedConnections.find(
                    //     (item) => item.tenantId === el._id
                    //   )
                    //     ? true
                    //     : false
                  }
                  handleClick={(e) => {
                    e.preventDefault();
                    removeConnection({
                      tenantId: el.tenantId,
                      projectId: props.project._id,
                    });
                  }}
                >
                  <Icon icon="mdi:remove" className="mr-2" /> Remove Connection
                </Button>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      {action === "add" && project && showAddConnection()}
      {action === "remove" && project && showRemoveConnection()}
    </div>
  );
}
