/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { templates } from "../../modules/_templates";
import AssessmentSelect from "../AssessmentSelect";
import { Projects } from "../../modules/_projects";
import YesNoQuestions from "../../components/YesNoQuestions";
import RadioGroupCards from "../../components/RadioGroupCards";
import MultiSelectListBox from "../../components/MultiSelectListBox";
import { Jurisdictions } from "../../modules/_jurisdiction_list";
import ComboboxSelector from "../../components/ComboBoxSelector";
import { PlusIcon } from "@heroicons/react/20/solid";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import JurisdictionInput from "./components/JurisdictionInput";
import DatePicker from "react-datepicker";
import Tip from "../../components/Tip";
import QuestionToolTip from "../../components/QuestionToolTip";
import { _auth } from "../../modules/_auth";
import { Switch } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Button from "../../elements/Button";
import { set } from "date-fns";
import { Icon } from "@iconify/react/dist/iconify.js";
import { RadioGroupSingle } from "./components/DataEntry";
import RadioOptionButtonsLarge from "../../components/RadioOptionButtonsLarge";
import Input from "../../components/Input";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function NewProject(props) {
  const history = useHistory();
  const [blankProject, setBlankProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(props.step);
  const [stepName, setStepName] = useState("Start");
  const [newProject, setNewProject] = useState({});
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [projectState, setProjectState] = useState(null);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [emailCriteria, setEmailCriteria] = useState("");
  const [generatedCodename, setGeneratedCodename] = useState("");
  const profile = _auth.getUserProfile();
  useMemo(() => {
    const jurisdictions = Jurisdictions.getJurisdictions();
    const priority = [
      { id: 124, alpha2: "ca", alpha3: "can", name: "Canada" },
      {
        id: 826,
        alpha2: "gb",
        alpha3: "gbr",
        name: "United Kingdom",
      },
      { id: 840, alpha2: "us", alpha3: "usa", name: "United States" },
    ];
    //combine the two arrays
    const combined = [...priority, ...jurisdictions];

    setJurisdictionOptions(combined);
  }, []);
  useEffect(() => {
    (async () => {
      getCodename();
    })();
  }, []);

  const getCodename = async () => {
    const data = await Projects.getRandomCodename();
    console.log("getRandomCodename", data.codename, data._id);
    setNewProject({
      ...newProject,
      name: `Project ${data.codename}`,
      codenameId: data._id,
    });
    setGeneratedCodename(data.codename);
    //when saving if GeneratedCodename does not match the project.name, do not set the codename as used = true.
  };

  useEffect(() => {
    console.log("newProject changed", newProject);
  }, [newProject]);

  useEffect(() => {
    //if quotesAtBreachlink is false, clear the onBehalfOf field
    if (newProject.quotesAtBreachlink === false) {
      onFieldChange("onBehalfOf", "");
    }
  }, [newProject.quotesAtBreachlink]);

  useEffect(() => {
    if (props.modalOpen) {
      let blank = {};
      if (props.project) {
        blank = props.project;
      } else {
        blank = templates.project;
      }
      profile && profile.tenantType === "admin"
        ? //update newProject.isForTesting to true
          setNewProject({ ...blank, isForTesting: true })
        : //update newProject.isForTesting to false
          setNewProject({ ...blank, isForTesting: false });
    } else {
      setNewProject({});
    }
  }, [props.modalOpen]);

  useState(() => {
    setStep(props.step);
  }, [props.modalOpen]);

  // const handleSelect = async (value, impediment, v, obj) => {
  //   // setLoading(true);
  //   console.log("handleSelect Render", value, impediment, v, obj);
  //   const updated = await _impediments.updateImpedimentCompetencyId(
  //     impediment,
  //     value,
  //     impedimentId
  //   );

  //   if (updated) {
  //     console.log("Update Competency UPDATED", updated);
  //     const fieldsToUpdate = [
  //       // { field: "Competency", value: updated.Competency },
  //       {
  //         colName: "Competency._id",
  //         value: updated.Competency._id,
  //         rowIndex: props.index,
  //       },
  //       {
  //         colName: "Competency.Name",
  //         value: updated.Competency.Name,
  //         rowIndex: props.index,
  //       },
  //       {
  //         colName: "Competency.Area",
  //         value: updated.Competency.Area,
  //         rowIndex: props.index,
  //       },
  //       // { colName: "Assigned_to", value: updated.Competency.Name },
  //     ];
  //     setEdit(false);
  //     updateData(updated, impedimentId, props, fieldsToUpdate);
  //     setSelectedArea(obj.area);
  //     setSelectedCompetency(obj.name);
  //   }
  // };
  function isSelected(value) {
    // console.log("isSelected", value, selectedPerson);
    const selected = selectedJurisdictions
      ? selectedJurisdictions.find((el) => el === value)
        ? true
        : false
      : false;

    console.log("isSelected", selected, value, selectedJurisdictions);
    return selected;
  }
  const handleSelectedJurisdictions = (selected) => {
    console.log(selected, "selected jurisdiction");
    //updated state array selectedJurisdictions with new items from selected
    if (isSelected(selected)) {
      console.log(selectedJurisdictions, "if selected state jurisdiction");
      const selectedJurisdictionsUpdated = selectedJurisdictions.filter(
        (el) => el !== selected
      );
      console.log(selectedJurisdictions, "if selected state jurisdiction");
      setSelectedJurisdictions(selectedJurisdictionsUpdated);
    } else {
      setSelectedJurisdictions((prev) => [...prev, selected]);
      console.log(selectedJurisdictions, "else selected state jurisdiction");
      //remove jurisdiction from newProject
      const updated = { ...newProject };

      console.log("else handleSelected jurisdictions", newProject);
      delete updated?.jurisdictions[selected];
      setNewProject(updated);
    }

    console.log("handleSelectedJurisdictions", newProject);
  };
  const addConnection = async (id) => {
    //update newProject.connections with the new connection
    // must follow this format:
    // "allowedConnections": [
    //   {
    //     "tenantId": "65c799ca34578fe86a5f659f"
    //   }
    // ]
    setInviteLoading(true);
    setTimeout(() => {
      const updated = { ...newProject };
      if (!updated.allowedConnections) {
        updated.allowedConnections = [];
      }
      const connection = { tenantId: id, allowedUsers: [], status: "active" };
      updated.allowedConnections.push(connection);

      setNewProject(updated);
      setInviteLoading(false);
    }, 750);
  };
  // const handleSelectedJurisdictions = (selected) => {
  //   //updated state array selectedJurisdictions with new items from selected
  //   if (isSelected(selected)) {
  //     const selectedJurisdictionsUpdated = selectedJurisdictions.filter(
  //       (el) => el !== selected
  //     );
  //     setSelectedJurisdictions(selectedJurisdictionsUpdated);
  //   } else {
  //     setSelectedJurisdictions((prev) => [...prev, selected]);
  //     //remove jurisdiction from newProject
  //     const updated = { ...newProject };
  //     console.log("handleSelectedJurisdictions", updated.jurisdictions);
  //     delete updated.jurisdictions[selected];
  //     setNewProject(updated);
  //   }

  //   console.log("handleSelectedJurisdictions", newProject);
  // };
  const handleClearAllSelections = () => {
    setSelectedJurisdictions([]);
    setNewProject({ ...newProject, jurisdictions: [] });
  };
  function handleDeselectedJurisdictions(value) {
    console.log("handleDeselectedJurisdictions", value);
    //remove jurisdiction from newProject
    // const updated = { ...newProject };
    // console.log("handleDeselectedJurisdictions", updated.jurisdictions);
    // delete updated.jurisdictions[value];
    // setNewProject(updated);
  }

  const onFieldChange = (field, e, key) => {
    console.log("onFieldChange", field, e, key);
    setErr(false);
    setErrMessage("");
    const value = e.target && e.target.value !== undefined ? e.target.value : e;
    const newState = { ...newProject };
    //update the newState.jurisdictions array with the new key/value pair
    if (field && field == "jurisdictions") {
      if (!newState[field]) {
        newState[field] = [];
      }
      let jdArray = newState[field];
      const jd = { key: key, value: value };
      // if jdArray does not contain {key: key}, add it
      if (!jdArray.find((el) => el.key == key)) {
        jdArray.push(jd);
      } else {
        //if jdArray contains {key: key}, update the value
        const index = jdArray.findIndex((el) => el.key == key);
        jdArray[index].value = value;
      }

      console.log("newproject jd", jdArray);

      //update the newState with the new array
      newState[field] = jdArray;
      //add up the total of all the jurisdictions
      let total = 0;
      jdArray.forEach((el) => {
        total += parseInt(el.value);
      });
      newState["total_affected"] = total;
      setNewProject(newState);
    } else if (field && field == "deadline") {
      console.log("onFieldChange field == deadline");
      newState[field] = e;
      setNewProject(newState);
    } else {
      newState[field] = value;
      setNewProject(newState);
    }
    console.log("newproject", newProject);
  };

  const handleSelect = (field, value) => {
    console.log("handleSelect NewProject.js", field, value);
    //update state with new key/value pair
    const newState = { ...newProject };
    // newState["blurbs"][name] = value;
    newState[field] = value;
    setNewProject(newState);
  };

  const cleanUpState = () => {
    // alert(1);

    setStep(1);
    setErr(false);
    setErrMessage("");
    setSelectedJurisdictions([]);
    setProjectState(null);
    props.setModalOpen(false);
    setNewProject(null);
  };

  const isValid = (field) => {
    console.log("Check isValid", newProject, field);

    if (newProject && Object.keys(newProject).length === 0) {
      setErr(true);
      setErrMessage("You need to give your project a name before saving.");
      setLoading(false);
      return false;
    } else {
      console.log("isValid the newProject is not empty");
      return true;
    }
  };
  const onSave = async () => {
    setLoading(true);
    console.log("onSave");
    const valid = isValid("project");
    if (!valid) {
      setLoading(false);
    } else {
      console.log("isValid true", valid);
      console.log(
        "Compare newProject and generatedCodename",
        newProject.name,
        generatedCodename
      );
      // const updateCodename = newProject.name === generatedCodename;
      //check if the newProject.name contains the generatedCodename
      let checked = newProject.name.includes(generatedCodename);
      console.log("checked", checked);
      const saved = await Projects.create(newProject, checked);
      // if (newProject.name === generatedCodename) {
      //   const updatedCodename = await Projects.updateCodename(
      //     newProject.codenameId
      //   );
      // }
      if (saved) {
        console.log("Saved successfully", saved);
        props.updateData(saved);
        setProjectState(saved);
        // cleanUpState();

        // props.setModalOpen(false);
      }
    }

    //   //close the modal and show success notification
  };
  const getDisabledStatus = () => {
    //if step 2 and newProject.isInsured is not present, return true
    //if step 2 and newProject.isInsured is present and newProject.insurer is not present, return true
    //else return false
    let returnValue = false;
    if (step == 1) {
      if (newProject.name === undefined || newProject.name === "") {
        returnValue = true;
      }
      if (
        newProject.description === undefined ||
        newProject.description === ""
      ) {
        returnValue = true;
      }
      if (
        newProject.quotesAtBreachlink &&
        (!newProject.onBehalfOf || newProject.onBehalfOf === "")
      ) {
        returnValue = true;
      }
    } else if (step == 2) {
      if (newProject.isInsured === undefined) {
        returnValue = true;
        // return true;
      } else if (newProject.isInsured && !newProject.insurerName) {
        returnValue = true;
        // return true;
      } else {
        returnValue = false;
        // return false;
      }
    } else {
      returnValue = false;
      // return false;
    }
    return returnValue;
  };
  const handleSetNextStep = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      if (
        profile &&
        profile.tenantInfo &&
        profile.tenantInfo.connectedTenants &&
        profile.tenantInfo.connectedTenants.length > 0 &&
        profile.tenantInfo.connectedTenants.some(
          (tenant) => tenant.status === "active"
        )
      ) {
        setStep(4);
      } else {
        setStep(5);
      }
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      setStep(6);
    } else if (step === 6) {
      setStep(7);
    }
  };

  const handleSetPreviousStep = () => {
    //needs to be tested with invites
    if (step === 5) {
      if (
        profile &&
        profile.tenantInfo &&
        profile.tenantInfo.connectedTenants &&
        profile.tenantInfo.connectedTenants.length > 0 &&
        profile.tenantInfo.connectedTenants.some(
          (tenant) => tenant.status === "active"
        )
      ) {
        setStep(4);
      } else {
        setStep(3);
      }
    } else {
      setStep(step - 1);
    }
  };

  const handleExecuteNextSteps = (e) => {
    if (e === "invite_connections") {
      setStep(10);
    } else if (e == "close") {
      props.setModalOpen(false);
      cleanUpState();
    } else if (e == "project") {
      // navigate to project details and pass state
      history.push({
        pathname: "/projects/project-details/" + projectState._id,
        state: { project: projectState },
      });
    } else if (e == "create_bid_request") {
      cleanUpState();
      props.setModalOpen(false);
      props.handleCreateNewSolicitation(projectState);
    } else {
      alert(3);
      // return props.setModalOpen(false)
    }
  };

  return (
    <div className="flex flex-col h-full w-full ">
      <div className="">
        {step == 0 && (
          <div>
            <AssessmentSelect type="project" />
          </div>
        )}
        {(step == 1 || step == "project-basics") && (
          <div>
            <div>
              <h3 className="text-base font-semibold leading-6 text-slate-900">
                Project Basics
              </h3>
              <p className="mt-2 mb-6 text-sm text-slate-500">
                Enter the project details below.
              </p>
              <Tip text="Project details are never shared with vendors.">
                A project is any incident, breach, event, or matter that you
                need help with. The project is designed to help you manage the
                various stages and tasks associated with the event.
              </Tip>
              {/* <p className="mt-1 mb-6 text-sm text-slate-900"> */}
              {/* <span className="font-semibold">
                    Note: Project details are never shared with vendors.
                  </span> */}
              {/* </p> */}
              {/* <Tip text="Project details are never shared with vendors.">
                  Once a project is created, you will be able to create as many
                  bid requests as you need for the various stages of your
                  project.{" "}
                </Tip> */}

              {/* <p className="p-2 bg-brand-50 border-2 border-dashed border-brand-500 mt-3 mb-2 text-xs text-slate-600">
                  Tip: Use the Identifier field to reference the project in your
                  matter management system for future integration.
                </p> */}
            </div>

            <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
              {/* <div className="sm:col-span-1">
                <label
                  htmlFor="project_id"
                  className="block text-sm font-medium text-slate-700"
                >
                  File Number
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="project_id"
                    id="project_id"
                    autoComplete="none"
                    value={newProject.identifier}
                    onChange={(e) => onFieldChange("identifier", e)}
                    className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                </div>
              </div> */}
              <div className="sm:col-span-5">
                <label
                  htmlFor="project_name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Project name{" "}
                  <span className="text-sm text-red-500">(required)</span>
                  <QuestionToolTip
                    color="brand"
                    field="Project Name"
                    text="We've suggested a project codename.  If you like it, feel free to use it.  Keep in mind that this name is visible to vendors."
                  />
                  <span
                    onClick={getCodename}
                    className="text-xs text-brand-500  cursor-pointer hover:text-brand-600"
                  >
                    {" "}
                    Generate new codename
                  </span>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="project_name"
                    id="project_name"
                    autoComplete="none"
                    value={newProject.name}
                    onChange={(e) => onFieldChange("name", e)}
                    className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-1">
                <label
                  htmlFor="deadline"
                  className="block text-sm font-medium text-slate-700"
                >
                  Deadline
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <DatePicker
                    // selected={null}
                    showMonthDropdown
                    minDate={new Date()}
                    onSelect={(e) => onFieldChange("deadline", e)}
                    selected={newProject.deadline}
                    value={newProject.deadline}
                    onChange={(e) => onFieldChange("deadline", e)}
                    // calendarClassName="calendar"

                    className="block w-full min-w-full flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                  {/* <input
                      type="text"
                      name="deadline"
                      id="deadline"
                      autoComplete="none"
                      value={newProject.deadline}
                      onChange={(e) => onFieldChange("deadline", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    /> */}
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-slate-700"
                >
                  Description{" "}
                  <span className="text-sm text-red-500">(required)</span>
                  <QuestionToolTip
                    color="brand"
                    field="Description"
                    text="This field helps you identify your project in a descriptive way. This is not seen by vendors."
                  />
                </label>
                <p className="mt-2 text-sm text-slate-500">
                  Write a few sentences to describe the nature of the project.
                  These are your notes. They will not be shared with vendors.
                </p>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    placeholder="Example: NewCo Mfg 500GB Ransomware Attack"
                    rows={3}
                    value={newProject.description}
                    onChange={(e) => onFieldChange("description", e)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    defaultValue={""}
                  />
                </div>
              </div>
              {profile &&
                profile.role === "Super Admin" &&
                profile.tenantType === "admin" && (
                  <div className="flex items-center px-4 space-x-3 sm:col-span-6 border-t border-b border-gray-200 bg-gray-50 py-6">
                    <label
                      htmlFor="isForTesting"
                      className="flex items-center cursor-pointer font-medium"
                    >
                      This project is for testing/demo purposes only.
                    </label>
                    <Switch
                      id={"isForTesting"}
                      // checked={newProject.isForTesting}
                      checked={newProject.isForTesting}
                      onChange={(e) => onFieldChange("isForTesting", e)}
                      className={classNames(
                        newProject.isForTesting
                          ? "bg-brand-600"
                          : "bg-gray-200",
                        "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          newProject.isForTesting
                            ? "translate-x-3.5"
                            : "translate-x-0",
                          "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                )}
              {profile && profile.role === "Super Admin" && (
                <div className="flex items-center justify-start px-4 space-x-3 sm:col-span-6 border-t border-b border-gray-200 bg-gray-50 py-6">
                  <label
                    htmlFor="isForTesting"
                    className="flex items-center cursor-pointer font-medium"
                  >
                    This is a Quotes@Breachlink Project.
                  </label>
                  <Switch
                    id={"quotesAtBreachlink"}
                    checked={newProject.quotesAtBreachlink}
                    onChange={(e) => onFieldChange("quotesAtBreachlink", e)}
                    className={classNames(
                      newProject.quotesAtBreachlink
                        ? "bg-brand-600"
                        : "bg-gray-200",
                      "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        newProject.quotesAtBreachlink
                          ? "translate-x-3.5"
                          : "translate-x-0",
                        "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>

                  {newProject.quotesAtBreachlink && (
                    <div className="flex  items-center pl-12 space-x-4">
                      <label
                        htmForm="onBehalfOf"
                        className="block whitespace-nowrap font-medium text-slate-700"
                      >
                        On Behalf Of
                      </label>
                      <div className="w-96 flex items-center rounded-md shadow-sm">
                        <Input
                          type="email"
                          name="onBehalfOf"
                          id="onBehalfOf"
                          value={newProject.onBehalfOf}
                          onChange={(e) => onFieldChange("onBehalfOf", e)}
                          className="block w-full"
                          placeholder="Enter an email address or company name" //Enter a company name to search email addresses to invite
                        />
                        {/* <Input
                        type="text"
                        name="onBehalfOf"
                        id="onBehalfOf"
                        autoComplete="none"
                        value={newProject.onBehalfOf}
                        onChange={(e) => onFieldChange("onBehalfOf", e)}
                        className="block w-96  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                      /> */}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* <div className="sm:col-span-6">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}

              {/* </> */}
              {/* )} */}
            </div>
          </div>
          //   </div>
          // </div>
        )}
        {(step == 2 || step == "client information") && (
          <div className="">
            <div>
              <h3 className="text-base font-semibold leading-6 text-slate-900">
                Client Information
              </h3>
              <p className="mt-2 mb-4 text-sm text-slate-500">
                Please enter the details of the client and insurer below.
              </p>
              <Tip>
                Insurance and Carrier information is necessary so that vendors
                can provide preferred pricing if they are on applicable panels.
              </Tip>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="client_name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Client Company Name
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="client_name"
                    id="client_name"
                    autoComplete="none"
                    value={newProject.client}
                    onChange={(e) => onFieldChange("client", e)}
                    className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="client_email"
                  className="block text-sm font-medium text-slate-700"
                >
                  Client Contact Email{" "}
                  <span className="text-sm text-slate-500">(optional)</span>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="client_email"
                    id="client_email"
                    autoComplete="none"
                    value={newProject.clientContact}
                    onChange={(e) => onFieldChange("clientContact", e)}
                    className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className=" items-center space-x-3 col-span-6 border-t border-b border-gray-200 bg-gray-50 py-6">
                <div className="px-4">
                  {" "}
                  <label
                    htmlFor="insurer"
                    className="block text-md font-semibold text-slate-700"
                  >
                    Insurance information{" "}
                    <span className="text-sm text-slate-500">(required)</span>
                  </label>
                </div>
                <RadioGroupCards
                  name="isInsured"
                  defaultValue={null} //temp fix. need to map the values of newProject.services get newProject.services.service value
                  value={newProject.isInsured}
                  type={"single"}
                  // className="grid  gap-y-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  sm:gap-x-3"
                  onChange={(e) => onFieldChange("isInsured", e)}
                  options={[
                    {
                      id: 1,
                      title: "Insured",
                      description:
                        "This event is insured or partially insured.",
                      value: true,
                      highlighted: false,
                    },
                    {
                      id: 2,
                      title: "Uninsured",
                      description: "This event is not insured.",
                      value: false,
                    },
                  ]}
                />

                {/* <Switch
                  id={"isInsured"}
                  checked={newProject.isInsured}
                  onChange={(e) => onFieldChange("isInsured", e)}
                  className={classNames(
                    newProject.isInsured ? "bg-brand-600" : "bg-gray-200",
                    "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      newProject.isInsured
                        ? "translate-x-3.5"
                        : "translate-x-0",
                      "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch> */}
                {/* <span className="text-sm text-slate-500">(optional)</span> */}
              </div>
              {newProject.isInsured && (
                <>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="insurer"
                      className="block text-sm font-medium text-slate-700"
                    >
                      Carrier{" "}
                      <span className="text-sm text-slate-500">(required)</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="insurerName"
                        id="insurerName"
                        autoComplete="none"
                        value={newProject.insurerName}
                        onChange={(e) => onFieldChange("insurerName", e)}
                        className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="insurerContact"
                      className="block text-sm font-medium text-slate-700"
                    >
                      Carrier Email{" "}
                      <span className="text-sm text-slate-500">(optional)</span>
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="insurerContact"
                        id="insurerContact"
                        autoComplete="none"
                        value={newProject.insurerContact}
                        onChange={(e) => onFieldChange("insurerContact", e)}
                        className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {(step == 3 || step == "affected individuals") && (
          <div className="">
            <div>
              <h3 className="text-base font-semibold leading-6 text-slate-900">
                Affected Individuals
              </h3>
              <p className="mt-2 mb-4 text-sm text-slate-500">
                If you already know the number of affected individuals, enter
                this information below.
              </p>
              <Tip>
                This information will be needed for the vendors to give you
                accurate bids on mailing, emailing, call center, and credit
                monitoring services. If you don't know the total, you can leave
                this page blanks.
              </Tip>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="client_name"
                  className="inline text-sm font-medium text-slate-700"
                >
                  Jurisdictions
                </label>{" "}
                <QuestionToolTip
                  field="Jurisdictions"
                  text="Select each applicable jurisdication and enter the total number of affected individuals.  If you don't know, you can leave it blank or give it your best estimate."
                />
                {/* <span className="text-sm text-slate-500">
                    Select all the jurisdication where affected individuals live
                    and then enter the total in each jurisdiction.
                  </span> */}
                <div className="mt-1  flex rounded-md shadow-sm">
                  {console.log(newProject.jurisdictions, "jurisdiction")}
                  <ComboboxSelector
                    name="jurisdictions"
                    options={jurisdictionOptions}
                    fieldType="multiSelect"
                    open={true}
                    handleSelect={handleSelectedJurisdictions}
                    handleDeselect={handleDeselectedJurisdictions}
                    handleClearAllSelections={handleClearAllSelections}
                    value={selectedJurisdictions ? selectedJurisdictions : []}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="client_name"
                  className="inline text-sm font-medium text-slate-700"
                >
                  Total affected individuals
                </label>
                <QuestionToolTip
                  field="Total affected individuals"
                  text="This is the total number of affected individuals across all jurisdictions. You can enter the total if you know it, or just leave it blank and we will calculate it based on the jurisdictions you selected."
                />

                {/* <span className="block text-sm text-slate-500">
                    If you don't know the total, we can calculate it for you
                    based on the jurisdictions you selected.
                  </span> */}
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    placeholder="Auto calculated but you can modify it if necessary"
                    disabled={selectedJurisdictions.length > 0 ? true : false}
                    name="client_name"
                    id="client_name"
                    autoComplete="none"
                    value={newProject.total_affected}
                    onChange={(e) => onFieldChange("total_affected", e)}
                    className="block h-10 w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-6 col-span-6">
                <label
                  htmlFor="is_insured"
                  className="inline text-sm font-medium text-slate-700"
                >
                  {selectedJurisdictions &&
                    selectedJurisdictions.length > 0 &&
                    "Let's break down your affected entities by population."}
                </label>
              </div>
              <div className="sm:col-span-6">
                <JurisdictionInput
                  create={true}
                  newProject={newProject}
                  field={"jurisdictions"}
                  onFieldChange={onFieldChange}
                  setTotal={null}
                  selectedJurisdictions={selectedJurisdictions}
                  setSelectedJurisdictions={setSelectedJurisdictions}
                />
              </div>
            </div>
          </div>
        )}

        {(step == 4 || step == "invite connections") && (
          <div className="">
            <div>
              <h3 className="text-base font-semibold leading-6 text-slate-900">
                Invite Connections
              </h3>
              <p className="mt-2 mb-4 text-sm text-slate-500">
                If you've created business connections in your settings, you can
                invite users from the connected company to view your project.
              </p>
              <Tip>
                You can skip this step and invite them later, and you can revoke
                permission at any time.
              </Tip>
            </div>
            <div className="mt-6">
              {
                //map profile.tenantInfo.connectedTenants
                profile &&
                  profile.tenantInfo &&
                  profile.tenantInfo.connectedTenants &&
                  profile.tenantInfo.connectedTenants.length > 0 &&
                  profile.tenantInfo.connectedTenants.some(
                    (tenant) => tenant.status === "active"
                  ) &&
                  profile.tenantInfo.connectedTenants.map((el, i) => {
                    return (
                      <div key={i} className="flex items-center space-x-3">
                        <div className="flex items-center">
                          <span className="ml-2 mr-4 text-sm font-semibold text-slate-700">
                            {el.tenantCompany}
                          </span>
                        </div>
                        {newProject.allowedConnections &&
                        newProject.allowedConnections.find(
                          (item) => item.tenantId === el._id
                        ) ? (
                          <div className="flex items-center ">
                            <Icon
                              icon="tabler:user-check"
                              className="h-5 w-5 text-green-500"
                            />
                            <span className="ml-2 font-medium text-sm text-slate-500">
                              Added
                            </span>
                          </div>
                        ) : (
                          <Button
                            size="xs"
                            rounded
                            color="secondary"
                            loader={inviteLoading}
                            disabled={
                              //if newProject.allowedConnections contains el._id, disable the button
                              newProject.allowedConnections &&
                              newProject.allowedConnections.find(
                                (item) => item.tenantId === el._id
                              )
                                ? true
                                : false
                            }
                            handleClick={(e) => {
                              e.preventDefault();
                              addConnection(el._id);
                              // alert("Invite Connection");
                            }}
                          >
                            Add Connection
                          </Button>
                        )}
                      </div>
                    );
                  })
              }
            </div>
          </div>
        )}
        {(step == 5 || step == "final") && (
          <div className="h-full">
            <div>
              {projectState == null && (
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  That's everything we need
                </h3>
              )}

              {projectState == null && (
                <p className="mt-3 mb-2 text-sm text-slate-900">
                  After creating the project, you will be able to create bid
                  requests to begin receiving bids related to this project.
                </p>
              )}
              {/* <Tip>
                  To create a bid request later, just go to the project and
                  click Create Bid Request.
                </Tip> */}
              {/* <p className="mt-3 mb-2 text-sm text-slate-900">
                  Remember, nothing will be shared externally without your
                  permission.
                </p> */}
            </div>
            <div className="flex flex-col h-full justify-center items-center">
              <div className="col-span-6  flex items-center justify-center min-h-full h-full">
                <div className="text-center mt-20">
                  {
                    //if projectState is null, show the save project button
                    projectState == null ? (
                      <div id="save_project">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                          Save Project
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Click below to save your project.
                        </p>
                        <div className="mt-6">
                          <button
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                            onClick={(e) => {
                              e.preventDefault();
                              onSave();
                            }}
                          >
                            <FolderPlusIcon
                              className="-ml-0.5 mr-1.5 h-5 w-5"
                              aria-hidden="true"
                            />{" "}
                            Save Project
                          </button>
                          {err && (
                            <p className="mt-1 text-sm text-red-500">
                              {errMessage}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="pt-20">
                        <div className="pb-6">
                          <div className="flex items-center justify-center">
                            <CheckCircleIcon
                              className="h-12 w-12 text-emerald-600"
                              aria-hidden="true"
                            />
                          </div>
                          <h3 className="mt-2 text-xl font-semibold text-gray-900">
                            Project saved successfully
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            You can now create bid requests for this project.
                          </p>
                        </div>

                        <RadioGroupCards
                          name="next_step_options"
                          defaultValue={null} //temp fix. need to map the values of newProject.services get newProject.services.service value
                          value={null}
                          type={"single"}
                          // className="grid  gap-y-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  sm:gap-x-3"
                          onChange={(e) => handleExecuteNextSteps(e)}
                          options={[
                            // {
                            //   id: 0,
                            //   title: "Invite Business Partners",
                            //   description:
                            //     "Invite 3rd party collaborators to this project.",
                            //   value: "invite_connections",
                            //   highlighted: true,
                            // },
                            {
                              id: 1,
                              title: "Create a bid request",
                              description:
                                "Create a bid request to begin receiving bids related to this project.",
                              value: "create_bid_request",
                              highlighted: true,
                            },
                            {
                              id: 2,
                              title: "Go to my project",
                              description:
                                "Go to the main page for this project.",
                              value: "project",
                            },
                            {
                              id: 3,
                              title: "Close this window",
                              description:
                                "Close this window and return to your home page.",
                              value: "close",
                            },
                          ]}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* </form> */}
      <footer className="absolute pt-3 -ml-10 px-6 mt-auto w-full border-t bg-white  bottom-5 ">
        <div className="relative flex items-center justify-between ">
          <div className="flex justify-start ">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
              onClick={(e) => {
                e.preventDefault();
                props.setModalOpen(false);
                cleanUpState();
              }}
            >
              Discard
            </button>
          </div>
          <div className="flex justify-end gap-x-4">
            {step !== 1 && projectState == null && (
              <button
                className="cursor-pointer ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-200 py-2 px-4 text-sm font-medium text-brand-500 shadow-sm hover:text-brand-800 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                disabled={step === 1}
                onClick={(e) => {
                  e.preventDefault();
                  //change to handlePreviousStep
                  handleSetPreviousStep();
                  // setStep(step - 1);
                }}
              >
                Previous Step
              </button>
            )}

            {step !== 5 && (
              <Button
                color="secondary"
                disabled={getDisabledStatus()}
                // className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                handleClick={(e) => {
                  e.preventDefault();
                  handleSetNextStep();
                  // setStep(step + 1);
                }}
              >
                Next Step
              </Button>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}
