import React, { useEffect, useMemo, useState } from "react";

import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

import { _auth } from "../../modules/_auth";
import { audit } from "../../modules/_audit";
import { useStore } from "../../store/usestore";

import { _dashboard } from "../../modules/_dashboard";

import PageHeader from "../../components/PageHeader";
import { Context } from "../../layouts/store";
import { _navigation } from "../../modules/_navigation";
import IntroPage from "../home/Home";
import NotificationBanner from "../../components/NotificationBanner";
import Profile from "../team/Profile";
import SettingsContent from "../../partials/settings/SettingsContent";
import { Transition } from "@headlessui/react";
import ToolbarGroupItem from "../../components/react-form-builder/toolbar-group-item";
import CenterCard from "../../components/CenterCard";
import VendorProfileBuilder from "../projects/components/VendorProfileBuilder";
import { Preferences } from "../../modules/_user_preferences";
import ModalBasic from "../../components/ModalBasic";
import DoNotShowAgain from "../../components/DoNotShowAgain";
import VendorWelcomeComponent from "./VendorWelcomeComponent";

//test change

function VenderHome() {
  const location = useLocation();
  const history = useHistory();
  // const manageLoading = utils.manageLoading();
  // const isLoading = utils.isLoading();

  const { userAssessmentHistory, isSuccess, getUserAssessmentHistory } =
    useStore();
  // const profile = history.location.state.profile;
  const [showHome, setShowHome] = useState(true);
  const { pathname } = location;

  const user = _auth.getUserProfile();

  // OlD Dashboard

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [rotCardData, setRotCardData] = useState([]);
  const [topAssessmentTakers, setTopAssessmentTakers] = useState([]);
  const [topAssessments, setTopAssessments] = useState([]);
  const [todaysHistory, setTodaysHistory] = useState([]);

  const [gettingAssessment, setGettingAssessment] = useState(true);
  const [auth, setAuth] = useState(false);
  const [avgRisk, setAvgRisk] = useState();
  const [riskCounts, setRiskCounts] = useState();
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [impediments, setImpediments] = useState([]);
  const [score, setScore] = useState(0);
  const [effort, setEffort] = useState(0);
  const [maturityScore, setMaturityScore] = useState(0);
  const [maturityLevel, setMaturityLevel] = useState(0);
  const [topImpedimentsByScore, setTopImpedimentsByScore] = useState([]);
  const [topCompetenciesByScore, setTopCompetenciesByScore] = useState([]);
  const [topImpedimentsByEffort, setTopImpedimentsByEffort] = useState([]);
  const [topCompetenciesByEffort, setTopCompetenciesByEffort] = useState([]);
  const [breakEven, setBreakEven] = useState(0);
  const [burdenSummary, setBurdenSummary] = useState({});
  const [allCompetenciesByScore, setAllCompetenciesByScore] = useState([]);
  const [burndown, setBurndown] = useState([]);
  const [resolvedAndRemaining, setResolvedAndRemaining] = useState([]);

  const [sweetSpotCompetencies, setSweetSpotCompetencies] = useState([]);
  const [competencyLeaderBoard, setCompetencyLeaderBoard] = useState([]);
  const [tenantType, setTenantType] = useState("");
  const [showBanner, setShowBanner] = useState(
    sessionStorage.getItem("Show Dashboard Banner") !== "false" ? true : false
  );

  // New Dashboard
  const [projects, setProjects] = useState(null);
  const [solicitations, setSolicitations] = useState(null);
  const [projectsDueSoon, setProjectsDueSoon] = useState([]);
  const [projectsPastDue, setProjectsPastDue] = useState([]);
  const [projectsDueAndPastDue, setProjectsDueAndPastDue] = useState([]);
  const [topFiveDeadlines, setTopFiveDeadlines] = useState([]);
  const [loading, setLoading] = useState([]);
  const [upcomingExpirations, setUpcomingExpirations] = useState([]);
  const [topFiveExpirations, setTopFiveExpirations] = useState([]);
  const [allBids, setAllBids] = useState([]);

  const [showOptions, setShowOptions] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [showVendorWelcomeScreen, setShowVendorWelcomeScreen] = useState(false);
  const [profile, setProfile] = useState(user);

  useEffect(() => {
    if (showOptions == true) {
      setShowProfile(false);
    }
  }, [showOptions]);
  const homelinks = useMemo(() =>
    // _navigation.getHomeLinksByTenantType(
    //   profile && profile.tenantType ? profile.tenantType : "admin"
    // ),
    {
      return [
        {
          id: "0",
          status: "prod",
          title: "See bid requests",

          subTitle: "See and respond to your open bid requests",
          rightText: "",
          path: "/vendor/projects",

          // handleClick: () => {
          //   console.log("onclick called set up profile");
          //   setShowOptions(false);
          //   setShowProfile(true);
          //   //reference a function in the settings page
        },

        {
          id: "1",
          status: "prod",
          title: "Set up your marketplace profile",

          subTitle:
            "Set up your marketplace profile to start receiving bid requests",
          rightText: "",
          // path: "/settings/account/#",

          handleClick: () => {
            history.push({
              pathname: "/settings/account",
              state: {
                panel: "marketplace-profile",
                group: "company",
                tabName: "Marketplace Profile",
              },
            });
          },
        },
        {
          id: "2",
          status: "beta",
          title: "Subscribe to the marketplace",
          subTitle: "Subscribe to the marketplace so people can find you",
          rightText: "",
          handleClick: (e) => {
            console.log("onclick called", e);
            // props.handleOpenAssessmentModalFromHome(e);
          },
        },
        {
          id: "3",
          status: "beta",
          title: "Subscribe to the Featured Vendors list",
          subTitle:
            "Subscribe to the Featured Vendors list so people can find you",
          rightText: "",
          handleClick: (e) => {
            // props.handleOpenContactModal(e);
          },
        },
      ];
    }, [profile]);

  useEffect(() => {
    const showVendorWelcomeScreen = Preferences.getPreferenceValue(
      profile.preferences,
      "show-beta-window"
    );
    setShowVendorWelcomeScreen(showVendorWelcomeScreen);
  }, [profile]);

  return (
    <div>
      {user.tenantInfo.type == "vendor" && showBanner == true && (
        <div className="relative w-screen -ml-4 -mt-4">
          <NotificationBanner
            showClose={false}
            text={
              "This portal is currently under construction. We are working on creating an incredible vendor experience, but we can't do it without you. Please report any issues you find and send us your ideas. We want to hear from you!"
            }
          />
        </div>
      )}
      <div className=" overflow-x-hidden overflow-y-hidden">
        <Transition
          enter="transform transition ease-in-out duration-500 sm:duration-500 "
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={showOptions}
          as={"div"}
          afterLeave={() => {
            setShowOptions(false);
            setShowProfile(true);
            // setHideEmailDiv(false);
          }}
        >
          <IntroPage
            handleOpenAssessmentModalFromHome={null}
            handleOpenContactModal={null}
            options={homelinks}
          />
        </Transition>
        <Transition
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={showProfile}
          as={"div"}
          afterLeave={() => {
            setShowOptions(true);
            setShowProfile(false);
          }}
        >
          <CenterCard
            size={"lg"}
            headingText={"Marketplace Profile"}
            headingSubtext={
              "Tell customers about your company by setting up your marketplace profile."
            }
          >
            {/* <ModalBasic
              id="vendor-onboarding-modal"
              modalOpen={true}
              setModalOpen={null}
              title="Create a new bid request"
              showClose={true}
              showFooter={true}
              closeButtonText="Close"
              // size="lg"
              // scrollable={true}
            >
              {" "}
            </ModalBasic> */}
            <VendorProfileBuilder
              goBackHome={() => {
                console.log("VendorProfileBuilder Prop goBackHome Click");
                setShowOptions(true);
              }}
            />
          </CenterCard>
        </Transition>
      </div>
    </div>
  );
}

export default VenderHome;
