import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";
import { utils } from "./_utils";

const { postRequest, getRequest } = require("./ApiRequest");

export const _stripe = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(_auth.getGuestProfile());
    } else {
      return _auth.getUserProfile();
    }
  },
  stripePortalSession: async () => {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const profile = _auth.getUserProfile();

    let stripeCustomerId = await utils.getCacheData("id", "Stripe");
    let body = {
      customerId: stripeCustomerId,
      tenantId: profile.tenantId,
      companyId: profile.tenantCompanyId,
    };
    console.log("getCacheDataResp stripeCustomerId", stripeCustomerId);
    let url = await postRequest("/stripe/create-customer-portal-session", body);
    console.log("session", url.data.data.url);
    return url.data.data.url;
  },

  async getStripeData() {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // const profile = console.log("Getting Stripe Data for id");
    const stripeId = await this.getStripeCustomerId();
    // const stripeSubscriptionForTenant = await this.getSubscription();
    const stripeProducts = await this.getProducts(stripeId);
    console.log("stripeProducts", stripeProducts[0]);
    const stripeTier = this.getTier(stripeProducts[0]);
    const userLimit = 100;
    const employeeLimit = 200;
    console.log("stripeId", this.profile());
    const profile = this.profile();
    let body = {
      tenantId: profile.tenantId,
      companyId: profile.tenantCompanyId,
    };
    const stripeData = await postRequest("/stripe/get-stripe-data", body);
    console.log("Full stripeData", stripeData);

    let data = {
      id: stripeId,
      products: stripeProducts,
      tier: stripeTier,
      userLimit: userLimit,
      employeeLimit: employeeLimit,
      fullStripeData: stripeData.data.data,
    };
    console.log("Caching stripeData", data);
    utils.cacheData("Stripe", data);

    // console.log("stripeData", data);

    return data;
  },

  async getStripeCustomerId() {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const profile = _auth.getUserProfile();

    let body = {
      tenantId: profile.tenantId,
      companyId: profile.tenantCompanyId,
      email: profile.email,
      name: profile.name,
      company: profile.tenantCompany,
      profile: profile,
    };

    //check tenants table for stripe customer id by tenantId
    let response = await postRequest("/stripe/get-customer-id", body);
    console.log("getStripeCustomerId", response.data.data.customerId);
    return response.data.data.customerId;
  },

  async getProducts(id) {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let customerId;
    if (id) {
      customerId = id;
    } else {
      customerId = await this.getStripeCustomerId();
    }
    console.log("getStripeProducts", customerId);
    let response = await postRequest("/stripe/get-products", {
      customerId: customerId,
    });
    if (response) {
      console.log("response getStripeProducts", response.data.data);
      return response.data.data;
    } else {
      console.log("getStripeProducts No product found");
      return [];
    }
  },

  async getStripeCustomer() {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const id = "cus_MMpg2jjQKlbCOB";
    let response = await postRequest("/stripe/get-customer", { id });
    if (response) {
      console.log("Stripe Customer", response.data.data);
      return response.data.data;
    } else {
      console.log("No customer found");
      return [];
    }
  },

  getTier(productId) {
    let tiers = this.getTiers();
    if (tiers) {
      console.log("StripeProducts tiers", productId, tiers);
      let tier = tiers.find((t) => t.productId === productId);
      if (tier) {
        return tier.tier;
      } else return "Free";
    } else {
      return "Free";
    }
  },

  getTiers() {
    //move to database
    let tiers = [
      {
        tier: "Free",
        productId: "prod_MnjZVY3AgKLiEr",
      },
      {
        tier: "Grow",
        productId: "prod_MnjMHGT994ZrmL",
      },
      {
        tier: "Scale",
        productId: "prod_MnjMgQ8kVqKJqt",
      },
    ];
    return tiers;
  },

  async getSubscription() {
    const id = "cus_MMpg2jjQKlbCOB";
    let response = await postRequest("get-subscription", { id });
    if (response) {
      console.log("Stripe Subscription", response.data.data);
      return response.data.data;
    } else {
      console.log("No subscription found");
      return [];
    }
  },

  getEntitledFeatures(tier) {
    //filter out features that are not entitled
    let tmp = [];
    let features = this.features();
    let entitledFeatures = features.filter((feature) => {
      if (feature.tiers.includes(tier)) {
        tmp.push(feature);
        utils.cacheData("entitledFeatures", tmp);
        return true;
      } else {
        return false;
      }
    });
    return tmp;
  },

  async getAvailableStripeProductsAndPrices(tierType) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let data = {};

    data.tenantId = profile.tenantId;
    data.tenantType =
      profile.tenantType === "admin" ? "vendor" : profile.tenantType;
    data.type = tierType;
    data.companyId = profile.tenantCompanyId;
    console.log("Call API and pass data", data);

    let response = await postRequest("stripe/get-available-tiers", data);
    if (response) {
      console.log("get-available-tiers got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  getMyTier(products) {
    console.log("getMyTier products", products);
    //find the product that has the product.metadata.type === tier
    let tier = products.find((product) => {
      return product.metadata?.type === "tier";
    });
    console.log("getMyTier my tier", tier);
    return tier;
  },
  getMyAddons(products) {
    console.log("getMyAddons addons", products);
    //find the product that has the product.metadata.type === tier
    let addons = products.filter((product) => {
      return product.metadata?.type === "add-on";
    });
    console.log("getMyAddons my addons", addons);
    return addons ? addons : [];
  },
  async updateSubscription(data) {
    let profile = _auth.profile();
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;
    console.log("Call API and pass data", data);

    let response = await postRequest("stripe/update-subscription", data);
    if (response) {
      console.log("updateSubscription got response", response);
      return response;
    } else {
      return response;
    }
  },
};
