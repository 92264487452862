import Input from "../../../components/Input";
import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../elements/Button";
import { set } from "date-fns";
import { _company } from "../../../modules/_company";
import { Responses } from "../../../modules/_responses";
import Tip from "../../../components/Tip";
import { _auth } from "../../../modules/_auth";
import { _marketplace } from "../../../modules/_marketplace";
import AddToListComponent from "../../../components/AddToListComponent";
import UploadComponent from "../../component/UploadComponent";
import TextEditor from "../../../components/TextEditor";

export default function VendorProfileBuilder(props) {
  const profile = _auth.getUserProfile();
  const [fullQuote, setFullQuote] = useState({});

  const [loader, setLoader] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableCompanyField, setDisableCompanyField] = useState(false);
  const [data, setData] = useState({});
  const [company, setCompany] = useState({});
  const [displayLogo, setDisplayLogo] = useState();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  //   const { newProject, onFieldChange } = props;
  const { updateResponse, manageSaved } = props;
  useEffect(() => {
    (async () => {
      let company = await _company.getCompanyById(profile.tenantCompanyId);
      if (company) {
        company = company.company;
        // setCompany(company);
        console.log("Company found", company);
        console.log("Getting marketplace");
        let marketplace = await _marketplace.getMarketplaceByCompanyId(
          profile.tenantCompanyId
        );
        // console.log("This is what I found for marketplace", marketplace);
        if (marketplace) {
          console.log("Found marketplace, using", marketplace);
          setData(marketplace);
        } else {
          console.log("Couldn't find marketplace, using company instead");
          setData({
            companyId: company._id,
            title: company.companyName,
            email: company.companyEmail,
            contact: company.contact,
          });
        }
      } else {
        console.log("no company");
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      console.log("Looking for image", data.image);
      if (data.image !== undefined) {
        let logo = await _marketplace.getMarketplaceLogo(data.image);
        if (logo) {
          setDisplayLogo(logo);
        }
      }
    })();
  }, [data.image]);

  useEffect(() => {
    console.log("Data Changed -->", data);
  }, [data]);

  useEffect(() => {
    console.log("List Changed -->", data.services);
    setServices(data.services);
  }, [data]);

  const onSave = async () => {
    //get domain from email address
    setLoader(true);

    setTimeout(async () => {
      //if any of the fields are empty, then return
      if (!data) {
        setLoader(false);
        setError(true);
        setErrorMessage("Please fill out all required fields.");
        return;
      }

      const marketplace = await _company.createMarketplace(data);
      if (marketplace) {
        console.log("marketplace reponse data", marketplace);

        const updated = await _marketplace.getMarketplaceByCompanyId(
          profile.tenantCompanyId
        );
        if (updated) {
          setData(updated);
        }
      } else {
        console.log("No marketplace created");
      }

      setLoader(false);
      props.goBackHome();
    }, 1000);

    //check if domain exists in company table
    //if domain exists, get company id and save it in state for use in responses table
    //if domain does not exist, save company information to company table and get company id
    //save company id in state for use in responses table
  };
  const onFieldChange = (e) => {
    const { name, value } = e.target;

    console.log("Setting state", { name, value });
    let newResponse = { ...data };
    newResponse[name] = value;
    setData(newResponse);
  };

  const onFieldChangeRTF = (e, content) => {
    const { name, value } = e.target;

    console.log("Setting state", { name, value });
    console.log(data, "onFieldChangeRTF");
    let newResponse = { ...data };
    newResponse[name] = value;
    newResponse["content"] = content;
    setData(newResponse);
  };

  return (
    <div className="col-span-8">
      <div className="px-4">
        <div className="space-y-6">
          <div className="col-span-full">
            <label
              htmlFor="company_name"
              className="block text-sm font-medium text-slate-700"
            >
              Company Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="title"
                id="title"
                autoComplete="none"
                disabled={disableCompanyField}
                value={
                  data && data.title
                    ? data.title
                    : company && company.companyName
                    ? company.companyName
                    : ""
                }
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");
                  // manageSaved("Supplier Information", false);
                  onFieldChange(e);
                }}
                // className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="company_name"
              className="block text-sm font-medium text-slate-700"
            >
              Tagline
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="previewText"
                id="previewText"
                autoComplete="none"
                disabled={disableCompanyField}
                value={data.previewText ? data.previewText : ""}
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");
                  // manageSaved("Supplier Information", false);
                  onFieldChange(e);
                }}
                // className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="company_name"
              className="block text-sm font-medium text-slate-700"
            >
              Website
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="website"
                id="website"
                autoComplete="none"
                disabled={disableCompanyField}
                value={data && data.website ? data.website : ""}
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");
                  // manageSaved("Supplier Information", false);
                  onFieldChange(e);
                }}
                // className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="project_name"
              className="block text-sm font-medium text-slate-700"
            >
              Company Contact
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="contactName"
                id="contactName"
                autoComplete="none"
                value={
                  data && data.contactName
                    ? data.contactName
                    : company.companyContact
                }
                disabled={fullQuote && fullQuote.status == "submitted"}
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");

                  // manageSaved("Supplier Information", false);
                  onFieldChange(e);
                }}
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="deadline"
              className="block text-sm font-medium text-slate-700"
            >
              Contact Email
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Input
                type="text"
                name="email"
                id="email"
                autoComplete="none"
                disabled={fullQuote && fullQuote.status == "submitted"}
                value={
                  data && data.email
                    ? data.email
                    : company && company.companyEmail
                    ? company.companyEmail
                    : ""
                }
                onChange={(e) => {
                  setError(false);
                  setErrorMessage("");
                  // console.log("EMAIL CHANGE", e.target.value);
                  // manageSaved("Supplier Information", false);
                  onFieldChange(e);
                }}
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="about"
              className="block text-sm font-medium text-slate-700"
            >
              Logo
            </label>
            <p className="mt-2 text-sm text-slate-500">
              Upload your company logo.
            </p>

            {!data.image ? (
              <UploadComponent
                id={"logo"}
                type={"logo"}
                filetype={[".png", ".jpg"]}
                fileList={[data.image]}
                updateFileList={(e, name, action) => {
                  onFieldChange({
                    target: {
                      value: name.filename,
                      name: "image",
                    },
                  });
                }}
              />
            ) : (
              <div className="flex items-center  max-w-12 h-12 w-12 aspect-h-1 aspect-w-1 rounded-full">
                {displayLogo &&
                  displayLogo !== "" &&
                  displayLogo !== null &&
                  displayLogo !== undefined && (
                    <img
                      //base64 image
                      src={displayLogo}
                      // alt={product.imageAlt}
                      className="inline-block w-12 object-scale-down rounded-full ring-2 ring-white"
                    />
                  )}
              </div>
            )}
          </div>
          {/* <div className="col-span-full">
            {" "}
            <label
              htmlFor="about"
              className="block text-sm font-medium text-slate-700"
            >
              Category
            </label>
            <p className="mt-2 text-sm text-slate-500">
              Select your service categories.
            </p>
          </div> */}
          <div className="col-span-full">
            {" "}
            <label
              htmlFor="about"
              className="block text-sm font-medium text-slate-700"
            >
              Offerings
            </label>
            <p className="mt-2 mb-4 text-sm text-slate-500">
              Select the services you offer.
            </p>
            <AddToListComponent
              updateList={(v) => {
                console.log("update called", { name: "services", value: v });
                onFieldChange({
                  target: {
                    name: "services",
                    value: v,
                  },
                });
              }}
              listItems={services ? services : []}
            />
          </div>
        </div>
        <div className="col-span-full mt-6">
          <label
            htmlFor="about"
            className="block text-sm font-medium text-slate-700"
          >
            About
          </label>
          <p className="mt-2 text-sm text-slate-500">
            Tell others about your company.
          </p>
          <div className="mt-1">
            {console.log("rtf", data.rtf_content)}
            {!loading && (
              <TextEditor
                placeholder={"write about us"}
                //  value={data.content ? data.content : ""}
                handleFieldChange={(e, rawContent, content) => {
                  console.log("handleFieldChangeRTF", e, rawContent, content);
                  // manageSaved("Supplier Information", false);
                  onFieldChangeRTF(
                    {
                      target: {
                        name: "rtf_content",
                        value: rawContent,
                        type: "rtf",
                      },
                    },
                    content
                  );
                }}
                value={
                  data && data.rtf_content
                    ? data.rtf_content
                    : data.content
                    ? data.content
                    : "No content found"
                }
              />
            )}
            {/* <textarea
              id="content"
              name="content"
              placeholder="(Optional)"
              rows={3}
              disabled={false}
              value={data.content ? data.content : ""}
              onChange={(e) => {
                // manageSaved("Supplier Information", false);
                onFieldChange(e);
              }}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            /> */}
          </div>
        </div>

        <div className="pt-5 pb-5 col-span-8">
          <div className="flex items-center justify-between ">
            <div className="flex items-center justify-start ">
              {error && (
                <div className="text-red-500 text-xs p-1">{errorMessage}</div>
              )}
            </div>
            {console.log(props.showButtons, "buttons")}

            <div className="flex w-full  justify-between mb-5 ">
              <Button
                color="text"
                loader={loader}
                disabled={false}
                // className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                handleClick={(e) => {
                  e.preventDefault();
                  props.goBackHome();
                }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                loader={loader}
                disabled={false}
                // className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                handleClick={(e) => {
                  e.preventDefault();
                  onSave();
                  // manageSaved("Supplier Information", true);
                  // setStep(step + 1);
                }}
              >
                Save Supplier Information
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
