import React from "react";
import { Dialog, Switch } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const PreferenceListItem = ({ item }) => {
  return (
    <li
      key={item.id}
      className="px-2 flex justify-between items-center py-6 border-b border-gray-200"
    >
      {/* Left */}
      <div>
        <div className="text-slate-800 font-semibold">{item.Name}</div>
        <div className="text-sm">{item.description}</div>
      </div>
      {/* Right */}
      <div className="flex items-center ml-4">
        <div className="text-sm text-slate-400 italic mr-2">
          {item.value ? "On" : "Off"}
        </div>
        <div className="form-switch">
          {/*SAHIL -  Please create a Switch component using headlessui switch */}
          {/* <input
            type="checkbox"
            id={item.id}
            className="sr-only"
            checked={item.value}
            onChange={(e) => {
              item.setValue(e.target.checked);
            }}
          /> */}
          <Switch
            id={item.id}
            checked={item.value}
            onChange={(newValue) => {
              item.setValue(newValue);
            }}
            className={classNames(
              item.value ? "bg-brand-600" : "bg-gray-200",
              "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                item.value ? "translate-x-3.5" : "translate-x-0",
                "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </div>
      </div>
    </li>
    // OTHER VERSIONS:
    //   <li className="flex justify-between items-center py-3 border-b border-gray-200">
    //   <div>
    //     <div className="text-slate-800 font-semibold">Team invites</div>
    //     <div className="text-sm">
    //       Excepteur sint occaecat cupidatat non in culpa qui officia
    //       deserunt mollit.
    //     </div>
    //   </div>

    //   <div className="flex items-center ml-4">
    //     <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm">
    //       Manage
    //     </button>
    //   </div>
    // </li>
    // <li className="flex justify-between items-center py-3 border-b border-gray-200">
    //   <div>
    //     <div className="text-slate-800 font-semibold">
    //       Multi Factor Authentication
    //     </div>
    //     <div className="text-sm">
    //       Choose how you want to receive your OTP codes.
    //     </div>
    //   </div>

    //   <div className="flex items-center ml-4">
    //     <div className="text-sm text-slate-400 italic mr-2 hidden md:block">
    //       Email
    //     </div>
    //     <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm text-red-500">
    //       SMS
    //     </button>
    //   </div>
    // </li>
  );
};

export default PreferenceListItem;
