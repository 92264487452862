import React, { useEffect } from "react";
import { useState } from "react";
import Tabs from "../pages/component/Tabs";
import UsersTable from "./UsersTable";
import UserImport from "../pages/UserImport";
import { _user } from "../modules/_user";
import FormPage from "../pages/component/FormPage";
import UserAddPanel from "./UserAddPanel";

export default function UsersPanel(props) {
  const profile = props.profile;
  const [selectedTab, setSelectedTab] = useState(props.panel);
  const [group, setGroup] = useState(props.group);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setGroup(props.group);
    const tabs = _user.getUserPanelTabs(props.group);
    setSelectedTab(props.selectedTab);
    // tabs && tabs.length > 0 && setSelectedTab(tabs[0].name);
    // setTabs(tabs);
  }, [props.group, props.selectedTab]);

  return (
    <>
      {/* <section
        className="h-full overflow-hidden 
          scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full
          "
      > */}
      {group == "workspace" &&
        (selectedTab == "collaborators" ||
          selectedTab == "workspace-users") && (
          <UsersTable
            tab={selectedTab}
            scope="tenant-users"
            showCheckbox={false}
            title="Workspace Users"
          />
        )}
      {group == "workspace" && selectedTab === "add-users" && (
        <UserAddPanel type="new" inviteType="add-users" profile={profile} />
      )}
      {group == "company" && selectedTab === "company-users" && (
        <UsersTable
          tab={selectedTab}
          scope="company-users"
          showCheckbox={false}
          title="Company Users"
        />
      )}
      {group == "company" && selectedTab === "create-team" && (
        <UserAddPanel
          type="add"
          inviteType="create-new-tenant"
          profile={profile}
        />
      )}
      {group == "system" && selectedTab === "create-company" && (
        <UserAddPanel
          type="add"
          inviteType="create-new-company"
          profile={profile}
        />
      )}
      {group == "system" && selectedTab === "system-users" && (
        <UsersTable
          tab={selectedTab}
          scope="system-users"
          showCheckbox={false}
          title="All System Users"
        />
      )}
      {/* </section> */}
    </>
  );
}
