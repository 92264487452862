import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";
import { _onboarding_options } from "../modules/_onboarding_options";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function StackedListButtons(
  {
    headingText,

    headingSubtext,
    options,
  },
  props
) {
  // const [selectedOption, setselectedOption] = useState(options options[0] || null);
  // const [selected, setSelected] = useState(props.selected);
  const [updatedOptions, setUpdatedOptions] = useState(options);

  // useEffect(() => {
  //   setSelected(props.selected);
  //   console.log("Selected changed -->", props.selected, selected);
  // }, [props.selected]);

  useEffect(() => {
    console.log("Options changed -->", options);
    setUpdatedOptions(options);
  }, [options]);

  return (
    // <div className="mx-auto min-w-[33vw] max-w-lg p-10 bg-white rounded shadow-lg overflow-auto max-w-1/2  max-h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
    <div>
      <div className="text-sm">
        {/* <div className=" max-h-[calc(100vh_-_30rem)]  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"> */}
        <div className="text-sm">
          {/* Options */}
          <ul className="space-y-2 mb-4 w-full">
            {updatedOptions.map((a) => {
              console.log("Option -->", a);
              const {
                id,
                status,
                selected,
                title,
                subTitle,
                rightText,
                handleClick,
              } = a;

              return (
                a.status != "Disabled" &&
                a.status !== "Deleted" && (
                  <li key={id}>
                    <button
                      disabled={a.status === "Invited"}
                      onClick={(e) => handleClick(id)}
                      className={`w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-300
                                shadow-sm duration-150 ease-in-out
                               
                                focus:outline-none focus:border-none focus:ring-2 focus:ring-offset-1 focus:ring-brand-500
                               
                                ${
                                  status === "Invited"
                                    ? "opacity-80 cursor-not-allowed hover-shadow-none hover-border-none"
                                    : " hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500  hover:border-brand-500 "
                                }
                                ${
                                  a.selected &&
                                  " outline-none ring-2 ring-offset-2 ring-brand-500 focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                                }
                                `}
                    >
                      <div className="flex items-center">
                        {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                        <div className="grow">
                          <div className="flex flex-wrap items-center justify-between mb-0.5">
                            <span className="flex font-semibold text-slate-800">
                              {title} {a.selected ? "Selected" : null}
                              {status != "Active" && (
                                <span className="normal-case text-xs italic text-accent-800 align-top ml-2">
                                  {status == "Invited"
                                    ? "    Accept your invitation to continue"
                                    : null}
                                </span>
                              )}
                            </span>
                            <span>
                              <span className="font-medium text-accent-600">
                                {rightText}
                              </span>
                            </span>
                          </div>
                          <div className="text-xs">
                            {subTitle && (
                              <span className="text-slate-500">{subTitle}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </button>
                  </li>
                )
              );
            })}
            {/* Create a new tenant */}
            {/* We need to check if this user owns a tenant of their own or if they are a guest only */}
            {/* If they are only a guest, we need to give them the ability to create an account of their own */}
            {/* <li key={"new_tenant"}>
                        <button
                          // color="white"
                          // disabled={a.status === "Invited"}
                          onClick={() => goToTenant("new", "new")}
                          className="w-full h-full text-left py-3 px-4 rounded bg-white 
                                border border-gray-200
                                shadow-sm duration-150 ease-in-out
                                hover:border-gray-300
                                focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                                
                               "
                        >
                          Create New Tenant
                        </button>
                      </li> */}
          </ul>
          {/* <div className="text-xs text-slate-500">
                  After selecting your assessment, click Next to begin.
                </div> */}
        </div>
      </div>
    </div>
    // </div>
    // </div>
    //   </div>
    // </div>
  );
}

export default StackedListButtons;
