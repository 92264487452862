import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import JurisdictionInput from "./JurisdictionInput";

export default function MailingInfo({
  create,
  newProject,
  languageOptions,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleDeselectedLanguage,
  handleMultiDeSelectNew,
  handleClearAllSelections,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
  currentProject,
}) {
  const [selectedJurisdictions1, setSelectedJurisdictions1] = useState([]);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState([]);

  // const opts =
  //   newProject &&
  //   newProject.services[
  //     newProject.services.findIndex((el) => el.service === service)
  //   ].opts2;

  // useEffect(() => {
  //   if (
  //     newProject &&
  //     newProject.services[
  //       newProject.services.findIndex((el) => el.service === service)
  //     ].options
  //   ) {
  //     setOptions(
  //       newProject.services[
  //         newProject.services.findIndex((el) => el.service === service)
  //       ].options
  //     );
  //   }
  // }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ]?.opts2
    ) {
      setOpts2(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
    console.log("createSolicitationFromTemplate", opts2);
  }, [newProject]);

  return (
    <div className="mt-8">
      {create && (
        <div>
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Options
          </h3>
          <Paragraph>
            Now, let's gather your specific requirements related to mailings.
          </Paragraph>
        </div>
      )}

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label sm htmlFor="delay_mail" className="my-auto  col-span-6 block">
            Days required to mail from receipt of final proofs
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                answer={
                  opts2.delay_mail
                    ? opts2.delay_mail
                    : "No requirement provided"
                }
                type="text"
              />
            </div>
          )}
          {create && (
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="delay_mail"
                id="delay_mail"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                defaultValue={opts2.delay_mail ? opts2.delay_mail : ""}
                value={opts2.delay_mail ? opts2.delay_mail : ""}
                onChange={(e) =>
                  onFieldChange("options", e, "delay_mail", "mailings")
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            htmlFor="project_name"
            className="my-auto  col-span-6 block text-slate-700"
          >
            Number of double-sided pages per letter
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                answer={
                  opts2.double_sided_pages
                    ? opts2.double_sided_pages
                    : "No requirement provided"
                }
                type="text"
              />
            </div>
          )}
          {create && (
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="double_sided_pages"
                id="double_sided_pages"
                placeholder="0"
                autoComplete="none"
                value={opts2.double_sided_pages ? opts2.double_sided_pages : ""}
                onChange={(e) =>
                  onFieldChange("options", e, "double_sided_pages", "mailings")
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            htmlFor="forms_of_letter"
            className="my-auto  col-span-6 block"
          >
            Number of forms of letter
          </Label>
          {!create && (
            <div className="col-span-full mb-6">
              <ReadOnlyAnswer
                answer={
                  opts2.forms_of_letter
                    ? opts2.forms_of_letter
                    : "No requirement provided"
                }
                type="text"
              />
            </div>
          )}
          {create && (
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="forms_of_letter"
                id="forms_of_letter"
                autoComplete="none"
                placeholder="0"
                value={opts2.forms_of_letter ? opts2.forms_of_letter : ""}
                onChange={(e) =>
                  onFieldChange("options", e, "forms_of_letter", "mailings")
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            bold
            sm
            htmlFor="required_languages"
            className="my-auto col-span-6 block  text-slate-700"
          >
            Required languages
          </Label>
          {!create && (
            <div className="col-span-full mb-1">
              <ReadOnlyAnswer
                answer={
                  newProject &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ] &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2 &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : []
                }
                type="chips"
              />
            </div>
          )}
          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="required_langages"
                options={languageOptions ? languageOptions : []}
                fieldType="multiSelect"
                open={true}
                handleSelect={(v) =>
                  handleSelectedLanguage(v, "mailings", "required_language")
                }
                // handleDeselect={handleDeselectedLanguage}
                handleDeselect={(value) =>
                  handleMultiDeSelectNew(value, "mailings", "required_language")
                }
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(value, service, "required_language")
                }
                // handleClearAllSelections={handleClearAllSelections} //{handleClearAllSelections}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : [];
                }}
                // value={
                //   () => {
                //     console.log(
                //       "opts2.required_language",
                //       newProject &&
                //         newProject.services[
                //           newProject.services.findIndex(
                //             (el) => el.service === service
                //           )
                //         ].opts2.required_language
                //     );
                //     // console.log("opts2.required_language", opts2);
                //     return (
                //       (newProject &&
                //         newProject.services[
                //           newProject.services.findIndex(
                //             (el) => el.service === service
                //           )
                //         ].opts2.required_language) ||
                //       []
                //     );
                //   }
                //   // opts2.required_language ? opts2.required_language : ["English"]
                // }
              />
            </div>
          )}
        </div>

        <div className="sm:col-span-6"></div>
        <div className="sm:col-span-6">
          {create && (
            <Paragraph className="mt-1 text-sm text-slate-500">
              Check all the options below that apply to your needs.
            </Paragraph>
          )}
          <div className="mt-4 space-y-4">
            {/* Address append */}
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="address_append"
                  name="address_append"
                  type="checkbox"
                  disabled={!create}
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  placeholder="required_languages_checkbox"
                  defaultChecked={
                    newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2 &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.address_append &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.address_append
                  }
                  value={() => {
                    console.log(
                      "opts2.address_append",
                      newProject &&
                        newProject.services[
                          newProject.services.findIndex(
                            (el) => el.service === service
                          )
                        ].opts2.address_append
                    );
                    return (
                      newProject &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.address_append &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.address_append
                    );
                  }}
                  // opts.address_append}
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "address_append",
                      "mailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label sm htmlFor="address_append" className="">
                  Address Append
                </Label>
                <p className="text-slate-600">
                  Check this box if you would like pricing for address appending
                  services.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="color_printing"
                  name="color_printing"
                  type="checkbox"
                  disabled={!create}
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  placeholder="required_languages_checkbox"
                  defaultChecked={
                    newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ] &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2 &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.color_printing &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.color_printing
                  }
                  value={() => {
                    console.log(
                      "opts2.color_printing",
                      newProject &&
                        newProject.services[
                          newProject.services.findIndex(
                            (el) => el.service === service
                          )
                        ].opts2.color_printing &&
                        newProject.services[
                          newProject.services.findIndex(
                            (el) => el.service === service
                          )
                        ].opts2.color_printing
                    );
                    return (
                      newProject &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.color_printing
                    );
                  }}
                  // opts.color_printing}
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "color_printing",
                      "mailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label sm htmlFor="color_printing" className="">
                  Color Printing
                </Label>
                <p className="text-slate-600">
                  Check this box if you would like the letters to be printed in
                  color.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="return_mail"
                  name="return_mail"
                  type="checkbox"
                  disabled={!create}
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  value={opts2.return_mail && opts2.return_mail}
                  defaultChecked={
                    newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ]?.opts2 &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.return_mail &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.return_mail
                  }
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "return_mail",
                      "mailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label sm htmlFor="candidates" className=" text-slate-700">
                  Return Mail
                </Label>
                <p className="text-slate-500">
                  Check this box if you require the vendor to handle return
                  mail.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="project_reporting"
                  name="project_reporting"
                  type="checkbox"
                  disabled={!create}
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  value={opts2.project_reporting && opts2.project_reporting}
                  defaultChecked={
                    newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ]?.opts2 &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.project_reporting
                  }
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "project_reporting",
                      "mailings",
                      null,
                      true,
                      "project_reporting_options"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label
                  sm
                  htmlFor="offers"
                  className="font-medium text-slate-700"
                >
                  Project Reporting
                </Label>
                <p className="text-slate-500">
                  Check this box if you require the vendor to provide project
                  reporting.
                </p>
                {opts2 &&
                  opts2.project_reporting &&
                  opts2.project_reporting == true && (
                    <div className="mt-2 space-y-2">
                      {/* Project Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="project_confirmation"
                              name="project_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .project_confirmation
                                  ? opts2.project_reporting_options
                                      .project_confirmation
                                  : false
                              }
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .project_confirmation
                                  ? opts2.project_reporting_options
                                      .project_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "project_confirmation",
                                  "mailings",
                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <Label
                            sm
                            normal
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Project Confirmation
                          </Label>
                        </div>
                      </div>
                      {/* Printing Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="printing_confirmation"
                              name="printing_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .printing_confirmation
                                  ? opts2.project_reporting_options
                                      .printing_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .printing_confirmation
                                  ? opts2.project_reporting_options
                                      .printing_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "printing_confirmation",
                                  "mailings",
                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Printing Confirmation
                          </label>
                        </div>
                      </div>
                      {/* Mailing Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="mailing_confirmation"
                              name="mailing_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .mailing_confirmation
                                  ? opts2.project_reporting_options
                                      .mailing_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .mailing_confirmation
                                  ? opts2.project_reporting_options
                                      .mailing_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "mailing_confirmation",
                                  "mailings",

                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Mailing Confirmation
                          </label>
                        </div>
                      </div>
                      {/* Other Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="other_confirmation"
                              name="other_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .other_confirmation
                                  ? opts2.project_reporting_options
                                      .other_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .other_confirmation
                                  ? opts2.project_reporting_options
                                      .other_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "other_confirmation",
                                  "mailings",

                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Other Project Reporting
                          </label>
                        </div>
                      </div>
                      {opts2.project_reporting_options &&
                        opts2.project_reporting_options.other_confirmation && (
                          <div className="sm:col-span-full">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-slate-700"
                            >
                              Other reporting
                            </label>
                            {!create && (
                              <div className="col-span-2">
                                <ReadOnlyAnswer
                                  answer={
                                    opts2.project_reporting_options
                                      .other_confirmation_details &&
                                    opts2.project_reporting_options
                                      .other_confirmation_details
                                  }
                                  type="text"
                                />
                              </div>
                            )}
                            {create && (
                              <p className="mt-2 text-sm text-slate-500">
                                Write a few sentences to describe your required
                                reporting.
                              </p>
                            )}
                            {create && (
                              <div className="mt-1">
                                <textarea
                                  id="description"
                                  name="description"
                                  rows={3}
                                  value={
                                    opts2.project_reporting_options
                                      .other_confirmation_details
                                  }
                                  onChange={(e) => {
                                    onFieldChange(
                                      "options",
                                      //if checked, true, else false
                                      e,

                                      "other_confirmation_details",
                                      "mailings",

                                      "project_reporting_options"
                                    );
                                  }}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                                  defaultValue={""}
                                />
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          {create && (
            <Paragraph>
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </Paragraph>
          )}
          <div className="mt-1">
            {create ? (
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "mailings",

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            ) : (
              <ReadOnlyAnswer
                answer={opts2 && opts2.additional_details}
                type="text"
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
