import { useEffect, useState } from "react";
import { _user } from "../modules/_user";
import Table from "./Table";
import TableItem from "./TableItem_OLD";

export default function UsersTable(props) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(props.tab);
  // const [showCheckbox, setShowCheckbox] = useState(
  //   props.showCheckbox ? props.showCheckbox : false
  // );
  // useEffect(() => {
  //   (async () => {
  //     let users = await _user.getAllUser(page);
  //     // //debugger;
  //     setUserData(users);
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      let users = [];

      users = await _user.getUsers(props.scope, page);

      if (users && users.length > 0) {
        setUserData(users);
      } else {
        setUserData([]);
      }
    })();
  }, [props.tab]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };
  return (
    <div className=" h-full">
      <div className="text-xl font-bold text-slate-800 mb-4">
        {props.title || ""}
      </div>
      <Table
        showCheckbox={props.showCheckbox}
        selectedItems={handleSelectedItems}
        data={userData}
        page={page}
        scope={props.scope}
        // setModalItem={setModalItem}
        fromDashboard={false}
        setUserData={setUserData}
      />
    </div>
  );
}
