import { Fragment, useEffect, useMemo, useState } from "react";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import { Paragraph } from "../../../components/Typography/Paragraph";
import { Label } from "../../../components/Typography/FieldLabel";
import ReadOnlyAnswer from "./ReadOnlyAnswer";

export default function EmailingInfo({
  create,
  newProject,
  languageOptions,
  project,
  onFieldChange,
  handleMultiDeSelectNew,
  handleSelectedLanguage,
  handleDeselectedLanguage,
  handleClearAllSelections,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [opts2, setOpts2] = useState([]);

  useEffect(() => {
    setData(newProject);
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOptions(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  useEffect(() => {
    if (
      newProject &&
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2
    ) {
      setOpts2(
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2
      );
    }
  }, [newProject]);

  return (
    <div className="mt-8">
      {create && (
        <div>
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Options
          </h3>
          <Paragraph>
            Now, let's gather your specific requirements related to mailings.
          </Paragraph>
        </div>
      )}

      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {/* 
        Delay delivery
        Circumvent spam filters
        languages
        Reporting
        - delivery
        - recepit 
        - open
        - other
        - additional_Details
         */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label sm htmlFor=" my-auto delay_mail" className="col-span-6 block ">
            Days required to email from receipt of final proofs
          </Label>
          {!create && (
            <div className="mt-1 col-span-full">
              <ReadOnlyAnswer
                answer={opts2.delay_mail ? opts2.delay_mail : ""}
                type={"text"}
              />
            </div>
          )}
          {create && (
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                name="delay_mail"
                id="delay_mail"
                autoComplete="none"
                placeholder="0" //field, e, key, service
                value={opts2.delay_mail ? opts2.delay_mail : ""}
                onChange={(e) =>
                  onFieldChange("options", e, "delay_mail", "emailings")
                }
                className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            </div>
          )}
        </div>
        {/* <div className="sm:col-span-6 grid grid-cols-6">
          <label
            htmlFor="project_name"
            className="my-auto  col-span-6 block text-sm font-medium text-slate-700"
          >
            Number of double-sided pages
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name="double_sided_pages"
              id="double_sided_pages"
              placeholder="0"
              autoComplete="none"
              value={
                opts2.double_sided_pages ? opts2.double_sided_pages.value : ""
              }
              onChange={(e) =>
                onFieldChange("options", e, "double_sided_pages", "mailings")
              }
              className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-6 grid grid-cols-6">
          <label
            htmlFor="forms_of_letter"
            className="my-auto  col-span-6 block text-sm font-medium text-slate-700"
          >
            Number of forms of letter
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name="forms_of_letter"
              id="forms_of_letter"
              autoComplete="none"
              placeholder="0"
              value={opts2.forms_of_letter ? opts2.forms_of_letter.value : ""}
              onChange={(e) =>
                onFieldChange("options", e, "forms_of_letter", "mailings")
              }
              className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
            />
          </div>
        </div> */}
        <div className="sm:col-span-6 grid grid-cols-6">
          <Label
            sm
            bold
            htmlFor="required_languages"
            className="my-auto col-span-6 block "
          >
            Required languages
          </Label>
          {!create && (
            <div className="mt-1 col-span-full">
              <ReadOnlyAnswer
                answer={
                  newProject &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2 &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : []
                }
                type={"chips"}
              />
            </div>
          )}

          {create && (
            <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
              <ComboboxSelector
                name="required_langages"
                options={languageOptions ? languageOptions : []}
                fieldType="multiSelect"
                open={true}
                handleSelect={(v) =>
                  handleSelectedLanguage(v, "emailings", "required_language")
                }
                // handleSelect={(value) =>
                //   onFieldChange(
                //     "options",
                //     value,
                //     "required_languages",
                //     "mailings",
                //     null
                //   )
                // }

                handleDeselect={(value) =>
                  handleMultiDeSelectNew(
                    value,
                    "emailings",
                    "required_language"
                  )
                }
                handleClearAllSelections={(value) =>
                  handleClearAllSelections(
                    value,
                    "emailings",
                    "required_language"
                  )
                }
                // handleClearAllSelections={handleClearAllSelections} //{handleClearAllSelections}
                value={() => {
                  return newProject &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2.required_language
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.required_language
                    : [];
                }}
              />
            </div>
          )}
        </div>

        <div className="sm:col-span-6"></div>
        <div className="sm:col-span-6">
          <Paragraph
          // className="mt-1 text-sm text-slate-500"
          >
            Check all the options below that apply to your needs.
          </Paragraph>
          <div className="mt-4 space-y-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="avoid_spam_filters"
                  name="avoid_spam_filters"
                  disabled={!create}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  placeholder="required_languages_checkbox"
                  value={opts2.avoid_spam_filters && opts2.avoid_spam_filters}
                  defaultChecked={
                    opts2.avoid_spam_filters && opts2.avoid_spam_filters
                  }
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "avoid_spam_filters",
                      "emailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label sm bold htmlFor="comments">
                  Circumvent Spam Filters
                </Label>
                <p className="text-slate-500">
                  Check this box if you would like the the provider to employ
                  techniques to circument spam filters.
                </p>
              </div>
            </div>
            {/* <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="return_mail"
                  name="return_mail"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  value={opts2.return_mail && opts2.return_mail}
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "return_mail",
                      "mailings"
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="candidates"
                  className="font-medium text-slate-700"
                >
                  Return Mail
                </label>
                <p className="text-slate-500">
                  Check this box if you require the vendor to handle return
                  mail.
                </p>
              </div>
            </div> */}
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="project_reporting"
                  name="project_reporting"
                  disabled={!create}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                  value={opts2.project_reporting && opts2.project_reporting}
                  defaultChecked={opts2.project_reporting}
                  onChange={(e) => {
                    onFieldChange(
                      "options",
                      //if checked, true, else false
                      e,

                      "project_reporting",
                      "emailings",
                      null,
                      true, //isParent
                      "project_reporting_options" //array
                    );
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <Label sm bold htmlFor="comments">
                  Project Reporting
                </Label>
                <p className="text-slate-500">
                  Check this box if you require the vendor to provide project
                  reporting.
                </p>
                {opts2 &&
                  opts2.project_reporting &&
                  opts2.project_reporting == true && (
                    <div className="mt-2 space-y-2">
                      {/* Project Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="project_confirmation"
                              name="project_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting == true &&
                                opts2.project_reporting_options
                                  .project_confirmation
                                  ? opts2.project_reporting_options
                                      .project_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                // opts2.project_reporting == true &&
                                opts2.project_reporting_options
                                  .project_confirmation
                                  ? opts2.project_reporting_options
                                      .project_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "project_confirmation",
                                  "emailings",
                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Project Confirmation
                          </label>
                        </div>
                      </div>
                      {/* Printing Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="delivery_confirmation"
                              name="delivery_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .delivery_confirmation
                                  ? opts2.project_reporting_options
                                      .delivery_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .delivery_confirmation
                                  ? opts2.project_reporting_options
                                      .delivery_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "delivery_confirmation",
                                  "emailings",
                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Delivery Confirmation
                          </label>
                        </div>
                      </div>
                      {/* Mailing Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="open_confirmation"
                              name="open_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .open_confirmation
                                  ? opts2.project_reporting_options
                                      .open_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .open_confirmation &&
                                opts2.project_reporting_options
                                  .open_confirmation
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "open_confirmation",
                                  "emailings",

                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Open Confirmation
                          </label>
                        </div>
                      </div>
                      {/* Mailing Confirmation */}
                      {/* <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="receipt_confirmation"
                              name="receipt_confirmation"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .receipt_confirmation
                                  ? opts2.project_reporting_options
                                      .receipt_confirmation
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "receipt_confirmation",
                                  "emailings",

                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Mailing Confirmation
                          </label>
                        </div>
                      </div> */}
                      {/* Other Confirmation */}
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id="other_confirmation"
                              name="other_confirmation"
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                              value={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .other_confirmation
                                  ? opts2.project_reporting_options
                                      .other_confirmation
                                  : false
                              }
                              defaultChecked={
                                opts2.project_reporting_options &&
                                opts2.project_reporting_options
                                  .other_confirmation &&
                                opts2.project_reporting_options
                                  .other_confirmation
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,

                                  "other_confirmation",
                                  "emailings",

                                  "project_reporting_options"
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="text-slate-700"
                          >
                            Other Project Reporting
                          </label>
                        </div>
                      </div>
                      {opts2.project_reporting_options &&
                        opts2.project_reporting_options.other_confirmation && (
                          <div className="sm:col-span-full">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-slate-700"
                            >
                              Other reporting
                            </label>
                            <p className="mt-2 text-sm text-slate-500">
                              Write a few sentences to describe your required
                              reporting.
                            </p>
                            <div className="mt-1">
                              {create ? (
                                <textarea
                                  id="description"
                                  name="description"
                                  rows={3}
                                  value={
                                    opts2.project_reporting_options
                                      .other_confirmation_details
                                  }
                                  onChange={(e) => {
                                    onFieldChange(
                                      "options",
                                      //if checked, true, else false
                                      e,

                                      "other_confirmation_details",
                                      "emailings",

                                      "project_reporting_options"
                                    );
                                  }}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                                  defaultValue={""}
                                />
                              ) : (
                                <ReadOnlyAnswer
                                  type={"text"}
                                  answer={
                                    opts2.project_reporting_options
                                      .other_confirmation_details
                                  }
                                />
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
        <div className="sm:col-span-6">
          <h3 className="text-base font-semibold leading-6 text-slate-900">
            Final Details
          </h3>
          <Paragraph className="mt-2 text-sm text-slate-500">
            Use the below field to provide any additional details about your
            project or bid request that you think might be helpful to the
            vendor.
          </Paragraph>
          <div className="mt-1">
            {create ? (
              <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    "emailings",

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
              />
            ) : (
              <ReadOnlyAnswer
                type={"text"}
                answer={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : "No additional details provided."
                }
              />
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
