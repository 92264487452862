import React, { useState } from "react";
import { useEffect } from "react";

import { _user } from "../../modules/_user";
import { NavLink } from "react-router-dom";
import { _auth } from "../../modules/_auth";
import Skeleton from "../../components/Skeleton";
import { Uploads } from "../../modules/_upload";

function UploadComponent(props) {
  const type = props.type;
  const filetype = props.filetype;
  const id = props.id;
  const fileSelectionDisabled = props.disabled ? props.disabled : false;
  const [success, setSuccess] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const fileReader = new FileReader();

  useEffect(() => {
    console.log("useEffect fileList", fileList);
    setFileList(props.fileList);
    console.log("useEffect props.fileList", fileList);
  }, [props.fileList]);

  const handleOnChange = (e) => {
    setDisabled(false);
    setFile(e.target.files[0]);
    console.log(e.target.files[0], "fileee");
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let user = _auth.getUserProfile();
    console.log("handleOnSubmit file", file);
    let done = await Uploads.upload(file, type ? type : "contract");
    if (done == false) {
      setErr(true);
      setErrMessage("An error occurred.");
      setSuccess(false);
      setLoading(false);
      setSuccessMessage("File upload failed.");
    } else {
      console.log("handleOnSubmit done", done);
      setTimeout(() => {
        setSuccess(true);

        setLoading(false);
        props.updateFileList(e, done, "add");
        setSuccessMessage("File uploaded successfully.");
      }, 1000);
    }
  };

  const uploadAgain = () => {
    setErr(false);
    setErrMessage("");
    setSuccessMessage("");
    setFile([]);
    setDisabled(true);
    setSuccess(false);
    setLoading(false);
  };

  return (
    // <div className="flex flex-col text-center px-2 py-0 space-y-6 ">
    //   <div className="">
    <form className="">
      {!success && (
        <input
          class={`inline-flex my-6 text-center justify-center text-sm text-slate-500 
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              ${
                fileSelectionDisabled == true
                  ? "file:bg-brand-50"
                  : "file:bg-gray-100 "
              } file:text-brand-700
              hover:file:bg-brand-100`}
          type={"file"}
          id={id ? id : "pdfFileInput"}
          accept={filetype ? filetype : ".pdf"}
          onChange={handleOnChange}
          disabled={fileSelectionDisabled}
        />
      )}
      {success && <div className="text-sm mb-10 w-full">{successMessage}</div>}
      {err && (
        <div className="text-sm mb-10 w-full text-red-500">{errMessage}</div>
      )}
      {!success ? (
        err ? (
          <div className="m-1.5">
            <button
              onClick={(e) => {
                uploadAgain();
              }}
              disabled={disabled}
              className={`btn btn-md w-full bg-emerald-500 hover:bg-emerald-600 text-white ${
                file.length === 0 &&
                "bg-gray-200 hover:bg-gray-200 active:bg-gray-200 disabled:bg-gray-200"
              }`}
            >
              {loading && <Skeleton forButton={true} />}
              Try again
            </button>
          </div>
        ) : (
          <div className="m-1.5">
            <button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              disabled={disabled}
              className={`btn btn-md w-full bg-emerald-500 hover:bg-emerald-600 text-white ${
                file.length === 0 &&
                "bg-gray-200 hover:bg-gray-200 active:bg-gray-200 disabled:bg-gray-200"
              }`}
            >
              {loading && <Skeleton forButton={true} />}
              Upload this file
            </button>
          </div>
        )
      ) : (
        <>
          <div className="m-1.5">
            {fileList &&
              fileList.length > 0 &&
              fileList.map((file, index) => {
                <span className="text-sm">
                  {file && file.name ? file.name : file}
                </span>;
              })}
            <button
              onClick={() => {
                uploadAgain();
              }}
              className={`btn btn-sm  bg-primary-500 hover:bg-primary-600 text-white ${
                file.length === 0 &&
                "bg-gray-200 hover:bg-gray-200 active:bg-gray-200 disabled:bg-gray-200"
              }`}
            >
              Upload another file
            </button>
          </div>

          <div></div>
        </>
      )}
    </form>
    //   </div>
    // </div>
  );
}

export default UploadComponent;
