import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ArrowSteps from "../components/ArrowSteps";
import LandingIntro from "../components/LandingIntro";
import SingleOptions from "../components/SingleOptions";
import StackedList from "../components/StackedList";
import ContactForm from "../components/ContactForm";
import Spinner from "../images/spinner.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import Fade from "react-reveal/Fade";
import { _auth } from "../modules/_auth";
import Skeleton from "../components/Skeleton";
import Input from "../components/Input";
import { _user } from "../modules/_user";
import InputList from "../components/InputList";
import ListBoxSelector from "../components/ListBoxSelector";
import MultiSelectListBox from "../components/MultiSelectListBox1";
import Button from "../elements/Button";
import { _teams } from "../modules/_teams";
import DoNotShowAgain from "../components/DoNotShowAgain";
import { Preferences } from "../modules/_user_preferences";
import VendorPublicLandingIntro from "../components/VendorPublicLandingIntro";
import { Responses } from "../modules/_responses";
import ModalBasic from "../components/ModalBasic";
import PaymentAgreement from "./PaymentAgreement";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = _onboarding_options.getAllSettingsOptions();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function VendorPaymentAgreement() {
  const history = useHistory();
  const location = useLocation();
  const launch = false;
  const [selectedMailingLists, setSelectedMailingLists] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [currentStep, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [viewPaymentAgreementModal, setViewPaymentAgreementModal] =
    useState(false);
  const [company, setCompany] = useState("");
  const [tenant, setTenant] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState(location.state.profile);
  const [buttonText, setButtonText] = useState("Take me to the bid request");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paramsState, setParamsState] = useState(location.state);

  const { token, userId, email, tenantId, type, inviteId, version } =
    location.state;

  const handleCheckboxClick = (e) => {
    setChecked(e.target.checked);
  };

  // async function handleCheckboxClick(clickType) {
  //   if (clickType === "dontshowagain") {
  //     setChecked(!checked);
  //   }
  //   if (clickType === "onboardFinished") {
  //     if (checked) {
  //       let ans = await _onboarding_options.setDontShowAgain(!checked);
  //       console.log(ans, "ansss");
  //       if (ans) {
  //         return history.push({
  //           pathname: "/dashboard",
  //         });
  //       }
  //     }
  //     // else {
  //     //   onFinish();
  //     // }
  //   }
  // }

  // const onFinish = async () => {
  //   setLoader(true);
  //   return history.push({
  //     pathname: "/home",
  //     state: { profile },
  //   });
  // };
  const handleContinue = async (e, action) => {
    e.preventDefault();

    let version = paramsState.version;
    let pathname;
    paramsState.agreedToBiddingTerms = checked;

    if (action === "decline") {
      console.log("Declined before viewing");
      pathname = "/confirm-decline";
      paramsState.declineToView = true;
      let updatedInvite = await updateInvitation();
      if (updatedInvite) {
        console.log("Successfully updated", updatedInvite);
      }
      history.push({
        pathname: pathname,
        state: paramsState,
      });
    } else {
      paramsState.declineToView = false;
      let updatedInvite = await updateInvitation();
      if (version && version == "v2") {
        console.log("Navigating to Version 2");
        pathname = "/projects/project-details/vendor-bid-info-v2";
        history.push({
          pathname: pathname,
          state: paramsState,
        });
      } else {
        console.log("Version 1");
        pathname = "/projects/project-details/vendor-bid-info";
        history.push({
          pathname: pathname,
          state: paramsState,
        });
      }
    }
  };

  const updateInvitation = async () => {
    console.log(
      "This is where we update the inviate to show that the vendor has not accepted the terms and conditions"
    );
    let updated = Responses.declineToView(paramsState);
    if (updated) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={`flex h-screen p-4 ${
        launch ? "flex-col items-center justify-center" : ""
      } w-full`}
    >
      <div>
        <Fade right>
          <div
            id="options_container"
            className={`${
              launch ? "h-fit" : "h-full"
            } w-full px-4 md:w-2/3 xl:w-1/3 2xl:w-1/2 3xl:w-1/3 
          mx-auto px-4 pt-6 overflow-y-none bg-white rounded border border-gray-200 `}
          >
            <div className="h-full flex flex-col justify-between w-full">
              <div
                id="landing-intro-container"
                className="h-full overflow-hidden"
              >
                <VendorPublicLandingIntro
                  tenantType={"vendor"}
                  launch={launch}
                  checked={checked}
                  handleCheckboxClick={handleCheckboxClick}
                  setViewPaymentAgreementModal={setViewPaymentAgreementModal}
                />
              </div>
              <div className="gap-x-2 flex items-center justify-center py-4 border-t border-gray-200">
                {launch && (
                  <Button
                    color="text-secondary"
                    size="lg"
                    fullWidth
                    audit={true}
                    action="Decline to view request"
                    details={paramsState}
                    handleClick={(e) => handleContinue(e, "decline")}
                  >
                    Decline
                  </Button>
                )}
                <Button
                  color={launch ? "emerald" : "emerald"}
                  fullWidth
                  shadow
                  size="lg"
                  exact
                  disabled={checked ? false : true}
                  audit={true}
                  action="Accept and View Request"
                  details={paramsState}
                  handleClick={(e) => handleContinue(e, "view")}
                >
                  {loader && (
                    <Skeleton
                      type="spinner"
                      color="gray"
                      fill="purple"
                      forButton={true}
                    />
                  )}{" "}
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default VendorPaymentAgreement;
