import React from "react";
import Empty from "../../components/Empty";

const DeclineConfirmation = () => {
  return (
    <div>
      <Empty
        title="Bid request declined"
        content="You have successfully declined to view this bid request. "
        showButton={false}
        buttonText="I changed my mind"
      />
    </div>
  );
};

export default DeclineConfirmation;
