import { ChartBarSquareIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardMaturityCard from "../partials/dashboard/DashboardMaturityCard";
import BidList from "../pages/projects/BidList";
import RightActionArea from "./ActionAreaRight";
import AnalyticsCard05 from "../partials/dashboard/AnalyticsCard05";
import AnalyticsCard01 from "../partials/analytics/AnalyticsCard01";
import { _dashboard } from "../modules/_dashboard";

const VendorDashboardComponent = (props) => {
  const { get2DArray } = props;
  const [data, setData] = useState([]);
  const [allOpps, setAllOpps] = useState([]);

  const allBids = [];
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await _dashboard.GetVendorOppData();
      console.log("Vendor Dashboard Data", data);
      setData(data);
    })();
  }, []);

  const isLoading = (item) => {
    if (loading.includes(item)) {
      return true;
    } else {
      return false;
    }
  };
  const firstRowCharts = [
    // {
    //     "totalBidRequestsReceived": 154,
    //     "valueOfAllBidRequestsReceived": 1679207.54,
    //     "totalBidsWon": 22,
    //     "valueOfAllWonBids": 38018.54,
    //     "totalBidsDeclined": 4,
    //     "valueOfAllDeclinedBids": 0,
    //     "totalBidsLost": 0,
    //     "valueOfAllLostBids": 0,
    //     "totalBidsSubmitted": 0,
    //     "valueOfAllSubmittedBids": 0,
    //     "currentPipelineValue": 980041,
    //     "currentPipelineCount": 86,
    //     "totalBidsInDraft": 2,
    //     "valueOfAllDraftBids": 45000
    // }
    {
      title: "Total Opportunities",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Projects")}
          data={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.sumOfBidRequestsReceived)}
          subData={[
            {
              label: "Total invitations",
              value: data.countOfBidRequestsReceived,
            },
          ]}
          title={"Total Opportunities"}
          colSpan={2}
          rowSpan={1}
          total={
            //show dollar value of all past and current opportunities
            data.countOfBidRequestsReceived
          }
        />
      ),
    },

    {
      title: "Submitted Bids",
      icon: <ChartBarSquareIcon />,
      component: (
        //total score of all unresolved impediments for the team
        <DashboardCard01
          loading={isLoading("Get Soliciations")}
          data={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.sumOfBidsSubmitted)}
          subData={[
            {
              label: "Total submissions",
              value: data.countOfBidsSubmitted,
            },
          ]}
          title="Submitted Bids"
          subTitle=""
          colSpan={2}
          rowSpan={1}
          effort={null}
          breakEven={null}
          burdenSummary={null}
          scores={
            data.totalBidsSubmitted +
            data.totalBidsWon +
            data.totalBidsDeclined +
            data.totalBidsLost
          }
        />
      ),
    },
    {
      title: "Opportunities I've Won",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Solicitations")}
          data={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.sumOfBidsWon)}
          subData={[
            {
              label: "Total won",
              value: data.countOfBidsWon,
            },
          ]}
          title="Won Bids"
          colSpan={2}
          rowSpan={1}
          // total={0}
          // unFinishedAssessments={data.totalBidsWon}
        />
      ),
    },
    {
      title: "Declined",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Projects")}
          data={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.sumOfBidsDeclined)}
          subData={[
            {
              label: "Total declined",
              value: data.countOfBidsDeclined,
            },
          ]}
          title={"Declined Bids"}
          colSpan={2}
          rowSpan={1}
          // unFinishedAssessments={
          //   //use this to display active projects with active bid requests
          //   data.totalBidsDeclined ? data.totalBidsDeclined : null
          // }
        />
      ),
    },
    {
      title: "Opportunities I've Lost",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Projects")}
          data={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.sumOfBidsLost)}
          title={"Lost Bids"}
          colSpan={2}
          rowSpan={1}
          subData={[
            { label: "Other winner selected", value: data.countOfBidsLost },
            {
              label: "No winner selected",
              value: data.countOfBidsClosedWithoutWinner,
            },
          ]}
          scores={
            data.countOfBidsLost
            // unfinishedToday.length != 0 ? unfinishedToday.length : null
          }
        />
      ),
    },
    {
      title: "Opportunities I've Bid On",
      icon: <ChartBarSquareIcon />,
      component: (
        //total score of all unresolved impediments for the team
        <DashboardCard01
          loading={isLoading("Get Soliciations")}
          data={new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(data.sumOfNoDecision)}
          subData={[
            {
              label: "Open Opportunities",
              value: data.countOfNoDecision,
            },
          ]}
          title="Open Opportunities"
          subTitle=""
          colSpan={2}
          rowSpan={1}
          effort={null}
          breakEven={null}
          burdenSummary={null}
        />
      ),
    },
  ];
  const secondRowCharts = [
    {
      title: "Open Opportunities",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Projects")}
          data={
            isLoading("Get Projects") == true
              ? "loading"
              : data
              ? data.length
              : 0
          }
          title={"Open Opportunities"}
          colSpan={2}
          rowSpan={1}
          unFinishedAssessments={
            data ? data.length : null
            // unfinishedToday.length != 0 ? unfinishedToday.length : null
          }
        />
      ),
    },
    {
      title: "Opporunities I've Won",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardMaturityCard
          loading={isLoading("Get Solicitations")}
          data={
            isLoading("GetSolicitations") == true ? "" : data ? data.length : 0
          }
          title="Opporunities I've Won"
          colSpan={2}
          rowSpan={1}
          total={0}
        />
      ),
    },
  ];
  return (
    <div className="col-span-full grid grid-cols-12 gap-6 sm:auto-cols-max">
      {firstRowCharts.map((chart, index) => {
        {
          return chart.component;
        }
      })}
      {/* {secondRowCharts.map((chart, index) => {
        {
          return chart.component;
        }
      })} */}
      <AnalyticsCard01
        // Burndown Chart
        loading={false}
        // data={burndown && burndown.length > 0 ? burndown : []}
        data={[]}
        title={"Opportunities by Month"}
        sorted={true}
        colSpan={8}
        rowSpan={2}
        labels={data.labels}
        values={data.values}
        score={2}
        effort={1}
        breakEven={12}
        burdenSummary={14} //props.burdenSummary
        resolved={3}
        remaining={1}
        impediments={3} //impediments.length}
      />
      <AnalyticsCard05
        title={"Upcoming Deadlines"}
        loading={false}
        data={
          data.length > 0
            ? data.length > 5
              ? get2DArray(data.slice(0, 5), "name", "deadline")
              : get2DArray(data, "name", "deadline")
            : "loading"
        }
        tableTitle={"Project"}
        tableValue={"Due Date"}
        titleField={"projectName"}
        valueField={"deadline"}
        colSpan={4}
        rowSpan={1}
        uppercase={false}
      />

      <div className="col-span-full  xl:col-span-4  grow-0 row-span-3 bg-white shadow-lg rounded-sm border border-gray-200">
        <header className="flex grid grid-flow-col px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-slate-800">Recent Bids</h2>
          <RightActionArea>
            <div className=" text-sm font-semibold text-accent-600">
              {/* <NavLink className="" to="/assessments">
                See All -&gt;
              </NavLink>{" "} */}
            </div>
          </RightActionArea>
        </header>
        {/* Proposals */}
        <div className=" flow-root">
          Show value of all won YTD
          {/* <BidList
              onItemClick={null}
              project={allBids}
              items={
                allBids.length > 0
                  ? allBids.length > 3
                    ? allBids
                        //sort by date newest to oldest
                        .sort((a, b) => {
                          return new Date(b.createdAt) - new Date(a.createdAt);
                        })
                        .slice(0, 3)
                    : allBids
                  : []
              }
            /> */}
        </div>
      </div>

      <AnalyticsCard05
        title={"Expiring Bid Requests"}
        loading={false}
        data={
          data.length > 0
            ? data.length > 5
              ? get2DArray(data.slice(0, 5), "name", "deadline")
              : data
            : "loading"
        }
        tableTitle={"Opportunities"}
        tableValue={"Value"}
        colSpan={4}
        rowSpan={2}
        uppercase={false}
      />
    </div>
  );
};

export default VendorDashboardComponent;
