import { Icon } from "@iconify/react";
import { useState } from "react";
import Badge from "../../components/Badge";
import MenuTag from "../../components/MenuTag";
import QuestionToolTip from "../../components/QuestionToolTip";
import { utils } from "../../modules/_utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs, count, icon, secure, spacing, ...props }) {
  !spacing && (spacing = "md");
  // const [selectedTab, setSelectedTab] = useState("View All");
  const setSelectedTab = props.setSelectedTab;
  const selectedTab = props.selectedTab;
  const isVisible = (tab) => {
    if (secure && secure == true) {
      return (
        tab.view?.includes(props.profile?.role) ||
        props.profile?.sysRole == "Super Admin"
      );
    } else {
      return true;
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md 	cursor-pointer;"
          defaultValue={
            tabs && tabs.length > 0 && tabs.find((tab) => tab.current)?.name
          }
          onChange={(e) => {
            // e.preventDefault();
            setSelectedTab(e.target.value);
          }}
        >
          {tabs.map((tab) => {
            if (isVisible(tab)) {
              return <option key={tab.name}>{tab.name}</option>;
            }
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className={`flex space-x-${
            spacing == "sm"
              ? "0"
              : spacing == "md"
              ? "4"
              : spacing == "lg"
              ? "4"
              : "4"
          }`}
          aria-label="Tabs"
        >
          {tabs.map((tab, index) => {
            if (isVisible(tab)) {
              return (
                <a
                  key={tab.name}
                  onClick={(e) => {
                    setSelectedTab(tab.name, index);
                  }}
                  // href={tab.href}
                  className={classNames(
                    tab.name === selectedTab
                      ? "bg-brand-100 text-brand-700"
                      : "text-slate-500 hover:text-slate-700",
                    "flex items-center space-x-2 px-3 py-2 font-medium text-sm rounded-md cursor-pointer capitalize"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.icon && tab.icon !== null && (
                    <span className="inline-flex">
                      <Icon icon={tab.icon} />
                    </span>
                  )}{" "}
                  <span>
                    {props.cleanUpKeys ? props.cleanUpKeys(tab.name) : tab.name}
                  </span>
                  <span> {tab.count}</span>
                  {tab.status && (
                    <>
                      <MenuTag status={tab.status.toUpperCase()} />
                      {tab.tooltip && (
                        <QuestionToolTip
                          color={"brand"}
                          field={"Free Preview"}
                          text={utils.getTooltipText(tab.status)}
                        />
                      )}
                    </>

                    // <span
                    //   className={`ml-2 uppercase rounded bg-${
                    //     tab.name === selectedTab ? "white" : "secondary-100"
                    //   }
                    // px-2 text-xs font-semibold leading-5 text-brand-800`}
                    // >
                    //   {tab.status}
                    // </span>
                  )}
                  {/* {tab.count && (
                    <span className="flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                     
                    </span>
                  )} */}
                </a>
              );
            }
          })}
        </nav>
      </div>
    </div>
  );
}
