import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { Reports } from "../../modules/_reports";
import ComboboxSelector from "../../components/ComboBoxSelector";
import Table from "./Table";
import Button from "../../elements/Button";
import Tabs from "../component/Tabs";
import DashboardCard01 from "../../partials/dashboard/DashboardCard01";
import { utils } from "../../modules/_utils";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function BidRequestReport(props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [month, setMonth] = useState();
  const [selectedTab, setSelectedTab] = useState();
  const [selectedTabData, setSelectedTabData] = useState();
  const history = useHistory();
  useEffect(() => {
    const date = new Date();
    const currentMonth = date.getMonth();

    const last12months = Array.from({ length: 12 }, (_, i) => {
      const d = new Date();
      d.setDate(1); // Set the date to the 1st to avoid month rollover issues
      d.setMonth(currentMonth - i);
      d.setHours(0, 0, 0, 0);

      const month = monthNames[d.getMonth()];
      return { name: month, value: d };
    });
    const tabs = [];
    last12months.forEach((month) => {
      tabs.push({
        name: month.name,
        id: month.name,
        value: month.value,
        href: "#",
        current: true,
      });
    });
    console.log("tabs", tabs);
    setTabs(tabs);
    setSelectedTabData(tabs[0]);
    setSelectedTab(tabs[0].name);
    setMonth(tabs[0].name);
  }, []);

  useEffect(() => {
    console.log("Selected tab", month);
    (async () => {
      let winReport = await Reports.getBidRequestReport({
        date: selectedTabData.value,
      });
      if (winReport) {
        console.log("Got winReport", winReport.data);
        setData(winReport.data);
        setColumns(winReport.columns);
      }
    })();
  }, [selectedTab]);

  const handleSelectTab = (tab) => {
    console.log("handleSelectTab Selected tab", tab);
    //get data for selected tab
    const tabData = tabs.find((t) => t.name === tab);
    console.log("handleSelectTab Selected tabData", tabData);
    setSelectedTabData(tabData);
    setSelectedTab(tab);
    setMonth(tab);
  };

  const downloadWinReport = async (data) => {
    console.log("Download winReport by month API and pass data", data.month);
    const response = await Reports.downloadWinReport(data);

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Create a link element
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute of the link to the filename from the Content-Disposition header
    // const contentDisposition = response.headers["content-disposition"];
    const filename = "report.xlsx"; //contentDisposition.split(";")[1].split("=")[1].trim();
    link.setAttribute("download", filename);

    // Append the link to the body and click it to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  const competencyCharts = [
    {
      title: "Total Bid Requests",
      // icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          textSmall
          loading={false} //isLoading("Get Projects")
          data={data && data.length}
          title={"Total Bid Requests"}
          colSpan={2}
          rowSpan={1}
          unFinishedAssessments={null}
        />
      ),
    },
    // {
    //   title: "Value of Wins",
    //   // icon: <ChartBarSquareIcon />,
    //   component: (
    //     <DashboardCard01
    //       textSmall
    //       loading={false} //isLoading("Get Projects")
    //       data={
    //         data &&
    //         data
    //           .reduce((acc, curr) => {
    //             return (
    //               acc + parseFloat(curr.Total.replace(/[^0-9.-]+/g, "")) //convert Total to number
    //             );
    //           }, 0)
    //           .toLocaleString("en-US", {
    //             style: "currency",
    //             currency: "USD",
    //           })
    //       }
    //       title={"Value of Wins"}
    //       colSpan={2}
    //       rowSpan={1}
    //       unFinishedAssessments={null}
    //     />
    //   ),
    // },
    // {
    //   title: "Most Common Vendor",
    //   // icon: <ChartBarSquareIcon />,
    //   component: (
    //     <DashboardCard01
    //       textSmall
    //       loading={false} //isLoading("Get Projects")
    //       data={
    //         Array.isArray(data) &&
    //         data.length > 0 &&
    //         Object.entries(
    //           data.reduce((acc, curr) => {
    //             acc[curr.Vendor] = (acc[curr.Vendor] || 0) + 1;
    //             return acc;
    //           }, {})
    //         ).reduce((a, b) => (a[1] > b[1] ? a : b), [null, 0])[0]
    //       }
    //       title={"Most Common Vendor"}
    //       colSpan={2}
    //       rowSpan={1}
    //       unFinishedAssessments={null}
    //     />
    //   ),
    // },
    // {
    //   title: "Highest Grossing Vendor",
    //   // icon: <ChartBarSquareIcon />,
    //   component: (
    //     <DashboardCard01
    //       textSmall
    //       loading={false} //isLoading("Get Projects")
    //       data={
    //         Array.isArray(data) &&
    //         data.length > 0 &&
    //         Object.entries(
    //           data.reduce((acc, curr) => {
    //             acc[curr.Vendor] = (acc[curr.Vendor] || 0) + curr.total;
    //             return acc;
    //           }, {})
    //         ).reduce((a, b) => (a[1] > b[1] ? a : b), [null, 0])[0]
    //       }
    //       title={"Highest Grossing Vendor"}
    //       colSpan={2}
    //       rowSpan={1}
    //       unFinishedAssessments={null}
    //     />
    //   ),
    // },
    // {
    //   title: "Most Common Service",
    //   // icon: <ChartBarSquareIcon />,
    //   component: (
    //     <DashboardCard01
    //       textSmall
    //       loading={false} //isLoading("Get Projects")
    //       data={
    //         Array.isArray(data) &&
    //         data.length > 0 &&
    //         utils.cleanUpKeys(
    //           Object.entries(
    //             data.reduce((acc, curr) => {
    //               Object.keys(curr).forEach((key) => {
    //                 if (key === key.toLowerCase()) {
    //                   acc[key] = (acc[key] || 0) + 1;
    //                 }
    //               });
    //               return acc;
    //             }, {})
    //           ).reduce((a, b) => (a[1] > b[1] ? a : b), [null, 0])[0]
    //         )
    //       }
    //       title={"Highest Grossing Service"}
    //       colSpan={2}
    //       rowSpan={1}
    //       unFinishedAssessments={null}
    //     />
    //   ),
    // },
    // {
    //   title: "Most Common Service",
    //   // icon: <ChartBarSquareIcon />,
    //   component: (
    //     <DashboardCard01
    //       textSmall
    //       loading={false} //isLoading("Get Projects")
    //       data={
    //         Array.isArray(data) &&
    //         data.length > 0 &&
    //         utils.cleanUpKeys(
    //           Object.entries(
    //             data.reduce((acc, curr) => {
    //               Object.entries(curr).forEach(([key, value]) => {
    //                 if (key === key.toLowerCase()) {
    //                   acc[key] = (acc[key] || 0) + value;
    //                 }
    //               });
    //               return acc;
    //             }, {})
    //           ).reduce((a, b) => (a[1] > b[1] ? a : b), [null, 0])[0]
    //         )
    //       }
    //       title={"Most Common Service"}
    //       colSpan={2}
    //       rowSpan={1}
    //       unFinishedAssessments={null}
    //     />
    //   ),
    // },
  ];

  return (
    <div
      id="page_container"
      className="w-full max-w-14xl flex flex-col grow-0  h-[calc(100%-120px)]"
    >
      <div className="px-4 h-fit">
        <div className="space-y-6 pb-2 md:flex md:items-center md:justify-between md:space-x-5  ">
          <div className="flex items-center">
            <div>
              <PageHeader>Bid Request Report</PageHeader>
              <p>This report shows all bid requests</p>
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            {/* <Button
              //   className="w-48"
              fullWidth
              handleClick={() =>
                downloadWinReport({ month: month, tableData: data })
              }
            >
              Download Report
            </Button>{" "} */}
            {/* <ComboboxSelector
              options={lastFourMonths}
              label="Select Month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              fieldType="single"
              handleClearAllSelections={() =>
                setMonth(monthNames[(new Date().getMonth() - 1 + 12) % 12])
              }
            /> */}
            {/* <select>
              {lastFourMonths.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select> */}
          </div>
          {/* <Filters
          filterOptions={filterOptions}
          filters={filters}
          setFilters={setFilters}
          handleChangeFilter={handleChangeFilter}
        /> */}
        </div>
        <div className="flex items-center  w-full">
          <div className="flex text-sm items-center">
            <Tabs
              // key={index}
              tabs={tabs || []}
              //sort tabs.tabs alphabetically by name
              // .sort((a, b) => (a.name > b.name ? 1 : -1))}
              setSelectedTab={(tab) => handleSelectTab(tab)}
              selectedTab={month}
            />
          </div>
        </div>
      </div>
      {/* <div>{JSON.stringify(data)}</div> */}
      <div className="py-4 gap-x-2 grid grid-cols-12 h-fit">
        {" "}
        {competencyCharts.map((chart, index) => {
          {
            return chart.component;
          }
        })}
      </div>
      <div className="h-full mb-0 bg-white rounded-sm shadow-md border border-gray-200">
        <Table
          columns={
            columns &&
            columns.map((column) => ({
              Header: column,
              accessor: column,
            }))
          }
          data={data}
          clickable={false}
          navigateTo={(p) => {
            history.push(
              `/projects/project-details/${p.projectId}/solicitation-details/${p.solicitationId}`
            );
          }} // Pass the navigateTo function as a prop
        />
      </div>
    </div>
  );
}
