import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Tabs from "./component/Tabs";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import DateSelect from "../components/DateSelect";
import FilterButton from "../components/DropdownFilter";
// import CustomersTable from "../partials/customers/CustomersTable";
import PaginationClassic from "../components/PaginationClassic";
import { customers } from "../modules/_customers";
import ModalForm from "../components/ModalForm";
import { _assessment } from "../modules/_assessment";
import ModalBasic from "../components/ModalBasic";
import AnswerList from "../components/AnswerList";
import AuditTable from "./AuditTable";
import { audit } from "../modules/_audit";
import Table from "./audit/Table";
import DropdownFull from "./audit/DropdownFull";
import { _dashboard } from "../modules/_dashboard";

function AuditPage() {
  const [showModal, setShowModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState();
  const [modalItem, setModalItems] = useState({
    questions: [{ questions: [] }],
  });

  const [topUsers, setTopUsers] = useState({});

  // let allData;
  let filterOptionsEmail = [{ id: 0, period: "AAll", display: "All Users" }];

  useEffect(() => {
    fetchData();
  }, [page, email]);
  // Fetch data whenever the page state changes
  useEffect(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      let metaData = ["Filter by " + "Email"];
      let resp = await audit.auditMetaData();
      metaData = [...metaData, ...resp.data];
      setAllData(metaData);
    };

    fetchMetaData();
  }, []);
  if (topUsers) {
    // console.log("topUsers", topUsers);
    Object.values(topUsers).map((item, index) => {
      if (item !== undefined) {
        filterOptionsEmail.push({
          id: index + 1,
          period: item.group,
          display: item.group,
        });
      }
    });
  }
  const list = allData;
  // const list = React.useMemo(() => {
  //   const options = [];
  //   options.push("Filter by " + "Email"); //render("Header")
  //   let added = [];
  //   if (allData === "loading") return options;
  //   if (allData.length === 0) return options;

  //   allData.map((row, index) => {
  //     // console.log("row", row);
  //     if (row.userId !== undefined) {
  //       if (!added.includes(row.userId)) {
  //         added.push(row.userId);
  //         if (!options.includes(row.email)) {
  //           options.push(row.email);
  //         }
  //       }
  //     }
  //   });
  //   //sort options
  //   options.sort();
  //   //add "Filter by Email" to top

  //   // allData.forEach((row, index) => {
  //   //   console.log("row", row);
  //   //   if (row.userId !== undefined) {
  //   //     if (!added.includes(row.userId)) {
  //   //       added.push(row.userId);
  //   //       options.add(row.email);
  //   //     }
  //   //   }

  //   //   // options.add(row.email);
  //   // });
  //   // //sort options

  //   // console.log("options", [...options.values()]);
  //   // return [...options.values()];
  //   return options;
  // }, [data]);

  // const list = React.useMemo(() => {
  //   const options = new Set();
  //   options.add("Filter by " + "Email"); //render("Header")
  //   let added = [];
  //   if (allData === "loading") return options;
  //   if (allData.length === 0) return options;
  //   allData.forEach((row, index) => {
  //     console.log("row", row);
  //     if (row.userId !== undefined) {
  //       if (!added.includes(row.userId)) {
  //         added.push(row.userId);
  //         options.add(row.email);
  //       }
  //     }

  //     // options.add(row.email);
  //   });
  //   //sort options

  //   console.log("options", [...options.values()]);
  //   return [...options.values()];
  // }, [data]);
  const handleScrollEnd = async () => {
    setPage((prevPage) => prevPage + 1);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let filter = {};
      if (email && email != "Filter by Email") {
        filter["email"] = email;
      }

      let resp = await audit.getAuditLogByTenant(page, 50, filter);
      if (resp.data && resp.data.data) {
        setData((prevData) => [...prevData, ...resp.data.data]);
        setTotalData(resp.data.totalCount);
      } // Append new data to existing data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const hadleFilterByPerson = async (e) => {
    setSelectedItems(e);
    if (e === "Filter by Email") {
      console.info("Filter All Data", e);

      setEmail(e);
      setData([]);
      setPage(1);
    } else {
      console.info("Filter By Person", e, allData);
      // setCombined(combined.filter((item) => item.status.includes(e)));
      //setData(allData.filter((item) => item.email === e));

      setData([]);
      setEmail(e);
      setPage(1);
    }
  };

  const setModalItem = (item) => {
    console.info("ATTENTION - C-TABLE-ITEM - SHOW MODAL");
    console.log("setModalItem", item);
    setModalItems(item);
    // showHideModal();
  };

  const openModal = (e, data) => {
    e.preventDefault();
    setModalItem(data);
    setShowModal(true);
  };

  const showHideModal1 = () => {
    //console.info("ATTENTION - CALLING showHideModal");

    setShowModal(false);
  };

  const showHideModal = () => {
    setShowModal(!showModal);
  };

  const renderDetailsByDataType = (data) => {
    // console.log("data", data);

    if (data === undefined) return;

    let isDate = false;
    const dateObj = new Date(data);

    if (dateObj.toString() !== "Invalid Date") {
      isDate = true;
    }
    return typeof data === "string"
      ? data
      : isNaN(parseInt(data.toString())) === false
      ? data.toString()
      : JSON.stringify(data);
  };
  // const counts = ["no", "no", "no", "no", "no", "no", "no", "no", "no", "no"];
  // const history = useHistory();
  // const goToActionPlan = () => {
  //   return history.push({
  //     pathname: "/actionPlan",

  //     state: {
  //       data: counts,
  //     },
  //   });
  // };
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto m-h-96 overflow-hidden">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-4">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h2 className="text-xl md:text-xl text-gray-800 font-semibold">
            Activity History
          </h2>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <div className="w-96 inline-flex">
            <DropdownFull
              className="w-96"
              options={list}
              handleFilter={hadleFilterByPerson}
            />
          </div>
          {/* Delete button */}
          {/* <DeleteButton selectedItems={selectedItems} /> */}
          {/* Dropdown */}
          {/* <DateSelect /> */}
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Add customer button */}
          {/* <Link to="../assessmentselect">
            <button className="btn bg-brand-500 hover:bg-brand-600 text-white">
              <span className="hidden xs:block ml-2">Send Assessment</span>
            </button>
          </Link> */}
          {/* <Link to="../assessmentselect">
            <button className="btn bg-lime-500 hover:bg-lime-600 text-white">
              <svg
                className="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2">Take Assessment</span>
            </button>
          </Link> */}
        </div>
      </div>

      <div className="mb-6">
        {/* grid grid-flow-col  sm:auto-cols-max justify-start sm:justify-end gap-2 */}
        <div className="mb-5  gap-2 mb-5 auto-cols-max justify-items-stretch ">
          {/* <div className="w-2/3 md:w-1/3  inline-flex mr-4">
            <DropdownFull options={list} handleFilter={hadleFilterByPerson} />
          </div> */}
          {/* <div className="w-1/6  inline-flex mr-4">
            <DropdownFull
              options={
                categoryOptions
                //   .sort((a, b) => {
                //   return a.period.localeCompare(b.period);
                // })
              }
              handleFilter={handleFilterCategories}
            />
          </div> */}
          {/* <div className="w-1/6  inline-flex mr-4">
            <DropdownFull
              options={capabilityOptions}
              handleFilter={handleFilterCapabilities}
            />
          </div> */}
          {/* <div className="w-1/6 inline-flex mr-4">
            <DropdownFull
              options={focusOptions}
              handleFilter={handleFilterFocus}
            />
          </div> */}
          {/* {impedimentsByResolution.length > 0 && (
            <div className="w-1/6  inline-flex mr-4">
              <DropdownFull
                options={resolutionOptions}
                handleFilter={handleFilterResolutions}
              />
            </div>
          )} */}
          {/* <div className="w-1/4 inline-flex mr-4">
            <input
              id="search"
              name="search"
              type="search"
              autoComplete="search"
              placeholder="Search by description"
              onChange={handleSearchList}
              // required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
            />
          </div> */}

          {/* <Tabs
            tabs={tabs}
            setSelectedTab={handleFilter}
            selectedTab={selectedTab}
          /> */}
        </div>
        {/* Filters */}
        {/* <div className="mb-4 border-b border-gray-200"> */}
        {/* Filters */}
        {/* <div className="mb-4 border-b border-gray-200"> */}
        {/* <div className="mb-5">
          <Tabs tabs={tabs} />
        </div> */}

        {/* </div> */}
      </div>
      {/* Table */}
      <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
        <header className="px-5 py-4">
          <h2 className="font-semibold text-gray-800">
            Activity History{" "}
            <span className="text-gray-400 font-medium">{totalData}</span>
          </h2>
        </header>
        <Table
          selectedItems={handleSelectedItems}
          data={data}
          setModalItem={openModal}
          fromDashboard={false}
          loading={loading}
          onScrollEnd={handleScrollEnd}
        />
      </div>
      {/* Pagination */}
      {/* <div className="mt-8">
        <PaginationClassic />
      </div> */}
      <ModalBasic
        id="scrollbar-modal"
        modalOpen={showModal}
        setModalOpen={showHideModal1}
        title="Details"
      >
        {/* Modal content */}
        {modalItem.details &&
          Object.keys(modalItem.details).map((key, index) => {
            return (
              <div key={index} className="flex flex-row px-4 py-2">
                <div className="font-semibold mr-2">{key} </div>
                <div className="font-normal">
                  {renderDetailsByDataType(modalItem.details[key])}
                </div>
              </div>
            );
          })}

        {/* Modal footer */}
        <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-brand-500 hover:bg-brand-600 text-white"
              onClick={(e) => {
                e.stopPropagation();
                showHideModal();
              }}
            >
              Done
            </button>
            {/* <button className="btn-sm bg-brand-500 hover:bg-brand-600 text-white">
              I Understand
            </button> */}
          </div>
        </div>
      </ModalBasic>
    </div>

    //     </main>

    //   </div>

    // </div>
  );
}

export default AuditPage;
