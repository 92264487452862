import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import Logo from "../images/logo.png";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../elements/Button";

function ResetPasswordEmail(props) {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [otp, setOtp] = useState();
  const [err, setErr] = useState(false);
  const [tokenErr, setTokenErr] = useState(false);
  const [tokenErrMessage, setTokenErrMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token, id } = useParams();
  const [loader, setLoader] = useState(false);

  console.log(token, "token");

  useEffect(() => {
    (async () => {
      let checkIfValid = await _auth.verifyToken(token, id);

      if (checkIfValid.isValid === false) {
        let valid = checkIfValid.isValid;
        let message = checkIfValid.message;
        setTokenErr(true);
        setTokenErrMessage(checkIfValid.message);
      }
    })();
  }, []);

  const resetPassword = async (event) => {
    setLoader(true);
    event.preventDefault();
    console.info(event);
    let path = "/home";
    console.log(id);
    console.log(token);

    if (password !== confirmPassword) {
      setErr(true);
      setLoader(false);
    } else {
      let data = {
        id,
        token,
        password,
        confirmPassword,
      };
      let profile = await _auth.resetPasswordEmail(data, history);
      console.log(profile, "profile");
      if (profile === false) {
        setLoader(false);
        setIsAuthenticated(true);
      } else {
        setLoader(false);
        history.push("/");
      }
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="flex max-w-sm mx-auto px-4">
              <img width="80" className="mx-auto px-4 py-8" src={Logo} />
              <span className="inline-flex text-3xl m-auto font-bold text-slate-700">
                Breachlink{" "}
              </span>
            </div>
            {tokenErr ? (
              <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                {tokenErrMessage}
              </div>
            ) : (
              <div className="max-w-sm mx-auto px-4 ">
                <h1 className="text-xl text-slate-700 font-bold mb-6">
                  Create a new password
                </h1>
                {/* Form */}
                <form onSubmit={resetPassword}>
                  <div className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        New Password
                      </label>
                      <input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none focus:ring-1 focus:ring-brand-500"
                        type="password"
                        autoComplete="on"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Retype New Password
                      </label>
                      <input
                        id="confirm-password"
                        className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none focus:ring-1 focus:ring-brand-500"
                        type="password"
                        autoComplete="on"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    {loader ? (
                      <div class="flex justify-center items-center">
                        <img
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          src={Spinner}
                          width="32"
                          height="32"
                          alt="loading.."
                        />
                      </div>
                    ) : (
                    
                      <button
                      disabled={!(password && confirmPassword)}
                      type="submit"
                      class={`btn btn-lg w-full ${!(password && confirmPassword) && "cursor-not-allowed"} ${ !(password && confirmPassword) ? "bg-brand-100 hover:bg-brand-100" : "bg-brand-600 hover:bg-brand-600"} hover:shadow-sm text-white `}
                      // onClick={auth}
                  >
                      Set Password
                  </button>
                    )}
                  </div>
                </form>
                {err && (
                  <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                    The passwords you entered do not match. Please try again.
                  </div>
                )}
                {/* Footer */}
                {isAuthenticated && (
                  <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                    Your link has expired. Please contact your administrator.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPasswordEmail;
