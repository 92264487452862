import React, { useEffect, useState } from "react";
import RadioOptionButtonsLarge from "../../../components/RadioOptionButtonsLarge";
import { Paragraph } from "../../../components/Typography/Paragraph";
import ProjectList from "../Projects";
import Tip from "../../../components/Tip";

function NewSolicitationShortcut(props) {
  const options = [
    {
      name: "Start with a new project",
      description:
        "If this is your first request related to this breach event, select this option.",
      icon: "heroicons:document-plus",
      id: "create-new",
    },
    {
      name: "Select an existing project",
      description:
        "If you've made previous requests related to this breach event, select this option.",
      icon: "heroicons:document-duplicate",
      id: "existing-project",
    },
  ];
  const [creationMethod, setCreationMethod] = useState();
  const [selectedProject, setSelectedProject] = useState();
  useEffect(() => {
    console.log("selectedProject", selectedProject);
  }, [selectedProject]);

  useEffect(() => {
    setCreationMethod("create-new");
    setSelectedProject(null);
  }, []);

  const cleanUpState = () => {
    setCreationMethod("create-new");
    setSelectedProject(null);
  };

  return (
    <div className="relative w-full h-[calc(100vh-105px)] overflow-y-hidden space-y-3">
      <h3 className="text-base font-semibold leading-6 text-slate-900">
        Start with a new project
      </h3>
      <p className="mt-2 mb-4 text-sm text-slate-500">
        A project is how you tie all the bid requests related to this breach
        event together. You can choose to create a new project or select an
        existing project
      </p>

      <Tip>
        {/* <span className="font-semibold"> */}
        Information you enter here will carry forward to future bid requests
        related to this breach event.
        {/* </span> */}
      </Tip>

      <RadioOptionButtonsLarge
        options={options}
        creationMethod={creationMethod}
        setCreationMethod={setCreationMethod}
        handleCreateNewSolicitation={() => {
          props.handleCreateNewSolicitation();
        }}
      />

      <div className="h-full  pb-96">
        {" "}
        {creationMethod === "existing-project" && (
          <div className="h-full ">
            <h3 className="text-base font-semibold leading-6 text-slate-900">
              Select a project from the list below.
            </h3>
            <div className="h-full pb-6 overflow-y-hidden ">
              <ProjectList
                fromModal={true}
                handleOpenProjectClick={(item) => {
                  console.log("handleOpenProjectClick", item);
                  setSelectedProject(item);
                }}
              />
            </div>
          </div>
        )}
      </div>

      <footer className="absolute pt-3 pb-2   px-6 mt-auto w-full border-t bg-white  bottom-0 ">
        <div className="relative flex items-center justify-between ">
          <div className="flex justify-start ">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
              onClick={(e) => {
                e.preventDefault();
                cleanUpState();
                props.setModalOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex justify-end">
            {creationMethod === "create-new" && (
              <button
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                onClick={(e) => {
                  e.preventDefault();

                  props.handleCreateNewProjectClick(selectedProject);
                  cleanUpState();
                }}
              >
                Next Step
              </button>
            )}
            {creationMethod === "existing-project" &&
              selectedProject !== null && (
                <button
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                  onClick={(e) => {
                    e.preventDefault();

                    props.handleCreateNewSolicitation(selectedProject);
                    cleanUpState();
                  }}
                >
                  Next Step
                </button>
              )}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default NewSolicitationShortcut;
