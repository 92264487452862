//Hooks
import React, { useState, useRef, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

//External Libraries
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Icon } from "@iconify/react";

//Icons
import {
  ArrowDownTrayIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  PaperClipIcon,
} from "@heroicons/react/20/solid";

//Elements

//Components
import RightActionArea from "../../components/ActionAreaRight";
import { Paragraph } from "../../components/Typography/Paragraph";
import { HeadingTwo } from "../../components/Typography/HeadingTwo";
import Divider from "../../components/Divider";
import Tip from "../../components/Tip";

import { Label } from "../../components/Typography/FieldLabel";
import * as constants from "../../constants/constants";

import ModalBasic from "../../components/ModalBasic";
import PdfViewer from "../../components/PdfViewer";
import Button from "../../elements/Button";
import Tabs from "../component/Tabs";
import Skeleton from "../../components/Skeleton";
//Form Elements
import SupplierInfo from "../projects/components/SupplierInfo";
import EmailingInfo from "../projects/components/EmailingInfo";
import JurisdictionInput from "../projects/components/JurisdictionInput";
import MailingInfo from "../projects/components/MailingInfo";
import CallCenterInfo from "../projects/components/CallCenterInfo";
import CreditMonitoringInfo from "../projects/components/CreditMonitoringInfo";
import Welcome from "../projects/components/Welcome";
//API's
import { Solicitations } from "../../modules/_solicitations";
import { Responses } from "../../modules/_responses";
import { utils } from "../../modules/_utils";
import { Files } from "../../modules/_files";
import { _company } from "../../modules/_company";

import Logo from "../../images/logo.png";

import { parse } from "node-html-parser";
import { _user } from "../../modules/_user";

function VendorProjectDetails(props) {
  //Refs
  const printRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const tabOrder = constants.SOLICITATION_TAB_ORDER;

  //UI States
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState([]);
  const [saved, setSaved] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    tabs && tabs[0] ? tabs[0].name : null
  );
  const [responseTabs, setResponseTabs] = useState([]);
  // const [selectedResponseTab, setSelectedResponseTab] = useState(
  //   responseTabs && responseTabs[0] ? responseTabs[0].name : null
  // );
  const [selectedService, setSelectedService] = useState(null);
  const [err, setErr] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitErr, setSubmitErr] = useState(false);
  const [submitErrMessage, setSubmitErrMessage] = useState("");
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadFile, setLoadFile] = useState(false);
  //Data States
  const [vendor, setVendor] = useState({});
  const [solicitation, setSolicitation] = useState({});
  const [response, setResponse] = useState({});
  const [fullQuote, setFullQuote] = useState({});
  const [responseId, setResponseId] = useState(null);
  const [responseTotal, setResponseTotal] = useState(0);
  const [project, setProject] = useState({});
  const [id, setId] = useState(
    location.state.solicitation && location.state.solicitation._id
      ? location.state.solicitation._id
      : null
  );
  const [submitted, setSubmitted] = useState(false);

  const [guestId, setGuestId] = useState(null);
  const [selectedFileBlob, setSelectedFileBlob] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [colSpan, setColSpan] = useState(8);
  const [costArr, setCostArr] = useState([]);
  const [costTotal, setCostTotal] = useState(0);

  // Constants
  const inviteId = location.state.inviteId;
  console.log("vendor location.state", location.state);
  //get initial data
  useEffect(() => {
    (async () => {
      if (location.state.inviteId) {
        let invite = await Responses.getFullInvite({ inviteId: inviteId });
        if (invite) {
          setGuestId(invite._id);
          console.log("Got guest id from invite id", invite);
        }
        //get soliticationId by inviteId
        let solicitationId = await Solicitations.getByInviteId({
          inviteId: location.state.inviteId,
        });
        if (solicitationId) {
          console.log(
            "Got solicitationId from InviteId, settings state",
            solicitationId
          );
          setId(solicitationId);
          const solicitation = await Solicitations.getById({
            id: solicitationId,
            fromVendor: true,
          });
          if (solicitation) {
            console.log("Got solicitation", solicitation);
            setSolicitation(solicitation);
            //initial tabs until NDA is selected
            let tabs = [];
            let orderedTabs = [];
            // orderedTabs.push({
            //   name: "Project Home",
            //   id: "home",
            //   active: false,
            // });
            orderedTabs.push({
              name: "Overview",
              id: "overview",
              active: false,
            });

            console.log("Setting initial tabs", orderedTabs);
            setTabs(orderedTabs);
            setSelectedTab(orderedTabs[0].name);

            if (solicitation && solicitation._id) {
              let quote = await Responses.getResponseByVendorId({
                solicitationId: solicitation._id,
                vendorId: invite._id,
              });
              if (quote) {
                console.log("Got quote from database", quote);
                setResponseId(quote._id);
                setResponse(quote.services);
                console.log("Setting FullQuote from quote", quote);
                setFullQuote(quote);
                console.log("FullQuote was set to quote", quote);
                if (quote.vendorCompanyId) {
                  console.log(
                    "Setting vendorCompanyId from quote",
                    quote.vendorCompanyId
                  );
                  setVendor(quote.vendor);
                } else {
                  console.log(
                    "Quote did not have vendorCompanyId, searching by domain"
                  );
                  //check to see if vendor has a company
                  let company = await _company.getCompanyByDomain(
                    location.state.email &&
                      location.state.email.split("@")[1].toLowerCase()
                  );
                  if (company) {
                    console.log(
                      "Got company by domain, setting vendor (fullQuote)",
                      company.company
                    );
                    setVendor(company.company);
                    console.log(
                      "Setting FullQuote ...fullQuote vendor",
                      fullQuote
                    );

                    quote.vendor = company.company;
                    quote.vendorCompanyId = company.company._id;
                    console.log("Setting FullQuote ...fullQuote vendor", quote);
                    setFullQuote(quote);

                    // setFullQuote({
                    //   ...fullQuote,
                    //   vendor: company.company,
                    // });
                    console.log("FullQuote was set to quote", quote);
                    // setFullQuote({
                    //   ...fullQuote,
                    //   vendorCompanyId: company.company._id,
                    // });
                  }
                }
                // setSaved(
                //   response
                //     ? response.status == "submitted"
                //       ? [...saved, "all"]
                //       : []
                //     : []
                // );
              } else {
                let res = {};
                //create a new key for each service
                // solicitation.services.forEach((service) => {
                //   console.log("Setting response", service.service);
                //   res[service.service] = {};
                // });

                setResponse(res);
                // setFullQuote({ services: res });
                let company = await _company.getCompanyByDomain(
                  location.state.email &&
                    location.state.email.split("@")[1].toLowerCase()
                );
                if (company) {
                  setVendor(company.company);
                  console.log("Setting fullquote no quote found", fullQuote);
                  setFullQuote({
                    ...fullQuote,
                    vendor: company.company,
                    vendorCompanyId: company.company._id,
                  });
                }
                setSaved(["Supplier Information"]);
                console.log("Setting response", res);
                console.log("Setting fullQuote", JSON.stringify(fullQuote));
              }
            }

            //get response by solicitationId
          } else {
            setSolicitation({});
          }
        }
      }
    })();
  }, [inviteId]);

  useEffect(() => {
    //move this to useMemo so it doesn't check after the first time
    let item = solicitation;
    console.log("useMemo item", item, fullQuote.accept_nda);
    let myTabs = [];
    let orderedTabs = [...tabs];
    if (
      (item.nda_required == false &&
        item.services &&
        item.services.length > 0) ||
      (item.nda_required == true &&
        fullQuote.accept_nda &&
        fullQuote.accept_nda == true &&
        item.services &&
        item.services.length > 0)
    ) {
      console.log("useMemo all checks passed", item, fullQuote);
      //if tabse are not aleady there, add them
      // if (!tabs.find((tab) => tab.name == "Supplier Information")) {
      //   orderedTabs.push({
      //     name: "Supplier Information",
      //     id: "supplier_information",
      //     active: true,
      //   });
      // }

      console.log("Added supplier to ordered Tabs", orderedTabs);

      item.services.forEach((service) => {
        if (
          !tabs.find((tab) => tab.name == service.service) &&
          service.service != "Supplier Information"
        ) {
          myTabs.push({
            name: service.service,
            id: service.id,
            active: false,
          });
        }
      });

      utils.orderTabs(myTabs, tabOrder, orderedTabs);
      if (!orderedTabs.find((tab) => tab.name == "Bid Summary")) {
        orderedTabs.push({
          name: "Bid Summary",
          id: "summary",
          active: false,
        });
      }

      console.log("Setting additional myTabs", orderedTabs);
      setTabs(orderedTabs);
      // setSelectedTab(orderedTabs[0].name);
    }
  }, [fullQuote.accept_nda, solicitation]);

  useEffect(() => {
    setColSpan(
      selectedTab !== "Bid Summary" && selectedTab !== "Supplier Information"
        ? 8
        : 12
    );
  }, [selectedTab]);
  // useEffect(() => {
  //   (async () => {
  //     let domain = location.state.email.split("@")[1];
  //     let vendor = await _company.getCompanyByDomain({ domain: domain });
  //     if (vendor) {
  //       console.log("Got vendor", vendor);
  //       setVendor(vendor);
  //     }
  //   })();
  // }, [location.state.email]);
  useEffect(() => {
    console.log("FULLQUOTE > Changed", fullQuote);
  }, [fullQuote]);

  useEffect(() => {
    //find the selected service in the solicitation based on selected tab
    if (tabs && tabs.length > 0) {
      let service = solicitation.services.find(
        (service) => service.service == selectedTab
      );
      if (service) {
        console.log("Setting selected service");
        setSelectedService(service);

        let quoteTabs = [];
        //this broke things when jurisidcitions not present
        // if (service !== "credit_monitoring") {
        //   console.log("Setting quote tabs", service.options[0].jurisdictions);
        //   service.options &&
        //     service.options[0].jurisdictions &&
        //     service.options[0].jurisdictions.forEach((jurisdiction) => {
        //       quoteTabs.push({
        //         name: cleanUpKeys(jurisdiction.key),
        //         id: jurisdiction.key,
        //         active: false,
        //       });
        //     });
        //   setResponseTabs(quoteTabs);
        // }
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    setSubmitted(
      response && response.status && response.status == "submitted" && true
    );
  }, [response]);

  useEffect(() => {
    (async () => {
      let isUser;
      let hasCompany;
      let hasTenants;
      if (submitted == true) {
        //see if this person is a user
        console.log(
          "TESTING --> Submitted is true, checking user",
          location.state.email
        );
        const user = await _user.getUserByEmail(location.state.email);
        if (user && user.length > 0) {
          console.log("TESTING --> User Found, setting isUser to true");
          isUser = true;
          // return;
        } else {
          console.log("TESTING --> User not found, setting isUser to false");
          isUser = false;
          // console.log(
          //   "Should we ask to create a user? Should we email the company admin and tell them? ",
          //   user
          // );
        }
        // if (user) {
        //   console.log("I DID NOT RETURN");
        // }
        //get company tenant count
        console.log("TESTING --> Getting company by id");
        const company = await _company.getCompanyById(
          // location.state.email && location.state.email.split("@")[1]
          fullQuote.vendorCompanyId
        );
        if (company) {
          console.log("TESTING --> COMPANY FOUND, setting hasCompany to true");
          hasCompany = true;

          if (
            company.company &&
            company.company.companyTenants &&
            company.company.companyTenants.length > 0
          ) {
            console.log(
              "TESTING --> Company has tenants, setting hasTenants to true"
            );
            hasTenants = true;
          } else {
            console.log(
              "TESTING --> Company does not have tenants, setting hasTenants to false"
            );
            hasTenants = false;
          }
        } else {
          console.log(
            "TESTING --> COMPANY NOT FOUND, setting hasCompany to false"
          );
          hasCompany = false;
        }

        if (isUser || hasTenants) {
          //for now we redirect and tell the customer to email their admin if they don't have an account
          //future state - tell user whether or not they have an account
          //future state - ask if we should email the admin on their behalf
          //further future state - sign the user up? how would this work?

          console.log(
            "TESTING --> isUser is",
            isUser,
            "hasTenants is",
            hasTenants
          );
          console.log(
            "TESTING --> User is ",
            user,
            " and company has ",
            company.company.companyTenants.length,
            " tenants"
          );
          console.log("TESTING --> Navigating to vendor options page");
          history.push({
            pathname: "/vendor-options",
            state: {
              info: vendor,
              email: location.state.email,
              company: company,
              user: user,
              isUser: true,
              hasCompany: true,
            },
          });
        } else {
          //navigate to signup page with company info and user info
          console.log(
            "TESTING --> isUser is",
            isUser,
            "hasTenants is",
            hasTenants
          );
          console.log("TESTING --> Navigating to signup page, info:", vendor);
          history.push({
            pathname: "/signup",
            state: {
              info: vendor,
              email: location.state.email,
            },
          });
        }
      }
    })();
  }, [submitted]);

  useEffect(() => {
    console.log("Loading state", loading);
  }, [loading]);

  //Set project state
  useEffect(() => {
    setProject(
      location.state && location.state.project ? location.state.project : {}
    );
  }, []);

  //Report selected tab to console on change
  useEffect(() => {
    console.log("Setting selected tab", selectedTab);
  }, [selectedTab]);

  //Add up the total of the response for all services
  useEffect(() => {
    let total = 0;
    if (response) {
      Object.keys(response).forEach((key) => {
        if (
          response[key].price &&
          response[key].price > 0 &&
          !isNaN(response[key].price)
        ) {
          total += +response[key].price;
        }
      });
    }
    setResponseTotal(total);
  }, [response]);

  const handleUpdateCost = (date, service, cost) => {
    console.log("Updating cost", date, service, cost);
    //convert "date" to date field
    let newDate = new Date(date);
    //convert cost to number with 2 decimal places
    let newCost = parseFloat(cost).toFixed(2);

    //if date and service already exist, update the cost

    let newCostArr = [...costArr];
    let newCostItem = {
      date: newDate,
      service: service,
      cost: newCost,
    };
    newCostArr.push(newCostItem);
    setCostArr(newCostArr);
  };

  const CostReportForm = () => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col justify-between">
          <div className="flex flex-col">
            <Label>Service</Label>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2"
            />
          </div>
          <div className="flex flex-col">
            <Label>Date</Label>
            <input
              type="date"
              value={new Date()}
              className="border border-gray-300 rounded-md p-2"
            />
          </div>
          {/* <div className="flex flex-col"> */}
          <div className="relative w-full col-span-full ">
            <label
              htmlFor="service_price"
              className="text-sm font-medium mb-3 capitalize"
            >
              Price for{" "}
              {cleanUpKeys(
                selectedTab && selectedTab !== undefined
                  ? selectedTab
                  : "Service"
              )}
            </label>
            <div className="pb-3 items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
              $
            </div>
            <input
              id="price"
              name="price"
              type="text"
              autoComplete="price"
              disabled={fullQuote && fullQuote.status == "submitted"}
              required
              className="block w-full min-w-full max-w-full px-10 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
              placeholder={`Enter your price for ${cleanUpKeys(
                selectedTab && selectedTab !== undefined ? selectedTab : ""
              )}`}
              value={
                response &&
                response[selectedTab] &&
                response[selectedTab]["price"]
                  ? response[selectedTab]["price"]
                  : ""
              }
              onChange={(e) => {
                handleFieldChange(e, selectedTab);
              }}
            />
          </div>
          {err && (
            <label
              className="w-full mt-1 space-y-0 text-sm text-red-600"
              id="email-error"
            >
              {errMessage}
            </label>
          )}
          {/* </div> */}
        </div>
        {/* <div className="flex flex-row justify-end">
          <Button
            label="Add"
            onClick={() => {
              console.log("Adding cost");
            }}
          />
        </div> */}
      </div>
    );
  };

  const handleAcceptNDA = () => {};

  const handleSetPdfModalOpen = (file) => {
    if (pdfModalOpen) {
      setPdfModalOpen(false);
      setSelectedFile(null);
      setSelectedFileName(null);
      setSelectedFileBlob(null);
      setLoadFile(false);
    } else {
      console.log("Setting selected file", file);
      setSelectedFile(file);
      getSelectedFile(file._id);
      setPdfModalOpen(true);
      setTimeout(() => {
        setLoadFile(true);
      }, 750);
    }
  };

  const getSelectedFile = async (id) => {
    if (id) {
      console.log("Getting file download link from server", id);
      const file = await Files.getFileById(
        id,
        "solicitation",
        solicitation._id
      );
      console.log("Got file download link from server", file);
      // let name = file.url.split("%5c");
      // name = name[name.length - 1];
      let name = file.name;
      setSelectedFileName(name);
      console.log("Setting selected fileName", name);
      setSelectedFileBlob(file.url);
      return file.url;
    }
  };

  const handleDownloadHTMLPdf = async () => {
    manageLoading("pdf", true);
    const elementHTML = printRef.current;
    var doc = new jsPDF();

    // Source HTMLElement or a string containing HTML.
    // var elementHTML = document.querySelector("#contentToPrint");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("document-html.pdf");
      },
      margin: [10, 10, 10, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 190, //target width in the PDF document
      windowWidth: 675, //window width in CSS pixels
    });

    manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };
  const handleDownloadPdf = async () => {
    manageLoading("pdf", true);
    //ORAN
    const root = parse(printRef.current);
    console.log("ROOT HTML PARSED", root);
    manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };

  // const handleDownloadPdf = async () => {
  //   manageLoading("pdf", true);
  //   // try {
  //   // debugger;
  //   const element = printRef.current;
  //   // debugger;
  //   const canvas = await html2canvas(element);
  //   // debugger;
  //   const imgWidth = 208;
  //   const pageHeight = 295;
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;
  //   heightLeft -= pageHeight;
  //   const doc = new jsPDF("p", "mm");
  //   doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     doc.addPage();
  //     doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
  //     heightLeft -= pageHeight;
  //   }
  //   manageLoading("pdf", true);
  //   doc.save("Bid Response.pdf");
  // };

  const acceptNDA = async () => {
    setFullQuote({
      ...fullQuote,
      accept_nda: true,
    });
    // await handleSaveServiceClick(null, "update", newFullQuote);

    let responseId = await Responses.create({
      solicitationId: solicitation._id,
      projectId: solicitation.projectId,
      companyId: solicitation.companyId,
      tenantId: solicitation.tenantId,
      vendorId: guestId,
      accept_nda: true,
      vendorCompanyId: fullQuote && fullQuote.vendorCompanyId,
      inviteId: inviteId,
      services: response,
      submittedByEmail: location.state.email ? location.state.email : "",
      submittedByName: location.state.name ? location.state.name : "",
      submittedByCompany: vendor.companyName
        ? vendor.companyName
        : location.state.company,
      // submittedByCompany: location.state.company ? location.state.company : "",
    });
  };

  const updateResponse = async (data) => {
    console.log("Updating newCompany reponse data", fullQuote, data);

    const vendor = data;
    const vendorCompanyId = data._id;
    // const update = { vendor: vendor, vendorCompanyId: vendorCompanyId };
    let newFullQuote = fullQuote;
    newFullQuote.vendor = vendor;
    newFullQuote.vendorCompanyId = vendorCompanyId;
    setFullQuote(newFullQuote);
    let updated = await handleSaveServiceClick(null, "update", newFullQuote);
    if (updated) {
      // setFullQuote(data);
      console.log("Full quote updated", updated);
    }
    manageSaved("Supplier Information", true);
  };

  //Utility Functions
  const cleanUpKeys = (key) => {
    let newKey = key.replace(/([A-Z])/g, " $1");

    newKey = newKey.replace(/_/g, " ");
    //capitalize first letter of each word
    let finalKey = newKey.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

    // let finalKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
    console.log("FinalKey", finalKey);
    return finalKey;
  };

  const manageLoading = (item, bool) => {
    console.log("Manage loading", item, bool, "current loading state", loading);
    //if item is already in loading array and bool is true, do nothing
    if (loading.includes(item) && bool == true) {
      console.log(
        "Manage loading - already in loading array and bool is true",
        loading
      );
      setLoading(item);
    } else if (loading.includes(item) && bool == false) {
      //if item is already in loading array and bool is false, remove it
      let newLoading = loading.filter((i) => i !== item);
      console.log(
        "Manage loading - already in loading array and bool is false",
        loading,
        newLoading
      );
      setLoading(newLoading);
    } else if (!loading.includes(item) && bool == true) {
      //if item is not in loading array and bool is true, add it
      let newLoading = [...loading, item];
      console.log(
        "Manage loading - not in loading array and bool is true, adding",
        loading,
        newLoading
      );

      setLoading(newLoading);
    } else {
      console.log(
        "Manage loading - not in loading array and bool is false",
        loading
      );
      let newLoading = loading.filter((i) => i !== item);
      //if item is not in loading array and bool is false, do nothing
      setLoading(newLoading);
    }
  };

  const isLoading = (item) => {
    if (loading.includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  const manageSaved = (item, bool) => {
    console.log("Manage saved", item, bool, "current saved state", saved);
    //if item is already in loading array and bool is true, do nothing
    if (saved.includes(item) && bool == true) {
      console.log(
        "Manage loading - already in loading array and bool is true",
        saved
      );
      // setSaved(item);
    } else if (saved.includes(item) && bool == false) {
      //if item is already in loading array and bool is false, remove it
      let newSaved = saved.filter((i) => i !== item);
      console.log(
        "Manage loading - already in loading array and bool is false",
        saved,
        newSaved
      );
      setSaved(newSaved);
    } else if (!saved.includes(item) && bool == true) {
      //if item is not in loading array and bool is true, add it
      let newSaved = [...saved, item];
      console.log(
        "Manage loading - not in loading array and bool is true, adding",
        saved,
        newSaved
      );

      setSaved(newSaved);
    } else {
      console.log(
        "Manage loading - not in loading array and bool is false",
        saved
      );
      let newSaved = saved.filter((i) => i !== item);
      //if item is not in loading array and bool is false, do nothing
      setSaved(newSaved);
    }
  };

  const isSaved = (item) => {
    console.log("Is saved", item, saved);
    // if (saved.includes("all")) {
    //   return true;
    // }
    if (saved && saved.includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  //Navigation Functions
  const handleClickBack = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/competencies",
      state: {},
    });
  };

  //Click Handler Functions
  const handleCleanUpClick = async (e) => {
    e.preventDefault();
    console.log("Cleaning up keys");
    manageLoading("cleanUp", true);
    if (!fullQuote._id) {
      console.log("No fullQuote id");
      return;
    }

    const deleted = await Responses.delete({
      id: fullQuote._id,
      solicitationId: solicitation._id,
    });
    console.log("Waiting for delete response");
    if (deleted) {
      console.log("Deleted response", deleted);
      //refresh the page
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      manageLoading("cleanUp", false);
      console.log("Error deleting response");
    }
  };

  const handleSubmitBidClick = async (e) => {
    e.preventDefault();
    manageLoading("submit", true);
    // if (!fullQuote.vendorCompanyId) {
    //   setSubmitErr(true);
    //   setSubmitErrMessage("Please enter supplier information");
    //   console.log("No vendor company id");
    //   manageLoading("submit", false);
    //   return;
    // }

    //save response
    console.log("Submitting fullQuote", JSON.stringify(fullQuote));
    const saved = await handleSaveServiceClick(e, "submit");

    if (saved) {
      console.log("Vendor Response Saved Success", saved);
      console.log("Submitting vendor response", saved);

      //mark the response as complete and send an email to the buyer
      const sent = await handleMarkComplete(e, saved);

      if (sent) {
        console.log("Vendor Response Sent", sent);
        setTimeout(() => {
          manageSaved("all", true);
          let newFullQuote = fullQuote;
          newFullQuote.status = "submitted";
          newFullQuote._id = saved._id;
          setFullQuote(newFullQuote);
        }, 1500);
      } else {
        setTimeout(() => {
          manageLoading("submit", false);
        }, 1500);
        // setErr(true);
        // setErrMessage("Error sending response");
      }
    } else {
      console.log("Error saving response", saved);
      setTimeout(() => {
        manageLoading("submit", false);
      }, 5000);
      // setErr(true);
      // setErrMessage("Error saving response");
    }
  };
  const handleSaveServiceClick = async (e, service, update) => {
    e && e.preventDefault();
    service ? (service = service) : (service = "save");
    setErr(false);
    setErrMessage("");
    manageLoading(service, true);

    console.log("Saving response", response);
    let responseId = await Responses.create({
      solicitationId: solicitation._id,
      projectId: solicitation.projectId,
      vendorId: guestId,

      vendorCompanyId: fullQuote && fullQuote.vendorCompanyId,
      inviteId: inviteId,
      services: response,
      submittedByEmail: location.state.email ? location.state.email : "",
      submittedByName: location.state.name ? location.state.name : "",
      submittedByCompany: vendor.companyName
        ? vendor.companyName
        : location.state.company,
      // submittedByCompany: location.state.company ? location.state.company : "",
    });

    if (responseId) {
      console.log("Got responseId", responseId);
      responseId.services = response;
      //full solicitation
      setResponse(responseId.services);
      setResponseId(responseId._id);
      manageSaved(service, true);
      //COULD THIS BE THE PROBLEM?
      // setFullQuote(responseId);
      // console.log("Got responseId - loading", responseId);
      setTimeout(() => {
        manageLoading(service, false);
      }, 1500);
      console.log("Saved response, returning true");
      return responseId;
    } else {
      setTimeout(() => {
        manageLoading(service, false);
      }, 1500);
      setErr(true);
      setErrMessage("Error saving response");
      console.log("Error saving response, returning false");
      return false;
    }
  };
  const handleMarkComplete = async (e, saved) => {
    //mark complete and submit
    e.preventDefault();
    // setLoading(true);
    //save response

    let quote = await Responses.send({
      solicitationId: solicitation._id,
      createdBy: solicitation.createdBy,
      services: response,
      id: saved._id,
      projectId: solicitation.projectId,
      status: "submitted",
      submittedByEmail: location.state.email ? location.state.email : "",
      submittedByName: location.state.name ? location.state.name : "",
      // submittedByCompany: location.state.company ? location.state.company : "",
      submittedByCompany: vendor.companyName,
      vendor: vendor,
      // submittedByCompany: location.state.company ? location.state.company : "",
    });
    if (quote) {
      console.log("Got submitted response", quote); //full solicitation
      setTimeout(() => {
        // setLoading(false);
        //set fullquote status true

        setErr(false);
        setErrMessage("");
        console.log("Got submitted response, returning quote", quote);
        setSubmitted(true);
        setFullQuote(quote.data.data);

        return quote;
      }, 1500);
    } else {
      setTimeout(() => {
        // setLoading(false);
        setErr(true);
        setErrMessage("Error sending response");
        console.log("Error sending response, returning false");
        return false;
      }, 1000);
    }
  };

  const handleFieldChange = (e, service) => {
    setSuccess(false);
    manageSaved(service, false);
    setErr(false);
    setErrMessage("");
    const { name, value } = e.target;
    if (name === "price") {
      if (isNaN(value)) {
        setErr(true);
        setErrMessage("Must be all numbers");
      }
    }

    console.log("Setting response", { service, name, value });

    setResponse((prevState) => ({
      ...prevState,
      [service]: { ...prevState[service], [name]: value },
    }));
    //write to local storage so we can save progress
    //don't forget to get from local storage on load
  };

  return (
    <div className="overflow-hidden">
      {/* <header className="sticky top-0 z-20 bg-white border-b border-gray-200  ">
        <div className="px-4 py-2 sm:px-6 lg:px-8">
          <div className="flex justify-between h-12 -mb-px">
            <div className="flex flex-row justify-center content-center">
              <img width="" className="mr-3 " src={Logo} />

              <span className="ml-0 inline-flex items-center">
                <h1 className="text-xl font-medium text-slate-900">
                  Bid Response Form
                </h1>
              </span>
            </div>
          </div>
        </div>
      </header> */}
      <div
        style={{ height: "calc(100vh - 120px)" }}
        className="px-6 mt-6 mx-auto h-full "
      >
        {/* <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div className="mb-0 sm:mb-0 inline-flex "></div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Button
              loader={loading}
              audit={true}
              action="Download Action Plan"
              details={{ data: data }}
              color="secondary"
              handleClick={handleDownloadPdf}
            >
              {!loading && (
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-2"
                  aria-hidden="true"
                />
              )}
              Download PDF
            </Button>
          </div>
        </div> */}
        {/* card */}
        <div className="h-full mx-auto max-w-10xl px-5 py-4 bg-white shadow-lg rounded-sm border border-gray-200 relative ">
          <div className="flex flex-row lg:flex-row  justify-between ">
            {/* left side */}
            <div className="w-full  ">
              {/* header */}
              <div className="flex flex-col lg:flex-row justify-between items-center">
                {/* Welcome banner */}
                <div className="mb-0 lg:mb-0 inline-flex ">
                  {/* <h1 className="text-xl font-medium text-slate-900">
                    {cleanUpKeys(selectedTab ? selectedTab : "General")}
                  </h1> */}
                  <div className=" inline-flex items-center ">
                    {tabs && tabs.length > 0 && (
                      <div className="col-span-full space-y-2">
                        <Tabs
                          tabs={tabs && tabs.length > 0 ? tabs : []}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          cleanUpKeys={cleanUpKeys}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center  space-x-3">
                {/* <Button
                loader={isLoading("pdf")}
                color="secondary"
                handleClick={handleDownloadPdf}
              >
                {!loading && (
                  <ArrowDownTrayIcon
                    className="h-5 w-5 mr-2"
                    aria-hidden="true"
                  />
                )}
                Download PDF
              </Button> */}
                {fullQuote.status &&
                  fullQuote.status.toLowerCase() == "submitted" && (
                    <Button
                      color="red"
                      handleClick={handleCleanUpClick}
                      loader={isLoading("cleanUp")}
                      audit
                      action="Delete Bid"
                      details={fullQuote}
                    >
                      <Icon icon="ic:delete" className="mr-2" />
                      Delete Response
                    </Button>
                  )}
                {submitErr && (
                  <span className="inline-flex items-center text-red-500 font-medium">
                    {submitErrMessage}
                  </span>
                )}

                {fullQuote.requestorDecision &&
                  fullQuote.requestorDecision.toLowerCase() == "winner" && (
                    <span className="inline-flex items-center text-kelly-500 font-medium">
                      {" "}
                      <Icon
                        icon="ic:check"
                        className="mr-1 w-6 h-6 text-green-500"
                      />
                      Winner{" "}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div>
            <div className="my-3">
              <hr className="border-gray-200" />
            </div>

            <div className="h-full pb-6 grid grid-cols-12 gap-x-4 gap-y-8  divide-x divide-gray-200  ">
              <div
                style={{ maxHeight: "75vh", minHeight: "75vh" }}
                className={`pb-12 pr-6 col-span-${colSpan} overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
              >
                {" "}
                {solicitation &&
                  solicitation.services &&
                  solicitation.services.map((service) => {
                    if (
                      selectedTab === "credit_monitoring" &&
                      service.service == "credit_monitoring"
                    ) {
                      if (service.service == "credit_monitoring") {
                        return (
                          <div>
                            <Tip>
                              Credit monitoring is broken into groups. Each
                              group contains different set of requirements.
                            </Tip>

                            {service.opts2 &&
                              Object.entries(service.opts2).map(
                                ([group, entries], index) => {
                                  return (
                                    <div>
                                      <CreditMonitoringInfo
                                        create={false}
                                        newProject={solicitation}
                                        project={entries}
                                        service={"credit_monitoring"}
                                        onFieldChange={null}
                                        handleDeleteCohort={null}
                                        groupIndex={index}
                                        cohort={entries}
                                        handleDeselectedLanguage={null}
                                        handleSelectedLanguage={null}
                                        handleClearAllSelections={null}
                                      />
                                      <div className="w-full mt-12 mb-6 pr-6 border-t border-primary-300 " />
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        );
                      }
                    } else if (
                      selectedTab === "emailings" &&
                      service.service === "emailings"
                    ) {
                      return (
                        <div>
                          <div className="w-3/4">
                            <Label className="mb-2">Jurisdictions</Label>
                            <p className="text-slate-500 mb-3">
                              This section shows the total number of affected
                              individuals in each jurisdiction.
                            </p>
                            <JurisdictionInput
                              create={false}
                              scope="solicitation"
                              field={"jurisdiction"}
                              service={"emailings"}
                              newProject={solicitation}
                              project={solicitation}
                              onFieldChange={null}
                              selectedJurisdictions={
                                solicitation &&
                                solicitation.services[
                                  solicitation.services.findIndex(
                                    (el) => el.service === "emailings"
                                  )
                                ].options &&
                                solicitation.services[
                                  solicitation.services.findIndex(
                                    (el) => el.service === "emailings"
                                  )
                                ].options[0].jurisdictions
                                  ? solicitation.services[
                                      solicitation.services.findIndex(
                                        (el) => el.service === "emailings"
                                      )
                                    ].options[0].jurisdictions
                                  : []
                              }
                              setSelectedJurisdictions={null}
                            />
                          </div>
                          <EmailingInfo
                            create={false}
                            scope="solicitation"
                            service={"emailings"}
                            newProject={solicitation}
                            project={solicitation}
                            onFieldChange={null}
                            onSelectionChange={null}
                            languageOptions={null}
                            handleDeselectedLanguage={null}
                            handleSelectedLanguage={null}
                            handleClearAllSelections={null}
                          />
                        </div>
                      );
                    } else if (
                      selectedTab === "mailings" &&
                      service.service === "mailings"
                    ) {
                      return (
                        <div>
                          <div className="w-3/4">
                            <Label className="mb-2">Jurisdictions</Label>
                            <p className="text-slate-500 mb-3">
                              This section shows the total number of affected
                              individuals in each jurisdiction.
                            </p>
                            <JurisdictionInput
                              create={false}
                              scope="solicitation"
                              field={"jurisdiction"}
                              service={"mailings"}
                              newProject={solicitation}
                              project={solicitation}
                              onFieldChange={null}
                              selectedJurisdictions={
                                solicitation &&
                                solicitation.services[
                                  solicitation.services.findIndex(
                                    (el) => el.service === "mailings"
                                  )
                                ].options &&
                                solicitation.services[
                                  solicitation.services.findIndex(
                                    (el) => el.service === "mailings"
                                  )
                                ].options[0].jurisdictions
                                  ? solicitation.services[
                                      solicitation.services.findIndex(
                                        (el) => el.service === "mailings"
                                      )
                                    ].options[0].jurisdictions
                                  : []
                              }
                              setSelectedJurisdictions={null}
                            />
                          </div>
                          {/* <Chips values={solicitation.jurisdiction} /> */}
                          <div>
                            <MailingInfo
                              create={false}
                              scope="solicitation"
                              service={"mailings"}
                              newProject={solicitation}
                              project={solicitation}
                              onFieldChange={null}
                              onSelectionChange={null}
                              languageOptions={null}
                              handleDeselectedLanguage={null}
                              handleSelectedLanguage={null}
                              handleClearAllSelections={null}
                            />
                          </div>
                        </div>
                      );
                    } else if (
                      selectedTab === "call_center" &&
                      service.service === "call_center"
                    ) {
                      return (
                        <div>
                          <CallCenterInfo
                            create={false}
                            scope="solicitation"
                            service={"call_center"}
                            newProject={solicitation}
                            project={solicitation}
                            onFieldChange={null}
                            onSelectionChange={null}
                            languageOptions={null}
                            handleDeselectedLanguage={null}
                            handleSelectedLanguage={null}
                            handleClearAllSelections={null}
                          />
                        </div>
                      );
                    } else if (
                      selectedTab === "landing_page" &&
                      service.service === "landing_page"
                    ) {
                      return null;
                    }
                  })}
                {selectedTab === "Overview" && (
                  <div className="space-y-6">
                    <Welcome
                      name={location.state.name}
                      handleSetPdfModalOpen={handleSetPdfModalOpen}
                      solicitation={solicitation}
                      fullQuote={fullQuote}
                      handleCheckNDABox={() => {
                        acceptNDA();
                        // setFullQuote({
                        //   ...fullQuote,
                        //   accept_nda: !fullQuote.accept_nda,
                        // });
                      }}
                      ndaRequired={solicitation.nda_required ? true : false}
                    />

                    {/* <div className="text-slate-500">
               
                      Overview of the bid request will go here. This includes
                      due date and externally facing project name? Maybe we
                      should get rid of the welcome and put it here. This can
                      also include checkbox for use to indicate they have read
                      the NDA and agree to the terms. Agreeing would create the
                      initial response, check the box in the database, and
                      unlock all the tabs.
                    </div> */}
                  </div>
                )}
                {selectedTab === "Bid Summary" && (
                  <div ref={printRef}>
                    <SupplierInfo
                      responderName={location.state.name}
                      responderEmail={location.state.email}
                      responderCompany={location.state.company}
                      fullQuote={fullQuote}
                      updateResponse={updateResponse}
                      isSaved={isSaved}
                      manageSaved={manageSaved}
                      setSaved={setSaved}
                      vendor={vendor}
                    />
                    <div className="mt-20">
                      {" "}
                      <h3 className="text-base font-semibold leading-6 text-slate-900">
                        Mailing Services
                      </h3>
                    </div>
                    <MailingInfo
                      create={false}
                      scope="solicitation"
                      service={"mailings"}
                      newProject={solicitation}
                      project={solicitation}
                      onFieldChange={null}
                      onSelectionChange={null}
                      languageOptions={null}
                      handleDeselectedLanguage={null}
                      handleSelectedLanguage={null}
                      handleClearAllSelections={null}
                    />

                    {tabs.includes("call_center") && (
                      <CallCenterInfo
                        create={false}
                        scope="solicitation"
                        service={"call_center"}
                        newProject={solicitation}
                        project={solicitation}
                        onFieldChange={null}
                        onSelectionChange={null}
                        languageOptions={null}
                        handleDeselectedLanguage={null}
                        handleSelectedLanguage={null}
                        handleClearAllSelections={null}
                      />
                    )}
                  </div>
                )}
                {selectedTab === "Supplier Information" && (
                  <SupplierInfo
                    responderName={location.state.name}
                    responderEmail={location.state.email}
                    responderCompany={location.state.company}
                    fullQuote={fullQuote}
                    updateResponse={updateResponse}
                    isSaved={isSaved}
                    manageSaved={manageSaved}
                    setSaved={setSaved}
                    vendor={vendor}
                  />
                )}
              </div>

              {
                // selectedTab !== "Bid Summary" &&
                selectedTab == "Overview" && (
                  // selectedTab !== "Supplier Information" &&
                  <div className="h-full col-span-4 px-3  overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full ">
                    <div className="space-y-2 mb-4">
                      <div className="flex items-center justify-between col-span-full">
                        <div className="inline-flex ">
                          <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
                            Vendor Response
                          </h2>
                        </div>
                        <RightActionArea>
                          {/* <Button
                      rounded
                      size={"sm"}
                      color={"outline"}
                      onClick={null}
                      className="mr-2"
                      variant="secondary"
                      loader={isLoading("decline")}
                    >
                      <Icon
                        icon="teenyicons:folder-no-access-solid"
                        className="mr-2"
                      />{" "}
                      Decline{" "}
                      {cleanUpKeys(
                        selectedTab && selectedTab !== undefined
                          ? selectedTab
                          : "Service"
                      )}
                    </Button> */}
                        </RightActionArea>
                      </div>

                      <div className="col-span-full mt-0">
                        <Paragraph>
                          Enter today's project total below.
                        </Paragraph>
                        <CostReportForm />
                      </div>
                    </div>

                    <div
                      id={"responsePanel"}
                      className="flex flex-col col-span-full items-center  p-6 m-auto "
                    >
                      {/* <div className="relative w-full col-span-full ">
                    <label
                      htmlFor="service_price"
                      className="text-sm font-medium mb-3 capitalize"
                    >
                      Price for{" "}
                      {cleanUpKeys(
                        selectedTab && selectedTab !== undefined
                          ? selectedTab
                          : "Service"
                      )}
                    </label>
                    <div className="pb-3 items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
                      $
                    </div>
                    <input
                      id="price"
                      name="price"
                      type="text"
                      autoComplete="price"
                      disabled={fullQuote && fullQuote.status == "submitted"}
                      required
                      className="block w-full min-w-full max-w-full px-10 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                      placeholder={`Enter your price for ${cleanUpKeys(
                        selectedTab && selectedTab !== undefined
                          ? selectedTab
                          : ""
                      )}`}
                      value={
                        response &&
                        response[selectedTab] &&
                        response[selectedTab]["price"]
                          ? response[selectedTab]["price"]
                          : ""
                      }
                      onChange={(e) => {
                        handleFieldChange(e, selectedTab);
                      }}
                    />
                  </div>
                  {err && (
                    <label
                      className="w-full mt-1 space-y-0 text-sm text-red-600"
                      id="email-error"
                    >
                      {errMessage}
                    </label>
                  )} */}

                      {/* <div className="w-full col-span-full ">
                    <label htmlFor="email-address">Last Name</label>
                    <input
                      id="lastname"
                      name="lastname"
                      type="text"
                      autoComplete="lastname"
                      required
                      className="w-full px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                      placeholder="Enter your last name"
                    />
                  </div>
                  <div className="w-full col-span-full ">
                    <label htmlFor="company" className="sr-only">
                      Company
                    </label>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      autoComplete="company"
                      required
                      className="w-full px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                      placeholder="What company do you work for? (optional)"
                    />
                  </div>
                  <div className="w-full col-span-full ">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="w-full px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                      placeholder="Enter your email"
                    />
                  </div> */}

                      {/* <div className="w-full col-span-full ">
                        <label
                          htmlFor="additional_comments"
                          className="text-sm font-medium pb-6"
                        >
                          Quote for
                          {cleanUpKeys(
                            selectedTab && selectedTab !== undefined
                              ? selectedTab
                              : "Service"
                          )}
                        </label>
                        <textarea
                          //multi line

                          id="additional_comments"
                          name="additional_comments"
                          type="text"
                          autoComplete="additional_comments"
                          rows={8}
                          disabled={
                            fullQuote && fullQuote.status == "submitted"
                          }
                          value={
                            response &&
                            response[selectedTab] &&
                            response[selectedTab]["additional_comments"]
                              ? response[selectedTab]["additional_comments"]
                              : ""
                          }
                          // value={response[selectedTab]["additional_comments"] || ""} //{selectedTab}
                          className="max-w-full min-w-full w-full  px-5 py-3 placeholder-gray-500 focus:ring-brand-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                          placeholder="Enter your quote for this service here"
                          onChange={(e) => {
                            handleFieldChange(e, selectedTab);
                          }}
                        />
                      </div> */}
                      <div className="mt-6 w-2/3 rounded-md shadow sm:mt-3 sm:ml-3 sm:flex-shrink-0">
                        <Button
                          loader={isLoading(selectedTab)}
                          disabled={
                            fullQuote.status == "submitted" ||
                            err ||
                            isSaved(selectedTab)
                          }
                          // audit
                          // action={
                          //   "Save " + selectedTab && selectedTab !== undefined
                          //     ? selectedTab
                          //     : "Service"
                          // }
                          // details={fullQuote}
                          // disabled={
                          //   loading || err || saved || fullQuote.status == "submitted"
                          // }
                          handleClick={(e) => {
                            handleSaveServiceClick(e, selectedTab);
                          }}
                          type="submit"
                          color="secondary"
                          fullWidth
                          className="w-full"
                          // disabled={err || saved}
                          // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                        >
                          <Icon icon="mdi:content-save" className="mr-2" />
                          Save{" "}
                          {cleanUpKeys(
                            selectedTab && selectedTab !== undefined
                              ? selectedTab
                              : "Service"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          {/* Comments*/}
        </div>
      </div>
      <ModalBasic
        id="pdf-viewer"
        modalOpen={pdfModalOpen}
        setModalOpen={(e) => handleSetPdfModalOpen(e)}
        title={selectedFile ? selectedFile.name : null}
        size={"lg"}
        scrollable
        showClose={true}
        // saveButtonText={"Save"}
        // saveButton={false}
        // saveButtonClick={saveTemplate}
        // handleCancelClick={(e) => handleShowCompareModalClick(e)}
        // showFooter={true}
      >
        {pdfModalOpen &&
          (!loadFile ? (
            <Skeleton type="loader" />
          ) : (
            <PdfViewer
              file={selectedFile ? selectedFile : {}}
              filename={selectedFileName ? selectedFileName : "Test"}
              url={selectedFileBlob ? selectedFileBlob : ""}
              selectedFileName={selectedFileName ? selectedFileName : "Test"}
              // file={selectedFile ? selectedFile : {}}
            />
          ))}
      </ModalBasic>
    </div>
    //{" "}
  );
}

export default VendorProjectDetails;
