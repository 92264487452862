import { Fragment, useEffect, useMemo, useState } from "react";

import { Paragraph } from "../../../components/Typography/Paragraph";

import ReadOnlyAnswer from "./ReadOnlyAnswer";
import {
  Checkboxes,
  Dropdown,
  RadioGroupSingle,
  Section,
  SectionDivider,
  TextArea,
  TextInput,
} from "./DataEntry";

import { Jurisdictions } from "../../../modules/_jurisdiction_list";

import Tip from "../../../components/Tip";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
import TextEditorNew from "../../../components/TextEditorNew";
import TextEditor from "../../../components/TextEditor";
import { convertFromRaw } from "draft-js";

export default function ForensicsInfo({
  id,
  create,
  newProject,
  serviceDrawerOpen,
  onFieldChangeRTF,
  languageOptions,
  callCenterLocationOptions,
  project,
  onFieldChange,
  handleSelectedLanguage,
  handleSingleSelect,
  handleMultiSelect,
  handleMultiDeSelect,
  handleDeselectedLanguage,
  handleClearAllSelections,
  handleSelect,
  onSelectionChange,
  selectedJurisdictions,
  setSelectedJurisdictions,
  field,
  service,
  scope,
  isChecked,
}) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});
  const [opts2, setOpts2] = useState({});
  const [loading, setLoading] = useState(true);
  const [jurisidctions, setJurisdictionOptions] = useState([]);

  useEffect(() => {
    console.log(`UseEffect ${service} services`, newProject.services);
    const opts2 =
      newProject.services[
        newProject.services.findIndex((el) => el.service === service)
      ].opts2;
    if (opts2) {
      console.log(`UseEffect ${service} opts2`, opts2);
      setOpts2(opts2);
      setOptions(opts2);
    } else {
      console.log(console.log(`UseEffect ${service} opts2`, opts2));
    }
  }, [newProject]);

  // useEffect(() => {
  //   if (
  //     newProject &&
  //     newProject.services &&
  //     newProject.services[
  //       newProject.services.findIndex((el) => el.service === service)
  //     ].opts2
  //   ) {
  //     setOptions(
  //       newProject.services[
  //         newProject.services.findIndex((el) => el.service === service)
  //       ].opts2
  //     );
  //   } else {
  //     console.log("UNDEFINED");
  //   }
  // }, [newProject]);

  return (
    <div className="mt-8">
      <div className=" mt-6 grid grid-cols-6 gap-y-6 gap-x-4 ">
        {create && (
          <Tip colSpan={"full"} noMargin>
            As we learn more about the needs of our customers, we will create
            more detailed forms. Being as specific as you can in your requests
            will help us tailor the request forms to your needs.
          </Tip>
        )}

        <Section
          label="  General Information"
          description={`${
            create
              ? "Let's start by gathering information related to the project. "
              : ""
          }`}
          create={create}
        />

        <div className="sm:col-span-6">
          {!create &&
            serviceDrawerOpen &&
            (() => {
              // if (
              //   !opts2?.initial_information ||
              //   opts2.initial_information === undefined
              // )
              //   return null;
              console.log(
                `WYSIWYGViewer ${service} opts2.initial_information`,
                opts2.initial_information
              );
              let rtf = opts2.initial_information;
              if (!rtf?.entityMap) {
                rtf.entityMap = {};
              }
              // const contentState = convertFromRaw(rtf);
              return (
                <WYSIWYGViewer
                  editorState={rtf}
                  readOnly={true}
                  className="mt-4"
                />
              );
            })()}
          {create && (
            <Paragraph>
              Use the below field to provide any additional details about your
              project or bid request that you think might be helpful to the
              vendor.
            </Paragraph>
          )}
          {create && (
            <div className="mt-1">
              {/* <textarea
                id="additional_details"
                name="additional_details"
                rows={3}
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                onChange={(e) => {
                  onFieldChange(
                    "options",

                    e,

                    "additional_details",
                    service,

                    null
                  );
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              /> */}

              <TextEditor
                placeholder="Use this area to give your bidding requirements."
                handleFieldChange={(e, rawContent, content) => {
                  console.log(`handleFieldChange ${service}`, rawContent);

                  onFieldChange(
                    "options",

                    {
                      target: {
                        name: "initial_information",
                        value: rawContent,
                        type: "rtf",
                      },
                    },

                    "initial_information",
                    service,

                    null
                  );
                }}
                handleSaveToAdditionalComments={(e, content) => {
                  console.log("handleSaveToAdditionalComments", content);
                  // onFieldChange("description", {
                  //   target: {
                  //     name: "description",
                  //     value: content,
                  //   },
                  // });
                }}
                value={
                  newProject.services &&
                  newProject.services[
                    newProject.services.findIndex(
                      (el) => el.service === service
                    )
                  ].opts2
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2.initial_information
                    : ""
                }
              />
              {/* )} */}

              {/* <TextEditor
                id="initial_information"
                name="initial_information"
                value={
                  opts2 && opts2.additional_details
                    ? opts2.additional_details
                    : ""
                }
                handleFieldChange={(e, rawContent, content) => {
                  onFieldChange(
                    "options",
                    //if checked, true, else false
                    e,

                    "additional_details",
                    service,

                    null
                  );
                }}
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
